// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import { Helmet } from "react-helmet";
// import CSS
import "../styles/movies_series.css";
import "../styles/documentaries_page.css";

// import paragraphs
import * as int from "../../paragraphs/documentaries_page_paragraphs";
// Import components
import SwiperJsComp from "../comps/SwiperJsComp";
// import components
import ProvidingSubtitles from "../comps/ProvidingSubtitles";
import OurQuality from "../comps/home_page/OurQuality";
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";

//
import documentaires_hero from "../images/documentaries_page/documentaries_hero.webp";
import commentary_poster from "../images/documentaries_page/languages_poster.webp";
//import channels logos
import netflix_box from "../images/movies_series_page/netflix_box_icon.svg";
import appleplus_box from "../images/movies_series_page/apple_tv_icon.svg";
import national_geographic_box from "../images/documentaries_page/national_geographic_box.svg";
import disney_nature from "../images/documentaries_page/disney_nature_box.svg";
import discovery_box from "../images/documentaries_page/discovery_box.svg";
import history_box from "../images/documentaries_page/history_box.svg";
import bbc_earth_box from "../images/documentaries_page/bbc_earth_box.svg";
import animal_planet from "../images/documentaries_page/animal_planet_box.svg";
//
import national_geographic_logo from "../images/documentaries_page/National-Geographic-Logo.svg";
import history_logo from "../images/home_page/logos/History_Logo.svg";

// import imagesnp
import national_geographic_swiper from "../images/documentaries_page/national_geographic_swiper.webp";
import history_swiper from "../images/documentaries_page/history_swiper.webp";
import bbc_earth_swiper from "../images/documentaries_page/bbc_earth_swiper.webp";
import video_quality_poster from "../images/documentaries_page/video_quality_documentaires.webp";

function SportPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // create a list of items for swiper slides
  const list = [
    {
      height: "40px",
      logo: national_geographic_logo,
      image: national_geographic_swiper,
      description: int.nationalGeographicDescription[displayLanguage],
    },
    {
      height: "50px",
      logo: history_logo,
      image: history_swiper,
      description: int.historyDescription[displayLanguage],
    },
    {
      height: "50px",
      logo: bbc_earth_box,
      image: bbc_earth_swiper,
      description: int.bbcEarthDescription[displayLanguage],
    },
  ];
  //
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitleDocumentaries[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescription[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com" />
      </Helmet>
      <div className="movies_series_wrapper">
        <div className="section_header" id="section_header_docs">
          <h1>{int.discoverTheWonders[displayLanguage]}</h1>
          <div className="channels">
            <img src={national_geographic_box} alt="national_geographic_box" />
            <img src={disney_nature} alt="disney_nature" />
            <img src={animal_planet} alt="animal_planet" />
            <img src={discovery_box} alt="discovery_box" />
            <img src={history_box} alt="history_box" />
            <img src={bbc_earth_box} alt="bbc_earth_box" />
            <img src={appleplus_box} alt="appleplus_box" />
            <img src={netflix_box} alt="netflix_box" />
          </div>
        </div>
        <div className="main_image_wrapper">
          <img
            src={documentaires_hero}
            alt="documentaires_hero"
            className="main_image"
          />
        </div>

        <h1>{int.uncoverATreasure[displayLanguage]}</h1>
        <SwiperJsComp items={list} />
      </div>
      <ProvidingSubtitles
        header={int.streamingChannelsDescription[displayLanguage]}
        image={commentary_poster}
      />
      <OurQuality image={video_quality_poster} />
      <PricingCardsCombined />
    </>
  );
}

export default SportPage;
