// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import { Helmet } from "react-helmet";
// import CSS
import "../styles/movies_series.css";
import "../styles/kidz_page.css";

// import paragraphs
import * as int from "../../paragraphs/kidz_page_paragraphs.js";
// Import components
import SwiperJsComp from "../comps/SwiperJsComp";
// import components
import ProvidingSubtitles from "../comps/ProvidingSubtitles";
import OurQuality from "../comps/home_page/OurQuality";
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";

// import images

import hbo_max_icon from "../images/movies_series_page/hbo_max_icon.svg";
import disney_box_icon from "../images/movies_series_page/disney_box_icon.svg";
import netflix_box_icon from "../images/movies_series_page/netflix_box_icon.svg";
import prime_video_box_icon from "../images/movies_series_page/prime_video_box_icon.svg";
import crunchyroll_icon from "../images/kidz_page/crunchyroll_icon.svg";
import hulu_icon from "../images/kidz_page/hulu_icon.svg";
import paramountplus_icon from "../images/kidz_page/paramountplus_icon.svg";
//
import kidz_hero from "../images/kidz_page/kidz_hero.webp";
//
// import images
import poster from "../images/kidz_page/subtitles_poster_kidz.webp";
import ben10 from "../images/kidz_page/ben10_slide.webp";
import andi_mack from "../images/kidz_page/andi_mack.webp";
import spongbob_slide from "../images/kidz_page/spongbob_slide.webp";
import bluey from "../images/kidz_page/bluey_slide.webp";
import tom_jerry from "../images/kidz_page/tom_jerry_slide.webp";
import video_quality_poster from "../images/kidz_page/video_quality_kidz.webp";
// import icons
import nickelodeon from "../images/kidz_page/Nickelodeon_2009_logo.svg";
import cartoon_network from "../images/kidz_page/Cartoon_Network_2010_logo.svg";
import disney_channel from "../images/kidz_page/Disney Channel.svg";
import cbeebies from "../images/kidz_page/CBeebies.svg";
import boomerange from "../images/kidz_page/boomerange.svg";

//
function KidzAnimePage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // create a list of items for swiper slides
  const list = [
    {
      logo: disney_channel,
      height: "40px",
      image: andi_mack,
      description: int.disneyChannel[displayLanguage],
    },
    {
      logo: cartoon_network,
      height: "40px",
      image: ben10,
      description: int.cartoonNetworks[displayLanguage],
    },

    {
      logo: nickelodeon,
      height: "20px",
      image: spongbob_slide,
      description: int.nickelodeon[displayLanguage],
    },
    {
      logo: cbeebies,
      height: "50px",
      image: bluey,
      description: int.cbeebies[displayLanguage],
    },
    {
      logo: boomerange,
      height: "50px",
      image: tom_jerry,
      description: int.boomerang[displayLanguage],
    },
  ];
  //
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.kidsChannelsHelmet[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescription[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com/kidz" />
      </Helmet>
      <div className="movies_series_wrapper">
        <div className="section_header" id="section_header_kidz">
          <h1>{int.escapeIntoImaginary[displayLanguage]}</h1>
          <div className="channels">
            <img src={netflix_box_icon} alt="netflix_box_icon" />
            <img src={prime_video_box_icon} alt="prime_video_box_icon" />
            <img src={crunchyroll_icon} alt="crunchyroll_icon" />
            <img src={hbo_max_icon} alt="hbo_max_icon" />
            <img src={disney_box_icon} alt="apple_tv_box_icon" />
            <img src={hulu_icon} alt="hulu_icon" />
            <img src={paramountplus_icon} alt="paramountplus_icon" />
          </div>
        </div>
        <div className="main_image_wrapper">
          <img src={kidz_hero} alt="kidz_hero" className="main_image" />
        </div>

        <h1>{int.animatedTreasure[displayLanguage]}</h1>
        <SwiperJsComp items={list} />
      </div>
      <ProvidingSubtitles
        header={int.providingSubtitles[displayLanguage]}
        image={poster}
      />
      <OurQuality image={video_quality_poster} />
      <PricingCardsCombined />
    </>
  );
}

export default KidzAnimePage;
