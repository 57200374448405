import { useState, useEffect, useRef } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import paragraphs
import * as int from "../../../paragraphs/account_settings_paragraphs";

// import css
import "../../styles/account_settings.css";

// import images
import copy_icon from "../../images/copy_icon.svg";

// define static classes/ i define them here cause they are too
//  long and they make the code hard to read
const selecetedCSS =
  "account_settings_options_button account_settings_options_button_selected";
const unselectedCSS = "account_settings_options_button";

function AccountSettingsHeader({ selected, setSelected }) {
  const [username, setUsername] = useState("");
  const [id, setId] = useState("");

  const displayLanguage = useRecoilValue(displayLanguageAtom);

  // define a function that capitalizes strings
  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  // define a function to handle copy id to clipboard icon
  async function handleCopyId() {
    try {
      await navigator.clipboard.writeText(id);
    } catch (err) {
      return;
    }
  }

  // handle currently seleced option mechanism
  const handleSelected = (btn) => {
    setSelected(btn);
  };

  //
  useEffect(() => {
    // get user info from the localstorage
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    try {
      let user_name = userInfo["first_name"];
      user_name = user_name.split(" ")[0];
      user_name = capitalizeFirstLetter(user_name);
      setUsername(user_name);
    } catch {}

    // /get the user id
    setId(Number(userInfo["id"]) * 2313252);
  }, []);

  return (
    <>
      <div className="account_settings_header_wrapper">
        <strong>
          {int.welcomeBack[displayLanguage]}, {username}
        </strong>

        <div className="user_id_wrapper">
          <p>ID {id}</p>
          <img
            onClick={handleCopyId}
            className="copy_btn"
            src={copy_icon}
            alt="copy_icon"
          />{" "}
        </div>
      </div>
      <div className="account_settings_options_wrapper">
        <button
          key={1}
          className={selected == "subscriptions" ? selecetedCSS : unselectedCSS}
          onClick={() => {
            handleSelected("subscriptions");
          }}
        >
          {int.subscriptions[displayLanguage]}
        </button>
        <button
          key={2}
          className={selected == "vouchers" ? selecetedCSS : unselectedCSS}
          onClick={() => {
            handleSelected("vouchers");
          }}
        >
          {int.myVouchers[displayLanguage]}
        </button>
        <button
          key={3}
          className={selected == "tickets" ? selecetedCSS : unselectedCSS}
          onClick={() => {
            handleSelected("tickets");
          }}
        >
          {int.supportTickets[displayLanguage]}
        </button>
        <button
          key={4}
          className={selected == "settings" ? selecetedCSS : unselectedCSS}
          onClick={() => {
            handleSelected("settings");
          }}
        >
          {int.settings[displayLanguage]}
        </button>
      </div>
    </>
  );
}

export default AccountSettingsHeader;
