export const endlessChannels = {
  en: "Stream TV Channels, Movies & Series in One Place!",
  fr: "Diffusez des chaînes de TV, des films et des séries!",
  es: "¡Transmite canales de televisión, películas y series en un solo lugar!",
  de: "IPTV-Kanäle, Filme und Serien an einem Ort streamen!",
  it: "In streaming canali TV, film e serie in un unico posto!",
  tr: "Tek Bir Yerde TV Kanalları, Filmler ve Dizileri İzleyin!",
  ru: "Смотрите телеканалы, фильмы и сериалы в одном месте!",
  pl: "Oglądaj kanały telewizyjne, filmy i seriale w jednym miejscu!",
};

export const entertainmentElevation = {
  en: "Elevate Your Entertainment",
  fr: "Rehaussez Votre Divertissement",
  es: "Eleva Tu Entretenimiento",
  de: "Steigern Sie Ihre Unterhaltung",
  it: "Eleva il Tuo Intrattenimento",
  tr: "Eğlencenizi Yükseltin",
  ru: "Поднимите своё развлечение",
  pl: "Podnieś Swoją Rozrywkę",
};
export const subscriptionPlans = {
  en: "Plans start at €03.99/month. Subscribe now.",
  fr: "Les abonnements commencent à partir de 03,99 €/mois. Abonnez-vous maintenant.",
  es: "Los planes comienzan desde €03.99/mes. ¡Suscríbete ahora!",
  de: "Pläne starten ab €03,99/Monat. Jetzt abonnieren.",
  it: "I piani partono da €03,99/mese. Abbonati ora.",
  tr: "Planlar €03,99/aydan başlıyor. Şimdi abone olun.",
  ru: "Планы начинаются от 03,99 €/месяц. Подпишитесь сейчас.",
  pl: "Plany zaczynają się od 03,99 €/miesiąc. Zapisz się teraz.",
};
export const planSelection = {
  en: "Choose your plan",
  fr: "Choisissez votre plan",
  es: "Elige tu plan",
  de: "Wähle deinen Plan",
  it: "Scegli il tuo piano",
  tr: "Planınızı seçin",
  ru: "Выберите свой план",
  pl: "Wybierz swój plan",
};
export const freeTrial = {
  en: "Free trial available to new subscribers.",
  fr: "Essai gratuit disponible pour les nouveaux abonnés.",
  es: "Prueba gratuita disponible para nuevos suscriptores.",
  de: "Kostenlose Testphase für neue Abonnenten verfügbar.",
  it: "Prova gratuita disponibile per i nuovi abbonati.",
  tr: "Yeni abonelere özel ücretsiz deneme sürümü mevcut.",
  ru: "Бесплатная пробная версия доступна для новых подписчиков.",
  pl: "Darmowa wersja próbna dostępna dla nowych subskrybentów.",
};
export const termsApply = {
  en: "Terms apply.",
  fr: "Des conditions s'appliquent.",
  es: "Aplican términos.",
  de: "Es gelten Bedingungen.",
  it: "Si applicano termini.",
  tr: "Koşullar geçerlidir.",
  ru: "Действуют условия.",
  pl: "Obowiązują warunki.",
};
export const visitUs = {
  en: "Visit us",
  fr: "Visitez-nous",
  es: "Visítanos",
  de: "Besuch uns",
  it: "Visita noi",
  tr: "Bizi ziyaret",
  ru: "Посетите нас",
  pl: "Odwiedź nas",
};
export const andMore = {
  en: "and more...",
  fr: "et plus...",
  es: "y más...",
  de: "und mehr...",
  it: "e altro...",
  tr: "ve daha fazla...",
  ru: "и многое другое...",
  pl: "i wiele więcej...",
};
export const discoveryText = {
  en: "Discover Can’t-Miss Originals and Exclusive New Movies and Shows",
  fr: "Découvrez des Originaux à ne pas manquer et des Films et Émissions exclusifs inédits",
  es: "Descubre Originales que no puedes perderte y Nuevas Películas y Series Exclusivas",
  de: "Entdecke unverzichtbare Originale und exklusive neue Filme und Serien",
  it: "Scopri Originali da non perdere e Nuovi Film ed Emissioni Esclusive",
  tr: "Kaçırılmayacak Özgünleri ve Özel Yeni Filmler ve Dizileri Keşfedin",
  ru: "Откройте Оригиналы, которые нельзя пропустить, и Эксклюзивные Новые Фильмы и Шоу",
  pl: "Odkryj nie do przegapienia Oryginały oraz Wyłączne Nowe Filmy i Seriale",
};
export const weeklyContent = {
  en: "Every week. All year long. Only on Crystal+",
  fr: "Chaque semaine. Toute l'année. Uniquement sur Crystal+",
  es: "Cada semana. Todo el año. Solo en Crystal+",
  de: "Jede Woche. Das ganze Jahr über. Nur auf Crystal+",
  it: "Ogni settimana. Tutto l'anno. Solo su Crystal+",
  tr: "Her hafta. Tüm yıl boyunca. Sadece Crystal+'ta",
  ru: "Каждую неделю. Весь год. Только на Crystal+",
  pl: "Co tydzień. Przez cały rok. Tylko na Crystal+",
};
export const latestMovies = {
  en: "Latest Movies",
  fr: "Derniers Films",
  es: "Últimas Películas",
  de: "Neueste Filme",
  it: "Ultimi Film",
  tr: "En Son Filmler",
  ru: "Последние Фильмы",
  pl: "Najnowsze Filmy",
};
export const movieMagic = {
  en: "Get ready for movie magic! The latest blockbusters are here, offering thrilling adventures and unforgettable stories for everyone",
  fr: "Préparez-vous pour la magie du cinéma ! Les derniers blockbusters sont là, offrant des aventures palpitantes et des histoires inoubliables pour tous",
  es: "¡Prepárate para la magia del cine! Los últimos éxitos de taquilla están aquí, ofreciendo emocionantes aventuras e historias inolvidables para todos",
  de: "Bereit für das Filmzauber? Die neuesten Blockbuster sind da und bieten aufregende Abenteuer und unvergessliche Geschichten für jeden",
  it: "Preparati per la magia del cinema! I più recenti successi cinematografici sono qui, offrendo avventure emozionanti e storie indimenticabili per tutti",
  tr: "Film büyüsüne hazır olun! En yeni gişe rekortmenleri burada, herkes için heyecan dolu maceralar ve unutulmaz hikayeler sunuyor",
  ru: "Приготовьтесь к волшебству кино! Самые свежие блокбастеры уже здесь, предлагая захватывающие приключения и незабываемые истории для всех",
  pl: "Przygotuj się na magię kina! Ostatnie hity filmowe są tutaj, oferując pasjonujące przygody i niezapomniane historie dla każdego",
};
export const barbieStory = {
  en: "Barbie and Ken are having the time of their lives in the colorful and seemingly perfect world of Barbie Land.",
  fr: "Barbie et Ken passent des moments inoubliables dans le monde coloré et apparemment parfait du Pays de Barbie.",
  es: "Barbie y Ken están pasando el mejor momento de sus vidas en el colorido y aparentemente perfecto mundo de Barbie Land.",
  de: "Barbie und Ken erleben die schönste Zeit ihres Lebens in der farbenfrohen und scheinbar perfekten Welt von Barbie Land.",
  it: "Barbie e Ken stanno trascorrendo dei momenti indimenticabili nel colorato e apparentemente perfetto mondo di Barbie Land.",
  tr: "Barbie ve Ken, Barbie Ülkesi'nin renkli ve görünüşte mükemmel dünyasında hayatlarının en güzel zamanını yaşıyorlar.",
  ru: "Барби и Кен наслаждаются лучшим временем своей жизни в ярком и казалось бы идеальном мире Страны Барби.",
  pl: "Barbie i Ken spędzają najlepszy czas w swoim życiu w kolorowym i pozornie idealnym świecie Krainy Barbie.",
};
export const championsLeagueExperience = {
  en: "Experience the best of European football in the UEFA Champions League: intense matches, stunning goals, and unforgettable moments of passion and skill.",
  fr: "Vivez le meilleur du football européen en Ligue des champions de l'UEFA : des matches intenses, des buts époustouflants et des moments inoubliables de passion et de talent.",
  es: "Experimenta lo mejor del fútbol europeo en la Liga de Campeones de la UEFA: partidos intensos, goles impresionantes e inolvidables momentos de pasión y habilidad.",
  de: "Erleben Sie das Beste des europäischen Fußballs in der UEFA Champions League: intensive Spiele, atemberaubende Tore und unvergessliche Momente voller Leidenschaft und Können.",
  it: "Vivi il meglio del calcio europeo nella UEFA Champions League: partite intense, gol sorprendenti e momenti indimenticabili di passione e abilità.",
  tr: "En iyi Avrupa futbolunu UEFA Şampiyonlar Ligi'nde deneyimleyin: yoğun maçlar, çarpıcı goller ve unutulmaz tutku ve beceri dolu anlar.",
  ru: "Почувствуйте лучшее из европейского футбола в Лиге Чемпионов УЕФА: интенсивные матчи, потрясающие голы и незабываемые моменты страсти и мастерства.",
  pl: "Doświadcz najlepszego europejskiego futbolu w Lidze Mistrzów UEFA: intensywne mecze, oszałamiające gole i niezapomniane chwile pasji i umiejętności.",
};
export const theLastOfUsDes = {
  en: "Twenty years after a fungal outbreak ravages the planet, survivors Joel and Tess are tasked with a mission that could change everything.",
  fr: "Vingt ans après qu'une épidémie fongique ait ravagé la planète, les survivants Joel et Tess sont chargés d'une mission qui pourrait tout changer.",
  es: "Veinte años después de que un brote de hongos arrase el planeta, los sobrevivientes Joel y Tess tienen la tarea de llevar a cabo una misión que podría cambiarlo todo.",
  de: "Zwanzig Jahre nachdem ein Pilzausbruch den Planeten verwüstet hat, sind die Überlebenden Joel und Tess mit einer Mission beauftragt, die alles verändern könnte.",
  it: "Vent'anni dopo che un'epidemia fungina ha devastato il pianeta, i sopravvissuti Joel e Tess hanno il compito di portare a termine una missione che potrebbe cambiare tutto.",
  tr: "Bir mantar salgını gezegeni harap ettikten yirmi yıl sonra, hayatta kalan Joel ve Tess her şeyi değiştirebilecek bir görevle görevlendirilirler.",
  ru: "Через двадцать лет после того, как грибковое заражение опустошает планету, выжившие Джоэл и Тесс получают задание, которое может изменить всё.",
  pl: "Dwadzieścia lat po wybuchu grzybowej epidemii niszczącej planetę, ocalali Joel i Tess zostają powierzeni misją, która mogłaby wszystko zmienić.",
};
export const natGeoExploration = {
  en: "National Geographic: Where curiosity leads to extraordinary discoveries. Embark on a journey of awe-inspiring exploration as we uncover the hidden marvels of our planet.",
  fr: "National Geographic : Où la curiosité mène à des découvertes extraordinaires. Embarquez pour un voyage d'exploration époustouflant alors que nous dévoilons les merveilles cachées de notre planète.",
  es: "National Geographic: Donde la curiosidad conduce a descubrimientos extraordinarios. Embárcate en un viaje de exploración asombroso mientras descubrimos las maravillas ocultas de nuestro planeta.",
  de: "National Geographic: Wo Neugier zu außergewöhnlichen Entdeckungen führt. Machen Sie sich auf eine Reise der eindrucksvollen Erforschung, während wir die verborgenen Wunder unseres Planeten enthüllen.",
  it: "National Geographic: Dove la curiosità porta a scoperte straordinarie. Intraprendi un viaggio di esplorazione che lascia senza parole mentre scopriamo le meraviglie nascoste del nostro pianeta.",
  tr: "National Geographic: Merakın olağanüstü keşiflere yol açtığı yer. Gezegenimizin gizli harikalarını ortaya çıkarırken hayranlık uyandıran bir keşif yolculuğuna çıkın.",
  ru: "National Geographic: Где любопытство ведет к необыкновенным открытиям. Погрузитесь в удивительное путешествие исследований, раскрывая скрытые чудеса нашей планеты.",
  pl: "National Geographic: Gdzie ciekawość prowadzi do nadzwyczajnych odkryć. Wyrusz w podróż zachwycającego odkrywania, odkrywając ukryte cuda naszej planety.",
};
export const kidsChannels = {
  en: "Kids Channels",
  fr: "Chaînes pour enfants",
  es: "Canales infantiles",
  de: "Kinderkanäle",
  it: "Canali per bambini",
  tr: "Çocuk Kanalları",
  ru: "Детские каналы",
  pl: "Kanały dla dzieci",
};
export const kidsContent = {
  en: "Newest episodes of kids' shows and animations are here! Spark laughter and learning for your little ones with exciting adventures and heartwarming tales.",
  fr: "Les tout derniers épisodes des émissions et animations pour enfants sont là ! Faites rire et apprendre à vos petits avec des aventures palpitantes et des contes émouvants.",
  es: "¡Los episodios más nuevos de programas y animaciones para niños están aquí! Despierta risas y aprendizaje en tus pequeños con emocionantes aventuras e historias conmovedoras.",
  de: "Die neuesten Episoden von Kindersendungen und Animationen sind da! Wecken Sie Lachen und Lernen bei den Kleinen mit aufregenden Abenteuern und herzerwärmenden Geschichten.",
  it: "I più nuovi episodi di programmi e animazioni per bambini sono qui! Scatena risate e apprendimento per i tuoi piccoli con avventure emozionanti e racconti commoventi.",
  tr: "En yeni çocuk programları ve animasyon bölümleri burada! Küçükleriniz için heyecan dolu maceralar ve duygusal hikayelerle gülümsemeler ve öğrenme tetikleyin.",
  ru: "Самые новые эпизоды детских передач и анимаций уже здесь! Зажгите смех и обучение для ваших малышей захватывающими приключениями и трогательными историями.",
  pl: "Najnowsze odcinki programów dla dzieci i animacji są tutaj! Rozśmieszaj i ucz swoje pociechy ekscytującymi przygodami i wzruszającymi opowieściami.",
};
export const outlanderStory = {
  en: "Claire Beauchamp Randall, a nurse in World War II, mysteriously goes back in time to Scotland in 1743. There, she meets a dashing Highland warrior and gets drawn into an epic rebellion.",
  fr: "Claire Beauchamp Randall, une infirmière pendant la Seconde Guerre mondiale, retourne mystérieusement dans le temps en Écosse en 1743. Là-bas, elle rencontre un séduisant guerrier des Highlands et se retrouve impliquée dans une rébellion épique.",
  es: "Claire Beauchamp Randall, una enfermera en la Segunda Guerra Mundial, misteriosamente viaja en el tiempo a Escocia en 1743. Allí, conoce a un apuesto guerrero de las Tierras Altas y se ve envuelta en una rebelión épica.",
  de: "Claire Beauchamp Randall, eine Krankenschwester im Zweiten Weltkrieg, reist mysteriös zurück ins Schottland des Jahres 1743. Dort trifft sie einen charmanten Krieger der Highlands und wird in eine epische Rebellion hineingezogen.",
  it: "Claire Beauchamp Randall, un'infermiera durante la Seconda guerra mondiale, misteriosamente torna indietro nel tempo in Scozia nel 1743. Lì incontra un affascinante guerriero delle Highlands e si trova coinvolta in una ribellione epica.",
  tr: "Claire Beauchamp Randall, II. Dünya Savaşı sırasında bir hemşire, gizemli bir şekilde 1743 yılında İskoçya'ya geri döner. Orada çarpıcı bir Yüksek Toprak savaşçısıyla tanışır ve epik bir isyana sürüklenir.",
  ru: "Клэр Бошам Рэндолл, медсестра времен Второй мировой войны, таинственным образом переносится назад во времени в Шотландию 1743 года. Там она встречает смелого воина с высокогорной местности и попадает в эпическое восстание.",
  pl: "Claire Beauchamp Randall, pielęgniarka podczas II wojny światowej, tajemniczo przenosi się w czasie do Szkocji w roku 1743. Tam spotyka przystojnego wojownika z Wysokich Ziemi i zostaje wciągnięta w epickie powstanie.",
};
export const selectPlan = {
  en: "Select the Ideal Plan For Your Needs",
  fr: "Sélectionnez le plan idéal selon vos besoins",
  es: "Selecciona el plan ideal para tus necesidades",
  de: "Wählen Sie den idealen Plan für Ihre Bedürfnisse aus",
  it: "Seleziona il piano ideale per le tue esigenze",
  tr: "İhtiyaçlarınıza uygun ideal planı seçin",
  ru: "Выберите идеальный план для ваших потребностей",
  pl: "Wybierz idealny plan według swoich potrzeb",
};

export const oneYear = {
  en: "1 Year",
  fr: "1 An",
  es: "1 Año",
  de: "1 Jahr",
  it: "1 Anno",
  tr: "1 Yıl",
  ru: "1 Год",
  pl: "1 Rok",
};
export const threeMonths = {
  en: "3 Months",
  fr: "3 Mois",
  es: "3 Meses",
  de: "3 Monate",
  it: "3 Mesi",
  tr: "3 Ay",
  ru: "3 Месяца",
  pl: "3 Miesiące",
};

export const sixMonths = {
  en: "6 Months",
  fr: "6 Mois",
  es: "6 Meses",
  de: "6 Monate",
  it: "6 Mesi",
  tr: "6 Ay",
  ru: "6 Месяцев",
  pl: "6 Miesięcy",
};
export const oneMonth = {
  en: "1 Month",
  fr: "1 Mois",
  es: "1 Mes",
  de: "1 Monat",
  it: "1 Mese",
  tr: "1 Ay",
  ru: "1 Месяц",
  pl: "1 Miesiąc",
};

export const bestValue = {
  en: "BEST VALUE",
  fr: "MEILLEURE OFFRE",
  es: "MEJOR VALOR",
  de: "BESTES ANGEBOT",
  it: "MIGLIOR VALORE",
  tr: "EN İYİ DEĞER",
  ru: "ЛУЧШАЯ ЦЕНА",
  pl: "NAJLEPSZA WARTOŚĆ",
};
export const oneYearDesc = {
  en: "Experience limitless entry to premium channels, movies, series, and shows, all in a unified hub.",
  fr: "Profitez d'un accès illimité aux chaînes premium, aux films, aux séries et aux émissions, le tout dans un hub unifié.",
  es: "Experimenta la entrada ilimitada a canales premium, películas, series y programas, todo en un centro unificado.",
  de: "Erleben Sie Zugang zu Premium-Kanälen, Filmen, Serien und Shows, alles in einem vereinten Hub.",
  it: "Vivi un ingresso illimitato a canali premium, film, serie e spettacoli, tutto in un unico hub unificato.",
  tr: "Premium kanallara, filmlere, dizilere ve gösterilere sınırsız erişimin tadını çıkarın, hepsi birleşik bir merkezde.",
  ru: "Познайте доступ к премиальным каналам, фильмам, сериалам и шоу, все в едином хабе.",
  pl: "Doświadcz nieograniczonego dostępu do filmów i programów w jednym zintegrowanym miejscu.",
};
export const sixMonthsDesc = {
  en: "Explore six months of premium channels, movies, series, and shows, all in one hub.",
  fr: "Explorez six mois de chaînes premium, de films, de séries et d'émissions, le tout dans un seul hub.",
  es: "Explora seis meses de canales premium, películas, series y programas, todo en un solo centro.",
  de: "Erkunden Sie sechs Monate lang Premium-Kanäle, Filme, Serien und Shows, alles in einem Hub.",
  it: "Esplora sei mesi di canali premium, film, serie e spettacoli, tutto in un unico hub.",
  tr: "Altı ay boyunca premium kanalların, filmlerin, dizilerin ve gösterilerin tadını tek bir merkezde çıkarın.",
  ru: "Исследуйте шесть месяцев премиум-каналов, фильмов, сериалов и шоу, все в одном хабе.",
  pl: "Odkryj sześć miesięcy kanałów premium, filmów, seriali i programów, wszystko w jednym miejscu.",
};
export const threeMonthsDesc = {
  en: "Discover three months of premium channels, movies, series, and shows, all in one hub.",
  fr: "Découvrez trois mois de chaînes premium, de films, de séries et d'émissions, le tout dans un seul hub.",
  es: "Descubre tres meses de canales premium, películas, series y programas, todo en un solo centro.",
  de: "Entdecken Sie drei Monate lang Premium-Kanäle, Filme, Serien und Shows, alles in einem Hub.",
  it: "Scopri tre mesi di canali premium, film, serie e spettacoli, tutto in un unico hub.",
  tr: "Üç ay boyunca premium kanalların, filmlerin, dizilerin ve gösterilerin tadını tek bir merkezde çıkarın.",
  ru: "Откройте для себя три месяца премиальных каналов, фильмов, сериалов и шоу, все в одном хабе.",
  pl: "Odkryj trzy miesiące kanałów premium, filmów, seriali i programów, wszystko w jednym miejscu.",
};
export const oneMonthDesc = {
  en: "Enjoy one month of premium channels, movies, series, and shows, all in one hub.",
  fr: "Profitez d'un mois de chaînes premium, de films, de séries et d'émissions, le tout dans un seul hub.",
  es: "Disfruta de un mes de canales premium, películas, series y programas, todo en un solo centro.",
  de: "Genießen Sie einen Monat lang Premium-Kanäle, Filme, Serien und Shows, alles in einem Hub.",
  it: "Goditi un mese di canali premium, film, serie e spettacoli, tutto in un unico hub.",
  tr: "Premium kanalların, filmlerin, dizilerin ve gösterilerin tadını tek bir merkezde bir ay boyunca çıkarın.",
  ru: "Наслаждайтесь одним месяцем премиальных каналов, фильмов, сериалов и шоу, все в одном хабе.",
  pl: "Ciesz się jednym miesiącem kanałów premium, filmów, seriali i programów, wszystko w jednym miejscu.",
};
export const choosePlan = {
  en: "Choose this plan now",
  fr: "Choisissez ce plan maintenant",
  es: "Elige este plan ahora",
  de: "Wählen Sie diesen Plan jetzt",
  it: "Scegli questo piano ora",
  tr: "Bu planı şimdi seç",
  ru: "Выберите этот план сейчас",
  pl: "Wybierz teraz ten plan",
};
export const watchAnywhere = {
  en: "Watch Anytime, Anywhere on Your Favorite Device",
  fr: "Regardez à tout moment, n'importe où sur votre appareil préféré",
  es: "Mira en cualquier momento y en cualquier lugar en tu dispositivo favorito",
  de: "Schauen Sie jederzeit und überall auf Ihrem Lieblingsgerät",
  it: "Guarda quando vuoi, ovunque sul tuo dispositivo preferito",
  tr: "Her zaman ve her yerde favori cihazınızda izleyin",
  ru: "Смотрите в любое время, в любом месте на вашем любимом устройстве",
  pl: "Oglądaj o dowolnej porze i w dowolnym miejscu na ulubionym urządzeniu",
};
export const streamOnDevices = {
  en: "Stream on your mobile device, tablet, computer, game console and connected TV",
  fr: "Diffusez sur votre appareil mobile, tablette, ordinateur, console de jeu et téléviseur connecté",
  es: "Transmite en tu dispositivo móvil, tablet, computadora, consola de videojuegos y TV conectada",
  de: "Streamen Sie auf Ihrem Mobilgerät, Tablet, Computer, Spielkonsole und verbundenem Fernseher",
  it: "Guarda in streaming sul tuo dispositivo mobile, tablet, computer, console da gioco e TV connessa",
  tr: "Mobil cihazınızda, tabletinizde, bilgisayarınızda, oyun konsolunuzda ve bağlı TV'nizde yayın yapın",
  ru: "Стрим на вашем мобильном устройстве, планшете, компьютере, игровой приставке и подключенном телевизоре",
  pl: "Odtwarzaj na urządzeniu mobilnym, tablecie, komputerze, konsoli do gier i telewizorze podłączonym do sieci",
};
export const year = {
  en: "Year",
  fr: "An",
  es: "Año",
  de: "Jahr",
  it: "Anno",
  tr: "Yıl",
  ru: "Год",
  pl: "Rok",
};
export const month = {
  en: "month",
  fr: "mois",
  es: "mes",
  de: "monat",
  it: "mese",
  tr: "ay",
  ru: "mесяц",
  pl: "miesiąc",
};
// HELMET PARAGRAPHS
export const helmetTitle = {
  en: "Crystal+ Premium IPTV, Movies & Series",
  fr: "Crystal+ Premium IPTV, Films et Séries",
  es: "Crystal+ Premium IPTV, Películas y Series",
  de: "Crystal+ Premium IPTV, Filme & Serien",
  it: "Crystal+ Premium IPTV, Film e Serie",
  tr: "Crystal+ Premium IPTV, Filmler ve Diziler",
  ru: "Crystal+ Premium IPTV, Фильмы и Сериалы",
  pl: "Crystal+ Premium IPTV, Filmy i Seriale",
};

export const helmetDescription = {
  en: "Discover premium IPTV subscriptions at Crystal +. Enjoy a vast selection of live TV, sports, movies, and more in stunning HD and 4K quality. Stream on any device with our affordable plans. Elevate your entertainment today!",
  fr: "Découvrez les abonnements IPTV premium chez Crystal +. Profitez d'une vaste sélection de télévision en direct, de sports, de films et bien plus en qualité HD et 4K époustouflante. Streamez sur n'importe quel appareil avec nos offres abordables. Rehaussez votre divertissement dès aujourd'hui !",
  es: "Descubre las suscripciones premium de IPTV en Crystal +. Disfruta de una amplia selección de televisión en vivo, deportes, películas y más en calidad HD y 4K impresionante. Transmite en cualquier dispositivo con nuestros planes asequibles. ¡Eleva tu entretenimiento hoy!",
  de: "Entdecken Sie Premium-IPTV-Abonnements bei Crystal +. Genießen Sie eine große Auswahl an Live-TV, Sport, Filmen und mehr in atemberaubender HD- und 4K-Qualität. Streamen Sie auf jedem Gerät mit unseren erschwinglichen Tarifen. Heben Sie Ihr Entertainment heute auf ein neues Level!",
  it: "Scopri le sottoscrizioni IPTV premium su Crystal +. Goditi una vasta selezione di televisione in diretta, sport, film e molto altro in incredibile qualità HD e 4K. Stream su qualsiasi dispositivo con i nostri piani convenienti. Eleva il tuo intrattenimento oggi!",
  tr: "Crystal+'da premium IPTV aboneliklerini keşfedin. Muhteşem HD ve 4K kalitesinde canlı TV, spor, filmler ve daha fazlasının keyfini çıkarın. Uygun fiyatlı planlarımızla herhangi bir cihazda yayın yapın. Eğlencenizi bugün yükseltin!",
  ru: "Откройте для себя премиум-подписки на IPTV в Crystal +. Наслаждайтесь широким выбором прямого телевидения, спорта, фильмов и многого другого в потрясающем HD и 4K качестве. Смотрите на любом устройстве по доступным тарифам. Поднимите ваше развлечение сегодня!",
  pl: "Odkryj premium IPTV w Crystal +. Ciesz się ogromnym wyborem telewizji na żywo, sportu, filmów i wiele więcej w oszałamiającej jakości HD i 4K. Oglądaj na dowolnym urządzeniu dzięki naszym przystępnym planom. Podnieś swój poziom rozrywki już dziś!",
};
