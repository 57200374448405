import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { chosenSubscriptionAtom } from "../../../Atoms";
import ReactGA from "react-ga";

// Note that at this component we will trigger Google tags manager or Google analytics to track plan clicks
/* global gtag */
const PricingCardExecute = ({ periodCode }) => {
  const navigate = useNavigate();
  const setChosenSubsription = useSetRecoilState(chosenSubscriptionAtom);

  // Google ads conversion tracking trigger function
  function gtag_report_conversion({ url, conversion_id }) {
    // Ads Conversion Tracking
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: `AW-11308954239/${conversion_id}`,

      event_callback: callback,
    });

    // Google Analytics Track Custom Event
    ReactGA.event({
      category: "User Interaction",
      action: `Chose ${periodCode} Months Plan`,
      label: "choose plan",
    });
    return false;
  }

  useEffect(() => {
    setChosenSubsription(periodCode);
    navigate("/process-order");
    //
    // trigger google ads conversion tracking functions
    if (periodCode === 12) {
      gtag_report_conversion({ conversion_id: "dYyMCPvqi_gYEP_kw5Aq" });
    } else if (periodCode === 6) {
      gtag_report_conversion({ conversion_id: "pchnCIHii_gYEP_kw5Aq" });
    }
  }, [periodCode]);

  //
  return null;
};

export default PricingCardExecute;
