export const signup = {
  en: "Sign up",
  fr: "Inscrivez-vous",
  es: "Registrarse",
  de: "Registrieren",
  it: "Iscriviti",
  tr: "Kaydol",
  ru: "Зарегистрироваться",
  pl: "Zapisz się",
};
export const login = {
  en: "Login",
  fr: "Connexion",
  es: "Iniciar sesión",
  de: "Anmelden",
  it: "Accedi",
  tr: "Giriş yap",
  ru: "Войти",
  pl: "Zaloguj się",
};
export const help = {
  en: "Help",
  fr: "Aide",
  es: "Ayuda",
  de: "Hilfe",
  it: "Aiuto",
  tr: "Yardım",
  ru: "Помощь",
  pl: "Pomoc",
};

export const accountSettings = {
  en: "Account Settings",
  fr: "Paramètres du compte",
  es: "Configuración",
  de: "Kontoeinstellungen",
  it: "Impostazioni dell'account",
  tr: "Hesap Ayarları",
  ru: "Настройки аккаунта",
  pl: "Ustawienia konta",
};

export const logout = {
  en: "Log out",
  fr: "Déconnexion",
  es: "Cerrar sesión",
  de: "Abmelden",
  it: "Esci",
  tr: "Çıkış yap",
  ru: "Выйти",
  pl: "Wyloguj się",
};
