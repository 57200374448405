import { useState } from "react";
import {
  Button,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { grey } from "@mui/material/colors";

//
const columns = [
  { id: "id", label: "ID", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 100,
    format: (value) => {
      const date = new Date(value);
      // Format the date as a human-readable string
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      const humanReadableDate = date.toLocaleDateString("en-US", options);
      return humanReadableDate;
    },
  },

  {
    id: "email",
    label: "Email",
    minWidth: 100,
  },
  { id: "price", label: "Price", minWidth: 100 },
  {
    id: "plan",
    label: "Plan",
    minWidth: 100,
    align: "right",
  },
];

export default function AdminPanelOrders({
  setCurrentOrderId,
  setShowTask,
  rows,
  fetchOrderApi,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Actual return ___________________________________________
  return (
    <>
      <Button
        sx={{ my: 1 }}
        variant="outlined"
        size="small"
        endIcon={<RefreshIcon />}
        onClick={() => {
          fetchOrderApi();
        }}
      >
        Refresh
      </Button>

      {/* rows would initially be a bloean, the API request will replace it with a list  */}
      {rows && (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            sx={{ maxHeight: 440 }}
            size="small"
            aria-label="a dense table"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: grey[300],
                        color: "black",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setCurrentOrderId(row.id);
                          setShowTask(true);
                        }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {!rows && (
        <Stack direction="column" sx={{ alignItems: "center" }}>
          <CircularProgress />
        </Stack>
      )}
    </>
  );
}
