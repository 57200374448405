export const discoverTheWonders = {
  en: "Discover the wonders of documentaries and nature like never before.",
  fr: "Découvrez les merveilles des documentaires et de la nature comme jamais auparavant.",
  es: "Descubre las maravillas de los documentales y la naturaleza como nunca antes.",
  de: "Entdecken Sie die Wunder von Dokumentationen und Natur wie nie zuvor.",
  it: "Scopri le meraviglie dei documentari e della natura come mai prima d'ora.",
  tr: "Belgesellerin ve doğanın harikalarını hiç olmadığı gibi keşfedin.",
  ru: "Откройте для себя чудеса документальных фильмов и природы, как никогда прежде.",
  pl: "Odkryj cuda dokumentów i natury jak nigdy wcześniej.",
};
export const uncoverATreasure = {
  en: "Uncover a treasure trove of captivating documentaries, thoughtfully curated for your inquisitive mind!",
  fr: "Découvrez un trésor de documentaires captivants, soigneusement sélectionnés pour votre esprit curieux !",
  es: "Descubre un tesoro de documentales cautivadores, cuidadosamente seleccionados para tu mente inquisitiva.",
  de: "Entdecken Sie einen Schatz fesselnder Dokumentationen, sorgfältig für Ihren wissbegierigen Geist zusammengestellt!",
  it: "Scopri un tesoro di documentari avvincenti, attentamente curati per la tua mente curiosa!",
  tr: "Meraklı zihniniz için özenle seçilmiş, büyüleyici belgesellerin hazine dolu dünyasını keşfedin!",
  ru: "Откройте для себя кладовую завораживающих документальных фильмов, тщательно отобранных для вашего любознательного ума!",
  pl: "Odkryj skarbnicę fascynujących dokumentów, starannie wyselekcjonowanych dla Twojego dociekliwego umysłu!",
};
export const nationalGeographicDescription = {
  en: "National Geographic, a renowned platform, streams captivating content spanning nature, wildlife, science, exploration, and cultural documentaries, showcasing the wonders of our planet and beyond.",
  fr: "National Geographic, une plateforme renommée, diffuse un contenu captivant couvrant la nature, la faune, la science, l'exploration et les documentaires culturels, mettant en valeur les merveilles de notre planète et au-delà.",
  es: "National Geographic, una plataforma de renombre, transmite contenido cautivador que abarca documentales de naturaleza, vida salvaje, ciencia, exploración y cultura, mostrando las maravillas de nuestro planeta y más allá.",
  de: "National Geographic, eine renommierte Plattform, streamt fesselnde Inhalte, die Natur, Wildtiere, Wissenschaft, Exploration und kulturelle Dokumentationen umfassen und die Wunder unseres Planeten und darüber hinaus präsentieren.",
  it: "National Geographic, una piattaforma rinomata, trasmette contenuti avvincenti che spaziano dalla natura, alla fauna selvatica, alla scienza, all'esplorazione e ai documentari culturali, mostrando le meraviglie del nostro pianeta e oltre.",
  tr: "Ulusal Geographic, tanınmış bir platform olarak, doğa, vahşi yaşam, bilim, keşif ve kültürel belgeselleri kapsayan büyüleyici içerikleri yayınlamakta ve gezegenimiz ve ötesinin harikalarını sergilemektedir.",
  ru: "Национальная географическая ассоциация, известная платформа, транслирует завораживающий контент, охватывающий документальные фильмы о природе, дикой природе, науке, исследованиях и культуре, демонстрируя чудеса нашей планеты и за ее пределами.",
  pl: "National Geographic, renomowana platforma, udostępnia fascynujące treści obejmujące dokumenty przyrodnicze, dziką przyrodę, naukę, eksplorację i dokumenty kulturalne, prezentujące cuda naszej planety i poza nią.",
};
export const historyDescription = {
  en: "History Channel offers gripping content, delving into the depths of human history. From ancient civilizations to significant events, it enlightens viewers with documentaries and series that bring the past to life.",
  fr: "La chaîne Histoire propose un contenu captivant qui explore les profondeurs de l'histoire humaine. Des civilisations antiques aux événements marquants, elle éclaire les téléspectateurs avec des documentaires et des séries qui donnent vie au passé.",
  es: "History Channel ofrece un contenido cautivador que se adentra en las profundidades de la historia humana. Desde las civilizaciones antiguas hasta los eventos significativos, ilumina a los espectadores con documentales y series que dan vida al pasado.",
  de: "Der History Channel bietet fesselnden Inhalt, der in die Tiefen der Menschheitsgeschichte eintaucht. Von antiken Zivilisationen bis hin zu bedeutenden Ereignissen erhellt er die Zuschauer mit Dokumentationen und Serien, die die Vergangenheit zum Leben erwecken.",
  it: "History Channel offre un contenuto avvincente che approfondisce le profondità della storia umana. Dalle antiche civiltà agli eventi significativi, illumina gli spettatori con documentari e serie che danno vita al passato.",
  tr: "History Channel, insanlık tarihinin derinliklerine inen büyüleyici içerik sunar. Antik medeniyetlerden önemli olaylara kadar, izleyicilere geçmişi canlandıran belgeseller ve dizilerle bilgi verir.",
  ru: "History Channel предлагает увлекательный контент, проникающий в глубины истории человечества. От древних цивилизаций до значимых событий, он просвещает зрителей документальными фильмами и сериалами, оживляющими прошлое.",
  pl: "History Channel oferuje wciągające treści, zagłębiając się w głębiny historii ludzkości. Od starożytnych cywilizacji po istotne wydarzenia, oświeca widzów dokumentami i serialami, które przywracają przeszłość do życia.",
};
export const bbcEarthDescription = {
  en: "BBC Earth brings awe-inspiring content that unravels the beauty and diversity of our planet. With breathtaking documentaries, it takes viewers on unforgettable journeys to explore nature's wonders and the incredible creatures that inhabit our world.",
  fr: "BBC Earth propose un contenu époustouflant qui dévoile la beauté et la diversité de notre planète. Avec des documentaires à couper le souffle, elle emmène les téléspectateurs dans des voyages inoubliables pour explorer les merveilles de la nature et les incroyables créatures qui peuplent notre monde.",
  es: "BBC Earth ofrece un contenido impresionante que desvela la belleza y diversidad de nuestro planeta. Con documentales impresionantes, lleva a los espectadores en viajes inolvidables para explorar las maravillas de la naturaleza y las increíbles criaturas que habitan nuestro mundo.",
  de: "BBC Earth präsentiert atemberaubende Inhalte, die die Schönheit und Vielfalt unseres Planeten enthüllen. Mit atemberaubenden Dokumentationen entführt sie die Zuschauer auf unvergessliche Reisen, um die Wunder der Natur und die unglaublichen Kreaturen zu erkunden, die unsere Welt bewohnen.",
  it: "BBC Earth offre contenuti mozzafiato che svelano la bellezza e la diversità del nostro pianeta. Con documentari mozzafiato, porta gli spettatori in viaggi indimenticabili per esplorare le meraviglie della natura e le incredibili creature che abitano il nostro mondo.",
  tr: "BBC Earth, gezegenimizin güzelliğini ve çeşitliliğini ortaya çıkaran büyüleyici içerikler sunar. Soluksuz belgesellerle izleyicileri unutulmaz yolculuklara çıkararak doğanın harikalarını ve dünyamızı mesken tutan inanılmaz yaratıkları keşfetmeye götürür.",
  ru: "BBC Earth предлагает потрясающий контент, раскрывающий красоту и разнообразие нашей планеты. Без удивительных документальных фильмов она проводит зрителей незабываемые путешествия по чудесам природы и невероятным созданиям, населяющим наш мир.",
  pl: "BBC Earth dostarcza zapierające dech w piersiach treści, odkrywając piękno i różnorodność naszej planety. Za pomocą zachwycających dokumentów zabiera widzów w niezapomniane podróże, aby odkrywać cuda natury i niesamowite stworzenia zamieszkujące nasz świat.",
};

export const streamingChannelsDescription = {
  en: "Streaming channels in multiple languages.",
  fr: "Chaînes de streaming dans plusieurs langues.",
  es: "Canales de transmisión en varios idiomas.",
  de: "Streaming-Kanäle in mehreren Sprachen.",
  it: "Canali di streaming in diverse lingue.",
  tr: "Birden fazla dilde yayın yapan kanallar.",
  ru: "Потоковые каналы на нескольких языках.",
  pl: "Kanały streamingowe w różnych językach.",
};
// HELMET PARAGRAPHS
export const helmetTitleDocumentaries = {
  en: "Crystal+ Premium IPTV, Documentaries & Knowledge",
  fr: "Crystal+ Premium IPTV, Documentaires & Connaissances",
  es: "Crystal+ Premium IPTV, Documentales y Conocimiento",
  de: "Crystal+ Premium IPTV, Dokumentationen & Wissen",
  it: "Crystal+ Premium IPTV, Documentari & Conoscenza",
  tr: "Crystal+ Premium IPTV, Belgeseller ve Bilgi",
  ru: "Crystal+ Premium IPTV, Документальные фильмы и Знание",
  pl: "Crystal+ Premium IPTV, Dokumenty i Wiedza",
};

export const helmetDescription = {
  en: "Discover premium IPTV subscriptions at Crystal +. Enjoy a vast selection of live TV, sports, movies, and more in stunning HD and 4K quality. Stream on any device with our affordable plans. Elevate your entertainment today!",
  fr: "Découvrez les abonnements IPTV premium chez Crystal +. Profitez d'une vaste sélection de télévision en direct, de sports, de films et bien plus en qualité HD et 4K époustouflante. Streamez sur n'importe quel appareil avec nos offres abordables. Rehaussez votre divertissement dès aujourd'hui !",
  es: "Descubre las suscripciones premium de IPTV en Crystal +. Disfruta de una amplia selección de televisión en vivo, deportes, películas y más en calidad HD y 4K impresionante. Transmite en cualquier dispositivo con nuestros planes asequibles. ¡Eleva tu entretenimiento hoy!",
  de: "Entdecken Sie Premium-IPTV-Abonnements bei Crystal +. Genießen Sie eine große Auswahl an Live-TV, Sport, Filmen und mehr in atemberaubender HD- und 4K-Qualität. Streamen Sie auf jedem Gerät mit unseren erschwinglichen Tarifen. Heben Sie Ihr Entertainment heute auf ein neues Level!",
  it: "Scopri le sottoscrizioni IPTV premium su Crystal +. Goditi una vasta selezione di televisione in diretta, sport, film e molto altro in incredibile qualità HD e 4K. Stream su qualsiasi dispositivo con i nostri piani convenienti. Eleva il tuo intrattenimento oggi!",
  tr: "Crystal+'da premium IPTV aboneliklerini keşfedin. Muhteşem HD ve 4K kalitesinde canlı TV, spor, filmler ve daha fazlasının keyfini çıkarın. Uygun fiyatlı planlarımızla herhangi bir cihazda yayın yapın. Eğlencenizi bugün yükseltin!",
  ru: "Откройте для себя премиум-подписки на IPTV в Crystal +. Наслаждайтесь широким выбором прямого телевидения, спорта, фильмов и многого другого в потрясающем HD и 4K качестве. Смотрите на любом устройстве по доступным тарифам. Поднимите ваше развлечение сегодня!",
  pl: "Odkryj premium IPTV w Crystal +. Ciesz się ogromnym wyborem telewizji na żywo, sportu, filmów i wiele więcej w oszałamiającej jakości HD i 4K. Oglądaj na dowolnym urządzeniu dzięki naszym przystępnym planom. Podnieś swój poziom rozrywki już dziś!",
};
