import { Link } from "react-router-dom";
// import paragraphs
import * as int from "../../../paragraphs/welcome_bar_paragraphs";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import images
import whatsapp_logo from "../../images/WhatsApp_logo.svg";
import mailit_icon from "../../images/mailit.svg";
import {
  SUPPORT_EMAIL_ADDRESS,
  WHATSAPP_NUMBER,
} from "../../../variable_contacts.js";
//
function WelcomeBarAuth() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  return (
    <div className="welcome_bar_wrapper">
      <div id="hide_on_mobile">
        <p>{int.streamingShop[displayLanguage]}</p>
      </div>
      <Link to="/signup" className="falvaour_btn welcome_bar_chose_your_plan">
        {int.signUp[displayLanguage]}
      </Link>
      <div style={{ display: "inline-flex" }}>
        <a href={`https://wa.me/${WHATSAPP_NUMBER}`} target="_blank">
          <button className="whatsapp_btn">
            <img src={whatsapp_logo} alt="whatsapp_logo" />
            <div>
              <p className="contact_us">{int.contactUs[displayLanguage]}</p>
              <p className="whatsapp">WhatsApp</p>
            </div>
          </button>
        </a>
        <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} target="_blank">
          <button className="whatsapp_btn">
            <img src={mailit_icon} alt="mailit_icon" />
            <div>
              <p className="contact_us">{int.contactUs[displayLanguage]}</p>
              <p className="whatsapp">Email</p>
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}

export default WelcomeBarAuth;
