import axios from "axios";
import { useState, useEffect } from "react";
import AdminPanelOrders from "../admin_panel/AdminPanelOrders";
import AdminPanelProccessOrderDialog from "./AdminPanelProcessOrderDialog";

//
function createData(id, date, subscription_period, email, price, plan) {
  const plans = { 1: "1 Month", 3: "3 Months", 6: "6 Months", 12: "1 Year" };
  return {
    id,
    date,
    subscription_period,
    email,
    price: `€${price}`,
    plan: plans[plan],
  };
}
//
function AdminPanelOrdersScreen() {
  const [showTask, setShowTask] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(1);
  const [ordersList, setOrdersList] = useState(false);

  const fetchOrderApi = () => {
    setOrdersList(false);
    // Fetch token from localstorage
    const userInfo = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userInfo);
    const token = userInfoJson.access;
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/orders/orders_list/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const data = res.data;
        const rows = data.map((item) => {
          return createData(
            item.id,
            item.adding_date,
            item.subscription_period,
            item.email,
            item.price,
            item.subscription_period
          );
        });
        setOrdersList(rows);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchOrderApi();
  }, []);

  //
  return (
    <>
      <AdminPanelOrders
        setCurrentOrderId={setCurrentOrderId}
        setShowTask={setShowTask}
        rows={ordersList}
        fetchOrderApi={fetchOrderApi}
      />
      <AdminPanelProccessOrderDialog
        currentOrderId={currentOrderId}
        setShowTask={setShowTask}
        showTask={showTask}
        fetchOrderApi={fetchOrderApi}
      />
    </>
  );
}

export default AdminPanelOrdersScreen;
