import { useState } from "react";
import plus_icon from "./images/plus_icon.svg";
import minus_icon from "./images/minus_icon.svg";

function FaqQuestion(props) {
  const [collapse, setCollapse] = useState(false);
  const { question, answer } = props;
  //custom style

  //
  return (
    <div className="faq_question">
      <div
        className="question_header"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <small>{question}</small>
        <img
          src={plus_icon}
          alt="plus_icon"
          style={{ display: collapse ? "none" : "block" }}
        />
        <img
          src={minus_icon}
          alt="minus_icon"
          style={{ display: collapse ? "block" : "none" }}
        />
      </div>
      <div
        className="answer_wrapper"
        style={{ gridTemplateRows: collapse ? "1fr" : "0fr" }}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <div> {answer} </div>
      </div>
    </div>
  );
}

export default FaqQuestion;
