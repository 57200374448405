import { useState, useEffect } from "react";
import axios from "axios";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import components
import IPTVPreferencesHeader from "./IPTVPreferencesHeader";
import IPTVPreferencesSubscriptions from "./IPTVPreferencesSubscriptions";
import IPTVPreferencesBundleSelection from "./IPTVPreferencesBundleSelection";
import IPTVPreferencesEmailWhatsapp from "./IPTVPreferencesEmailWhatsapp";
// Import images

// import CSS
import "../../styles/account_settings.css";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";

function IPTVPreferences({ stepSetter }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper_entrance2 animation_wrapper"
  );

  // this is the selected state based on which the settings comps will be conditionally rendered
  // please note that the setter and the state have been passed down to the AccountSettingsHeader and its from there where we updtae the state
  const [selected, setSelected] = useState("subscriptions");

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="payment_detail_header">
        <h1>{int.iptvPreferences[displayLanguage]}</h1>
      </div>
      <IPTVPreferencesHeader setSelected={setSelected} selected={selected} />
      {selected == "subscriptions" && (
        <IPTVPreferencesSubscriptions setSelected={setSelected} />
      )}
      {/* {selected == "bundle_selection" && (
        <IPTVPreferencesBundleSelection setSelected={setSelected} />
      )} */}
      {selected == "email_whatsapp" && (
        <IPTVPreferencesEmailWhatsapp
          setSelected={setSelected}
          stepSetter={stepSetter}
        />
      )}
    </div>
  );
}

export default IPTVPreferences;
