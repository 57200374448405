export const pricing = {
  en: "Pricing",
  fr: "Tarification",
  es: "Precios",
  de: "Preise",
  it: "Prezzi",
  tr: "Fiyatlandırma",
  ru: "Ценообразование",
  pl: "Cennik",
};

export const channelsList = {
  en: "TV Channels List",
  fr: "Liste des chaînes ",
  es: "Lista de canales",
  de: "Fernsehsenderliste",
  it: "Elenco dei canali",
  tr: "Kanalları Listesi",
  ru: "Список телеканалов",
  pl: "Lista kanałów",
};
export const moviesAndSeriesList = {
  en: "Movies & Series List",
  fr: "Liste des films et séries",
  es: "Lista de películas y series",
  de: "Filme & Serien Liste",
  it: "Elenco di film e serie",
  tr: "Film ve Dizi Listesi",
  ru: "Список фильмов и сериалов",
  pl: "Lista filmów i seriali",
};
export const refundPolicy = {
  en: "Refund policy",
  fr: "Politique de remboursement",
  es: "Política de reembolso",
  de: "Rückerstattungsrichtlinie",
  it: "Politica di rimborso",
  tr: "İade politikası",
  ru: "Политика возврата",
  pl: "Polityka zwrotów",
};
export const howItWorks = {
  en: "How it works?",
  fr: "Comment ça marche ?",
  es: "¿Cómo funciona?",
  de: "Wie funktioniert es?",
  it: "Come funziona?",
  tr: "Nasıl çalışır?",
  ru: "Как это работает?",
  pl: "Jak to działa?",
};
