// import CSS
import "../styles/switch.css";
import "../styles/account_settings.css";

function Switch(props) {
  // destructure the state and the state setter from props
  const { actualState, actualStateSetter, flagSetter } = props;
  // handle click
  const hanleOnClick = () => {
    actualStateSetter(!actualState);
    flagSetter(Math.floor(Math.random() * (1000 - 0 + 1)) + 0);
  };

  return (
    <div className="switch_wrapper">
      <label
        className={`switch ${actualState ? "switch_off" : ""}`}
        onClick={hanleOnClick}
      >
        <span className="slider round"></span>
      </label>
      <div>
        <h3 className="switch_header">{props.header}</h3>
        <p>{props.description} </p>
      </div>
    </div>
  );
}

export default Switch;
