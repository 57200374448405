import PricingCard from "../../comps/pricing_cards/PricingCard";
import PricingCardUnique from "../../comps/pricing_cards/PricingCardUnique";
import "../../styles/pricing_card.css";

// import Recoil and atoms
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "./../../../Atoms";

// import paragraphs
import * as int from "../../../paragraphs/pricing_cards_paragraphs";

function PricingCardsCombined() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  return (
    <div
      style={{ padding: "40px 0 " }}
      id="pricing_cards"
      className="pricing_cards_wrapper"
    >
      <h1>{int.chooseYourPlan[displayLanguage]}</h1>
      <div className="pricing_cards_row">
        <PricingCard
          color="red"
          period={`1 ${int.month[displayLanguage]}`}
          price="15.00"
          periodCode={1}
          trackingTagCode={""}
        />
        <PricingCardUnique
          period={`3 ${int.months[displayLanguage]}`}
          price="25.00"
          periodCode={3}
        />
        <PricingCard
          color="green"
          period={`6 ${int.months[displayLanguage]}`}
          price="40.00"
          periodCode={6}
        />
        <PricingCard
          color="blue"
          period={`1 ${int.year[displayLanguage]}`}
          price="60.00"
          periodCode={12}
          popular
        />
      </div>
    </div>
  );
}

export default PricingCardsCombined;
