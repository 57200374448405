// recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import images
import netflix_logo from "../../images/landing_page/channels_logos/netflix_logo.svg";
import hbo_logo from "../../images/landing_page/channels_logos/hbo_max_logo.svg";
import disneyplus_logo from "../../images/landing_page/channels_logos/disney_plus_logo.svg";
import primevideo_logo from "../../images/landing_page/channels_logos/prime_video_logo.svg";
import appletv_logo from "../../images/landing_page/channels_logos/apple_tv_plus_logo.svg";
import iplayer_logo from "../../images/landing_page/channels_logos/iplayer_box_logo.svg";
import paramount_plus from "../../images/landing_page/channels_logos/paramount_plus_logo.svg";
// UK CHANNELS
import bbc_logo from "../../images/landing_page/channels_logos/bbc_logo.svg";
import sky_logo from "../../images/landing_page/channels_logos/sky_logo.svg";
import disneychannel_logo from "../../images/landing_page/channels_logos/disney_channel_logo.svg";
import cnn_logo from "../../images/landing_page/channels_logos/cnn_logo.svg";
import itv_logo from "../../images/landing_page/channels_logos/itv_logo.svg";
import dave_logo from "../../images/landing_page/channels_logos/dave_logo.svg";
import discovery_logo from "../../images/landing_page/channels_logos/discovery_logo.svg";
import nationalgeographic_logo from "../../images/landing_page/channels_logos/nat_geo_logo.svg";
import historychannel_logo from "../../images/landing_page/channels_logos/history_logo.svg";
import curiousity_stream from "../../images/landing_page/channels_logos/curiosity_stream_logo.svg";
// // FRANCE CHANNELS
import tf1_logo from "../../images/landing_page/channels_logos/tf1_logo.svg";
import canalplus_logo from "../../images/landing_page/channels_logos/canal_plus_logo.svg";
import beinsports_logo from "../../images/landing_page/channels_logos/bein_sports_logo.svg";
import m6_logo from "../../images/landing_page/channels_logos/m6_logo.svg";
import rmc_logo from "../../images/landing_page/channels_logos/rmc_sport_logo.svg";
import arte_logo from "../../images/landing_page/channels_logos/arte_logo.svg";
// // SPAIN CHANNELS
import telemundo_logo from "../../images/landing_page/channels_logos/telemundo_logo.svg";
import cnnespana_logo from "../../images/landing_page/channels_logos/cnn_espana.svg";
import antena3_logo from "../../images/landing_page/channels_logos/antena_logo.svg";
import movistar_logo from "../../images/landing_page/channels_logos/movistar_logo.svg";
import daznlaliga_logo from "../../images/landing_page/channels_logos/dazn_laliga_logo.svg";
import fox_logo from "../../images/landing_page/channels_logos/fox_logo.svg";
// // GERMANY CHANNELS
import zdf_logo from "../../images/landing_page/channels_logos/zdf_logo.svg";
import rtl_logo from "../../images/landing_page/channels_logos/rtl_logo.svg";
import dazn_logo from "../../images/landing_page/channels_logos/dazn_logo.svg";
import sport1_logo from "../../images/landing_page/channels_logos/sport1_logo.svg";
import wdr_logo from "../../images/landing_page/channels_logos/wdr_logo.svg";
// // ITALY CHANNELS
import rai_logo from "../../images/landing_page/channels_logos/rai_logo.svg";
import canale5_logo from "../../images/landing_page/channels_logos/canal_5_logo.svg";
import italia1_logo from "../../images/landing_page/channels_logos/italia1_logo.svg";
import dmax_logo from "../../images/landing_page/channels_logos/dmax_logo.svg";
import la7_logo from "../../images/landing_page/channels_logos/la7_logo.svg";
import cartoonito_logo from "../../images/landing_page/channels_logos/cartoonito_logo.svg";
// // TURKEY CHANNELS
import trt_logo from "../../images/landing_page/channels_logos/trt_logo.svg";
import kanald_logo from "../../images/landing_page/channels_logos/kanal_d_logo.svg";
import star_logo from "../../images/landing_page/channels_logos/star_logo.svg";

// // POLAND CHANNELS
import tvp_logo from "../../images/landing_page/channels_logos/tvp_logo.svg";
import polsat_logo from "../../images/landing_page/channels_logos/polsat_logo.svg";
import tvn_logo from "../../images/landing_page/channels_logos/tvn_logo.svg";
import elevensports_logo from "../../images/landing_page/channels_logos/eleven_sports_logo.svg";
import animalplanet_logo from "../../images/landing_page/channels_logos/animal_planet_logo.svg";

// // RUSSIA CHANNELS
import russia1_logo from "../../images/landing_page/channels_logos/russia_1_logo.svg";
import channelsone_logo from "../../images/landing_page/channels_logos/russia_channel_logo.svg";
import ren_logo from "../../images/landing_page/channels_logos/ren_logo.svg";
import matchtv_log from "../../images/landing_page/channels_logos/match_logo.svg";

function LandingPageHighlighedChannels() {
  const region = useRecoilValue(displayLanguageAtom);

  //
  const ukChannels = () => {
    if (region == "en") {
      return (
        <>
          <img src={bbc_logo} alt="BBC_logo" className="l_30" />
          <img src={sky_logo} alt="sky_logo" className="l_35" />
          <img
            src={disneychannel_logo}
            alt="disneychannel_logo"
            className="l_35"
          />
          <img src={cnn_logo} alt="cnn_logo" className="l_30" />
          <img src={itv_logo} alt="itv_logo" className="l_30" />
          <img src={dave_logo} alt="dave_logo" className="l_30" />
          <img src={discovery_logo} alt="discovery_logo" className="l_30" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_30"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="l_35"
          />
          <img
            src={curiousity_stream}
            alt="curiousity_stream"
            className="l_35"
          />
        </>
      );
    }
  };

  const franceChannels = () => {
    if (region == "fr") {
      return (
        <>
          <img src={tf1_logo} alt="tf1_logo" className="l_30" />
          <img src={canalplus_logo} alt="canalplus_logo" className="l_30" />
          <img src={beinsports_logo} alt="beinsports_logo" className="l_30" />
          <img src={m6_logo} alt="m6_logo" className="l_35" />
          <img src={rmc_logo} alt="rmc_logo" className="l_35" />
          <img src={arte_logo} alt="arte_logo" className="l_30" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_35"
          />
          <img src={discovery_logo} alt="discovery_logo" className="l_30" />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="l_35"
          />
        </>
      );
    }
  };

  const spainChannels = () => {
    if (region == "es") {
      return (
        <>
          <img src={telemundo_logo} alt="telemundo_logo" className="l_35" />
          <img src={cnnespana_logo} alt="cnnespana_logo" className="l_35" />
          <img src={antena3_logo} alt="antena3_logo" className="l_35" />
          <img src={movistar_logo} alt="movistar_logo" className="l_30" />
          <img src={daznlaliga_logo} alt="daznlaliga_logo" className="l_30" />
          <img src={fox_logo} alt="fox_logo" className="l_30" />
          <img src={discovery_logo} alt="discovery_logo" className="l_30" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_35"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="l_35"
          />
          <img
            src={curiousity_stream}
            alt="curiousity_stream"
            className="l_35"
          />
        </>
      );
    }
  };

  const germanyChannels = () => {
    if (region == "de") {
      return (
        <>
          <img src={zdf_logo} alt="zdf_logo" className="l_35" />
          <img src={rtl_logo} alt="rtl_logo" className="l_35" />
          <img src={sky_logo} alt="sky_logo" className="l_35" />
          <img src={dazn_logo} alt="dazn_logo" className="l_35" />
          <img src={sport1_logo} alt="sport1_logo" className="l_30" />
          <img src={wdr_logo} alt="wdr_logo" className="l_30" />
          <img src={arte_logo} alt="arte_logo" className="l_30" />
          <img src={discovery_logo} alt="discovery_logo" className="l_30" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_35"
          />
          <img
            src={historychannel_logo}
            alt="historychannel_logo"
            className="l_35"
          />
        </>
      );
    }
  };

  const italyChannels = () => {
    if (region == "it") {
      return (
        <>
          <img src={rai_logo} alt="rai_logo" />
          <img src={canale5_logo} alt="canale5_logo" />
          <img src={italia1_logo} alt="italia1_logo" />
          <img src={sky_logo} alt="sky_logo" />
          <img src={dmax_logo} alt="dmax_logo" />
          <img src={la7_logo} alt="la7_logo" />
          <img src={cartoonito_logo} alt="cartoonito_logo" />
          <img src={historychannel_logo} alt="historychannel_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img src={nationalgeographic_logo} alt="nationalgeographic_logo" />
        </>
      );
    }
  };

  const turkeyChannels = () => {
    if (region == "tr") {
      return (
        <>
          <img src={trt_logo} alt="trt_logo" />
          <img src={kanald_logo} alt="kanald_logo" />
          <img src={star_logo} alt="star_logo" />
          <img src={beinsports_logo} alt="beinsports_logo" />
          <img src={cnn_logo} alt="cn_logo" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_35"
          />
          <img src={historychannel_logo} alt="historychannel_logo" />
        </>
      );
    }
  };

  const polandChannels = () => {
    if (region == "pl") {
      return (
        <>
          <img src={tvp_logo} alt="tvp_logo" className="l_hc40" />
          <img src={polsat_logo} alt="polsat_logo" />
          <img src={tvn_logo} alt="tvn_logo" />
          <img src={elevensports_logo} alt="elevensports_logo" />
          <img src={canalplus_logo} alt="canalplus_logo" className="l_hc25" />
          <img src={disneychannel_logo} alt="disneychannel_logo" />
          <img
            src={animalplanet_logo}
            alt="animalplanet_logo"
            className="l_35"
          />
          <img src={discovery_logo} alt="discovery_logo" />
          <img
            src={nationalgeographic_logo}
            alt="nationalgeographic_logo"
            className="l_35"
          />
          <img src={historychannel_logo} alt="historychannel_logo" />
        </>
      );
    }
  };
  const russiaChannels = () => {
    if (region == "ru") {
      return (
        <>
          <img src={russia1_logo} alt="russia1_logo" />
          <img
            src={channelsone_logo}
            alt="channelsone_logo"
            className="l_hc30"
          />
          <img src={ren_logo} alt="ren_logo" />
          <img src={matchtv_log} alt="matchtv_log" />
          <img src={discovery_logo} alt="discovery_logo" />
          <img src={nationalgeographic_logo} alt="nationalgeographic_logo" />
          <img src={historychannel_logo} alt="historychannel_logo" />
        </>
      );
    }
  };

  //
  return (
    <div className="landing_highlighted_channels">
      {/* VOD PROVIDERS  */}
      <img src={netflix_logo} alt="netflix_logo" className="l_25" />
      <img src={hbo_logo} alt="hbo_max_logo" className="l_20" />
      <img src={disneyplus_logo} alt="disney+_logo" className="l_35" />
      <img
        src={primevideo_logo}
        alt="amazon_video_prime_logo"
        className="l_30"
      />
      <img src={appletv_logo} alt="apple_tv_plus" className="l_35" />
      <img src={iplayer_logo} alt="iplayer_logo" className="l_35" />
      <img src={paramount_plus} alt="paramount_plus" className="l_35" />
      {/* Channels based on the user's language  */}
      {ukChannels()} {franceChannels()} {spainChannels()} {germanyChannels()}
      {italyChannels()} {turkeyChannels()} {russiaChannels()} {polandChannels()}
    </div>
  );
}

export default LandingPageHighlighedChannels;
