export const crystalIPTVOverview = {
  en: "CRYSTAL IPTV is a prominent provider of Internet Protocol Television (IPTV) services, headquartered in Shanghai, China. Founded in 2016, the company has rapidly emerged as a significant player in the IPTV industry, serving a vast and diverse clientele primarily in Europe, the United States, and Canada.",
  fr: "CRYSTAL IPTV est un fournisseur de premier plan de services de télévision par protocole Internet (IPTV), dont le siège est à Shanghai, en Chine. Fondée en 2016, l'entreprise est rapidement devenue un acteur majeur de l'industrie de l'IPTV, desservant une clientèle vaste et diversifiée principalement en Europe, aux États-Unis et au Canada.",
  es: "CRYSTAL IPTV es un destacado proveedor de servicios de Televisión por Protocolo de Internet (IPTV), con sede en Shanghái, China. Fundada en 2016, la empresa ha emergido rápidamente como un jugador significativo en la industria de IPTV, atendiendo a una amplia y diversa clientela principalmente en Europa, Estados Unidos y Canadá.",
  de: "CRYSTAL IPTV ist ein bedeutender Anbieter von Internet-Protokoll-Fernsehdiensten (IPTV) mit Sitz in Shanghai, China. Gegründet im Jahr 2016 hat sich das Unternehmen schnell als wichtiger Akteur in der IPTV-Branche etabliert und bedient hauptsächlich eine breite und vielfältige Kundschaft in Europa, den Vereinigten Staaten und Kanada.",
  it: "CRYSTAL IPTV è un importante fornitore di servizi televisivi basati su Internet Protocol Television (IPTV), con sede a Shanghai, in Cina. Fondata nel 2016, l'azienda è emersa rapidamente come un attore significativo nell'industria dell'IPTV, servendo una vasta e diversificata clientela principalmente in Europa, negli Stati Uniti e in Canada.",
  tr: "CRYSTAL IPTV, merkezi Şangay, Çin'de bulunan İnternet Protokolü Televizyon (IPTV) hizmetlerinin önde gelen bir sağlayıcısıdır. 2016 yılında kurulan şirket, hızla IPTV sektöründe önemli bir oyuncu olarak ortaya çıkmış, genellikle Avrupa, Amerika Birleşik Devletleri ve Kanada'da geniş ve çeşitli bir müşteri kitlesine hizmet vermektedir.",
  ru: "CRYSTAL IPTV - это ведущий поставщик услуг интернет-телевидения (IPTV) с штаб-квартирой в Шанхае, Китай. Основанная в 2016 году компания быстро стала значительным игроком на рынке IPTV, обслуживая обширную и разнообразную клиентелю, в основном, в Европе, Соединенных Штатах и Канаде.",
  pl: "CRYSTAL IPTV to prominentny dostawca usług telewizji internetowej opartej na protokole internetowym (IPTV) z siedzibą w Szanghaju, w Chinach. Założona w 2016 roku firma szybko stała się znaczącym graczem na rynku IPTV, obsługując szeroką i zróżnicowaną klientelę głównie w Europie, Stanach Zjednoczonych i Kanadzie.",
};
export const cuttingEdgeTechnologyText = {
  en: "Cutting-Edge Technology for Superior Streaming",
  fr: "Technologie de pointe pour un streaming supérieur",
  es: "Tecnología de vanguardia para una transmisión superior",
  de: "Hochmoderne Technologie für erstklassiges Streaming",
  it: "Tecnologia all'avanguardia per uno streaming superiore",
  tr: "Üstün Akış İçin Son Teknoloji",
  ru: "Современные технологии для высококачественного стриминга",
  pl: "Najnowocześniejsza technologia dla doskonałego przesyłania strumieniowego",
};
export const cuttingEdgeDataCenterText = {
  en: `Central to CRYSTAL IPTV's success is its cutting-edge data center located in China. Equipped with state-of-the-art server technologies, the company ensures a seamless and uninterrupted viewing experience for its customers. A noteworthy technological aspect of their service is the utilization of H.265 video compression technology. H.265 is renowned for its ability to efficiently reduce video file sizes while maintaining exceptional image quality. This technology ensures high-resolution content delivery without excessive data consumption, guaranteeing a buffer-free streaming experience.`,
  fr: `Au cœur du succès de CRYSTAL IPTV se trouve son centre de données de pointe situé en Chine. Équipée des technologies de serveur de pointe, l'entreprise garantit une expérience de visionnage fluide et ininterrompue pour ses clients. Un aspect technologique remarquable de leur service est l'utilisation de la technologie de compression vidéo H.265. Le H.265 est renommé pour sa capacité à réduire efficacement la taille des fichiers vidéo tout en maintenant une qualité d'image exceptionnelle. Cette technologie assure la diffusion de contenu haute résolution sans consommation excessive de données, garantissant une expérience de streaming sans tampon.`,
  es: `El centro de datos de vanguardia de CRYSTAL IPTV ubicado en China es fundamental para su éxito. Equipada con tecnologías de servidor de última generación, la empresa garantiza una experiencia de visualización fluida e ininterrumpida para sus clientes. Un aspecto tecnológico destacado de su servicio es la utilización de la tecnología de compresión de video H.265. El H.265 es conocido por su capacidad para reducir eficientemente el tamaño de los archivos de video manteniendo una calidad de imagen excepcional. Esta tecnología asegura la entrega de contenido de alta resolución sin un consumo excesivo de datos, garantizando una experiencia de streaming sin problemas.`,
  de: `Ein zentraler Erfolgsfaktor von CRYSTAL IPTV ist sein hochmodernes Rechenzentrum in China. Ausgestattet mit modernster Servertechnologie gewährleistet das Unternehmen ein nahtloses und unterbrechungsfreies Seherlebnis für seine Kunden. Ein bemerkenswerter technologischer Aspekt ihres Dienstes ist die Verwendung der H.265-Videokompressionstechnologie. H.265 ist bekannt für seine Fähigkeit, Videodateigrößen effizient zu reduzieren, während eine außergewöhnliche Bildqualität beibehalten wird. Diese Technologie ermöglicht die Lieferung von Inhalten in hoher Auflösung ohne übermäßigen Datenverbrauch und garantiert ein pufferfreies Streaming-Erlebnis.`,
  it: `Al centro del successo di CRYSTAL IPTV si trova il suo centro dati all'avanguardia situato in Cina. Dotata delle più moderne tecnologie dei server, l'azienda garantisce un'esperienza di visione senza soluzione di continuità per i suoi clienti. Un aspetto tecnologico notevole del loro servizio è l'utilizzo della tecnologia di compressione video H.265. L'H.265 è noto per la sua capacità di ridurre efficacemente le dimensioni dei file video mantenendo un'eccezionale qualità delle immagini. Questa tecnologia assicura la consegna di contenuti ad alta risoluzione senza un consumo eccessivo di dati, garantendo un'esperienza di streaming senza interruzioni.`,
  tr: `CRYSTAL IPTV'nin başarısının merkezinde Çin'de bulunan son teknoloji veri merkezi bulunmaktadır. En son teknoloji sunucu teknolojileri ile donatılmış olan şirket, müşterilerine kesintisiz bir izleme deneyimi sağlar. Hizmetlerinin dikkate değer teknolojik yönlerinden biri H.265 video sıkıştırma teknolojisinin kullanılmasıdır. H.265, video dosya boyutlarını veri kalitesini üstün bir şekilde koruyarak etkili bir şekilde azaltma yeteneği ile ünlüdür. Bu teknoloji, aşırı veri tüketmeden yüksek çözünürlüklü içerik sunumunu sağlayarak tampon olmayan bir akış deneyimi garanti eder.`,
  ru: `Центральным элементом успеха CRYSTAL IPTV является современный центр обработки данных, расположенный в Китае. Оборудованный передовыми технологиями серверов, компания обеспечивает бесперебойное и непрерывное просмотр клиентами. Замечательной технологической особенностью их услуги является использование технологии сжатия видео H.265. H.265 известен своей способностью эффективно уменьшать размеры видеофайлов при сохранении исключительного качества изображения. Эта технология обеспечивает доставку контента высокого разрешения без избыточного потребления данных, гарантируя бесперебойное потоковое воспроизведение.`,
  pl: `Kluczowym elementem sukcesu CRYSTAL IPTV jest nowoczesne centrum przetwarzania danych zlokalizowane w Chinach. Wyposażone w najnowocześniejsze technologie serwerowe, firma zapewnia swoim klientom płynne i nieprzerwane doświadczenie z oglądania. Istotnym aspektem technologicznym ich usługi jest wykorzystanie technologii kompresji wideo H.265. H.265 jest znane z efektywnego zmniejszania rozmiarów plików wideo przy zachowaniu wyjątkowej jakości obrazu. Ta technologia zapewnia dostarczanie treści o wysokiej rozdzielczości bez nadmiernego zużycia danych, co gwarantuje bezproblemowe korzystanie ze strumieniowego przesyłania.`,
};
export const videoQualityOptions = {
  en: "Diverse Video Quality Options",
  fr: "Options de qualité vidéo diverses",
  es: "Opciones de calidad de video diversas",
  de: "Vielfältige Videoqualitätsoptionen",
  it: "Diverse opzioni di qualità video",
  tr: "Çeşitli Video Kalite Seçenekleri",
  ru: "Разнообразные варианты видеокачества",
  pl: "Różnorodne opcje jakości wideo",
};

export const videoQualityOptionsDescription = {
  en: `CRYSTAL IPTV prides itself on offering a comprehensive range of video quality options to cater to the varied preferences of its global audience. Customers can choose from Standard Definition (SD), High Definition (HD), Full High Definition (FHD), and the highest quality, Ultra High Definition (UHD/4K). This versatility ensures that viewers can enjoy their favorite content with unparalleled clarity and detail.`,
  fr: `CRYSTAL IPTV est fier de proposer une gamme complète d'options de qualité vidéo pour répondre aux préférences variées de son public mondial. Les clients peuvent choisir parmi la définition standard (SD), haute définition (HD), haute définition intégrale (FHD) et la meilleure qualité, Ultra haute définition (UHD/4K). Cette polyvalence garantit que les téléspectateurs peuvent profiter de leur contenu préféré avec une clarté et un détail inégalés.`,
  es: `CRYSTAL IPTV se enorgullece de ofrecer una amplia gama de opciones de calidad de video para satisfacer las diversas preferencias de su audiencia global. Los clientes pueden elegir entre Definición Estándar (SD), Alta Definición (HD), Alta Definición Completa (FHD) y la mejor calidad, Ultra Alta Definición (UHD/4K). Esta versatilidad asegura que los espectadores puedan disfrutar de su contenido favorito con una claridad y detalle incomparables.`,
  de: `CRYSTAL IPTV ist stolz darauf, eine umfassende Palette von Videoqualitätsoptionen anzubieten, um den unterschiedlichen Vorlieben seines globalen Publikums gerecht zu werden. Kunden können zwischen Standardauflösung (SD), High Definition (HD), Full High Definition (FHD) und der höchsten Qualität, Ultra High Definition (UHD/4K), wählen. Diese Vielseitigkeit garantiert, dass Zuschauer ihre Lieblingsinhalte mit unübertroffener Klarheit und Detailtreue genießen können.`,
  it: `CRYSTAL IPTV è orgogliosa di offrire una vasta gamma di opzioni di qualità video per soddisfare le diverse preferenze del suo pubblico globale. I clienti possono scegliere tra Definizione Standard (SD), Alta Definizione (HD), Alta Definizione Completa (FHD) e la massima qualità, Ultra Alta Definizione (UHD/4K). Questa versatilità garantisce che gli spettatori possano godersi i loro contenuti preferiti con una chiarezza e un dettaglio senza paragoni.`,
  tr: `CRYSTAL IPTV, küresel izleyici kitlesinin çeşitli tercihlerini karşılamak için kapsamlı bir video kalitesi seçeneği sunmaktan gurur duyar. Müşteriler, Standart Tanımlama (SD), Yüksek Tanımlama (HD), Tam Yüksek Tanımlama (FHD) ve en yüksek kalite olan Ultra Yüksek Tanımlama (UHD/4K) arasından seçim yapabilirler. Bu çok yönlülük, izleyicilerin favori içeriklerini eşsiz netlik ve detayla keyifle izlemelerini sağlar.`,
  ru: `CRYSTAL IPTV гордится предлагать всесторонний выбор вариантов качества видео, чтобы удовлетворить разнообразные предпочтения своей глобальной аудитории. Клиенты могут выбирать между стандартным разрешением (SD), высоким разрешением (HD), полным высоким разрешением (FHD) и самым высоким качеством - Ультра высоким разрешением (UHD/4K). Эта универсальность гарантирует, что зрители могут наслаждаться своими любимыми контентом с непревзойденной четкостью и детализацией.`,
  pl: `CRYSTAL IPTV dumna jest z oferowania kompleksowej gamy opcji jakości wideo, aby sprostać zróżnicowanym preferencjom swojej globalnej publiczności. Klienci mogą wybierać spośród standardowej rozdzielczości (SD), wysokiej rozdzielczości (HD), pełnej wysokiej rozdzielczości (FHD) i najwyższej jakości, ultra wysokiej rozdzielczości (UHD/4K). Ta wszechstronność gwarantuje, że widzowie mogą cieszyć się ulubioną zawartością z niespotykaną klarownością i szczegółowością.`,
};
export const globalContentSelection = {
  en: "Global Content Selection",
  fr: "Sélection de contenu mondial",
  es: "Selección de contenido global",
  de: "Weltweite Inhaltsauswahl",
  it: "Selezione di contenuti globali",
  tr: "Küresel İçerik Seçimi",
  ru: "Глобальный выбор контента",
  pl: "Globalny wybór treści",
};
export const contentLibraryDescription = {
  en: `The content library of CRYSTAL IPTV is extensive and diverse. It encompasses a wide array of worldwide television channels, radio stations, as well as an extensive collection of movies and series. While the company emphasizes European channels and shows, its commitment to diversity is evident in its comprehensive selection, which appeals to viewers from all corners of the globe.`,
  fr: `La bibliothèque de contenu de CRYSTAL IPTV est vaste et diversifiée. Elle englobe une grande variété de chaînes de télévision du monde entier, de stations de radio, ainsi qu'une vaste collection de films et de séries. Bien que la société mette l'accent sur les chaînes et émissions européennes, son engagement envers la diversité est évident dans sa sélection complète, qui séduit les téléspectateurs du monde entier.`,
  es: `La biblioteca de contenido de CRYSTAL IPTV es amplia y diversa. Incluye una amplia variedad de canales de televisión de todo el mundo, estaciones de radio y una extensa colección de películas y series. Si bien la empresa enfatiza los canales y programas europeos, su compromiso con la diversidad es evidente en su selección completa, que atrae a espectadores de todos los rincones del mundo.`,
  de: `Die Inhaltsbibliothek von CRYSTAL IPTV ist umfangreich und vielfältig. Sie umfasst eine breite Palette weltweiter Fernsehsender, Radiosender sowie eine umfangreiche Sammlung von Filmen und Serien. Obwohl das Unternehmen den Schwerpunkt auf europäische Sender und Shows legt, zeigt sich sein Engagement für Vielfalt in seiner umfassenden Auswahl, die Zuschauer aus allen Teilen der Welt anspricht.`,
  it: `La libreria di contenuti di CRYSTAL IPTV è estesa e diversificata. Comprende una vasta gamma di canali televisivi di tutto il mondo, stazioni radio e una vasta collezione di film e serie. Sebbene l'azienda metta in evidenza canali e programmi europei, il suo impegno per la diversità è evidente nella sua selezione completa, che attrae spettatori da tutti gli angoli del mondo.`,
  tr: `CRYSTAL IPTV'nin içerik kütüphanesi geniş ve çeşitlidir. Dünya genelinde televizyon kanallarının, radyo istasyonlarının yanı sıra geniş bir film ve dizi koleksiyonunu içerir. Şirket, Avrupa kanallarına ve programlara vurgu yapsa da çeşitliliğe olan taahhüdü, dünya genelinden izleyicilere hitap eden kapsamlı seçiminde açıkça görülmektedir.`,
  ru: `Библиотека контента CRYSTAL IPTV обширна и разнообразна. Она охватывает широкий спектр телевизионных каналов по всему миру, радиостанций, а также обширную коллекцию фильмов и сериалов. Несмотря на акцент на европейских каналах и передачах, ее приверженность разнообразию проявляется в ее всеобъемлющем выборе, который привлекает зрителей со всех уголков мира.`,
  pl: `Biblioteka treści CRYSTAL IPTV jest obszerna i zróżnicowana. Obejmuje szeroki zakres światowych kanałów telewizyjnych, stacji radiowych oraz obszerną kolekcję filmów i seriali. Choć firma skupia się na kanałach i programach europejskich, jej zaangażowanie w różnorodność jest widoczne w jej wszechstronnej selekcji, która przyciąga widzów z całego świata.`,
};
export const contentPartnershipsText = {
  en: "Content Partnerships",
  fr: "Partenariats de contenu",
  es: "Alianzas de contenido",
  de: "Inhalts-Partnerschaften",
  it: "Partnership per i contenuti",
  tr: "İçerik İşbirlikleri",
  ru: "Партнерство в контенте",
  pl: "Partnerstwa treści",
};
export const contentPartnershipsDescription = {
  en: `CRYSTAL IPTV has cultivated strategic content partnerships with leading content providers, studios, and broadcasters. These partnerships enrich the platform with exclusive and premium content, enhancing the overall viewing experience.`,
  fr: `CRYSTAL IPTV a développé des partenariats stratégiques avec les principaux fournisseurs de contenu, studios et diffuseurs. Ces partenariats enrichissent la plateforme avec du contenu exclusif et premium, améliorant ainsi l'expérience de visionnage globale.`,
  es: `CRYSTAL IPTV ha cultivado alianzas estratégicas de contenido con los principales proveedores de contenido, estudios y emisoras. Estas alianzas enriquecen la plataforma con contenido exclusivo y premium, mejorando la experiencia de visualización en general.`,
  de: `CRYSTAL IPTV hat strategische Inhaltspartnerschaften mit führenden Inhaltspartnern, Studios und Sendeanstalten entwickelt. Diese Partnerschaften bereichern die Plattform mit exklusivem und Premium-Inhalt und verbessern insgesamt das Seherlebnis.`,
  it: `CRYSTAL IPTV ha coltivato partnership strategiche di contenuti con i principali fornitori di contenuti, studi e broadcaster. Queste partnership arricchiscono la piattaforma con contenuti esclusivi e premium, migliorando complessivamente l'esperienza di visione.`,
  tr: `CRYSTAL IPTV, önde gelen içerik sağlayıcıları, stüdyolar ve yayıncılarla stratejik içerik işbirlikleri geliştirmiştir. Bu işbirlikleri, platformu özel ve premium içeriklerle zenginleştirerek genel izleme deneyimini artırır.`,
  ru: `CRYSTAL IPTV выработала стратегические партнерства в области контента с ведущими поставщиками контента, студиями и вещателями. Эти партнерства обогащают платформу эксклюзивным и премиальным контентом, улучшая общее визуальное восприятие.`,
  pl: `CRYSTAL IPTV nawiązała strategiczne partnerstwa w zakresie treści z czołowymi dostawcami treści, studiami i nadawcami. Te partnerstwa wzbogacają platformę o ekskluzywne i premiowe treści, poprawiając ogólny komfort oglądania.`,
};
export const customerSupportText = {
  en: "Customer Support and Service",
  fr: "Service client et assistance",
  es: "Soporte al cliente y servicio",
  de: "Kundensupport und Service",
  it: "Assistenza clienti e servizio",
  tr: "Müşteri Destek ve Hizmeti",
  ru: "Поддержка и обслуживание клиентов",
  pl: "Obsługa klienta i serwis",
};
export const customerSupportDescription = {
  en: `CRYSTAL IPTV is dedicated to providing exceptional customer support. Their multi-channel support system ensures that customers receive timely assistance, contributing to high levels of customer satisfaction.`,
  fr: `CRYSTAL IPTV est dédié à fournir un support client exceptionnel. Leur système de support multi-canal garantit que les clients reçoivent une assistance en temps opportun, contribuant à un niveau élevé de satisfaction de la clientèle.`,
  es: `CRYSTAL IPTV se dedica a proporcionar un excepcional soporte al cliente. Su sistema de soporte multicanal asegura que los clientes reciban asistencia oportuna, contribuyendo a niveles elevados de satisfacción del cliente.`,
  de: `CRYSTAL IPTV widmet sich der Bereitstellung außergewöhnlichen Kundensupports. Ihr mehrkanaliges Supportsystem gewährleistet, dass Kunden zeitnah Unterstützung erhalten und somit zu hoher Kundenzufriedenheit beitragen.`,
  it: `CRYSTAL IPTV è dedicata a fornire un eccezionale supporto clienti. Il loro sistema di supporto multi-canale garantisce che i clienti ricevano assistenza tempestiva, contribuendo a elevati livelli di soddisfazione del cliente.`,
  tr: `CRYSTAL IPTV, istisnai müşteri desteği sağlamaya adanmıştır. Çok kanallı destek sistemi, müşterilerin zamanında yardım almasını sağlayarak yüksek düzeyde müşteri memnuniyetine katkı sağlar.`,
  ru: `CRYSTAL IPTV посвящена предоставлению исключительной поддержки клиентов. Их многочисленная система поддержки обеспечивает своевременное предоставление помощи клиентам и способствует высокому уровню удовлетворенности клиентов.`,
  pl: `CRYSTAL IPTV jest dedykowana zapewnianiu wyjątkowego wsparcia dla klientów. Ich wielokanałowy system wsparcia zapewnia, że klienci otrzymują szybką pomoc, co przyczynia się do wysokiego poziomu satysfakcji klientów.`,
};
export const securityMeasures = {
  en: "Security Measures",
  fr: "Mesures de sécurité",
  es: "Medidas de seguridad",
  de: "Sicherheitsmaßnahmen",
  it: "Misure di sicurezza",
  tr: "Güvenlik Tedbirleri",
  ru: "Меры безопасности",
  pl: "Środki bezpieczeństwa",
};
export const securityMeasuresDescription = {
  en: `The company places a strong emphasis on user data security, employing advanced security measures to protect user information and ensure secure streaming experiences.`,
  fr: `La société met fortement l'accent sur la sécurité des données des utilisateurs, en utilisant des mesures de sécurité avancées pour protéger les informations des utilisateurs et garantir des expériences de streaming sécurisées.`,
  es: `La empresa pone un fuerte énfasis en la seguridad de los datos de los usuarios, empleando medidas de seguridad avanzadas para proteger la información del usuario y garantizar experiencias de transmisión seguras.`,
  de: `Das Unternehmen legt großen Wert auf die Sicherheit der Benutzerdaten und setzt fortschrittliche Sicherheitsmaßnahmen ein, um Benutzerinformationen zu schützen und sichere Streaming-Erlebnisse zu gewährleisten.`,
  it: `L'azienda pone una forte enfasi sulla sicurezza dei dati degli utenti, utilizzando misure di sicurezza avanzate per proteggere le informazioni degli utenti e garantire esperienze di streaming sicure.`,
  tr: `Şirket, kullanıcı veri güvenliğine büyük önem verir ve kullanıcı bilgilerini korumak ve güvenli akış deneyimleri sağlamak için gelişmiş güvenlik önlemleri kullanır.`,
  ru: `Компания уделяет большое внимание безопасности данных пользователей, используя передовые меры безопасности для защиты информации пользователей и обеспечения безопасного потокового воспроизведения.`,
  pl: `Firma bardzo przykłada wagę do bezpieczeństwa danych użytkowników, stosując zaawansowane środki bezpieczeństwa w celu ochrony informacji użytkowników i zapewnienia bezpiecznych doświadczeń związanych z przesyłaniem strumieniowym.`,
};
export const futureExpansion = {
  en: "Future Expansion and Developments",
  fr: "Expansion future et développements",
  es: "Expansión futura y desarrollos",
  de: "Zukünftige Expansion und Entwicklungen",
  it: "Espansione futura e sviluppi",
  tr: "Gelecekteki Genişleme ve Gelişmeler",
  ru: "Перспективное расширение и развитие",
  pl: "Przyszły rozwój i rozwijanie",
};
export const futureExpansionDescription = {
  en: `The company is continually evolving, with plans for future developments and expansion to enhance the viewing experience for its global audience.`,
  fr: `La société évolue constamment, avec des projets de développements futurs et d'expansion visant à améliorer l'expérience de visionnage pour son public mondial.`,
  es: `La empresa está en constante evolución, con planes de futuros desarrollos y expansión para mejorar la experiencia de visualización de su audiencia global.`,
  de: `Das Unternehmen entwickelt sich kontinuierlich weiter und plant zukünftige Entwicklungen und Expansionen, um das Seherlebnis für sein globales Publikum zu verbessern.`,
  it: `L'azienda è in continua evoluzione, con piani per sviluppi futuri ed espansione al fine di migliorare l'esperienza di visione per il suo pubblico globale.`,
  tr: `Şirket sürekli olarak gelişiyor ve küresel izleyici kitlesi için görüntüleme deneyimini artırmak amacıyla gelecekteki gelişme ve genişleme planlarına sahiptir.`,
  ru: `Компания постоянно развивается и имеет планы по будущим разработкам и расширению, чтобы улучшить опыт просмотра для своей глобальной аудитории.`,
  pl: `Firma stale ewoluuje, mając plany na przyszłe rozwijanie się i ekspansję, aby poprawić doświadczenie oglądania dla swojej globalnej publiczności.`,
};
export const serviceText = {
  en: "Service",
  fr: "Service",
  es: "Servicio",
  de: "Dienstleistung",
  it: "Servizio",
  tr: "Hizmet",
  ru: "Услуга",
  pl: "Usługa",
};
