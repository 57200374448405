// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
// import CSS
import "../styles/movies_series.css";

// import paragraphs
import * as int from "../../paragraphs/movies_series_page_paragraphs.js";
// Import components
import SwiperJsComp from "../comps/SwiperJsComp";
// import components
import ProvidingSubtitles from "../comps/ProvidingSubtitles";
import OurQuality from "../comps/home_page/OurQuality";
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";

// import images
import apple_tv_box_icon from "../images/movies_series_page/apple_tv_icon.svg";
import disney_box_icon from "../images/movies_series_page/disney_box_icon.svg";
import hbo_max_icon from "../images/movies_series_page/hbo_max_icon.svg";
import iplayer_box_icon from "../images/movies_series_page/iplayer_box_icon.svg";
import netflix_box_icon from "../images/movies_series_page/netflix_box_icon.svg";
import prime_video_box_icon from "../images/movies_series_page/prime_video_box_icon.svg";
import showtime_box_icon from "../images/movies_series_page/showtime_box_icon.svg";
import sky_box_icon from "../images/movies_series_page/sky_box_icon.svg";
//
import movies_series_hero from "../images/movies_series_page/movies_series_hero.webp";
//import channels logos
import sky_Atlantic_2020 from "../images/movies_series_page/Sky_Atlantic_2020.svg";
import film4_2018 from "../images/movies_series_page/Film4_2018.svg";
import TNT_2016 from "../images/movies_series_page/TNT_2016.svg";
import Fox_Movies from "../images/movies_series_page/Fox_Movies.svg";

// import images
import poster from "../images/movies_series_page/subtitles_poster_movies_series.webp";
import sky_atlantic from "../images/movies_series_page/sky_atlantic.webp";
import film4 from "../images/movies_series_page/swiper_slide2.webp";
import creed3 from "../images/movies_series_page/swiper_slide3.webp";
import stars_on_mars from "../images/movies_series_page/stars_on_mars.webp";

import video_quality_poster from "../images/movies_series_page/video_quality_poster.webp";

function MoviesSeriesPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // create a list of items for swiper slides
  const list = [
    {
      logo: sky_Atlantic_2020,
      height: "30px",
      image: sky_atlantic,
      description: int.streamingDescription[displayLanguage],
    },
    {
      logo: film4_2018,
      height: "50px",
      image: film4,
      description: int.film4Channel[displayLanguage],
    },
    {
      logo: TNT_2016,
      height: "50px",
      image: creed3,
      description: int.tntChannel[displayLanguage],
    },

    {
      logo: Fox_Movies,
      height: "30px",
      image: stars_on_mars,
      description: int.foxChannel[displayLanguage],
    },
  ];
  //
  return (
    <>
      <div className="movies_series_wrapper">
        <div className="section_header">
          <h1>{int.cinematicExperience[displayLanguage]}</h1>
          <div className="channels">
            <img src={netflix_box_icon} alt="netflix_box_icon" />
            <img src={hbo_max_icon} alt="hbo_max_icon" />
            <img src={apple_tv_box_icon} alt="apple_tv_box_icon" />
            <img src={prime_video_box_icon} alt="prime_video_box_icon" />
            <img src={disney_box_icon} alt="disney_box_icon" />
            <img src={showtime_box_icon} alt="showtime_box_icon" />
            <img src={sky_box_icon} alt="sky_box_icon" />
            <img src={iplayer_box_icon} alt="iplayer_box_icon" />
          </div>
        </div>
        <div className="main_image_wrapper">
          <img
            src={movies_series_hero}
            alt="movies_series_hero"
            className="main_image"
          />
        </div>

        <h1>{int.unleashCollection[displayLanguage]}</h1>
        <SwiperJsComp items={list} />
      </div>
      <ProvidingSubtitles
        header={int.providingSubtitles[displayLanguage]}
        image={poster}
      />
      <OurQuality image={video_quality_poster} />
      <PricingCardsCombined />
    </>
  );
}

export default MoviesSeriesPage;
