const sizes = {
  small: "20px ",
  medium: "30px ",
  large: "40px ",
};

function Spinner({ size }) {
  return (
    <span
      className="spinner"
      style={{ height: sizes[size], width: sizes[size] }}
    ></span>
  );
}

export default Spinner;
