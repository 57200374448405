import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import crystal_logo from "../../images/crystaliptv_logo_black.svg";
import * as int from "../../../paragraphs/about_us.js";

function createData(key, value) {
  return { key, value };
}
function calculateCompanyAge(foundationDate) {
  const foundationDateObj = new Date(foundationDate);
  const currentDate = new Date();
  const timeDifference = currentDate - foundationDateObj;
  const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
  return years;
}

const OverviewTable = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  ///
  const rows = [
    createData(int.serviceText[displayLanguage], "IPTV"),
    createData("Country", "China"),
    createData("Broadcast area", "Europe"),
    createData("", "USA"),
    createData("", "Canada"),
    createData("", "MENA"),
    createData("Headquarters", "China, Hong Kong"),
    createData("Language(s)", "English"),
    createData("", "French"),
    createData("", "Spanish"),
    createData("", "Deutsh"),
    createData("", "Italian"),
    createData("", "Turkish"),
    createData("", "Russian"),
    createData("", "Polish"),
    createData("Picture format", "2160p UHDTV"),
    createData("", "1080p FHDTV"),
    createData("", "720p HDTV"),
    createData("", "480i SDTV"),
    createData("Owner", "CRYSTAL PLUS™"),
    createData(
      "Launched",
      `1 June 2016; ${calculateCompanyAge("1-06-2016")} years ago`
    ),
    createData("Website", "www.crystaliptv.co.uk"),
  ];
  ///
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableRow>
          <TableCell colSpan={2} align="center" padding={"20px"}>
            <img
              src={crystal_logo}
              alt="Crystal IPTV logo"
              style={{
                height: "30px",
              }}
            />
          </TableCell>
        </TableRow>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row" width={100}>
                {row.key}
              </TableCell>
              <TableCell align="right" width={100} sx={{ color: grey[600] }}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OverviewTable;
