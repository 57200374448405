import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  displayLanguageAtom,
  authenticatedAtom,
} from "../../Atoms";
// import toastify notificatons
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Components imports
import Spinner from "../../app_comps/comps/Spinner";
// Images import
import google_icon from "../images/google_icon.svg";

// we wimport the signup css cause we need to use some classes form it
import "./../styles/signuppage.css";

// import paragraphs
import * as int from "../../paragraphs/login_paragraphs.js";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [spinning, setSpinning] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );
  const [submitted, setSubmitted] = useState(false);
  const [credentialsError, setCredentialsError] = useState(false);
  const emailInput = useRef();
  const passwordInput = useRef();
  const submitButton = useRef();
  const setAuthenticatedAtom = useSetRecoilState(authenticatedAtom);
  const navigate = useNavigate();
  // Define error handlers that return JSX, note that these function are handling the GUI onplay,
  // the functionality of the log in is handled by the submit function
  // define a function that renders [this field is required] when needed after
  // the user hits submit, we pass in the state related to the input and the input referecend to by Ref
  const handleEmailFieldError = () => {
    if (submitted && email == "") {
      // get our hand on the input using useRef and change its id
      emailInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    }
  };
  // define an error handler for the email field, it will show this field is required and also
  // show the credentials are invalid after sending the HTTP request
  const handlePasswordFieldError = () => {
    if (submitted && password == "") {
      // get our hand on the input using useRef and change its id
      passwordInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    } else if (credentialsError) {
      return (
        <div className="auth_error prevent_select">
          {int.errorMessageNotFound[displayLanguage]}
        </div>
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // set credentials error to false cause this is another try
    setCredentialsError(false);
    // turn on the spinner showing progess
    setSpinning(true);
    // disable the submit btutton
    submitButton.current.disabled = true;
    // we set submitted to "true" to let handleErrorRender know that the use
    // submitted the form
    setSubmitted(true);
    // check if all the fields conditions were satisfied, if so, fire the HTTP request

    if (email != "" && password != "") {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/token/`,
        data: {
          username: email,
          password: password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          const accestoken = res.data.access;
          const userInfo = JSON.stringify(res.data);
          // we need to store the token in the localstorage and set the Auth atom to true
          localStorage.setItem("accessToken", accestoken);
          localStorage.setItem("userInfo", userInfo);
          setAuthenticatedAtom(true);

          // redirect the user to the home page
          navigate("/");
        })
        .catch((err) => {
          // we first check if the error came from the network being offline
          if (err.code == "ERR_NETWORK") {
            toast.error(int.connectionIssue[displayLanguage]);
          }

          // console.log(err);
          // if the request was unscuccessfull we will render the "network error" component
          else if (
            err.response.data.detail ==
            "No active account found with the given credentials"
          ) {
            setCredentialsError(true);
          }
          // reset the spinner and the re-enable the submit button
          setSpinning(false);
          submitButton.current.disabled = false;
        });
    } else {
      setSpinning(false);
      submitButton.current.disabled = false;
    }
  };

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);

  return (
    <div className="page">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={wrapperClass}>
        <h1>{int.login[displayLanguage]}</h1>
        <p>{int.welcomeMessage[displayLanguage]}</p>
        {/* <div className="google_auth prevent_select">
          <img src={google_icon} alt="google_logo" />
          <strong>{int.ContinueWithGoogle[displayLanguage]}</strong>
        </div> */}
        <form onSubmit={handleSubmit} className="form_wrapper">
          <label>{int.emailLabel[displayLanguage]}:</label>
          <input
            ref={emailInput}
            type="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              emailInput.current.id = "";
            }}
          />
          {handleEmailFieldError()}
          <label>{int.passwordLabel[displayLanguage]}:</label>
          <input
            ref={passwordInput}
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
              passwordInput.current.id = "";
            }}
          />
          <div className="redirect">
            <span>{int.forgotPassword[displayLanguage]}</span>
            <Link to="/reset_password">
              {int.resetPassword[displayLanguage]}
            </Link>
          </div>
          {handlePasswordFieldError()}
          <button ref={submitButton} type="submit" className="submit_btn">
            {spinning ? <Spinner size="small" /> : int.login[displayLanguage]}
          </button>
        </form>
        <div className="redirect">
          <span>{int.didNotHaveAnAccount[displayLanguage]}</span>
          <Link to="/signup">{int.signUp[displayLanguage]}</Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
