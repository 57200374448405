import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../.././../Atoms";
// import images
import unrestricted_access_image from "../../images/home_page/unrestricted_access_image.webp";

// import CSS
import "../../styles/unrestricted_access.css";

// import paragraphs
import * as int from "../../../paragraphs/AllIn.js";

//
function UnrestrictedAccess() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  return (
    <div className="unrestricted_access_wrapper">
      <h1>{int.unrestrictedAccess[displayLanguage]}</h1>
      <div className="unrestricted_access_body">
        <img
          src={unrestricted_access_image}
          alt="unrestricted_access_image"
          loading="lazy"
        />

        <p>{int.allDevices[displayLanguage]}</p>
      </div>
    </div>
  );
}

export default UnrestrictedAccess;
