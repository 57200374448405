// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
// import CSS
import "../styles/swiper.css";

function SwiperJsComp(props) {
  const list = props.items;

  //
  const swiperSlidesJSX = (list) => {
    return list.map((item, index) => {
      return (
        <SwiperSlide
          key={`swiperkey${index}`}
          className="slide"
          style={{
            backgroundImage: `url(${item.image})`,
          }}
        >
          <img
            src={item.logo}
            alt="channels_logo"
            style={{ height: item.height }}
          />
          <p>{item.description}</p>
        </SwiperSlide>
      );
    });
  };

  //
  return (
    <Swiper
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode, Pagination, Navigation, Autoplay]}
      className="mySwiper"
    >
      {swiperSlidesJSX(list)}
    </Swiper>
  );
}

export default SwiperJsComp;
