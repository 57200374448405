// import recoil
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
// import components
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
// import paragraphs
import * as int from "../../paragraphs/terms_and_conditions.js";
// import CSS
import "../styles/terms_and_conditions.css";

//
function TermsAndConditions() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  const chapterOne = {
    title: int.subscriptionServices[displayLanguage],
    paragraphs: [
      int.crystalIPTVSubscriptionInfo[displayLanguage],
      int.subscriptionServicesProviderInfo[displayLanguage],
      int.crystalIPTVTermsAndConditions[displayLanguage],
      int.subscriptionServicesAvailabilityInfo[displayLanguage],
      int.subscriptionServicesConfirmationInfo[displayLanguage],
      int.subscriberAgeDeclaration[displayLanguage],
      int.subscriptionServiceManagementInfo[displayLanguage],
      int.thirdPartySoftwareInfo[displayLanguage],
    ],
  };
  const chapterTwo = {
    title: int.definitions[displayLanguage],
    paragraphs: [
      int.affiliateDefinition[displayLanguage],
      int.contentDefinition[displayLanguage],
      int.channelsDefinition[displayLanguage],
      int.personalDataDefinition[displayLanguage],
      int.subscriberDefinition[displayLanguage],
      int.subscriptionPassDefinition[displayLanguage],
      int.inWritingDefinition[displayLanguage],
    ],
  };
  const chapterThree = {
    title: int.subscriptionBasisDefinition[displayLanguage],
    paragraphs: [
      int.twentyFourHourFreeTrial[displayLanguage],
      int.trialValidityNotice[displayLanguage],
      int.trialEntitlementNotice[displayLanguage],
      int.trialRequestNotice[displayLanguage],
      int.subscriptionServicesOffer[displayLanguage],
      int.subscriptionServiceChanges[displayLanguage],
      int.subscriptionPriceChanges[displayLanguage],
      int.subscriptionAccessFactors[displayLanguage],
      int.registrationAndPersonalData[displayLanguage],
      int.subscriptionApplicationRefusal[displayLanguage],
      int.subscriptionAccountAccess[displayLanguage],
    ],
  };
  const chapterFour = {
    title: int.permittedUse[displayLanguage],
    paragraphs: [
      int.permittedUsePrivate[displayLanguage],
      int.noExhibitionToPublic[displayLanguage],
    ],
  };

  const chapterFive = {
    title: int.paymentMethods[displayLanguage],
    paragraphs: [
      int.paymentMethodsValid[displayLanguage],
      int.acquireSubscriptionPass[displayLanguage],
      int.paymentSuspension[displayLanguage],
    ],
  };
  const chapterSix = {
    title: int.downloadPaymentsAndRenewal[displayLanguage],
    paragraphs: [
      int.subscriptionPaymentTransaction[displayLanguage],
      int.subscriptionPassRenewal[displayLanguage],
      int.subscriptionPassRenewalPayment[displayLanguage],
      int.subscriptionPassAgreement[displayLanguage],
    ],
  };
  const chapterSeven = {
    title: int.subscriberObligations[displayLanguage],
    paragraphs: [
      int.subscriberResponsibility[displayLanguage],
      int.subscriberUsageAgreement[displayLanguage],
      int.subscriberProhibitedActivitiesHeader[displayLanguage],
      int.subscriberProhibitedActivityA[displayLanguage],
      int.subscriberProhibitedActivityB[displayLanguage],
      int.subscriberProhibitedActivityC[displayLanguage],
      int.subscriberProhibitedActivityD[displayLanguage],
      int.subscriberProhibitedActivityE[displayLanguage],
      int.subscriberProhibitedActivityF[displayLanguage],
      int.subscriberTerminationPolicy[displayLanguage],
      int.subscriberSecurityResponsibility[displayLanguage],
      int.identificationDataRights[displayLanguage],
      int.subscriberResponsibility7[displayLanguage],
    ],
  };
  //

  const produceChapter = (library) => {
    return (
      <>
        <br />
        <Typography
          variant="h2"
          gutterBottom
          fontSize={"18px"}
          fontWeight={"500"}
        >
          {library.title}
        </Typography>
        <ul>
          {library.paragraphs.map((item, index) => {
            const indent = item[0] === "(" ? true : false;
            return (
              <li key={index} style={{ marginLeft: indent ? "20px" : "0" }}>
                <Typography variant="p" gutterBottom color={grey[800]}>
                  {item}
                </Typography>
              </li>
            );
          })}
        </ul>
      </>
    );
  };
  return (
    <Container maxWidth="md" sx={{ margin: "50px auto" }}>
      <Typography variant="h1" gutterBottom fontSize={"27px"}>
        {int.conditionsForCrystalIPTVService[displayLanguage]}
      </Typography>
      {produceChapter(chapterOne)}
      {produceChapter(chapterTwo)}
      {produceChapter(chapterThree)}
      {produceChapter(chapterFour)}
      {produceChapter(chapterFive)}
      {produceChapter(chapterSix)}
      {produceChapter(chapterSeven)}
    </Container>
  );
}

export default TermsAndConditions;
