// impprt images
import subtitles_icon from "../images/movies_series_page/subtitles_icon.svg";

function ProvidingSubtitles(props) {
  return (
    <div className="providing_subtitles_wrapper">
      <img src={subtitles_icon} alt="subtitles_icon" className="icon" />
      <h1>{props.header}</h1>
      <img src={props.image} alt="poster" className="poster" />
    </div>
  );
}

export default ProvidingSubtitles;
