// PRICING PAGE
export const readyToGetStarted = {
  en: "Ready to get started?",
  fr: "Prêt à commencer ?",
  es: "¿Listo para comenzar?",
  de: "Bereit loszulegen?",
  it: "Pronto per cominciare?",
  tr: "Başlamaya hazır mısınız?",
  ru: "Готовы приступить?",
  pl: "Gotowy, aby zacząć?",
};
export const choosePlanThatSuitsYou = {
  en: "Choose a plan that suits you",
  fr: "Choisissez un plan qui vous convient",
  es: "Elige un plan que se adapte a ti",
  de: "Wähle einen Plan, der zu dir passt",
  it: "Scegli un piano che fa per te",
  tr: "Sana uygun bir plan seçin",
  ru: "Выберите план, который подходит вам",
  pl: "Wybierz plan, który ci odpowiada",
};
export const faqHeader = {
  en: "Frequently Asked Questions (FAQ)",
  fr: "Questions Fréquemment Posées (FAQ)",
  es: "Preguntas Frecuentes (FAQ)",
  de: "Häufig gestellte Fragen (FAQ)",
  it: "Domande Frequenti (FAQ)",
  tr: "Sıkça Sorulan Sorular (SSS)",
  ru: "Часто задаваемые вопросы (FAQ)",
  pl: "Najczęściej Zadawane Pytania (FAQ)",
};

export const helmetTitle = {
  en: "Crystal+ IPTV - Pricing",
  fr: "Crystal+ IPTV - Tarification",
  es: "Crystal+ IPTV - Precios",
  de: "Crystal+ IPTV - Preise",
  it: "Crystal+ IPTV - Prezzi",
  tr: "Crystal+ IPTV - Fiyatlandırma",
  ru: "Crystal+ IPTV - Ценообразование",
  pl: "Crystal+ IPTV - Cennik",
};
export const helmetDescription = {
  en: "Explore our IPTV subscription pricing plans. Compare and choose from a range of affordable options, including monthly and yearly subscriptions, each tailored to fit your budget. Find the perfect plan to access live TV channels, sports, movies, and more in stunning HD quality at competitive prices.",
  fr: "Explorez nos plans de tarification d'abonnement IPTV. Comparez et choisissez parmi une gamme d'options abordables, y compris des abonnements mensuels et annuels, chacun adapté à votre budget. Trouvez le plan parfait pour accéder aux chaînes de télévision en direct, au sport, aux films et plus encore en qualité HD époustouflante à des prix compétitifs.",
  es: "Explora nuestros planes de precios de suscripción a IPTV. Compara y elige entre una variedad de opciones asequibles, incluyendo suscripciones mensuales y anuales, cada una diseñada para adaptarse a tu presupuesto. Encuentra el plan perfecto para acceder a canales de televisión en vivo, deportes, películas y más en impresionante calidad HD a precios competitivos.",
  de: "Erkunden Sie unsere Preisgestaltung für IPTV-Abonnements. Vergleichen Sie und wählen Sie aus einer Reihe von erschwinglichen Optionen, einschließlich monatlicher und jährlicher Abonnements, die jeweils auf Ihr Budget zugeschnitten sind. Finden Sie den perfekten Plan, um live TV-Sender, Sport, Filme und mehr in atemberaubender HD-Qualität zu wettbewerbsfähigen Preisen zu nutzen.",
  it: "Esplora i piani di pricing delle nostre sottoscrizioni IPTV. Confronta e scegli tra una serie di opzioni convenienti, inclusi abbonamenti mensili e annuali, ognuno progettato per adattarsi al tuo budget. Trova il piano perfetto per accedere a canali TV in diretta, sport, film e altro in sorprendente qualità HD a prezzi competitivi.",
  tr: "IPTV abonelik fiyat planlarımızı keşfedin. Aylık ve yıllık abonelikler dahil olmak üzere uygun seçenekler arasından karşılaştırın ve seçin, her biri bütçenize uyacak şekilde tasarlanmıştır. Canlı TV kanallarına, sporlara, filmlere ve daha fazlasına erişmek için mükemmel bir planı uygun fiyatlarla bulun.",
  ru: "Исследуйте ценовые планы подписки на наш IPTV. Сравнивайте и выбирайте из разнообразных доступных вариантов, включая ежемесячные и годовые подписки, каждая из которых разработана с учетом вашего бюджета. Найдите идеальный план для доступа к прямым телевизионным каналам, спорту, фильмам и многому другому в потрясающем HD качестве по конкурентоспособным ценам.",
  pl: "Zapoznaj się z naszymi planami cenowymi subskrypcji IPTV. Porównaj i wybierz spośród wielu dostępnych opcji, w tym abonamentów miesięcznych i rocznych, każdy dostosowany do Twojego budżetu. Znajdź idealny plan dostępu do kanałów TV na żywo, sportu, filmów i wielu innych w oszałamiającej jakości HD w konkurencyjnych cenach.",
};
