export const accessChannels = {
  en: "Unlimited IPTV Entertainment: 50,000+ Channels, Movies, and Series",
  fr: "Divertissement IPTV illimité : plus de 50000 chaînes, films et séries",
  es: "Entretenimiento de IPTV ilimitado: más de 50,000 canales, películas y series",
  de: "Unbegrenztes IPTV-Entertainment: Über 50.000 Kanäle, Filme und Serien",
  it: "Intrattenimento IPTV illimitato: oltre 50.000 canali, film e serie",
  tr: "Sınırsız IPTV Eğlencesi: 50.000'den fazla kanal, film ve dizi seçeneği.",
  ru: "Неограниченный IPTV-развлекательный контент: 50000+ каналов, фильмов и сериалов",
  pl: "Nieograniczona rozrywka IPTV: ponad 50000 kanałów, filmów i seriali",
  el: "Απεριόριστη ψυχαγωγία IPTV: 50.000+ κανάλια, ταινίες και σειρές",
};
export const subscriptionPlans = {
  en: "Plans start at €05.00/month.",
  fr: "Les abonnements commencent à partir de €05,00/mois.",
  es: "Los planes comienzan desde €05.00/mes.",
  de: "Pläne starten ab €05,00/Monat.",
  it: "I piani partono da €05,00/mese.",
  tr: "Planlar €05,00/aydan başlıyor.",
  ru: "Планы начинаются от 05,00 €/месяц.",
  pl: "Plany zaczynają się od 05,00€/miesiąc.",
  el: "Οι σχέδιοι ξεκινούν από €05,00/μήνα",
};

export const forAslowAs = {
  en: "For as low as",
  fr: "À partir de seulement",
  es: "Por tan solo",
  de: "Schon ab",
  it: "A partire da soli",
  tr: "Sadece",
  ru: "Всего за",
  pl: "Już od",
  el: "Για τόσο χαμηλά όσο",
};

// HELMET PARAGRAPHS
export const helmetTitle = {
  en: "Crystal+ Premium IPTV, Movies & Series",
  fr: "Crystal+ Premium IPTV, Films et Séries",
  es: "Crystal+ Premium IPTV, Películas y Series",
  de: "Crystal+ Premium IPTV, Filme & Serien",
  it: "Crystal+ Premium IPTV, Film e Serie",
  tr: "Crystal+ Premium IPTV, Filmler ve Diziler",
  ru: "Crystal+ Premium IPTV, Фильмы и Сериалы",
  pl: "Crystal+ Premium IPTV, Filmy i Seriale",
  el: "Crystal+ Premium IPTV, Ταινίες & Σειρές",
};

export const helmetDescription = {
  en: "Discover premium IPTV subscriptions at Crystal +. Enjoy a vast selection of live TV, sports, movies, and more in stunning HD and 4K quality. Stream on any device with our affordable plans. Elevate your entertainment today!",
  fr: "Découvrez les abonnements IPTV premium chez Crystal +. Profitez d'une vaste sélection de télévision en direct, de sports, de films et bien plus en qualité HD et 4K époustouflante. Streamez sur n'importe quel appareil avec nos offres abordables. Rehaussez votre divertissement dès aujourd'hui !",
  es: "Descubre las suscripciones premium de IPTV en Crystal +. Disfruta de una amplia selección de televisión en vivo, deportes, películas y más en calidad HD y 4K impresionante. Transmite en cualquier dispositivo con nuestros planes asequibles. ¡Eleva tu entretenimiento hoy!",
  de: "Entdecken Sie Premium-IPTV-Abonnements bei Crystal +. Genießen Sie eine große Auswahl an Live-TV, Sport, Filmen und mehr in atemberaubender HD- und 4K-Qualität. Streamen Sie auf jedem Gerät mit unseren erschwinglichen Tarifen. Heben Sie Ihr Entertainment heute auf ein neues Level!",
  it: "Scopri le sottoscrizioni IPTV premium su Crystal +. Goditi una vasta selezione di televisione in diretta, sport, film e molto altro in incredibile qualità HD e 4K. Stream su qualsiasi dispositivo con i nostri piani convenienti. Eleva il tuo intrattenimento oggi!",
  tr: "Crystal+'da premium IPTV aboneliklerini keşfedin. Muhteşem HD ve 4K kalitesinde canlı TV, spor, filmler ve daha fazlasının keyfini çıkarın. Uygun fiyatlı planlarımızla herhangi bir cihazda yayın yapın. Eğlencenizi bugün yükseltin!",
  ru: "Откройте для себя премиум-подписки на IPTV в Crystal +. Наслаждайтесь широким выбором прямого телевидения, спорта, фильмов и многого другого в потрясающем HD и 4K качестве. Смотрите на любом устройстве по доступным тарифам. Поднимите ваше развлечение сегодня!",
  pl: "Odkryj premium IPTV w Crystal +. Ciesz się ogromnym wyborem telewizji na żywo, sportu, filmów i wiele więcej w oszałamiającej jakości HD i 4K. Oglądaj na dowolnym urządzeniu dzięki naszym przystępnym planom. Podnieś swój poziom rozrywki już dziś!",
  el: "Ανακαλύψτε τις premium συνδρομές IPTV στην Crystal+. Απολαύστε μια τεράστια επιλογή από ζωντανή τηλεόραση, αθλητικά, ταινίες και πολλά άλλα σε εκπληκτική ποιότητα HD και 4K. Κάντε streaming σε οποιηδήποτε συσκευή με τα οικονομικά μας σχέδια. Αναβαθμίστε την ψυχαγωγία σας σήμερα!",
};
