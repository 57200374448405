import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import profile_settings_icon from "../../images/profile_settings_icon.svg";
// Rcoil imports
import { useRecoilState, useRecoilValue } from "recoil";
import { authenticatedAtom, displayLanguageAtom } from "../../../Atoms";

// import Int
import * as int from "../../../paragraphs/profile_settings_gear_paragraphs";

function ProfileSettingsGear() {
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(authenticatedAtom);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // EVENTS HANDLERS
  //   Define a function that handles the disply (on or off) of the  dropdown menu
  const handleDropdownDisplay = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  //   JSX PRODUCERS
  const profileOptions = () => {
    if (isAuthenticated && isOpen) {
      return (
        <div className="profile_settings_dropdown prevent_select">
          <Link to="/account-settings" onClick={closeMenu}>
            {int.accountSettings[displayLanguage]}
          </Link>
          <Link to="/help" onClick={closeMenu}>
            {int.help[displayLanguage]}
          </Link>
          <Link
            to="/login"
            onClick={() => {
              // when the user click log out, we set the isAuthenticated atom to false
              // and remove the access token from the local storage
              closeMenu();
              setIsAuthenticated(false);
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
            }}
          >
            {int.logout[displayLanguage]}
          </Link>
        </div>
      );
    } else if (isOpen) {
      return (
        <div className="profile_settings_dropdown prevent_select">
          <Link to="/signup" onClick={closeMenu}>
            {int.signup[displayLanguage]}
          </Link>
          <Link to="/login" onClick={closeMenu}>
            {int.login[displayLanguage]}
          </Link>
          <Link to="/" onClick={closeMenu}>
            {int.help[displayLanguage]}
          </Link>
        </div>
      );
    }
  };

  // Define useEffect for initializing some mouse click EventListeners
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="profile_settings_wrapper" ref={dropdownRef}>
      <img
        onClick={handleDropdownDisplay}
        className="profile_settings_icon"
        src={profile_settings_icon}
        alt="profile_settings_icon"
      />
      {profileOptions()}
    </div>
  );
}

export default ProfileSettingsGear;
