import { useEffect, useState } from "react";
import ContactUSWhatsapp from "../comps/ContactUSWhatsapp.js";
import screenshot1 from "../images/installation_guide/windows_screenshot_1.webp";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import * as int from "../../paragraphs/installation_guides_paragraphs.js";
//
const WindowsGuide = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );

  useEffect(() => {
    setWrapperClass("animation_wrapper");
  }, []);
  //
  return (
    <div className={wrapperClass}>
      <div className="installation-steps-wrapper">
        <h1>{int.iptvInstallationGuide[displayLanguage]}</h1>
        <p>{int.iptvInstallationSteps[displayLanguage]}</p>
        <h2>{int.prerequisites[displayLanguage]}</h2>
        <p>{int.installationRequirements[displayLanguage]}</p>
        <ul className="guideul">
          <li>{int.stableInternetConnection[displayLanguage]}</li>
          <li>{int.compatibleWindowsOS[displayLanguage]}</li>
        </ul>
        <br />
        <h2>{int.installationSteps[displayLanguage]}</h2>

        <ol className="guideul">
          <li>
            <strong>1 </strong> {int.downloadInstaller[displayLanguage]}
            <ul className="guideul2">
              <li>
                {int.downloadInstructions[displayLanguage]}{" "}
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}/orders/downloadables/iptv-smarters-pro-1-1-1.exe/`}
                >
                  {int.download[displayLanguage]}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>2 </strong>
            {int.runInstaller[displayLanguage]}
            <ul className="guideul2">
              <li>{int.locateInstaller[displayLanguage]}</li>
              <li>{int.doubleClickToInstall[displayLanguage]}</li>
            </ul>
          </li>
          <li>
            <strong>3 </strong>
            {int.launchSmartersIPTV[displayLanguage]}
            <ul className="guideul2">
              <li>{int.installationConfirmation[displayLanguage]}</li>
              <li>{int.clickFinishButton[displayLanguage]}</li>
              <li>{int.installationCompleteMessage[displayLanguage]}</li>
            </ul>
          </li>
          <li>
            <strong>4 </strong>
            {int.configureSmartersIPTV[displayLanguage]}
            <ul className="guideul2">
              <li>{int.launchFromShortcut[displayLanguage]}</li>
              <li>{int.enterCredentials[displayLanguage]}</li>
              <img
                className="screenshot"
                src={screenshot1}
                alt="installation_guide_screenshot_1"
              />
            </ul>
          </li>
          <li>
            <strong>5 </strong>
            {int.enjoyStreaming[displayLanguage]}
            <ul className="guideul2">
              <li>{int.enjoyIPTVStreaming[displayLanguage]}</li>
              <li>{int.exploreChannelsAndContent[displayLanguage]}</li>
            </ul>
          </li>
        </ol>
        <h2> {int.conclusion[displayLanguage]}</h2>
        <ul>
          <li>
            <p>{int.congratulationsMessage[displayLanguage]}</p>
          </li>
        </ul>
      </div>
      <div className="contact_us_faq_wrapper">
        <strong>{int.feelFreeToContactUs[displayLanguage]}</strong>
        <a href="https://wa.me/15551234567" target="_blank">
          <ContactUSWhatsapp color={"white"} />
        </a>
      </div>
    </div>
  );
};

export default WindowsGuide;
