import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
import * as int from "../../../paragraphs/landing_page_paragraphs.js";
import any_device from "../../images/landing_page/any_device.webp";

function LandingPageSection5() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  return (
    <>
      <h1>{int.watchAnywhere[displayLanguage]}</h1>
      <p>{int.streamOnDevices[displayLanguage]}</p>
      <img src={any_device} alt="any_device" />
    </>
  );
}

export default LandingPageSection5;
