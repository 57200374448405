export const cinematicExperience = {
  en: "Bring The Cinematic Experience To Your Living Room",
  fr: "Apportez l'expérience cinématographique dans votre salon",
  es: "Lleva la experiencia cinematográfica a tu sala de estar",
  de: "Holen Sie sich das Kinoerlebnis in Ihr Wohnzimmer",
  it: "Porta l'esperienza cinematografica nel tuo salotto",
  tr: "Sinematik Deneyimi Oturma Odanıza Getirin",
  ru: "Приведите кинематографический опыт в вашу гостиную",
  pl: "Przenieś do swojego salonu kinowe doświadczenie",
};

export const unleashCollection = {
  en: "Unleash the Latest Movies and Series Collection, All at Your Fingertips!",
  fr: "Découvrez la dernière collection de films et de séries, le tout à portée de main !",
  es: "¡Desata la última colección de películas y series, todo al alcance de tus dedos!",
  de: "Entfesseln Sie die neueste Sammlung von Filmen und Serien, alles in greifbarer Nähe!",
  it: "Scatena l'ultima collezione di film e serie, tutto a portata di mano!",
  tr: "En Son Filmler ve Diziler Koleksiyonunu Parmağınızın Ucunda Keşfedin!",
  ru: "Приготовьтесь к последней коллекции фильмов и сериалов - все у вас под рукой!",
  pl: "Uwolnij najnowszą kolekcję filmów i seriali, wszystko na wyciągnięcie ręki!",
};

export const avatarDescription = {
  en: "Jake Sully lives with his newfound family formed on the extrasolar moon Pandora. Once a familiar threat returns to finish what was previously started, Jake must work with Neytiri and the army of the Na'vi race to protect their home.",
  fr: "Jake Sully vit avec sa nouvelle famille formée sur la lune extrasolaire Pandora. Lorsqu'une menace familière revient pour terminer ce qui avait été précédemment commencé, Jake doit travailler avec Neytiri et l'armée de la race Na'vi pour protéger leur foyer.",
  es: "Jake Sully vive con su nueva familia formada en la luna extrasolar Pandora. Una vez que una amenaza familiar regresa para terminar lo que se había comenzado anteriormente, Jake debe trabajar con Neytiri y el ejército de la raza Na'vi para proteger su hogar.",
  de: "Jake Sully lebt mit seiner neu gefundenen Familie auf dem extrasolaren Mond Pandora. Als eine vertraute Bedrohung zurückkehrt, um das zu beenden, was zuvor begonnen wurde, muss Jake mit Neytiri und der Armee der Na'vi-Rasse zusammenarbeiten, um ihr Zuhause zu schützen.",
  it: "Jake Sully vive con la sua nuova famiglia formata sulla luna extrasolare Pandora. Una volta che una minaccia familiare torna per finire ciò che era stato precedentemente iniziato, Jake deve lavorare con Neytiri e l'esercito della razza Na'vi per proteggere la loro casa.",
  tr: "Jake Sully, ekstrasolar ay Pandora'da kurulan yeni bulduğu ailesiyle yaşamaktadır. Daha önceden başlananı tamamlamak için tanıdık bir tehdit geri döndüğünde, Jake Neytiri ve Na'vi ırkının ordusuyla birlikte evlerini korumak için çalışmak zorundadır.",
  ru: "Джейк Салли живет со своей новой семьей, образованной на экзосолнечной луне Пандора. Когда возвращается знакомая угроза, чтобы завершить то, что было начато ранее, Джейку придется сотрудничать с Нейтири и армией народа на'ви, чтобы защитить свой дом.",
  pl: "Jake Sully żyje z nowo znalezioną rodziną, którą tworzy na pozaziemskim księżycu Pandora. Gdy powraca znajome zagrożenie, by dokończyć to, co zostało wcześniej rozpoczęte, Jake musi współpracować z Neytiri i armią rasy Na'vi, aby chronić swoje domostwo.",
};

export const successionDescription = {
  en: "The Roy family is known for controlling the biggest media and entertainment company in the world. However, their world changes when their father steps down from the company.",
  fr: "La famille Roy est connue pour contrôler la plus grande entreprise de médias et de divertissement au monde. Cependant, leur monde change lorsque leur père se retire de l'entreprise.",
  es: "La familia Roy es conocida por controlar la compañía de medios y entretenimiento más grande del mundo. Sin embargo, su mundo cambia cuando su padre se retira de la empresa.",
  de: "Die Roy-Familie ist dafür bekannt, das größte Medien- und Unterhaltungsunternehmen der Welt zu kontrollieren. Ihr Weltbild ändert sich jedoch, als ihr Vater von der Firma zurücktritt.",
  it: "La famiglia Roy è conosciuta per controllare la più grande azienda di media e intrattenimento al mondo. Tuttavia, il loro mondo cambia quando il loro padre si ritira dall'azienda.",
  tr: "Roy ailesi, dünyanın en büyük medya ve eğlence şirketini kontrol etmeleriyle tanınır. Ancak, babaları şirketten ayrıldığında dünyaları değişir.",
  ru: "Семья Рой известна своим контролем над крупнейшей медиа- и развлекательной компанией в мире. Однако их мир меняется, когда их отец уходит с поста в компании.",
  pl: "Rodzina Royów jest znana z kontrolowania największej na świecie firmy mediowej i rozrywkowej. Jednak ich świat zmienia się, gdy ojciec rezygnuje z firmy.",
};

export const littleMermaidDescription = {
  en: "A young mermaid makes a deal with a sea witch to trade her beautiful voice for human legs so she can discover the world above water and impress a prince.",
  fr: "Une jeune sirène passe un marché avec une sorcière des mers pour échanger sa belle voix contre des jambes humaines afin de découvrir le monde en surface et impressionner un prince.",
  es: "Una joven sirena hace un trato con una bruja del mar para intercambiar su hermosa voz por piernas humanas, de modo que pueda descubrir el mundo sobre el agua e impresionar a un príncipe.",
  de: "Eine junge Meerjungfrau macht einen Deal mit einer Meerhexe und tauscht ihre wunderschöne Stimme gegen menschliche Beine ein, um die Welt über dem Wasser zu entdecken und einen Prinzen zu beeindrucken.",
  it: "Una giovane sirena fa un patto con una strega del mare per scambiare la sua bellissima voce con delle gambe umane, così da poter scoprire il mondo sopra l'acqua e impressionare un principe.",
  tr: "Genç bir denizkızı, bir deniz cadısıyla anlaşma yapar ve güzel sesini insan bacaklarıyla değiş tokuş ederek suyun üzerindeki dünyayı keşfeder ve bir prensi etkilemeye çalışır.",
  ru: "Молодая русалка заключает сделку с морской ведьмой, обменяв свой прекрасный голос на человеческие ноги, чтобы открыть для себя мир над водой и впечатлить принца.",
  pl: "Młoda syrena zawiera umowę z wiedźmą morską, zamieniając swój piękny głos na ludzkie nogi, aby odkryć świat nad wodą i imponować księciu.",
};

export const guardiansDescription = {
  en: "Still reeling from the loss of Gamora, Peter Quill rallies his team to defend the universe and one of their own - a mission that could mean the end of the Guardians if not successful.",
  fr: "Encore sous le choc de la perte de Gamora, Peter Quill rassemble son équipe pour défendre l'univers et l'un des leurs - une mission qui pourrait signifier la fin des Gardiens s'ils échouent.",
  es: "Aún afectado por la pérdida de Gamora, Peter Quill reúne a su equipo para defender el universo y a uno de los suyos, una misión que podría significar el fin de los Guardianes si no tienen éxito.",
  de: "Immer noch von dem Verlust von Gamora gezeichnet, mobilisiert Peter Quill sein Team, um das Universum und einen ihrer eigenen zu verteidigen - eine Mission, die das Ende der Guardians bedeuten könnte, wenn sie scheitern.",
  it: "Ancora sotto shock per la perdita di Gamora, Peter Quill raduna la sua squadra per difendere l'universo e uno di loro, una missione che potrebbe significare la fine dei Guardiani se non avranno successo.",
  tr: "Gamora'nın kaybının etkisi altında olan Peter Quill, takımını birleştirerek evreni ve kendi aralarından birini savunmaya çağırır - başarısızlık halinde Gardiyanların sonunu getirebilecek bir görev.",
  ru: "Еще ошеломленный потерей Гаморы, Питер Квилл собирает свою команду, чтобы защитить вселенную и одного из своих - миссия, которая может означать конец Стражей, если не будет выполнена успешно.",
  pl: "Peter Quill, nadal pogrążony w żałobie po stracie Gamory, mobilizuje swoją drużynę, aby bronić wszechświata i jednego z nich - misja, która może oznaczać koniec Strażników, jeśli nie powiedzie się.",
};

export const providingSubtitles = {
  en: "Providing Subtitles In Every European Language.",
  fr: "Fourniture de sous-titres dans toutes les langues européennes.",
  es: "Proporcionando subtítulos en todos los idiomas europeos.",
  de: "Bereitstellung von Untertiteln in allen europäischen Sprachen.",
  it: "Fornitura di sottotitoli in tutte le lingue europee.",
  tr: "Avrupa'daki tüm dillerde altyazı sağlama.",
  ru: "Предоставление субтитров на всех европейских языках.",
  pl: "Dostarczanie napisów w każdym języku europejskim.",
};
export const streamingDescription = {
  en: "Stream captivating dramas and acclaimed shows on IPTV with Sky Atlantic. Explore a diverse lineup of engaging storytelling, including exclusive series and top-notch entertainment. Experience the best of Sky Atlantic's content from the comfort of your own screen.",
  fr: "Diffusez des drames captivants et des émissions acclamées sur IPTV avec Sky Atlantic. Découvrez une programmation diversifiée avec des histoires captivantes, y compris des séries exclusives et un divertissement de qualité supérieure. Découvrez le meilleur contenu de Sky Atlantic depuis le confort de votre propre écran.",
  es: "Disfruta de cautivadores dramas y aclamados programas en IPTV con Sky Atlantic. Explora una variada programación de historias cautivadoras, que incluye series exclusivas y entretenimiento de primer nivel. Experimenta lo mejor del contenido de Sky Atlantic desde la comodidad de tu propia pantalla.",
  de: "Streamen Sie fesselnde Dramen und gefeierte Shows auf IPTV mit Sky Atlantic. Entdecken Sie eine vielfältige Auswahl an fesselnden Geschichten, darunter exklusive Serien und erstklassige Unterhaltung. Erleben Sie das Beste vom Inhalt von Sky Atlantic bequem auf Ihrem eigenen Bildschirm.",
  it: "Guarda emozionanti drammi e spettacoli acclamati su IPTV con Sky Atlantic. Esplora una vasta selezione di storie coinvolgenti, inclusa serie esclusive e intrattenimento di prim'ordine. Vivi il meglio dei contenuti di Sky Atlantic comodamente dal tuo schermo.",
  tr: "Sky Atlantic ile IPTV üzerinden büyüleyici dramaları ve övgü alan programları izleyin. Özel diziler ve üstün kaliteli eğlence de dahil olmak üzere çeşitli bir hikaye anlatımı dizisini keşfedin. Sky Atlantic'in içeriğinin en iyisini kendi ekranınızın rahatlığıyla deneyimleyin.",
  ru: "Смотрите захватывающие драмы и признанные шоу на IPTV с Sky Atlantic. Исследуйте разнообразный репертуар увлекательных историй, включая эксклюзивные сериалы и первоклассное развлечение. Погрузитесь в лучший контент Sky Atlantic с комфортом вашего собственного экрана.",
  pl: "Oglądaj urzekające dramaty i uznane programy na IPTV z Sky Atlantic. Przeżyj różnorodną kolekcję wciągających historii, w tym ekskluzywne seriale i najwyższej jakości rozrywkę. Doświadcz najlepszych treści Sky Atlantic z wygody własnego ekranu.",
};
export const film4Channel = {
  en: "The UK's premier film channel, supporting outstanding cinema for 40+ years. Pioneering film funding, nurturing British talent. Dive into a world of cinematic excellence, from classics to groundbreaking works. Enjoy the finest movies at home.",
  fr: "La première chaîne de cinéma au Royaume-Uni, soutenant le cinéma d'exception depuis plus de 40 ans. Financement cinématographique pionnier, soutien aux talents britanniques. Plongez dans un monde d'excellence cinématographique, des classiques aux œuvres révolutionnaires. Profitez des meilleurs films chez vous.",
  es: "El principal canal de cine del Reino Unido, apoyando el cine excepcional durante más de 40 años. Financiamiento cinematográfico pionero, fomentando el talento británico. Sumérgete en un mundo de excelencia cinematográfica, desde clásicos hasta obras innovadoras. Disfruta de las mejores películas en casa.",
  de: "Der führende Filmkanal in Großbritannien, der herausragendes Kino seit über 40 Jahren unterstützt. Pionierhaftes Filmförderung, Förderung britischer Talente. Tauchen Sie ein in eine Welt der filmischen Exzellenz, von Klassikern bis zu bahnbrechenden Werken. Genießen Sie die besten Filme zu Hause.",
  it: "Il primo canale cinematografico del Regno Unito, a sostegno del cinema eccezionale da oltre 40 anni. Finanziamenti cinematografici pionieristici, sostegno al talento britannico. Tuffati in un mondo di eccellenza cinematografica, dai classici alle opere innovative. Goditi i migliori film a casa.",
  tr: "40 yıldan fazla süredir olağanüstü sinemayı destekleyen İngiltere'nin önde gelen film kanalı. İnovatif film finansmanı ve İngiliz yeteneklerini destekleme. Klasiklerden çığır açan eserlere kadar sinematik mükemmelliğin dünyasına dalın. En iyi filmlerin tadını evinizde çıkarın.",
  ru: "Главный киноканал Великобритании, поддерживающий выдающееся кино уже более 40 лет. Пионерское финансирование кино, развитие британского таланта. Погрузитесь в мир кинематографического совершенства, от классики до революционных произведений. Наслаждайтесь лучшими фильмами у себя дома.",
  pl: "Przywódczy kanał filmowy w Wielkiej Brytanii, wspierający wybitne kino od ponad 40 lat. Pionierskie finansowanie filmów, wspieranie brytyjskiego talentu. Zanurz się w świat doskonałości filmowej, od klasyków po przełomowe dzieła. Ciesz się najlepszymi filmami w domowym zaciszu.",
};
export const tntChannel = {
  en: "TNT channel in Europe, owned by Warner Bros, offers a diverse range of captivating television content, including thrilling dramas, exciting movies, and engaging reality shows. With its dynamic programming lineup, TNT keeps viewers entertained and hooked to their screens. Experience the adrenaline rush and immerse yourself in a world of entertainment on TNT.",
  fr: "La chaîne TNT en Europe, détenue par Warner Bros, propose une gamme diversifiée de contenus télévisuels captivants, comprenant des dramas palpitants, des films excitants et des émissions de télé-réalité captivantes. Avec sa programmation dynamique, TNT divertit et accroche les téléspectateurs à leurs écrans. Vivez l'adrénaline et plongez dans un monde de divertissement sur TNT.",
  es: "El canal TNT en Europa, propiedad de Warner Bros, ofrece una amplia gama de contenido televisivo cautivador, que incluye emocionantes dramas, películas emocionantes y programas de realidad cautivadores. Con su programación dinámica, TNT mantiene a los espectadores entretenidos y enganchados a sus pantallas. Experimenta la adrenalina y sumérgete en un mundo de entretenimiento en TNT.",
  de: "Der TNT-Kanal in Europa, im Besitz von Warner Bros, bietet eine vielfältige Auswahl an fesselnden Fernsehinhalten, darunter spannende Dramen, aufregende Filme und fesselnde Reality-Shows. Mit seinem dynamischen Programm hält TNT die Zuschauer unterhalten und an ihre Bildschirme gefesselt. Erleben Sie den Adrenalinkick und tauchen Sie ein in eine Welt des Entertainment auf TNT.",
  it: "Il canale TNT in Europa, di proprietà di Warner Bros, offre una vasta gamma di contenuti televisivi accattivanti, tra cui drammi avvincenti, film emozionanti e coinvolgenti reality show. Con la sua programmazione dinamica, TNT tiene gli spettatori intrattenuti e appassionati ai loro schermi. Vivi l'adrenalina e immergiti in un mondo di intrattenimento su TNT.",
  tr: "Warner Bros'a ait olan TNT kanalı, Avrupa'da heyecan verici dramalar, heyecan dolu filmler ve ilgi çekici reality şovlar dahil olmak üzere çeşitli etkileyici televizyon içeriği sunar. Dinamik programlama anlayışıyla TNT, izleyicileri eğlendirir ve ekranlara bağlı tutar. TNT'de adrenalini yaşayın ve eğlence dünyasına dalın.",
  ru: "TNT канал в Европе, принадлежащий Warner Bros, предлагает разнообразный и увлекательный телевизионный контент, включая захватывающие драмы, увлекательные фильмы и увлекательные реалити-шоу. Благодаря своей динамичной программе, TNT держит зрителей в напряжении и привязывает их к экранам. Почувствуйте прилив адреналина и окунитесь в мир развлечений на TNT.",
  pl: "Kanał TNT w Europie, należący do Warner Bros, oferuje różnorodną i porywającą zawartość telewizyjną, w tym fascynujące dramaty, ekscytujące filmy i angażujące programy reality show. Dzięki swojej dynamicznej linii programowej TNT utrzymuje widzów zainteresowanych i przywiązanych do swoich ekranów. Przeżyj przypływ adrenaliny i zanurz się w świecie rozrywki na TNT.",
};
export const foxChannel = {
  en: "Fox Channel is a popular network known for its captivating scripted series, reality TV, sports coverage, and unbiased news. With high-quality entertainment, Fox keeps viewers engaged with thrilling dramas, reality shows, sports, and accurate news reporting. Experience the best in TV entertainment with Fox Channel.",
  fr: "Fox est une chaîne populaire connue pour ses séries captivantes, télé-réalité, couverture sportive et actualités impartiales. Avec un divertissement de qualité, Fox garde les téléspectateurs engagés avec des drames palpitants, des émissions de télé-réalité, du sport et des informations précises. Vivez le meilleur du divertissement télé avec Fox.",
  es: "Fox Channel es una cadena popular conocida por sus cautivadoras series, reality shows, cobertura deportiva y noticias imparciales. Con entretenimiento de alta calidad, Fox mantiene a los espectadores enganchados con emocionantes dramas, programas de realidad, deportes e información precisa. Vive lo mejor del entretenimiento televisivo con Fox Channel.",
  de: "Fox Channel ist ein beliebtes Netzwerk, bekannt für fesselnde Serien, Reality-TV, Sportberichterstattung und unvoreingenommene Nachrichten. Mit hochwertigem Entertainment hält Fox die Zuschauer mit packenden Dramen, Reality-Shows, Sport und genauen Nachrichten auf dem Laufenden. Erlebe das Beste im TV-Entertainment mit Fox Channel.",
  it: "Fox Channel è una rete popolare nota per le sue serie avvincenti, programmi di realtà, copertura sportiva e notizie imparziali. Con intrattenimento di alta qualità, Fox coinvolge gli spettatori con emozionanti drammi, reality show, sport e notizie accurate. Vivi il meglio dell'intrattenimento televisivo con Fox Channel.",
  tr: "Fox Kanalı, etkileyici dramaları, reality şovları, spor haberleri ve tarafsız haberleriyle bilinen popüler bir ağdır. Yüksek kaliteli eğlenceyle Fox, izleyicileri heyecan verici dramalar, reality şovlar, sporlar ve doğru haberlerle bağlı tutar. Fox Kanalı ile en iyi TV eğlencesini yaşayın.",
  ru: "Fox Channel - популярный канал, известный своими увлекательными сериалами, реалити-шоу, спортивными трансляциями и беспристрастными новостями. С высококачественным контентом Fox увлекает зрителей захватывающими драмами, реалити-шоу, спортом и точной информацией. Почувствуйте лучшее телевизионное развлечение с Fox Channel.",
  pl: "Fox Channel to popularna sieć znana z wciągających seriali, programów reality show, relacji sportowych i wiarygodnych informacji. Z wysokiej jakości rozrywką, Fox utrzymuje widzów w napięciu dzięki emocjonującym dramatom, programom reality show, sportowi i dokładnym informacjom. Doświadcz najlepszego telewizyjnego rozrywki z Fox Channel.",
};
