import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import * as int from "../../paragraphs/AllIn.js";
import not_found from "../images/not_found.svg";
import "../../styles.css";

function NotFound() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  return (
    <div className="found_wrapper">
      <h1>{int.errorPageMessageH[displayLanguage]}</h1>
      <img src={not_found} alt="404_not_found" className="not_found_404" />
      <p>{int.errorPageMessageP[displayLanguage]}</p>
    </div>
  );
}

export default NotFound;
