import { useState } from "react";
import "../../styles/pricing_card.css";
// import images
import ring_white from "../../images/ring_white.svg";
import checkbox from "../../images/grey_check.svg";
import decorative_shape from "../../images/decorative_shape.svg";
// import comps
import PricingCardExecute from "./PricingCardsExecute";
// import Recoil and atoms
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "./../../../Atoms";

// import paragraphs
import * as int from "../../../paragraphs/pricing_cards_paragraphs";

function PricingCard(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [clicked, setClicked] = useState(false);
  // destructure props
  const { popular, price, period, periodCode } = props;

  return (
    <>
      {/* if btn clicked this comp will render which will execute some js code to handle the payment  */}
      {clicked && <PricingCardExecute periodCode={periodCode} />}
      <div className="pricing_card_wrapper  unique_card">
        <img
          src={decorative_shape}
          alt="decorative shape"
          className="decorative"
        />
        <div className="ring_popular_wrapper">
          <img src={ring_white} alt="ring" className="ring" />
          {popular && (
            <span className="popular">{int.popular[displayLanguage]}</span>
          )}
        </div>
        <p className="unique_card_font_color">{period}</p>
        <div className="price">
          <strong className="unique_card_font_color">€{price}</strong>
          <small style={{ color: "#fff" }}>
            {int.perMonth[displayLanguage]}
          </small>
        </div>
        <ul className="unique_card_font_color">
          <li>
            <img src={checkbox} alt="checkbox" /> 50,000{" "}
            {int.channels[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.imageQuality[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.premiumChannels[displayLanguage]}
          </li>
          <li>
            <img src={checkbox} alt="checkbox" />
            {int.seriesAndMovies[displayLanguage]}
          </li>
        </ul>
        <button
          className="choose_plan_btn"
          onClick={() => {
            setClicked(true);
          }}
        >
          {int.choosePlan[displayLanguage]}
        </button>
      </div>
    </>
  );
}

export default PricingCard;
