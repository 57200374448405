export const thisFieldIsRequired = {
  en: "This field is required",
  fr: "Ce champ est obligatoire",
  es: "Este campo es obligatorio",
  de: "Dieses Feld ist erforderlich",
  it: "Questo campo è obbligatorio",
  tr: "Bu alan gereklidir",
  ru: "Это поле обязательно для заполнения",
  pl: "To pole jest wymagane",
};
export const passwordNotlong = {
  en: "Password must be at least 8 characters long",
  fr: "Le mot de passe doit comporter au moins 8 caractères",
  es: "La contraseña debe tener al menos 8 caracteres",
  de: "Das Passwort muss mindestens 8 Zeichen lang sein",
  it: "La password deve contenere almeno 8 caratteri",
  tr: "Şifre en az 8 karakter uzunluğunda olmalıdır",
  ru: "Пароль должен содержать не менее 8 символов",
  pl: "Hasło musi mieć co najmniej 8 znaków",
};
