export const weJoinTheBest = {
  en: "We join the best to give you the best",
  fr: "Nous joignons les meilleurs pour vous donner le meilleur",
  es: "Nos unimos a los mejores para darte lo mejor",
  de: "Wir vereinen die Besten, um Ihnen das Beste zu geben",
  it: "Uniamo i migliori per darti il meglio",
  tr: "Size en iyisini sunmak için en iyilerle birleşiyoruz",
  ru: "Мы объединяем лучших, чтобы дать вам лучшее",
  pl: "Łączymy się z najlepszymi, aby dać ci najlepsze",
  el: "Συνενώνουμε τους καλύτερους για να σας προσφέρουμε το καλύτερο",
};

export const findOutMore = {
  en: "Find out more",
  fr: "En savoir plus",
  es: "Más información",
  de: "Erfahren Sie mehr",
  it: "Scopri di più",
  tr: "Daha fazla bilgi edinin",
  ru: "Узнать больше",
  pl: "Dowiedz się więcej",
  el: "Μάθετε περισσότερα",
};

export const bestFeatures = {
  en: "Best features",
  fr: "Meilleures fonctionnalités",
  es: "Mejores características",
  de: "Beste Eigenschaften",
  it: "Migliori caratteristiche",
  tr: "En iyi özellikler",
  ru: "Лучшие особенности",
  pl: "Najlepsze cechy",
  el: "Καλύτερα χαρακτηριστικά",
};

export const oneThousandChannel = {
  en: "100,000+ Live channels and VOD",
  fr: "100 000+ chaînes en direct et VOD",
  es: "100,000+ Canales en vivo y VOD",
  de: "100.000+ Live-Kanäle und VOD",
  it: "100.000+ Canali in diretta e VOD",
  tr: "100.000'den fazla canlı kanal ve VOD",
  ru: "100 000+ Прямых каналов и видео по требованию",
  pl: "100 000+ Dostępnych kanałów na żywo i VOD",
};

export const oneThousandChannelPara = {
  en: "Experience endless entertainment with our IPTV service, offering 100,000+ channels and VOD choices. Find captivating content for every interest.",
  fr: "Profitez d'un divertissement sans fin avec notre service IPTV, offrant plus de 100 000 chaînes et choix de VOD. Trouvez un contenu captivant pour tous les intérêts.",
  es: "Experimenta entretenimiento sin fin con nuestro servicio de IPTV, que ofrece más de 100,000 canales y opciones de VOD. Encuentra contenido cautivador para cada interés.",
  de: "Erleben Sie endloses Entertainment mit unserem IPTV-Service, der über 100.000 Kanäle und VOD-Optionen bietet. Entdecken Sie fesselnde Inhalte für jedes Interesse.",
  it: "Vivi un intrattenimento senza fine con il nostro servizio IPTV, che offre più di 100.000 canali e opzioni di VOD. Trova contenuti avvincenti per ogni interesse.",
  tr: "100.000'den fazla kanal ve VOD seçeneği sunan IPTV hizmetimizle sonsuz eğlence deneyimi yaşayın. Her ilgi alanı için etkileyici içerikleri bulun.",
  ru: "Познакомьтесь с бесконечным развлечением благодаря нашей IPTV-услуге, предлагающей 100 000+ каналов и VOD-выбор. Найдите увлекательный контент для любого интереса.",
  pl: "Zapewniamy niekończącą się rozrywkę dzięki naszej usłudze IPTV, oferującej ponad 100 000 kanałów i opcje VOD. Znajdź wciągające treści dla każdego zainteresowania.",
  el: "Ζήστε ατελείωτη ψυχαγωγία με την υπηρεσία IPTV μας, προσφέροντας 100.000+ κανάλια και επιλογές VOD. Βρείτε συναρπαστικό περιεχόμενο για κάθε ενδιαφέρον.",
};

export const support24 = {
  en: "24h Support",
  fr: "Support 24h",
  es: "Soporte 24h",
  de: "24-Stunden-Support",
  it: "Supporto 24h",
  tr: "24 Saat Destek",
  ru: "Поддержка 24/7",
  pl: "Wsparcie 24h",
  el: "Υποστήριξη 24 ώρες το 24ωρο",
};

export const support24Para = {
  en: "Reach out to us anytime via live chat or by leaving a message, and we'll gladly assist you. Our goal is to be there for you whenever you need support.",
  fr: "N'hésitez pas à nous contacter à tout moment via le chat en direct ou en laissant un message, nous vous aiderons avec plaisir. Notre objectif est d'être là pour vous chaque fois que vous avez besoin de support.",
  es: "Comunícate con nosotros en cualquier momento a través del chat en vivo o dejando un mensaje, y estaremos encantados de ayudarte. Nuestro objetivo es estar allí para ti siempre que necesites soporte.",
  de: "Kontaktieren Sie uns jederzeit über den Live-Chat oder indem Sie eine Nachricht hinterlassen, und wir helfen Ihnen gerne weiter. Unser Ziel ist es, Ihnen immer dann Unterstützung zu bieten, wenn Sie sie benötigen.",
  it: "Contattaci in qualsiasi momento tramite chat dal vivo o lasciando un messaggio e saremo lieti di assisterti. Il nostro obiettivo è essere al tuo fianco ogni volta che hai bisogno di supporto.",
  tr: "Canlı sohbet veya mesaj bırakarak herhangi bir zamanda bizimle iletişime geçin ve size memnuniyetle yardımcı olalım. Amacımız, destek ihtiyacınız olduğunda her zaman yanınızda olmaktır.",
  ru: "Вы можете связаться с нами в любое время через онлайн-чат или оставив сообщение, и мы с радостью поможем вам. Наша цель - быть рядом с вами всегда, когда вам нужна поддержка.",
  pl: "Skontaktuj się z nami w dowolnym momencie za pośrednictwem czatu na żywo lub pozostaw wiadomość, a z przyjemnością Ci pomożemy. Naszym celem jest bycie dla Ciebie zawsze, kiedy potrzebujesz wsparcia.",
  el: "Επικοινωνήστε μαζί μας οποτεδήποτε μέσω της ζωντανής συνομιλίας ή αφήστε ένα μήνυμα, και θα είμαστε ευχαρίστηση να σας βοηθήσουμε. Ο στόχος μας είναι να είμαστε εκεί για εσάς όποτε χρειάζεστε υποστήριξη.",
};

export const instantActivation = {
  en: "Instant Activation",
  fr: "Activation instantanée",
  es: "Activación instantánea",
  de: "Sofortige Aktivierung",
  it: "Attivazione istantanea",
  tr: "Anında Etkinleştirme",
  ru: "Мгновенная активация",
  pl: "Natychmiastowa aktywacja",
  el: "Άμεση ενεργοποίηση",
};

export const instantActivationPara = {
  en: "Once your order is placed, your service will be ready within moments. We take pride in delivering fast and efficient service to all our valued customers.",
  fr: "Une fois votre commande passée, votre service sera prêt en quelques instants. Nous sommes fiers de fournir un service rapide et efficace à tous nos précieux clients.",
  es: "Una vez realizada tu orden, tu servicio estará listo en cuestión de momentos. Nos enorgullece ofrecer un servicio rápido y eficiente a todos nuestros valiosos clientes.",
  de: "Sobald Ihre Bestellung aufgegeben wurde, wird Ihr Service in wenigen Augenblicken bereit sein. Wir sind stolz darauf, allen unseren geschätzten Kunden einen schnellen und effizienten Service zu bieten.",
  it: "Una volta effettuato l'ordine, il tuo servizio sarà pronto in pochi istanti. Siamo orgogliosi di offrire un servizio veloce ed efficiente a tutti i nostri preziosi clienti.",
  tr: "Siparişiniz alındıktan sonra hizmetiniz anında hazır olacaktır. Tüm değerli müşterilerimize hızlı ve verimli hizmet sunmaktan gurur duyuyoruz.",
  ru: "После оформления заказа ваш сервис будет готов в течение мгновений. Мы гордимся тем, что предоставляем быстрый и эффективный сервис всем нашим дорогим клиентам.",
  pl: "Po złożeniu zamówienia usługa będzie gotowa w ciągu chwil. Jesteśmy dumni z dostarczania szybkiej i efektywnej obsługi wszystkim naszym wartościowym klientom.",
  el: "Μόλις το παραγγείλετε, η υπηρεσία σας θα είναι έτοιμη σε λίγες στιγμές. Είμαστε περήφανοι που παρέχουμε γρήγορη και αποτελεσματική εξυπηρέτηση σε όλους τους εκτιμημένους μας πελάτες.",
};

export const unrestrictedAccess = {
  en: "Unrestricted Access: Enjoy IPTV on Any Device",
  fr: "Accès illimité : Profitez de l'IPTV sur n'importe quel appareil",
  es: "Acceso sin restricciones: Disfruta de IPTV en cualquier dispositivo",
  de: "Uneingeschränkter Zugriff: Genießen Sie IPTV auf jedem Gerät",
  it: "Accesso senza restrizioni: Goditi l'IPTV su qualsiasi dispositivo",
  tr: "Sınırsız Erişim: Herhangi bir cihazda IPTV keyfini çıkarın",
  ru: "Неограниченный доступ: Наслаждайтесь IPTV на любом устройстве",
  pl: "Nielimitowany dostęp: Ciesz się IPTV na dowolnym urządzeniu",
  el: "Απεριόριστη πρόσβαση: Απολαύστε την IPTV σε οποιαδήποτε συσκευή",
};

export const allDevices = {
  en: "Our IPTV service is compatible with all devices, including smart TVs, mobile phones, tablets, laptops, and desktop computers. Enjoy your favorite channels and programs anytime, anywhere without any content restrictions.",
  fr: "Notre service IPTV est compatible avec tous les appareils, y compris les téléviseurs intelligents, les téléphones mobiles, les tablettes, les ordinateurs portables et les ordinateurs de bureau. Profitez de vos chaînes et programmes préférés où vous le souhaitez, sans aucune restriction de contenu.",
  es: "Nuestro servicio de IPTV es compatible con todos los dispositivos, incluyendo televisores inteligentes, teléfonos móviles, tabletas, portátiles y computadoras de escritorio. Disfruta de tus canales y programas favoritos siempre que quieras y donde quieras, sin restricciones de contenido.",
  de: "Unser IPTV-Service ist mit allen Geräten kompatibel, einschließlich Smart-TVs, Mobiltelefonen, Tablets, Laptops und Desktop-Computern. Genießen Sie Ihre Lieblingskanäle und -programme jederzeit und überall, ohne Inhaltsbeschränkungen.",
  it: "Il nostro servizio IPTV è compatibile con tutti i dispositivi, inclusi smart TV, telefoni cellulari, tablet, laptop e computer desktop. Goditi i tuoi canali e programmi preferiti quando e dove vuoi, senza alcuna restrizione di contenuto.",
  tr: "IPTV hizmetimiz, akıllı TV'ler, cep telefonları, tabletler, dizüstü bilgisayarlar ve masaüstü bilgisayarlar dahil olmak üzere tüm cihazlarla uyumludur. Favori kanallarınızı ve programlarınızı istediğiniz zaman ve istediğiniz yerde, içerik kısıtlamaları olmadan izleyin.",
  ru: "Наша IPTV-служба совместима со всеми устройствами, включая Smart TV, мобильные телефоны, планшеты, ноутбуки и настольные компьютеры. Наслаждайтесь своими любимыми каналами и программами в любое время и в любом месте, без ограничений на контент.",
  pl: "Nasza usługa IPTV jest kompatybilna z wszystkimi urządzeniami, w tym smart TV, telefonami komórkowymi, tabletami, laptopami i komputerami stacjonarnymi. Ciesz się ulubionymi kanałami i programami kiedy i gdziekolwiek chcesz, bez żadnych ograniczeń treści.",
  el: "Η υπηρεσία IPTV μας είναι συμβατή με όλες τις συσκευές, συμπεριλαμβανομένων των smart τηλεοράσεων, κινητών τηλεφώνων, ταμπλέτ, φορητών υπολογιστών και επιτραπέζιων υπολογιστών. Απολαύστε τα αγαπημένα σας κανάλια και προγράμματα οποιαδήποτε στιγμή, οπουδήποτε, χωρίς περιορισμούς στο περιεχόμενο.",
};

export const videoResolutions = {
  en: "Supporting A Wide Range Of Video Resolutions: SD, FHD, And Even 4K.",
  fr: "Prend en charge une large gamme de résolutions vidéo : SD, FHD et même 4K.",
  es: "Compatibilidad con una amplia gama de resoluciones de video: SD, FHD e incluso 4K.",
  de: "Unterstützung einer Vielzahl von Videoauflösungen: SD, FHD und sogar 4K.",
  it: "Supporto a una vasta gamma di risoluzioni video: SD, FHD e persino 4K.",
  tr: "Geniş Bir Video Çözünürlük Aralığını Destekleme: SD, FHD ve Hatta 4K.",
  ru: "Поддержка широкого диапазона видеоразрешений: SD, FHD и даже 4K.",
  pl: "Obsługa szerokiego zakresu rozdzielczości wideo: SD, FHD, a nawet 4K.",
  el: "Υποστηρίζοντας μια ευρεία γκάμα αναλύσεων βίντεο: SD, FHD και ακόμη και 4K.",
};
export const twentyFourHourTest = {
  en: "24H Test",
  fr: "Essai de 24 heures",
  es: "Prueba de 24 horas",
  de: "24-Stunden-Test",
  it: "Test di 24 ore",
  tr: "24 Saatlik Test",
  ru: "24-часовой тест",
  pl: "Test 24 godzinny",
  el: "24ωρη Δοκιμή",
};

// CONTACT US VIA WHATSAPP
export const contactUs = {
  en: "Contact us via",
  fr: "Contactez-nous via",
  es: "Contáctenos a través de",
  de: "Kontaktiere uns über",
  it: "Contattaci tramite",
  tr: "Bize ulaşın üzerinden",
  ru: "Свяжитесь с нами через",
  pl: "Skontaktuj się z nami via",
  el: "Επικοινωνήστε μαζί μας μέσω",
};
// channels_list paragraphs
export const tvChannelsList = {
  en: "TV Channels List",
  fr: "Liste des chaînes de télévision",
  es: "Lista de canales de televisión",
  de: "TV-Senderliste",
  it: "Elenco dei canali TV",
  tr: "TV Kanalları Listesi",
  ru: "Список телеканалов",
  pl: "Lista kanałów telewizyjnych",
};
export const moviesAndSeriesList = {
  en: "Movies and Series List",
  fr: "Liste des films et séries",
  es: "Lista de películas y series",
  de: "Filme und Serienliste",
  it: "Elenco di film e serie",
  tr: "Film ve Dizi Listesi",
  ru: "Список фильмов и сериалов",
  pl: "Lista filmów i seriali",
};
export const channelsTableDescription = {
  en: "The following table lists the channels available on CRYSTAL IPTV",
  fr: "Le tableau suivant répertorie les chaînes disponibles sur CRYSTAL IPTV",
  es: "La siguiente tabla lista los canales disponibles en CRYSTAL IPTV",
  de: "Die folgende Tabelle enthält eine Liste der auf CRYSTAL IPTV verfügbaren Sender",
  it: "La tabella seguente elenca i canali disponibili su CRYSTAL IPTV",
  tr: "Aşağıdaki tablo, CRYSTAL IPTV'de mevcut olan kanalları listeler",
  ru: "В таблице ниже перечислены каналы, доступные на CRYSTAL IPTV",
  pl: "Poniższa tabela zawiera listę dostępnych kanałów na CRYSTAL IPTV",
};
export const moviesAndSeriesTableDescription = {
  en: "The following table lists Movies and Series available on CRYSTAL IPTV",
  fr: "Le tableau suivant répertorie les films et séries disponibles sur CRYSTAL IPTV",
  es: "La siguiente tabla lista las películas y series disponibles en CRYSTAL IPTV",
  de: "Die folgende Tabelle enthält eine Liste der auf CRYSTAL IPTV verfügbaren Filme und Serien",
  it: "La tabella seguente elenca i film e le serie disponibili su CRYSTAL IPTV",
  tr: "Aşağıdaki tablo, CRYSTAL IPTV'de mevcut olan filmler ve dizileri listeler",
  ru: "В таблице ниже перечислены фильмы и сериалы, доступные на CRYSTAL IPTV",
  pl: "Poniższa tabela zawiera listę dostępnych filmów i seriali na CRYSTAL IPTV",
};
// HELMET PARAGRAPHS for Channels List
export const helmetTitleChannelsList = {
  en: "Crystal+ Premium IPTV, Channels List",
  fr: "Crystal+ Premium IPTV, Liste des Chaînes",
  es: "Crystal+ Premium IPTV, Lista de Canales",
  de: "Crystal+ Premium IPTV, Senderliste",
  it: "Crystal+ Premium IPTV, Elenco dei Canali",
  tr: "Crystal+ Premium IPTV, Kanal Listesi",
  ru: "Crystal+ Premium IPTV, Список Каналов",
  pl: "Crystal+ Premium IPTV, Lista Kanałów",
};

export const helmetDescriptionChannelsList = {
  en: "This page lists all the channels included in Crystal IPTV",
  fr: "Cette page répertorie tous les chaînes incluses dans Crystal IPTV",
  es: "Esta página lista todos los canales incluidos en Crystal IPTV",
  de: "Diese Seite listet alle Kanäle auf, die in Crystal IPTV enthalten sind",
  it: "Questa pagina elenca tutti i canali inclusi in Crystal IPTV",
  tr: "Bu sayfa, Crystal IPTV'de bulunan tüm kanalları listeleyen bir sayfadır",
  ru: "На этой странице перечислены все каналы, включенные в Crystal IPTV",
  pl: "Ta strona zawiera listę wszystkich kanałów dostępnych w Crystal IPTV",
};
export const helmetTitleMoviesSeriesList = {
  en: "Crystal+ Premium IPTV, Movies & Series List",
  fr: "Crystal+ Premium IPTV, Liste des Films et Séries",
  es: "Crystal+ Premium IPTV, Lista de Películas y Series",
  de: "Crystal+ Premium IPTV, Filme & Serien Liste",
  it: "Crystal+ Premium IPTV, Elenco di Film e Serie",
  tr: "Crystal+ Premium IPTV, Filmler ve Diziler Listesi",
  ru: "Crystal+ Premium IPTV, Список Фильмов и Сериалов",
  pl: "Crystal+ Premium IPTV, Lista Filmów i Seriali",
};
export const helmetDescriptionMoviesSeriesList = {
  en: "This page lists all the Movies and Series included in Crystal IPTV",
  fr: "Cette page répertorie tous les films et séries inclus dans Crystal IPTV",
  es: "Esta página lista todas las películas y series incluidas en Crystal IPTV",
  de: "Diese Seite listet alle Filme und Serien auf, die in Crystal IPTV enthalten sind",
  it: "Questa pagina elenca tutti i film e le serie inclusi in Crystal IPTV",
  tr: "Bu sayfa, Crystal IPTV'de bulunan tüm filmleri ve dizileri listeleyen bir sayfadır",
  ru: "На этой странице перечислены все фильмы и сериалы, включенные в Crystal IPTV",
  pl: "Ta strona zawiera listę wszystkich filmów i seriali dostępnych w Crystal IPTV",
};

// NOT FOUND 404
export const errorPageMessageH = {
  en: "Oops! Page Not Found",
  fr: "Oups ! Page non trouvée",
  es: "¡Vaya! Página no encontrada",
  de: "Hoppla! Seite nicht gefunden",
  it: "Ops! Pagina non trovata",
  tr: "Oops! Sayfa Bulunamadı",
  ru: "Упс! Страница не найдена",
  pl: "Ups! Strona nie znaleziona",
};
export const errorPageMessageP = {
  en: "We are sorry but the page you requested was not found",
  fr: "Nous sommes désolés, mais la page que vous avez demandée n'a pas été trouvée",
  es: "Lo sentimos, pero la página que solicitó no se ha encontrado",
  de: "Es tut uns leid, aber die von Ihnen angeforderte Seite wurde nicht gefunden",
  it: "Ci dispiace, ma la pagina che hai richiesto non è stata trovata",
  tr: "Üzgünüz, istediğiniz sayfa bulunamadı",
  ru: "Извините, но запрошенная вами страница не найдена",
  pl: "Przepraszamy, ale strony, której szukasz, nie znaleziono",
};
