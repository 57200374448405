export const resetPassword = {
  en: "Reset password",
  fr: "Réinitialiser le mot de passe",
  es: "Restablecer contraseña",
  de: "Passwort zurücksetzen",
  it: "Ripristina password",
  tr: "Şifreyi sıfırla",
  ru: "Сбросить пароль",
  pl: "Zresetuj hasło",
};

export const resetPasswordInstructions = {
  en: "Enter the email address associated with your account and we'll send you a link to reset your password.",
  fr: "Veuillez entrer l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  es: "Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña.",
  de: "Gib die E-Mail-Adresse ein, die mit deinem Konto verknüpft ist, und wir senden dir einen Link zum Zurücksetzen deines Passworts.",
  it: "Inserisci l'indirizzo email associato al tuo account e ti invieremo un link per reimpostare la password.",
  tr: "Hesabınıza bağlı olan e-posta adresini girin ve size şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.",
  ru: "Введите адрес электронной почты, связанный с вашей учетной записью, и мы вышлем вам ссылку для сброса пароля.",
  pl: "Podaj adres e-mail powiązany z twoim kontem, a wyślemy ci link do zresetowania hasła.",
};

export const email = {
  en: "Email",
  fr: "Email",
  es: "Correo electrónico",
  de: "E-Mail",
  it: "Email",
  tr: "E-posta",
  ru: "Электронная почта",
  pl: "Email",
};
export const login = {
  en: "Log in",
  fr: "Connexion",
  es: "Iniciar sesión",
  de: "Anmelden",
  it: "Accedi",
  tr: "Giriş yap",
  ru: "Войти",
  pl: "Zaloguj się",
};
export const thisFieldIsRequired = {
  en: "This field is required",
  fr: "Ce champ est obligatoire",
  es: "Este campo es obligatorio",
  de: "Dieses Feld ist erforderlich",
  it: "Questo campo è obbligatorio",
  tr: "Bu alan gereklidir",
  ru: "Это поле обязательно для заполнения",
  pl: "To pole jest wymagane",
};
export const didNotHaveAnAccount = {
  en: "Did not have an account?",
  fr: "Vous n'avez pas de compte ?",
  es: "¿No tienes una cuenta?",
  de: "Kein Konto vorhanden?",
  it: "Non hai un account?",
  tr: "Hesabınız yok mu?",
  ru: "У вас нет аккаунта?",
  pl: "Nie masz konta?",
};
export const signUp = {
  en: "Sign up",
  fr: "S'inscrire",
  es: "Registrarse",
  de: "Registrieren",
  it: "Registrati",
  tr: "Kaydol",
  ru: "Зарегистрироваться",
  pl: "Zarejestruj się",
};

export const passwordResetLinkSent = {
  en: "Password reset link has been sent to your mailbox",
  fr: "Le lien de réinitialisation du mot de passe a été envoyé dans votre boîte de réception",
  es: "Se ha enviado el enlace de restablecimiento de contraseña a tu buzón",
  de: "Der Link zum Zurücksetzen des Passworts wurde an Ihr Postfach gesendet",
  it: "Il link di reset della password è stato inviato alla tua casella di posta",
  tr: "Şifre sıfırlama bağlantısı e-posta kutunuza gönderildi",
  ru: "Ссылка для сброса пароля отправлена на ваш почтовый ящик",
  pl: "Link do zresetowania hasła został wysłany na Twój skrzynkę pocztową",
};
export const passwordResetNote = {
  en: "Please note that if the email you entered is valid and associated with your account, you should receive the reset password email shortly.",
  fr: "Veuillez noter que si l'adresse e-mail que vous avez saisie est valide et associée à votre compte, vous devriez recevoir sous peu l'e-mail de réinitialisation du mot de passe.",
  es: "Ten en cuenta que si el correo electrónico que has ingresado es válido y está asociado a tu cuenta, deberías recibir pronto el correo electrónico de restablecimiento de contraseña.",
  de: "Bitte beachten Sie, dass Sie in Kürze die E-Mail zum Zurücksetzen des Passworts erhalten, sofern die eingegebene E-Mail-Adresse gültig ist und mit Ihrem Konto verknüpft ist.",
  it: "Si prega di notare che se l'indirizzo email che hai inserito è valido e associato al tuo account, dovresti ricevere a breve l'email di reset della password.",
  tr: "Lütfen girdiğiniz e-postanın geçerli ve hesabınızla ilişkili olduğunu unutmayın. Kısa süre içinde şifre sıfırlama e-postasını almanız gerekmektedir.",
  ru: "Обратите внимание, что если введенный вами адрес электронной почты действителен и связан с вашей учетной записью, вы должны в ближайшее время получить письмо для сброса пароля.",
  pl: "Proszę zwrócić uwagę, że jeśli wprowadzony przez Ciebie adres email jest prawidłowy i powiązany z Twoim kontem, wkrótce otrzymasz email z linkiem do zresetowania hasła.",
};
export const emailNotReceivedNote = {
  en: "If you do not receive the email within a few minutes, please check your spam or junk folder.",
  fr: "Si vous ne recevez pas l'e-mail dans les quelques minutes qui suivent, veuillez vérifier votre dossier de spam ou de courrier indésirable.",
  es: "Si no recibes el correo electrónico en unos minutos, por favor revisa tu carpeta de spam o correo no deseado.",
  de: "Wenn Sie die E-Mail nicht innerhalb weniger Minuten erhalten, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner.",
  it: "Se non ricevi l'email entro pochi minuti, controlla la tua cartella spam o posta indesiderata.",
  tr: "E-postayı birkaç dakika içinde almadıysanız, lütfen spam veya gereksiz posta klasörünüzü kontrol edin.",
  ru: "Если вы не получили письмо в течение нескольких минут, проверьте папку со спамом или нежелательной почтой.",
  pl: "Jeśli nie otrzymasz wiadomości e-mail w ciągu kilku minut, proszę sprawdzić folder spam lub wiadomości niechciane.",
};
