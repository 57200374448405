export const refundPolicy = {
  en: "Our Refund Policy",
  fr: "Notre politique de remboursement",
  es: "Nuestra política de reembolso",
  de: "Unsere Rückerstattungsrichtlinie",
  it: "La nostra politica di rimborso",
  tr: "İade Politikamız",
  ru: "Наша политика возврата",
  pl: "Nasza polityka zwrotów",
};

export const commitmentText = {
  en: "1. Our Commitment to Fairness and Transparency:",
  fr: "1. Notre engagement envers l'équité et la transparence :",
  es: "1. Nuestro compromiso con la equidad y la transparencia:",
  de: "1. Unser Engagement für Fairness und Transparenz:",
  it: "1. Il nostro impegno per equità e trasparenza:",
  tr: "1. Adil ve Şeffaflık Taahhütümüz:",
  ru: "1. Наша приверженность справедливости и прозрачности:",
  pl: "1. Nasze zobowiązanie do uczciwości i przejrzystości:",
};
export const refundPolicyDetails = {
  en: "At Crystal Plus, our refund policy is grounded in the principles of fairness and transparency. We prioritize open communication with our customers, aiming to address and resolve any concerns before the need for a return arises.",
  fr: "Chez Crystal Plus, notre politique de remboursement est basée sur les principes d'équité et de transparence. Nous accordons une priorité à la communication ouverte avec nos clients, cherchant à résoudre tout problème avant que la nécessité d'un retour ne se présente.",
  es: "En Crystal Plus, nuestra política de reembolso se basa en los principios de equidad y transparencia. Priorizamos la comunicación abierta con nuestros clientes, con el objetivo de abordar y resolver cualquier inquietud antes de que surja la necesidad de una devolución.",
  de: "Bei Crystal Plus basiert unsere Rückerstattungsrichtlinie auf den Prinzipien von Fairness und Transparenz. Wir legen Wert auf offene Kommunikation mit unseren Kunden und streben an, etwaige Bedenken zu klären und zu lösen, bevor ein Rückgabeantrag gestellt wird.",
  it: "Da Crystal Plus, la nostra politica di rimborso si basa sui principi di equità e trasparenza. Diamo priorità alla comunicazione aperta con i nostri clienti, cercando di affrontare e risolvere eventuali preoccupazioni prima che si renda necessario un reso.",
  tr: "Crystal Plus'ta iade politikamız, adillik ve şeffaflık prensiplerine dayanmaktadır. Müşterilerimizle açık iletişime öncelik veriyor, bir iade ihtiyacı ortaya çıkmadan önce herhangi bir endişeyi ele almayı ve çözmeyi amaçlıyoruz.",
  ru: "В Crystal Plus наша политика возврата основана на принципах справедливости и прозрачности. Мы отдаем предпочтение открытому общению с нашими клиентами, стремясь решить любые проблемы до возникновения необходимости возврата.",
  pl: "W Crystal Plus nasza polityka zwrotów opiera się na zasadach uczciwości i przejrzystości. Priorytetem jest dla nas otwarta komunikacja z naszymi klientami, dążąc do rozwiązania wszelkich problemów przed koniecznością zwrotu.",
};
export const refundTimeframe = {
  en: "2. Timeframe for Refund Requests:",
  fr: "2. Délai pour les demandes de remboursement :",
  es: "2. Plazo para solicitudes de reembolso:",
  de: "2. Zeitrahmen für Rückerstattungsanfragen:",
  it: "2. Tempistica per le richieste di rimborso:",
  tr: "2. İade Talepleri İçin Zaman Çerçevesi:",
  ru: "2. Сроки рассмотрения запросов на возврат:",
  pl: "2. Ramy czasowe dla wniosków o zwrot środków:",
};

export const refundTimeframeDetails = {
  en: "Understanding the importance of flexibility, customers can request a refund within specific timeframes. For subscriptions lasting three months or more, the window is two weeks from the purchase date. One-month subscription holders have a one-week timeframe. It's crucial to note that the countdown begins upon our team's delivery of the subscription, excluding any delays caused by our end.",
  fr: "Comprendre l'importance de la flexibilité, les clients peuvent demander un remboursement dans des délais spécifiques. Pour les abonnements d'une durée de trois mois ou plus, la période est de deux semaines à compter de la date d'achat. Les détenteurs d'abonnements d'un mois ont une période d'une semaine. Il est essentiel de noter que le compte à rebours commence à la livraison de l'abonnement par notre équipe, à l'exclusion de tout retard causé par notre part.",
  es: "Entendiendo la importancia de la flexibilidad, los clientes pueden solicitar un reembolso dentro de plazos específicos. Para suscripciones de tres meses o más, la ventana es de dos semanas desde la fecha de compra. Los titulares de suscripciones mensuales tienen un plazo de una semana. Es crucial tener en cuenta que la cuenta regresiva comienza al momento de la entrega de la suscripción por parte de nuestro equipo, excluyendo cualquier retraso causado por nuestro lado.",
  de: "Um die Bedeutung von Flexibilität zu verstehen, können Kunden innerhalb bestimmter Zeitrahmen eine Rückerstattung beantragen. Bei Abonnements mit einer Laufzeit von drei Monaten oder mehr beträgt das Zeitfenster zwei Wochen ab dem Kaufdatum. Inhaber von Einmonatsabonnements haben einen Zeitrahmen von einer Woche. Es ist wichtig zu beachten, dass der Countdown mit der Lieferung des Abonnements durch unser Team beginnt, unter Ausschluss von Verzögerungen, die durch unser Ende verursacht werden.",
  it: "Comprendendo l'importanza della flessibilità, i clienti possono richiedere un rimborso entro determinati limiti temporali. Per gli abbonamenti con una durata di tre mesi o più, la finestra è di due settimane dalla data di acquisto. I titolari di abbonamenti mensili hanno un periodo di una settimana. È cruciale notare che il conteggio alla rovescia inizia con la consegna dell'abbonamento da parte del nostro team, escludendo eventuali ritardi causati dal nostro lato.",
  tr: "Esnekliğin önemini anlayarak, müşteriler belirli zaman çerçeveleri içinde iade talebinde bulunabilirler. Üç ay veya daha uzun süreli abonelikler için pencere, satın alma tarihinden itibaren iki haftadır. Bir aylık abonelik sahiplerinin bir haftalık bir zaman çerçevesi bulunmaktadır. Önemli bir not olarak, geri sayım ekibimizin aboneliği teslim etmesiyle başlar ve tarafımızdan kaynaklanan herhangi bir gecikmeyi hariç tutar.",
  ru: "Понимая важность гибкости, клиенты могут запрашивать возврат средств в определенные сроки. Для подписок длительностью три месяца и более окно составляет две недели с даты покупки. Владельцы подписок на один месяц имеют срок в одну неделю. Важно отметить, что обратный отсчет начинается с момента доставки подписки нашей командой, исключая любые задержки, вызванные нашей стороной.",
  pl: "Rozumiejąc ważność elastyczności, klienci mogą składać wnioski o zwrot środków w określonych ramach czasowych. Dla subskrypcji trwających trzy miesiące lub dłużej okno czasowe wynosi dwa tygodnie od daty zakupu. Posiadacze subskrypcji miesięcznych mają okres jednego tygodnia. Ważne jest zauważenie, że odliczanie czasu rozpoczyna się w chwili dostarczenia subskrypcji przez nasz zespół, z wyłączeniem wszelkich opóźnień spowodowanych naszej stronie.",
};
export const qualifyingForRefund = {
  en: "3. Qualifying for a Refund:",
  fr: "3. Éligibilité pour un remboursement :",
  es: "3. Requisitos para un reembolso:",
  de: "3. Berechtigung für eine Rückerstattung:",
  it: "3. Requisiti per un rimborso:",
  tr: "3. İade İçin Uygunluk:",
  ru: "3. Право на возврат средств:",
  pl: "3. Kwalifikacja do zwrotu środków:",
};
export const refundRequestRights = {
  en: "Customers reserve the right to request a refund if the service does not meet expectations. This may include discrepancies in the channels listed or a subpar streaming experience. Our dedicated team is committed to exhausting all possible solutions before considering a refund.",
  fr: "Les clients ont le droit de demander un remboursement si le service ne répond pas aux attentes. Cela peut inclure des divergences dans les chaînes répertoriées ou une expérience de streaming médiocre. Notre équipe dévouée s'engage à épuiser toutes les solutions possibles avant de considérer un remboursement.",
  es: "Los clientes tienen el derecho de solicitar un reembolso si el servicio no cumple con las expectativas. Esto puede incluir discrepancias en los canales enumerados o una experiencia de transmisión deficiente. Nuestro equipo dedicado se compromete a agotar todas las soluciones posibles antes de considerar un reembolso.",
  de: "Kunden haben das Recht, eine Rückerstattung zu beantragen, wenn der Service nicht den Erwartungen entspricht. Dies kann Diskrepanzen in den aufgeführten Kanälen oder eine unzureichende Streaming-Erfahrung einschließen. Unser engagiertes Team verpflichtet sich, alle möglichen Lösungen auszuschöpfen, bevor eine Rückerstattung in Betracht gezogen wird.",
  it: "I clienti hanno il diritto di richiedere un rimborso se il servizio non soddisfa le aspettative. Ciò può includere discrepanze nei canali elencati o un'esperienza di streaming non all'altezza. Il nostro team dedicato si impegna a esaurire tutte le soluzioni possibili prima di considerare un rimborso.",
  tr: "Müşteriler, hizmetin beklentileri karşılamaması durumunda bir iade talebinde bulunma hakkına sahiptir. Bu, listelenen kanallarda uyumsuzluklar veya yetersiz bir yayın deneyimi içerebilir. Özel ekibimiz, bir iadeyi düşünmeden önce tüm olası çözümleri tüketmeye kararlıdır.",
  ru: "У клиентов есть право запрашивать возврат средств, если услуга не соответствует ожиданиям. Это может включать в себя расхождения в перечисленных каналах или недостаточный опыт просмотра. Наша выделенная команда обязуется исчерпать все возможные решения перед рассмотрением вопроса о возврате.",
  pl: "Klienci mają prawo do żądania zwrotu środków, jeśli usługa nie spełnia oczekiwań. Może to obejmować rozbieżności w wymienionych kanałach lub poniżej oczekiwań doświadczenia ze strumieniowaniem. Nasz oddany zespół zobowiązuje się do wyczerpania wszystkich możliwych rozwiązań przed rozważeniem zwrotu środków.",
};
export const refundInitiation = {
  en: "4. Initiating a Refund Request:",
  fr: "4. Initiation d'une demande de remboursement :",
  es: "4. Iniciar una solicitud de reembolso:",
  de: "4. Einleitung einer Rückerstattungsanfrage:",
  it: "4. Avviare una richiesta di rimborso:",
  tr: "4. İade Talebi Başlatma:",
  ru: "4. Инициирование запроса на возврат средств:",
  pl: "4. Inicjowanie wniosku o zwrot środków:",
};

export const dissatisfactionRefundRequest1 = {
  en: "In cases of dissatisfaction, initiating a refund request is a straightforward process. Reach out to us via our WhatsApp account at ",
  fr: "En cas de mécontentement, l'initiation d'une demande de remboursement est un processus simple. Contactez-nous via notre compte WhatsApp au :",
  es: "En casos de insatisfacción, iniciar una solicitud de reembolso es un proceso sencillo. Comuníquese con nosotros a través de nuestra cuenta de WhatsApp al :",
  de: "Bei Unzufriedenheit ist die Einleitung einer Rückerstattungsanfrage ein unkomplizierter Vorgang. Kontaktieren Sie uns über unser WhatsApp-Konto unter ",
  it: "Nei casi di insoddisfazione, l'avvio di una richiesta di rimborso è un processo semplice. Contattaci tramite il nostro account WhatsApp al :",
  tr: "Memnuniyetsizlik durumlarında iade talebi başlatma işlemi oldukça basittir. Bize :",
  ru: "В случае неудовлетворенности инициирование запроса на возврат средств - простой процесс. Свяжитесь с нами через наш аккаунт WhatsApp по номеру :",
  pl: "W przypadku niezadowolenia, zainicjowanie wniosku o zwrot środków to prosty proces. Skontaktuj się z nami za pośrednictwem naszego konta WhatsApp pod numerem ",
};
export const dissatisfactionRefundRequest2 = {
  en: "or through email at ",
  fr: "ou par e-mail à ",
  es: "o por correo electrónico a ",
  de: "oder per E-Mail unter ",
  it: "o via email all'indirizzo ",
  tr: "veya e-posta ile ",
  ru: "или по электронной почте на ",
  pl: "lub za pośrednictwem poczty elektronicznej pod adresem ",
};
export const dissatisfactionRefundRequest3 = {
  en: "Remember, a valid reason substantiating the request is essential. Refunds are considered only after our team has explored all available solutions.",
  fr: "N'oubliez pas, une raison valable étayant la demande est essentielle. Les remboursements ne sont envisagés qu'après que notre équipe a exploré toutes les solutions disponibles.",
  es: "Recuerda, es esencial contar con una razón válida que respalde la solicitud. Los reembolsos se consideran solo después de que nuestro equipo haya explorado todas las soluciones disponibles.",
  de: "Denke daran, dass eine gültige Begründung für die Anfrage unerlässlich ist. Rückerstattungen werden nur in Betracht gezogen, nachdem unser Team alle verfügbaren Lösungen erkundet hat.",
  it: "Ricorda, è essenziale fornire una ragione valida che giustifichi la richiesta. I rimborsi vengono considerati solo dopo che il nostro team ha esplorato tutte le soluzioni disponibili.",
  tr: "Unutma, talebi destekleyen geçerli bir neden önemlidir. İadeler, ekibimiz tüm mevcut çözümleri inceledikten sonra düşünülmektedir.",
  ru: "Помните, что существование веской причины в основу запроса является неотъемлемым условием. Возвраты рассматриваются только после того, как наша команда исследовала все возможные решения.",
  pl: "Pamiętaj, że istotne jest podanie ważnego powodu popierającego wniosek. Zwroty są rozważane dopiero po tym, jak nasz zespół zbadał wszystkie dostępne rozwiązania.",
};
export const refundProcessingAndDifficulty = {
  en: "5. Refund Processing and Difficulty:",
  fr: "5. Traitement des remboursements et difficultés :",
  es: "5. Procesamiento de reembolsos y dificultades:",
  de: "5. Rückerstattungsabwicklung und Schwierigkeiten:",
  it: "5. Elaborazione dei rimborsi e difficoltà:",
  tr: "5. İade İşlemi ve Zorluklar:",
  ru: "5. Обработка возврата и сложности:",
  pl: "5. Przetwarzanie zwrotów i trudności:",
};
export const refundProcessingDetails = {
  en: "We aim to process reasonable refund requests promptly. However, our dedicated team will diligently work towards resolving the issue before resorting to a refund. While we stand by the quality of our service, it's important to recognize that technical issues may arise. Therefore, refunds will be issued based on valid reasons, ensuring fairness and preventing frivolous claims.",
  fr: "Nous visons à traiter rapidement les demandes de remboursement raisonnables. Cependant, notre équipe dévouée travaillera diligemment pour résoudre le problème avant de recourir à un remboursement. Bien que nous garantissions la qualité de notre service, il est important de reconnaître que des problèmes techniques peuvent survenir. Par conséquent, les remboursements seront émis sur la base de raisons valables, assurant l'équité et évitant les demandes frivoles.",
  es: "Nuestro objetivo es procesar rápidamente las solicitudes de reembolso razonables. Sin embargo, nuestro equipo dedicado trabajará diligentemente para resolver el problema antes de recurrir a un reembolso. Aunque respaldamos la calidad de nuestro servicio, es importante reconocer que pueden surgir problemas técnicos. Por lo tanto, los reembolsos se emitirán basados en razones válidas, asegurando la equidad y evitando reclamaciones frívolas.",
  de: "Unser Ziel ist es, vernünftige Rückerstattungsanfragen schnell zu bearbeiten. Unser engagiertes Team wird jedoch mit Nachdruck daran arbeiten, das Problem zu lösen, bevor auf eine Rückerstattung zurückgegriffen wird. Obwohl wir zur Qualität unseres Dienstes stehen, ist es wichtig zu erkennen, dass technische Probleme auftreten können. Daher werden Rückerstattungen auf der Grundlage gültiger Gründe ausgestellt, um Fairness zu gewährleisten und unbegründete Ansprüche zu verhindern.",
  it: "Il nostro obiettivo è elaborare prontamente le richieste di rimborso ragionevoli. Tuttavia, il nostro team dedicato lavorerà diligentemente per risolvere il problema prima di ricorrere a un rimborso. Pur sostenendo la qualità del nostro servizio, è importante riconoscere che possono sorgere problemi tecnici. Pertanto, i rimborsi saranno emessi sulla base di motivi validi, garantendo equità e prevenendo reclami frivoli.",
  tr: "Makul iade taleplerini hızlı bir şekilde işlemeyi amaçlıyoruz. Ancak, özel ekibimiz, iadeye başvurmadan önce sorunu çözmek için özenle çalışacaktır. Hizmetimizin kalitesinin arkasında dursak da, teknik sorunların ortaya çıkabileceğini fark etmek önemlidir. Bu nedenle, iadeler geçerli nedenlere dayalı olarak yapılacak, adil bir şekilde sağlanacak ve hafifletilmiş talepleri önleyecektir.",
  ru: "Мы стремимся оперативно обрабатывать разумные запросы на возврат средств. Тем не менее, наша выделенная команда будет усердно работать над урегулированием проблемы, прежде чем прибегнуть к возврату средств. Несмотря на то, что мы придерживаемся качества нашего сервиса, важно понимать, что могут возникнуть технические проблемы. Поэтому возвраты будут выплачиваться на основе веских причин, обеспечивая справедливость и предотвращая беспочвенные требования.",
  pl: "Zależy nam na szybkim rozpatrywaniu rozsądnych wniosków o zwrot środków. Jednak nasz oddany zespół będzie starannie pracować nad rozwiązaniem problemu, zanim zdecyduje się na zwrot środków. Chociaż stoimy za jakością naszej usługi, ważne jest uznanie, że mogą wystąpić problemy techniczne. Dlatego zwroty będą uwzględniane na podstawie ważnych powodów, zapewniając uczciwość i zapobiegając nieuzasadnionym roszczeniom.",
};
