import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import components
import PricingCardsCombined from "../../comps/pricing_cards/PricingCardsCombined";

// import images
import subscriptions_icon from "../../images/subscriptions_icon.svg";

// import paragraphs
import * as int from "../../../paragraphs/account_settings_paragraphs";

function AccountSettingsSubscriptions(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );
  function calculateRemainingDays(endingDate) {
    // Parse the endingDate string to a Date object
    const endDate = new Date(endingDate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - currentDate;

    // Check if the subscription has expired
    if (timeDifference < 0) {
      return "Expired";
    }

    // Calculate the remaining days
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return remainingDays.toString();
  }

  // JXS producer
  const produceSusbscriptionsTableRows = (data) => {
    return data.map((data, index) => {
      return (
        <tr key={index}>
          <td>{data.id * 2342}</td>
          <td>{data.starting_date}</td>
          <td>{data.ending_date}</td>
          <td>{data.iptv_username}</td>
          <td>{data.iptv_password}</td>
          <td>{calculateRemainingDays(data.ending_date)}</td>
        </tr>
      );
    });
  };
  const produceSusbsriptionsEntireTable = () => {
    return (
      <div className="settings_table_wrapper">
        <table className="settings_table">
          <thead>
            <tr>
              <th>{int.subscription_id[displayLanguage]}</th>
              <th>{int.starting_date[displayLanguage]}</th>
              <th>{int.ending_date[displayLanguage]}</th>
              <th>{int.username[displayLanguage]}</th>
              <th>{int.password[displayLanguage]}</th>
              <th>{int.remaining_days[displayLanguage]}</th>
            </tr>
          </thead>
          <tbody>{produceSusbscriptionsTableRows(props.data)}</tbody>
        </table>
      </div>
    );
  };

  const youHaveNoSubscription = () => {
    return (
      <>
        <p className="no_data_to_show">
          {int.subscriptionStatus[displayLanguage]}
        </p>
        <PricingCardsCombined />
      </>
    );
  };
  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="settings_header_wrapper">
        <div className="settings_header_icon_wrapper">
          <div className="header_icon">
            <img src={subscriptions_icon} alt="subscriptions_icon" />
          </div>

          <div>
            <h1>{int.sub_history[displayLanguage]}</h1>
            <p>{int.sub_description[displayLanguage]}</p>
          </div>
        </div>{" "}
      </div>

      {/* Please wait  and table*/}
      {props.data == undefined ? (
        <p className="no_data_to_show">{int.pleaseWait[displayLanguage]}</p>
      ) : props.data.length != 0 ? (
        produceSusbsriptionsEntireTable()
      ) : (
        youHaveNoSubscription()
      )}
    </div>
  );
}

export default AccountSettingsSubscriptions;
