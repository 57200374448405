import { useState, useEffect } from "react";
// import paragraphs
import * as int from "../../../paragraphs/landing_page_paragraphs.js";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms.js";
// import images
import landing_page_poster_1 from "../../images/landing_page/landing_page_poster_1.webp";
import landing_page_poster_1_1 from "../../images/landing_page/landing_page_poster_1_1.webp";
import landing_page_poster_2 from "../../images/landing_page/landing_page_poster_2.webp";
import landing_page_poster_2_1 from "../../images/landing_page/landing_page_poster_2_1.webp";
import landing_page_poster_3 from "../../images/landing_page/landing_page_poster_3.webp";
import landing_page_poster_3_1 from "../../images/landing_page/landing_page_poster_3_1.webp";
import landing_page_poster_4 from "../../images/landing_page/landing_page_poster_4.webp";
import landing_page_poster_4_1 from "../../images/landing_page/landing_page_poster_4_1.webp";
import landing_page_poster_5 from "../../images/landing_page/landing_page_poster_5.webp";
import landing_page_poster_6 from "../../images/landing_page/landing_page_poster_6.webp";
import landing_page_poster_7 from "../../images/landing_page/landing_page_poster_7.webp";
// Import components
import LandingPageHighlighedChannels from "./LandingPageHighlightedChannels";
//
function LandingPageSection2() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  const maxWidth = 550;
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to track window resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <>
      <h1>{int.entertainmentElevation[displayLanguage]}</h1>

      <div className="posters_grid">
        <img src={landing_page_poster_5} alt="landing_page_poster_5" />
        {/* Puss in boots */}
        {screenWidth >= maxWidth ? (
          <img src={landing_page_poster_4} alt="landing_page_poster_4" />
        ) : (
          <img src={landing_page_poster_4_1} alt="landing_page_poster_4_1" />
        )}
        {screenWidth <= maxWidth && (
          <img src={landing_page_poster_1_1} alt="landing_page_poster_1" />
        )}
        {screenWidth <= maxWidth && (
          <img src={landing_page_poster_7} alt="landing_page_poster_7" />
        )}

        {screenWidth >= maxWidth && (
          <div>
            <img src={landing_page_poster_1} alt="landing_page_poster_1" />
            <img src={landing_page_poster_7} alt="landing_page_poster_7" />
          </div>
        )}
        {screenWidth >= maxWidth ? (
          <img src={landing_page_poster_2} alt="landing_page_poster_2" />
        ) : (
          <img src={landing_page_poster_2_1} alt="landing_page_poster_2_1" />
        )}

        <img src={landing_page_poster_6} alt="landing_page_poster_6" />

        {screenWidth >= maxWidth ? (
          <img src={landing_page_poster_3} alt="landing_page_poster_3" />
        ) : (
          <img src={landing_page_poster_3_1} alt="landing_page_poster_3" />
        )}
      </div>
      <LandingPageHighlighedChannels />
      <span className="and_more">{int.andMore[displayLanguage]}</span>
    </>
  );
}

export default LandingPageSection2;
