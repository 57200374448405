export const welcomeMessage = {
  en: "Welcome back to our IPTV service! Login to access your account's settings, tickets, orders, and more.",
  fr: "Bienvenue de nouveau sur notre service IPTV ! Connectez-vous pour accéder aux paramètres de votre compte, aux tickets, aux commandes et plus encore.",
  es: "¡Bienvenido de nuevo a nuestro servicio de IPTV! Inicia sesión para acceder a la configuración de tu cuenta, tus tickets, tus pedidos y más.",
  de: "Willkommen zurück zu unserem IPTV-Service! Melden Sie sich an, um auf die Kontoeinstellungen, Tickets, Bestellungen und mehr zuzugreifen.",
  it: "Benvenuto di nuovo al nostro servizio IPTV! Accedi per accedere alle impostazioni del tuo account, ai ticket, agli ordini e altro ancora.",
  tr: "IPTV hizmetimize hoş geldiniz! Hesap ayarlarına, biletlerinize, siparişlerinize ve daha fazlasına erişmek için giriş yapın.",
  ru: "Добро пожаловать обратно в наш IPTV-сервис! Войдите, чтобы получить доступ к настройкам вашего аккаунта, билетам, заказам и другим возможностям.",
  pl: "Witamy z powrotem w naszej usłudze IPTV! Zaloguj się, aby uzyskać dostęp do ustawień konta, biletów, zamówień i nie tylko.",
};

export const ContinueWithGoogle = {
  en: "Continue with Google",
  fr: "Continuer avec Google",
  es: "Continuar con Google",
  de: "Mit Google fortfahren",
  it: "Continua con Google",
  tr: "Google ile devam et",
  ru: "Продолжить с Google",
  pl: "Kontynuuj z Google",
};

export const login = {
  en: "Log in",
  fr: "Connexion",
  es: "Iniciar sesión",
  de: "Anmelden",
  it: "Accedi",
  tr: "Giriş yap",
  ru: "Войти",
  pl: "Zaloguj się",
};
export const emailLabel = {
  en: "Email",
  fr: "Email",
  es: "Correo electrónico",
  de: "E-Mail",
  it: "Email",
  tr: "E-posta",
  ru: "Электронная почта",
  pl: "Email",
};

export const passwordLabel = {
  en: "Password",
  fr: "Mot de passe",
  es: "Contraseña",
  de: "Passwort",
  it: "Password",
  tr: "Parola",
  ru: "Пароль",
  pl: "Hasło",
};

export const didNotHaveAnAccount = {
  en: "Did not have an account?",
  fr: "Vous n'avez pas de compte ?",
  es: "¿No tienes una cuenta?",
  de: "Kein Konto vorhanden?",
  it: "Non hai un account?",
  tr: "Hesabınız yok mu?",
  ru: "У вас нет аккаунта?",
  pl: "Nie masz konta?",
};

export const signUp = {
  en: "Sign up",
  fr: "S'inscrire",
  es: "Registrarse",
  de: "Registrieren",
  it: "Registrati",
  tr: "Kaydol",
  ru: "Зарегистрироваться",
  pl: "Zarejestruj się",
};

export const errorMessageNotFound = {
  en: "No active account found with the given credentials. Please check your email and password.",
  fr: "Aucun compte actif trouvé avec les identifiants fournis. Veuillez vérifier votre adresse e-mail et votre mot de passe.",
  es: "No se encontró una cuenta activa con las credenciales proporcionadas. Por favor, verifique su correo electrónico y contraseña.",
  de: "Es wurde kein aktives Konto mit den angegebenen Anmeldedaten gefunden. Bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort.",
  it: "Nessun account attivo trovato con le credenziali fornite. Si prega di controllare la tua email e la password.",
  tr: "Verilen kimlik bilgileriyle eşleşen aktif bir hesap bulunamadı. Lütfen e-posta adresinizi ve parolanızı kontrol edin.",
  ru: "С указанными учетными данными активная учетная запись не найдена. Пожалуйста, проверьте свою электронную почту и пароль.",
  pl: "Nie znaleziono aktywnego konta o podanych danych uwierzytelniających. Proszę sprawdzić swój adres e-mail i hasło.",
};
export const thisFieldIsRequired = {
  en: "This field is required",
  fr: "Ce champ est obligatoire",
  es: "Este campo es obligatorio",
  de: "Dieses Feld ist erforderlich",
  it: "Questo campo è obbligatorio",
  tr: "Bu alan gereklidir",
  ru: "Это поле обязательно для заполнения",
  pl: "To pole jest wymagane",
};

export const forgotPassword = {
  en: "Forgot password?",
  fr: "Mot de passe oublié ?",
  es: "¿Olvidaste tu contraseña?",
  de: "Passwort vergessen?",
  it: "Hai dimenticato la password?",
  tr: "Parolanızı mı unuttunuz?",
  ru: "Забыли пароль?",
  pl: "Zapomniałeś hasła?",
};

export const resetPassword = {
  en: "Reset password",
  fr: "Réinitialiser le mot de passe",
  es: "Restablecer contraseña",
  de: "Passwort zurücksetzen",
  it: "Ripristina password",
  tr: "Parolayı sıfırla",
  ru: "Сбросить пароль",
  pl: "Zresetuj hasło",
};
export const connectionIssue = {
  en: "Connection Issue, Please ensure connectivity.",
  fr: "Problème de connexion, veuillez vérifier votre connexion.",
  es: "Problema de conexión, por favor asegúrese de tener conectividad.",
  de: "Verbindungsproblem, bitte stellen Sie sicher, dass Sie eine Verbindung haben.",
  it: "Problema di connessione, assicurati di essere connesso.",
  tr: "Bağlantı Sorunu, lütfen bağlantınızı kontrol edin.",
  ru: "Проблема с подключением, пожалуйста, убедитесь в наличии соединения.",
  pl: "Problem z połączeniem, proszę sprawdzić połączenie.",
};
