import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";

function createData(Email, fullname, purchased_subscription, whatsapp) {
  return {
    Email,
    fullname,
    purchased_subscription,
    whatsapp: whatsapp.toString().replace(/^(\d{3})(\d{3})(\d+)$/, "$1-$2-$3"),
  };
}
const columns = [
  "Email",
  "Full name",
  "Purchased Subscriptions",
  "WhatsApp Number",
];
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 43643645),
  createData("Ice cream sandwich", 237, 9.0, 57634572),
  createData("Eclair", 262, 16.0, 65372752),
  createData("Cupcake", 305, 3.7, 375426542),
  createData("Gingerbread", 356, 16.0, 655375242),
];
function AdminPanelCustomers() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((item) => {
              return (
                <TableCell
                  style={{ backgroundColor: grey[300], color: "black" }}
                >
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Email}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Email}
              </TableCell>
              <TableCell align="right">{row.fullname}</TableCell>
              <TableCell align="right">{row.purchased_subscription}</TableCell>
              <TableCell align="right">{row.whatsapp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AdminPanelCustomers;
