import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
// import components
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";
// import CSS
import "../styles/pricing_page.css";
//import paragpraphs
import * as int from "../../paragraphs/pricing_page_paragraphs.js";

function PricingPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );
  //
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);
  //
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitle[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescription[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystal-plus.com" />
      </Helmet>

      <div className={wrapperClass}>
        <h2 className="pricing_page_header">
          {int.readyToGetStarted[displayLanguage]}
        </h2>
        <p className="pricing_page_p">
          {int.choosePlanThatSuitsYou[displayLanguage]}
        </p>
        <PricingCardsCombined />
      </div>
    </>
  );
}

export default PricingPage;
