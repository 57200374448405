import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import { useEffect, useState } from "react";
import { record } from "rrweb";

const RRWeb = () => {
  let fingerprint = "";

  useEffect(() => {
    let actions = [];
    record({
      emit(event) {
        actions.push(event);
      },
    });

    Fingerprint2.get((components) => {
      fingerprint = Fingerprint2.x64hash128(
        components.map((pair) => pair.value).join(""),
        31
      );
    });
    //
    setInterval(() => {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/analytics/recordsession/`,
        data: {
          user_hash: fingerprint,
          events: actions,
        },
      })
        .then(() => {
          console.log("Session recorded");
        })
        .catch((err) => {
          console.log("Session failed", err);
        });
    }, 5 * 1000);
  }, []);

  return null;
};

export default RRWeb;
