import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeviceCard from "../comps/installation_guide/DeviceCard";
import android_logo from "../images/installation_guide/android_os_logo.svg";
import ios_logo from "../images/installation_guide/IOS_logo.svg";
import lg_logo from "../images/installation_guide/LG_logo.svg";
import windows_logo from "../images/installation_guide/Windows_Logo.svg";
import amazon_fire_stick from "../images/installation_guide/Amazon_Fire_stick.svg";
import enigma_logo from "../images/installation_guide/ENIGMA.svg";
import kodi_logo from "../images/installation_guide/kodi.svg";
import samsung_logo from "../images/installation_guide/samsung.svg";
import "../styles/installation_guide.css";

function InstallationGuide() {
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );

  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  });

  return (
    <section className="installation_guide_wrapper">
      <div className={wrapperClass}>
        <h1>Installation Guide</h1>
        <p>
          Find guides for various devices. If your device isn't listed, contact
          our team for assistance
        </p>
        <div className="device_cards_wrapper">
          <DeviceCard img={android_logo} title={"Android OS"} height={50} />
          <DeviceCard img={ios_logo} title={"Apple IOS"} height={60} />
          <DeviceCard img={lg_logo} title={"LG Smart TV"} height={50} />
          <Link to="/installation-guide/windows-guide">
            <DeviceCard img={windows_logo} title={"Windows"} height={50} />
          </Link>
          <DeviceCard
            img={amazon_fire_stick}
            title={"Amazon Fire Stick"}
            height={50}
          />
          <DeviceCard img={enigma_logo} title={"Enigma"} height={50} />
          <DeviceCard img={kodi_logo} title={"Kodi"} height={70} />
          <Link to="/installation-guide/samsung-guide">
            <DeviceCard img={samsung_logo} title={"Samsung"} height={20} />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default InstallationGuide;
