// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import images
import tvset_icon from "../../images/home_page/tvset_icon.svg";
import support_icon from "../../images/home_page/support_icon.svg";
import instant_activation_icon from "../../images/home_page/instant_activation_icon.svg";

// import paragraphs
import * as int from "../../../paragraphs/AllIn.js";

// import CSS
import "../../styles/features_card.css";

function FeaturesCard() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  return (
    <div className="bestfeatures_wrapper">
      <h1>{int.bestFeatures[displayLanguage]}</h1>
      <div className="bestfeatures">
        <div className="one_feature">
          <img src={tvset_icon} alt="tvset_icon" />
          <strong>{int.oneThousandChannel[displayLanguage]}</strong>
          <p>{int.oneThousandChannelPara[displayLanguage]}</p>
        </div>
        <div className="one_feature">
          <img src={support_icon} alt="tvset_icon" />
          <strong>{int.support24[displayLanguage]}</strong>
          <p>{int.support24Para[displayLanguage]}</p>
        </div>
        <div className="one_feature">
          <img src={instant_activation_icon} alt="tvset_icon" />
          <strong>{int.instantActivation[displayLanguage]}</strong>
          <p>{int.instantActivationPara[displayLanguage]}</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturesCard;
