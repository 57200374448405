import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import OverviewTable from "../comps/overview/OverviewTable.js";
import "../styles/overview.css";
import * as int from "../../paragraphs/about_us.js";
//
const createPara = (item) => {
  return (
    <Box>
      <Typography
        variant="h1"
        gutterBottom
        fontSize={"23px"}
        textAlign={"left"}
      >
        {item.h}
      </Typography>
      <Typography variant="p" gutterBottom fontSize={"14px"} fontWeight={100}>
        {item.p}
      </Typography>
    </Box>
  );
};

//
const AboutUs = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);

  const paragraphs = [
    {
      h: "CRYSTAL IPTV",
      p: int.crystalIPTVOverview[displayLanguage],
    },
    // {
    //   h: int.cuttingEdgeTechnologyText[displayLanguage],
    //   p: int.cuttingEdgeDataCenterText[displayLanguage],
    // },
    {
      h: int.videoQualityOptions[displayLanguage],
      p: int.videoQualityOptionsDescription[displayLanguage],
    },
    {
      h: int.globalContentSelection[displayLanguage],
      p: int.contentLibraryDescription[displayLanguage],
    },
    {
      h: int.contentPartnershipsText[displayLanguage],
      p: int.contentPartnershipsDescription[displayLanguage],
    },
    {
      h: int.customerSupportText[displayLanguage],
      p: int.customerSupportDescription[displayLanguage],
    },
    {
      h: int.securityMeasures[displayLanguage],
      p: int.securityMeasuresDescription[displayLanguage],
    },
    {
      h: int.futureExpansion[displayLanguage],
      p: int.futureExpansionDescription[displayLanguage],
    },
  ]; //
  return (
    <Box maxWidth={1000} sx={{ margin: "20px auto" }}>
      <br />
      <Grid container spacing={0}>
        <Grid md={8} xs={12}>
          <Stack
            spacing={5}
            sx={{
              padding: "20px",
            }}
          >
            {paragraphs.map((item) => {
              return createPara(item);
            })}
          </Stack>
        </Grid>
        <Grid md={4} xs={12}>
          <OverviewTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
