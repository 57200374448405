import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from ".././../../Atoms.js";
import { useState } from "react";
import { Link } from "react-router-dom";
// import components
import FaqQuestion from "../../../app_comps/FaqQuestion.js";

// import CSS
import "../../styles/faq.css";
// import paragraphs
import * as int from "../../../paragraphs/faq_paragraphs.js";
import { useEffect } from "react";

function Faq() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance faq_screen"
  );
  //
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper faq_screen");
  }, []);
  // Questions / Answers
  const QA = [
    {
      question: int.WhatDoesCrystalInclude[displayLanguage],
      answer: (
        <>
          <p>{int.WhatDoesCrystalIncludeAnswer[displayLanguage]}</p>
        </>
      ),
    },
    {
      question: int.howMuchDoesItCost[displayLanguage],
      answer: (
        <>
          <p>{int.howMuchDoesItCostAnswer[displayLanguage]}</p>
        </>
      ),
    },
    {
      question: int.devicesToStream[displayLanguage],
      answer: (
        <>
          <p>{int.devicesToStreamAnswer[displayLanguage]}</p>
        </>
      ),
    },
    {
      question: int.channelsInfo[displayLanguage],
      answer: (
        <p>
          {int.channelsInfoAnswer[displayLanguage]}{" "}
          <Link to="/channels-list" target="_blank">
            {int.channelsList[displayLanguage]}
          </Link>
        </p>
      ),
    },
    {
      question: int.discountQuestion[displayLanguage],
      answer: <p>{int.discountQuestionAnswer[displayLanguage]} </p>,
    },
  ];
  return (
    <div className={wrapperClass}>
      <h1 className="faq_h1">{int.faqHeader[displayLanguage]}</h1>
      <div className="faq_wrapper">
        {QA.map((qa) => {
          return <FaqQuestion question={qa.question} answer={qa.answer} />;
        })}
      </div>
    </div>
  );
}

export default Faq;
