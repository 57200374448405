export const joinUs = {
  en: "Join Us",
  fr: "Rejoignez-nous",
  es: "Únete a nosotros",
  de: "Mach mit",
  it: "Unisciti a noi",
  tr: "Bize Katılın",
  ru: "Присоединяйтесь",
  pl: "Dołącz do nas",
};

export const paragraph1 = {
  en: "Looking for a better way to watch your favorite shows and channels? Sign up for our IPTV service today and get started!",
  fr: "À la recherche d'un meilleur moyen de regarder vos émissions et chaînes préférées ? Inscrivez-vous à notre service IPTV dès aujourd'hui et commencez !",
  es: "¿Buscas una mejor manera de ver tus programas y canales favoritos? ¡Regístrate en nuestro servicio de IPTV hoy mismo y comienza!",
  de: "Auf der Suche nach einer besseren Möglichkeit, deine Lieblingssendungen und Kanäle zu schauen? Melde dich heute für unseren IPTV-Service an und leg los!",
  it: "Cerchi un modo migliore per guardare i tuoi programmi e canali preferiti? Iscriviti al nostro servizio IPTV oggi stesso e inizia!",
  tr: "En sevdiğiniz programları ve kanalları izlemek için daha iyi bir yol mu arıyorsunuz? Bugün IPTV hizmetimize kaydolun ve başlayın!",
  ru: "Ищете лучший способ смотреть ваши любимые шоу и каналы? Зарегистрируйтесь на нашем IPTV-сервисе сегодня и начните!",
  pl: "Szukasz lepszego sposobu na oglądanie ulubionych programów i kanałów? Zapisz się na naszą usługę IPTV jeszcze dziś i zacznij!",
};

export const or = {
  en: "OR",
  fr: "OU",
  es: "O",
  de: "ODER",
  it: "O",
  tr: "VEYA",
  ru: "ИЛИ",
  pl: "LUB",
};

export const ContinueWithGoogle = {
  en: "Continue with Google",
  fr: "Continuer avec Google",
  es: "Continuar con Google",
  de: "Mit Google fortfahren",
  it: "Continua con Google",
  tr: "Google ile devam et",
  ru: "Продолжить с Google",
  pl: "Kontynuuj z Google",
};

export const fullname = {
  en: "Full Name",
  fr: "Nom complet",
  es: "Nombre completo",
  de: "Vollständiger Name",
  it: "Nome completo",
  tr: "Tam adı",
  ru: "Полное имя",
  pl: "Imię i nazwisko",
};
export const email = {
  en: "Email",
  fr: "Email",
  es: "Correo electrónico",
  de: "E-Mail",
  it: "Email",
  tr: "E-posta",
  ru: "Электронная почта",
  pl: "Email",
};
export const password = {
  en: "Password",
  fr: "Mot de passe",
  es: "Contraseña",
  de: "Passwort",
  it: "Password",
  tr: "Şifre",
  ru: "Пароль",
  pl: "Hasło",
};
export const confirmPassword = {
  en: "Confirm Password",
  fr: "Confirmer le mot de passe",
  es: "Confirmar contraseña",
  de: "Passwort bestätigen",
  it: "Conferma password",
  tr: "Şifreyi Onayla",
  ru: "Подтвердите пароль",
  pl: "Potwierdź hasło",
};

export const enterYourFullname = {
  en: "Enter your fullname",
  fr: "Entrez votre nom complet",
  es: "Ingresa tu nombre completo",
  de: "Geben Sie Ihren vollständigen Namen ein",
  it: "Inserisci il tuo nome completo",
  tr: "Tam adınızı girin",
  ru: "Введите ваше полное имя",
  pl: "Wprowadź swoje imię i nazwisko",
};

export const enterYourEmail = {
  en: "Enter your email",
  fr: "Entrez votre adresse e-mail",
  es: "Ingresa tu correo electrónico",
  de: "Geben Sie Ihre E-Mail-Adresse ein",
  it: "Inserisci la tua email",
  tr: "E-posta adresinizi girin",
  ru: "Введите вашу электронную почту",
  pl: "Wprowadź swój adres email",
};

export const enterPassword = {
  en: "Enter password",
  fr: "Entrez votre mot de passe",
  es: "Ingresa tu contraseña",
  de: "Geben Sie Ihr Passwort ein",
  it: "Inserisci la tua password",
  tr: "Şifrenizi girin",
  ru: "Введите пароль",
  pl: "Wprowadź hasło",
};

export const submit = {
  en: "Join now",
  fr: "Rejoindre maintenant",
  es: "Únete ahora",
  de: "Jetzt beitreten",
  it: "Unisciti ora",
  tr: "Şimdi katıl",
  ru: "Присоединиться сейчас",
  pl: "Dołącz teraz",
};

export const alredyHaveAnAccount = {
  en: "Already have an account?",
  fr: "Vous avez déjà un compte ?",
  es: "¿Ya tienes una cuenta?",
  de: "Hast du bereits ein Konto?",
  it: "Hai già un account?",
  tr: "Zaten bir hesabınız var mı?",
  ru: "У вас уже есть аккаунт?",
  pl: "Masz już konto?",
};

export const logIn = {
  en: "Log in",
  fr: "Se connecter",
  es: "Iniciar sesión",
  de: "Anmelden",
  it: "Accedi",
  tr: "Giriş yap",
  ru: "Войти",
  pl: "Zaloguj się",
};

export const thisFieldIsRequired = {
  en: "This field is required",
  fr: "Ce champ est obligatoire",
  es: "Este campo es obligatorio",
  de: "Dieses Feld ist erforderlich",
  it: "Questo campo è obbligatorio",
  tr: "Bu alan gereklidir",
  ru: "Это поле обязательно для заполнения",
  pl: "To pole jest wymagane",
};

export const emailIsNotValid = {
  en: "Apologies, but the provided email is not valid",
  fr: "Veuillez nous excuser, mais l'adresse e-mail fournie n'est pas valide",
  es: "Disculpa, pero el correo electrónico proporcionado no es válido",
  de: "Entschuldigung, aber die angegebene E-Mail-Adresse ist ungültig",
  it: "Ci scusiamo, ma l'email fornita non è valida",
  tr: "Özür dileriz, ancak sağlanan e-posta geçerli değil",
  ru: "Приносим извинения, но указанный адрес электронной почты недействителен",
  pl: "Przepraszamy, ale podany adres e-mail jest nieprawidłowy",
};

export const passwordNotlong = {
  en: "Password must be at least 8 characters long",
  fr: "Le mot de passe doit comporter au moins 8 caractères",
  es: "La contraseña debe tener al menos 8 caracteres",
  de: "Das Passwort muss mindestens 8 Zeichen lang sein",
  it: "La password deve contenere almeno 8 caratteri",
  tr: "Şifre en az 8 karakter uzunluğunda olmalıdır",
  ru: "Пароль должен содержать не менее 8 символов",
  pl: "Hasło musi mieć co najmniej 8 znaków",
};

export const passwordsMatch = {
  en: "Passwords must match. Please verify",
  fr: "Les mots de passe doivent correspondre. Veuillez vérifier",
  es: "Las contraseñas deben coincidir. Por favor, verifique",
  de: "Die Passwörter müssen übereinstimmen. Bitte überprüfen",
  it: "Le password devono corrispondere. Verifica, per favore",
  tr: "Şifreler eşleşmiyor. Lütfen kontrol edin",
  ru: "Пароли должны совпадать. Пожалуйста, проверьте",
  pl: "Hasła muszą być takie same. Proszę sprawdzić",
};

export const emailAlreadyInUse = {
  en: "User with this email already exists",
  fr: "Un utilisateur avec cette adresse e-mail existe déjà",
  es: "Ya existe un usuario con este correo electrónico",
  de: "Ein Benutzer mit dieser E-Mail existiert bereits",
  it: "Esiste già un utente con questa email",
  tr: "Bu e-posta adresine sahip bir kullanıcı zaten mevcut",
  ru: "Пользователь с таким email уже существует",
  pl: "Użytkownik z tym adresem e-mail już istnieje",
};

export const completeCaptcha = {
  en: "Please complete the CAPTCHA",
  fr: "Veuillez compléter le CAPTCHA",
  es: "Por favor, completa el CAPTCHA",
  de: "Bitte vervollständige das CAPTCHA",
  it: "Si prega di completare il CAPTCHA",
  tr: "Lütfen CAPTCHA'yı tamamlayın",
  ru: "Пожалуйста, пройдите CAPTCHA",
  pl: "Proszę uzupełnić CAPTCHA",
};
export const connectionIssue = {
  en: "Connection Issue, Please ensure connectivity.",
  fr: "Problème de connexion, veuillez vérifier votre connexion.",
  es: "Problema de conexión, por favor asegúrese de tener conectividad.",
  de: "Verbindungsproblem, bitte stellen Sie sicher, dass Sie eine Verbindung haben.",
  it: "Problema di connessione, assicurati di essere connesso.",
  tr: "Bağlantı Sorunu, lütfen bağlantınızı kontrol edin.",
  ru: "Проблема с подключением, пожалуйста, убедитесь в наличии соединения.",
  pl: "Problem z połączeniem, proszę sprawdzić połączenie.",
};
