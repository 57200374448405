export const ourService = {
  en: "Our service",
  fr: "Notre service",
  es: "Nuestro servicio",
  de: "Unser Service",
  it: "Il nostro servizio",
  tr: "Hizmetimiz",
  ru: "Наш сервис",
  pl: "Nasza usługa",
};

export const vouchers = {
  en: "Vouchers",
  fr: "Bons de réduction",
  es: "Vales",
  de: "Gutscheine",
  it: "Buoni",
  tr: "Kuponlar",
  ru: "Купоны",
  pl: "Kupony",
};

export const howItWorks = {
  en: "How it works?",
  fr: "Comment ça marche ?",
  es: "¿Cómo funciona?",
  de: "Wie funktioniert es?",
  it: "Come funziona?",
  tr: "Nasıl çalışır?",
  ru: "Как это работает?",
  pl: "Jak to działa?",
};

export const support = {
  en: "Support",
  fr: "Support",
  es: "Soporte",
  de: "Unterstützung",
  it: "Supporto",
  tr: "Destek",
  ru: "Поддержка",
  pl: "Wsparcie",
};
export const contactSupport = {
  en: "Contact support",
  fr: "Contacter le support",
  es: "Contactar al soporte",
  de: "Support kontaktieren",
  it: "Contatta il supporto",
  tr: "Destek ile iletişime geçin",
  ru: "Связаться со службой поддержки",
  pl: "Skontaktuj się z obsługą",
};
export const email = {
  en: "Email",
  fr: "Email",
  es: "Correo electrónico",
  de: "E-Mail",
  it: "Email",
  tr: "E-posta",
  ru: "Электронная почта",
  pl: "E-mail",
};
export const ourCompany = {
  en: "Our company",
  fr: "Notre entreprise",
  es: "Nuestra empresa",
  de: "Unser Unternehmen",
  it: "La nostra azienda",
  tr: "Firmamız",
  ru: "Наша компания",
  pl: "Nasza firma",
};
export const termsAndConditions = {
  en: "Terms and Conditions",
  fr: "Conditions générales",
  es: "Términos y condiciones",
  de: "Allgemeine Geschäftsbedingungen",
  it: "Termini e condizioni",
  tr: "Kullanım Şartları",
  ru: "Условия использования",
  pl: "Regulamin",
};

export const aboutUs = {
  en: "About us",
  fr: "À propos de nous",
  es: "Sobre nosotros",
  de: "Über uns",
  it: "Chi siamo",
  tr: "Hakkımızda",
  ru: "О нас",
  pl: "O nas",
};
export const supportedPaymentSystems = {
  en: "Supported Payment Systems",
  fr: "Systèmes de paiement pris en charge",
  es: "Sistemas de pago admitidos",
  de: "Unterstützte Zahlungssysteme",
  it: "Sistemi di pagamento supportati",
  tr: "Desteklenen Ödeme Sistemleri",
  ru: "Поддерживаемые платежные системы",
  pl: "Obsługiwane systemy płatności",
};
export const subscribeNow = {
  en: "Subscribe now",
  fr: "Abonnez-vous maintenant",
  es: "Suscríbete ahora",
  de: "Jetzt abonnieren",
  it: "Iscriviti ora",
  tr: "Şimdi abone ol",
  ru: "Подписаться сейчас",
  pl: "Zapisz się teraz",
};
export const signUp = {
  en: "Sign up",
  fr: "S'inscrire",
  es: "Registrarse",
  de: "Registrieren",
  it: "Registrati",
  tr: "Kaydol",
  ru: "Зарегистрироваться",
  pl: "Zarejestruj się",
};

export const allRightsReserved = {
  en: "All Rights Reserved",
  fr: "Tous droits réservés",
  es: "Todos los derechos reservados",
  de: "Alle Rechte vorbehalten",
  it: "Tutti i diritti riservati",
  tr: "Tüm hakları saklıdır",
  ru: "Все права защищены",
  pl: "Wszelkie prawa zastrzeżone",
};
