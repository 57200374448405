import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";

// import css
import "../styles/reset_password.css";

// import images
import checkmark_green from "../images/checkmark_green.svg";

// import paragraphs
import * as int from "../../paragraphs/reset_password";

function PasswordResetEmailSent() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);
  return (
    <div className={wrapperClass}>
      <div className="reset_email_sent">
        <img src={checkmark_green} alt="checkmark green" />
        <h1>{int.passwordResetLinkSent[displayLanguage]}</h1>
        <p>
          {int.passwordResetNote[displayLanguage]}
          <strong>{int.emailNotReceivedNote[displayLanguage]}</strong>
        </p>
      </div>
    </div>
  );
}

export default PasswordResetEmailSent;
