export const chooseYourPlan = {
  en: "Choose your plan",
  fr: "Choisissez votre plan",
  es: "Elige tu plan",
  de: "Wähle deinen Plan",
  it: "Scegli il tuo piano",
  tr: "Planınızı seçin",
  ru: "Выберите свой план",
  pl: "Wybierz swój plan",
};

export const popular = {
  en: "Popular",
  fr: "Populaire",
  es: "Popular",
  de: "Beliebt",
  it: "Popolare",
  tr: "Popüler",
  ru: "Популярное",
  pl: "Popularne",
};

export const month = {
  en: "Month",
  fr: "Mois",
  es: "Mes",
  de: "Monat",
  it: "Mese",
  tr: "Ay",
  ru: "Месяц",
  pl: "Miesiąc",
};

export const months = {
  en: "Months",
  fr: "Mois",
  es: "Meses",
  de: "Monate",
  it: "Mesi",
  tr: "Aylar",
  ru: "Месяцы",
  pl: "Miesiące",
};

export const perMonth = {
  en: "per month",
  fr: "par mois",
  es: "por mes",
  de: "pro Monat",
  it: "al mese",
  tr: "aylık",
  ru: "в месяц",
  pl: "miesięcznie",
};

export const year = {
  en: "Year",
  fr: "Année",
  es: "Año",
  de: "Jahr",
  it: "Anno",
  tr: "Yıl",
  ru: "Год",
  pl: "Rok",
};

export const channels = {
  en: "channels",
  fr: "chaînes",
  es: "canales",
  de: "Kanäle",
  it: "canali",
  tr: "kanal",
  ru: "каналы",
  pl: "kanały",
};

export const imageQuality = {
  en: "High image quality",
  fr: "Haute qualité d'image",
  es: "Alta calidad de imagen",
  de: "Hohe Bildqualität",
  it: "Alta qualità dell'immagine",
  tr: "Yüksek görüntü kalitesi",
  ru: "Высокое качество",
  pl: "Wysoka jakość obrazu",
};

export const premiumChannels = {
  en: "Premium channels",
  fr: "Chaînes premium",
  es: "Canales premium",
  de: "Premium-Kanäle",
  it: "Canali premium",
  tr: "Premium kanallar",
  ru: "Премиум-каналы",
  pl: "Kanały premium",
};

export const seriesAndMovies = {
  en: "Series & Movies",
  fr: "Séries et films",
  es: "Series y películas",
  de: "Serien und Filme",
  it: "Serie e film",
  tr: "Diziler ve Filmler",
  ru: "Сериалы и фильмы",
  pl: "Seriale i filmy",
};

export const choosePlan = {
  en: "Choose plan",
  fr: "Choisir un plan",
  es: "Seleccionar plan",
  de: "Plan auswählen",
  it: "Scegli un piano",
  tr: "Plan seçin",
  ru: "Выберите план",
  pl: "Wybierz plan",
};
