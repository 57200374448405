import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Recoil imports
import { useRecoilValue } from "recoil";
import { authenticatedAtom } from "../../Atoms";

// import toast notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import components
import AccountSettingsHeader from "../comps/account_settings/AccountSettingsHeader";
import AccountSettingsSubscriptions from "../comps/account_settings/AccountSettingsSubscriptions";
import AccountSettingsTickets from "../comps/account_settings/AccountSettingsTickets";
import AccountSettingsVouchers from "../comps/account_settings/AccountSettingsVouchers";
import AccountSettingsSettings from "../comps/account_settings/AccountSettingsSettings";
// import CSS
import "../styles/account_settings.css";

function AccountSettings() {
  const isAuthenticated = useRecoilValue(authenticatedAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );
  const navigate = useNavigate();
  // this is the selected state based on which the settings comps will be conditionally rendered
  // please note that the setter and the state have been passed down to the AccountSettingsHeader and its from there where we updtae the state
  const [selected, setSelected] = useState("subscriptions");
  const [accountSettingsData, setAccountSettingsData] = useState([]);

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // We gotta first check if the user is authenticated, if not we would throw him to the login page
    if (!isAuthenticated) {
      // redirect the user to the login page
      navigate("/login");
    }
    // change the wrapper classes on the components mount
    setWrapperClass("account_settings_wrapper");
    // API call to get the user data from the DB
    const token = localStorage.getItem("accessToken");
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/accountsettings/data/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setAccountSettingsData(res.data);
      })
      .catch((err) => {
        console.log(err.code);
        toast.error(
          "Network Error, please make sure you are connected to the internet!"
        );
      });
  }, [selected]);

  return (
    <div className={wrapperClass}>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AccountSettingsHeader setSelected={setSelected} selected={selected} />
      {/* // Conditionally render the tickets tab */}
      {selected == "subscriptions" && (
        <AccountSettingsSubscriptions
          data={accountSettingsData["subscriptions"]}
        />
      )}
      {/* // Conditionally render the tickets tab */}
      {selected == "tickets" && (
        <AccountSettingsTickets data={accountSettingsData["tickets"]} />
      )}

      {/* Conditionally render the vouchers tab  */}
      {selected == "vouchers" && (
        <AccountSettingsVouchers data={accountSettingsData["vouchers"]} />
      )}
      {selected == "settings" && (
        <AccountSettingsSettings data={accountSettingsData["settings"]} />
      )}
    </div>
  );
}

export default AccountSettings;
