import { useState, useEffect } from "react";
import axios from "axios";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
// import toastify notificatons
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import components
import Switch from "../Switch";

// import images
import settings_icon from "../../images/settings_icon.svg";

// import paragraphs
import * as int from "../../../paragraphs/account_settings_paragraphs";

function AccountSettingsVouchers(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [newUpdates, setNewsUpdates] = useState(true);
  const [supportNotifications, setSupportNotifications] = useState(true);
  const [feedbackSurveys, setFeedbackSurveys] = useState(true);
  const [subscriptionExpiry, setSubscriptionExpiry] = useState(true);
  const [updatedFromSwitch, setUpdatedFromSwitch] = useState(0);

  //
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");

    //
    if (props.data != undefined) {
      const settingsData = props.data;
      setNewsUpdates(settingsData["get_updates"]);
      setSupportNotifications(settingsData["support_notifications"]);
      setFeedbackSurveys(settingsData["feedback_surveys"]);
      setSubscriptionExpiry(settingsData["susbscriptions_expiry"]);
    }
  }, []);

  useEffect(() => {
    // send a PUT request to update the user settings in case one of the settings states got updated
    if (updatedFromSwitch != 0) {
      const token = localStorage.getItem("accessToken");
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_BACKEND_URL}/accountsettings/update_settings/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          get_updates: newUpdates,
          support_notifications: supportNotifications,
          feedback_surveys: feedbackSurveys,
          susbscriptions_expiry: subscriptionExpiry,
        },
      })
        .then((res) => {
          console.log(res);
          // fire notification telling the client that changes have been made
          toast.success("Change saved");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [updatedFromSwitch]);

  return (
    <div className={wrapperClass}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="settings_header_wrapper">
        <div className="settings_header_icon_wrapper">
          <div className="header_icon">
            <img src={settings_icon} alt="ticket_icon" />
          </div>

          <div>
            <h1>{int.accountSettings[displayLanguage]}</h1>
            <p>{int.settingsInstructions[displayLanguage]}</p>
          </div>
        </div>
      </div>
      {/* settings  */}
      <div className="settings_box">
        <h2>{int.emailNotifications[displayLanguage]}</h2>
        <p>{int.emailSubscriptionMessage[displayLanguage]}</p>
        <div className="settings_sub_box">
          <Switch
            actualState={newUpdates}
            actualStateSetter={setNewsUpdates}
            flagSetter={setUpdatedFromSwitch}
            header={int.newsAndUpdates[displayLanguage]}
            description={int.newsAboutUpdates[displayLanguage]}
          />
          <Switch
            actualState={supportNotifications}
            actualStateSetter={setSupportNotifications}
            flagSetter={setUpdatedFromSwitch}
            header={int.supportNotifications[displayLanguage]}
            description={int.supportTicketNotification[displayLanguage]}
          />
          <Switch
            actualState={feedbackSurveys}
            actualStateSetter={setFeedbackSurveys}
            flagSetter={setUpdatedFromSwitch}
            header={int.feedbackAndSurveys[displayLanguage]}
            description={int.emailFeedbackSurveys[displayLanguage]}
          />
          <Switch
            actualState={subscriptionExpiry}
            actualStateSetter={setSubscriptionExpiry}
            flagSetter={setUpdatedFromSwitch}
            header={int.subscriptionExpiry[displayLanguage]}
            description={int.subscriptionExpiryEmails[displayLanguage]}
          />
        </div>
      </div>
    </div>
  );
}

export default AccountSettingsVouchers;
