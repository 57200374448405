import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
// Recoil imports
import { useRecoilValue, useRecoilState } from "recoil";
import { displayLanguageAtom, authenticatedAtom } from "../../Atoms";
// import components
import Spinner from "../../app_comps/comps/Spinner";

// import paragraphs
import * as int from "../../paragraphs/change_password_paragraphs";

function ChangePassword() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [authenticated, setAuthenticated] = useRecoilState(authenticatedAtom);
  const [newPassword, setNewPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );
  const newPasswordInput = useRef();
  const submitButton = useRef();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleNewPasswordFieldError = () => {
    if (submitted && newPassword == "") {
      // get our hand on the input using useRef and change its id
      newPasswordInput.current.id = "error_input";
      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    } else if (submitted && newPassword.length < 8) {
      return (
        <div className="auth_error prevent_select">
          {int.passwordNotlong[displayLanguage]}
        </div>
      );
    }
  };

  //   define handle submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    // turn on the spinner showing progess
    setSpinning(true);
    // disable the submit btutton
    submitButton.current.disabled = true;
    // we set submitted to "true" to let handleErrorRender know that the use
    // submitted the form
    setSubmitted(true);
    // check if all the fields conditions were satisfied, if so, fire the HTTP request};
    if (newPassword != "" && newPassword.length >= 8) {
      axios({
        method: "PUT",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/update/userpassword/`,
        data: {
          new_password: newPassword,
          language: displayLanguage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const accestoken = res.data["token"];
          const userInfo = JSON.stringify(res.data);
          console.log("reset passowrd res", res);
          // we need to store the token in the localstorage and set the Auth atom to true
          localStorage.setItem("accessToken", accestoken);
          localStorage.setItem("userInfo", userInfo);
          //   add user info to the localstorage
          setAuthenticated(true);
          // redirect the user to the home page
          navigate("/");
        })
        .catch((err) => {
          // if the request was unscuccessfull we will render the "network error" component
          if (err.code == "ERR_NETWORK") {
            alert("your internet connexion is currently offline");
          } else if (
            err.response.data.detail ==
            "No active account found with the given credentials"
          ) {
            alert("No active account found with the given credentials");
          } else {
            alert(err);
          }
          setSpinning(false);
          submitButton.current.disabled = false;
        });
    } else {
      setSpinning(false);
      submitButton.current.disabled = false;
    }
  };
  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <h1>Change password</h1>
      <p>
        Choose a strong password for account security. Mix uppercase, lowercase,
        numbers, and symbols. Avoid common phrases or personal info. Thanks for
        prioritizing safety!
      </p>
      <form onSubmit={handleSubmit} className="form_wrapper">
        <label>{"New password"}:</label>
        <input
          ref={newPasswordInput}
          type="password"
          value={newPassword}
          onChange={(event) => {
            setNewPassword(event.target.value);
            newPasswordInput.current.id = "";
          }}
        />
        {handleNewPasswordFieldError()}

        <button ref={submitButton} type="submit" className="submit_btn">
          {spinning ? <Spinner size="small" /> : "Change password"}
        </button>
      </form>
    </div>
  );
}

export default ChangePassword;
