export const welcomeBack = {
  en: "Welcome back",
  fr: "Bienvenue de retour",
  es: "Bienvenido de vuelta",
  de: "Willkommen zurück",
  it: "Benvenuto di nuovo",
  tr: "Tekrar hoş geldin",
  ru: "С возвращением",
  pl: "Witaj z powrotem",
};

export const subscriptions = {
  en: "Subscriptions",
  fr: "Abonnements",
  es: "Suscripciones",
  de: "Abonnements",
  it: "Abbonamenti",
  tr: "Abonelikler",
  ru: "Подписки",
  pl: "Subskrypcje",
};

export const myVouchers = {
  en: "My Vouchers",
  fr: "Mes bons d'achat",
  es: "Mis vales",
  de: "Meine Gutscheine",
  it: "I miei buoni",
  tr: "Kuponlarım",
  ru: "Мои купоны",
  pl: "Moje vouchery",
};

export const supportTickets = {
  en: "Support Tickets",
  fr: "Tickets de support",
  es: "Tickets de soporte",
  de: "Support-Tickets",
  it: "Ticket di supporto",
  tr: "Destek Biletleri",
  ru: "Техническая поддержка",
  pl: "Bilety wsparcia",
};

export const settings = {
  en: "Settings",
  fr: "Paramètres",
  es: "Configuración",
  de: "Einstellungen",
  it: "Impostazioni",
  tr: "Ayarlar",
  ru: "Настройки",
  pl: "Ustawienia",
};

export const sub_history = {
  en: "Subscriptions history",
  fr: "Historique des abonnements",
  es: "Historial de suscripciones",
  de: "Abonnementverlauf",
  it: "Cronologia delle iscrizioni",
  tr: "Abonelik geçmişi",
  ru: "История подписок",
  pl: "Historia subskrypcji",
};

export const sub_description = {
  en: "Have a look at your subscriptions and their expiration dates",
  fr: "Consultez vos abonnements et leurs dates d'expiration",
  es: "Echa un vistazo a tus suscripciones y sus fechas de vencimiento",
  de: "Schauen Sie sich Ihre Abonnements und deren Ablaufdaten an",
  it: "Dai un'occhiata alle tue iscrizioni e alle relative date di scadenza",
  tr: "Aboneliklerinizi ve son kullanma tarihlerini inceleyin",
  ru: "Ознакомьтесь с вашими подписками и их датами окончания",
  pl: "Zobacz swoje subskrypcje i daty ich wygaśnięcia",
};

export const subscription_id = {
  en: "ID",
  fr: "ID",
  es: "ID",
  de: "ID",
  it: "ID",
  tr: "ID",
  ru: "ID",
  pl: "ID",
};
export const starting_date = {
  en: "Starting date",
  fr: "Date de début",
  es: "Fecha de inicio",
  de: "Startdatum",
  it: "Data di inizio",
  tr: "Başlangıç tarihi",
  ru: "Дата начала",
  pl: "Data rozpoczęcia",
};
export const ending_date = {
  en: "Ending date",
  fr: "Date de fin",
  es: "Fecha de finalización",
  de: "Enddatum",
  it: "Data di fine",
  tr: "Bitiş tarihi",
  ru: "Дата окончания",
  pl: "Data zakończenia",
};
export const price = {
  en: "Price",
  fr: "Prix",
  es: "Precio",
  de: "Preis",
  it: "Prezzo",
  tr: "Fiyat",
  ru: "Цена",
  pl: "Cena",
};
export const remaining_days = {
  en: "Remaining days",
  fr: "Jours restants",
  es: "Días restantes",
  de: "Verbleibende Tage",
  it: "Giorni rimanenti",
  tr: "Kalan günler",
  ru: "Оставшиеся дни",
  pl: "Pozostałe dni",
};
export const username = {
  en: "Username",
  fr: "Nom d'utilisateur",
  es: "Nombre de usuario",
  de: "Benutzername",
  it: "Nome utente",
  tr: "Kullanıcı adı",
  ru: "Имя пользователя",
  pl: "Nazwa użytkownika",
};
export const password = {
  en: "Password",
  fr: "Mot de passe",
  es: "Contraseña",
  de: "Passwort",
  it: "Password",
  tr: "Parola",
  ru: "Пароль",
  pl: "Hasło",
};

export const status = {
  en: "1",
  fr: "J1",
  es: "1",
  de: "1",
  it: "1",
  tr: "1",
  ru: "1",
  pl: "1",
};

export const pending = {
  en: "Pending",
  fr: "En attente",
  es: "Pendiente",
  de: "Ausstehend",
  it: "In sospeso",
  tr: "Beklemede",
  ru: "В ожидании",
  pl: "Oczekujące",
};

export const delivered = {
  en: "Delivered",
  fr: "Livré",
  es: "Entregado",
  de: "Geliefert",
  it: "Consegnato",
  tr: "Teslim edildi",
  ru: "Доставлено",
  pl: "Dostarczono",
};

export const cancelled = {
  en: "Cancelled",
  fr: "Annulé",
  es: "Cancelado",
  de: "Storniert",
  it: "Annullato",
  tr: "İptal edildi",
  ru: "Отменено",
  pl: "Anulowane",
};

export const title = {
  en: "Title",
  fr: "Titre",
  es: "Título",
  de: "Titel",
  it: "Titolo",
  tr: "Başlık",
  ru: "Заголовок",
  pl: "Tytuł",
};

export const openingDate = {
  en: "Opening date",
  fr: "Date d'ouverture",
  es: "Fecha de apertura",
  de: "Eröffnungsdatum",
  it: "Data di apertura",
  tr: "Açılış tarihi",
  ru: "Дата открытия",
  pl: "Data otwarcia",
};

export const expirationDate = {
  en: "Expiration date",
  fr: "Date d'expiration",
  es: "Fecha de caducidad",
  de: "Ablaufdatum",
  it: "Data di scadenza",
  tr: "Son kullanma tarihi",
  ru: "Срок годности",
  pl: "Data ważności",
};

export const resolved = {
  en: "Resolved",
  fr: "Résolu",
  es: "Resuelto",
  de: "Gelöst",
  it: "Risolto",
  tr: "Çözüldü",
  ru: "Решено",
  pl: "Rozwiązane",
};

export const tickets = {
  en: "Tickets",
  fr: "Billets",
  es: "Entradas",
  de: "Tickets",
  it: "Biglietti",
  tr: "Biletler",
  ru: "Билеты",
  pl: "Bilety",
};

export const ticketInstructions = {
  en: "You can use tickets to request assistance, ask for offers, or report issues.",
  fr: "Vous pouvez utiliser des billets pour demander de l'assistance, demander des offres ou signaler des problèmes.",
  es: "Puedes usar entradas para solicitar asistencia, pedir ofertas o informar problemas.",
  de: "Sie können Tickets verwenden, um Unterstützung anzufordern, Angebote anzufordern oder Probleme zu melden.",
  it: "Puoi utilizzare i biglietti per richiedere assistenza, chiedere offerte o segnalare problemi.",
  tr: "Destek talebi, teklif isteme veya sorun bildirimi yapmak için biletleri kullanabilirsiniz.",
  ru: "Вы можете использовать билеты для запроса помощи, запроса предложений или сообщения о проблемах.",
  pl: "Możesz używać biletów do prośby o pomoc, pytania o oferty lub zgłaszania problemów.",
};
export const vouchers = {
  en: "Vouchers",
  fr: "Bons d'achat",
  es: "Vales",
  de: "Gutscheine",
  it: "Buoni",
  tr: "Kuponlar",
  ru: "Ваучеры",
  pl: "Vouchery",
};
export const voucherInstructions = {
  en: "You can use voucher code to get a discount whenever you would like to make a new purchase.",
  fr: "Vous pouvez utiliser un code de bon d'achat pour obtenir une réduction chaque fois que vous souhaitez effectuer un nouvel achat.",
  es: "Puedes utilizar un código de vale para obtener un descuento cada vez que desees realizar una nueva compra.",
  de: "Du kannst einen Gutscheincode verwenden, um einen Rabatt zu erhalten, wann immer du einen neuen Kauf tätigen möchtest.",
  it: "Puoi utilizzare un codice buono per ottenere uno sconto ogni volta che desideri effettuare un nuovo acquisto.",
  tr: "Yeni bir satın alma yapmak istediğinizde indirim almak için bir kupon kodu kullanabilirsiniz.",
  ru: "Вы можете использовать код ваучера, чтобы получить скидку в любое время, когда хотите сделать новую покупку.",
  pl: "Możesz użyć kodu vouchera, aby otrzymać zniżkę za każdym razem, gdy chcesz dokonać nowego zakupu.",
};
export const discountRate = {
  en: "Discount Rate",
  fr: "Taux de réduction",
  es: "Tasa de descuento",
  de: "Rabatt-Satz",
  it: "Tasso di sconto",
  tr: "İndirim Oranı",
  ru: "Ставка скидки",
  pl: "Stopa rabatu",
};
export const consumed = {
  en: "Consumed",
  fr: "Consommé",
  es: "Consumido",
  de: "Verbraucht",
  it: "Consumato",
  tr: "Tüketildi",
  ru: "Потреблено",
  pl: "Zużyte",
};
export const available = {
  en: "Available",
  fr: "Disponible",
  es: "Disponible",
  de: "Verfügbar",
  it: "Disponibile",
  tr: "Mevcut",
  ru: "Доступно",
  pl: "Dostępne",
};
export const accountSettings = {
  en: "Account Settings",
  fr: "Paramètres du compte",
  es: "Configuración de la cuenta",
  de: "Kontoeinstellungen",
  it: "Impostazioni dell'account",
  tr: "Hesap Ayarları",
  ru: "Настройки аккаунта",
  pl: "Ustawienia konta",
};
export const settingsInstructions = {
  en: "Utilize our settings feature to easily update your username and access other account management options.",
  fr: "Utilisez notre fonctionnalité de paramètres pour mettre à jour facilement votre nom d'utilisateur et accéder à d'autres options de gestion de compte.",
  es: "Utiliza nuestra función de configuración para actualizar fácilmente tu nombre de usuario y acceder a otras opciones de gestión de cuenta.",
  de: "Nutzen Sie unsere Einstellungsfunktion, um Ihren Benutzernamen einfach zu aktualisieren und auf andere Kontoverwaltungsoptionen zuzugreifen.",
  it: "Utilizza la nostra funzione di impostazioni per aggiornare facilmente il tuo nome utente e accedere ad altre opzioni di gestione dell'account.",
  tr: "Kullanıcı adınızı kolayca güncellemek ve diğer hesap yönetimi seçeneklerine erişmek için ayarlar özelliğimizi kullanın.",
  ru: "Используйте нашу функцию настроек, чтобы легко обновить ваше имя пользователя и получить доступ к другим опциям управления аккаунтом.",
  pl: "Skorzystaj z naszej funkcji ustawień, aby łatwo aktualizować swoją nazwę użytkownika i uzyskać dostęp do innych opcji zarządzania kontem.",
};

export const emailNotifications = {
  en: "Email notifications",
  fr: "Notifications par email",
  es: "Notificaciones por correo electrónico",
  de: "E-Mail-Benachrichtigungen",
  it: "Notifiche via email",
  tr: "E-posta bildirimleri",
  ru: "Уведомления по электронной почте",
  pl: "Powiadomienia e-mail",
};
export const emailSubscriptionMessage = {
  en: "Get emails to find out about our new offers and additions. You can turn these off.",
  fr: "Recevez des e-mails pour être informé de nos nouvelles offres et ajouts. Vous pouvez les désactiver.",
  es: "Recibe correos electrónicos para enterarte de nuestras nuevas ofertas y novedades. Puedes desactivarlos.",
  de: "Erhalten Sie E-Mails, um über unsere neuen Angebote und Ergänzungen informiert zu werden. Sie können diese deaktivieren.",
  it: "Ricevi e-mail per scoprire le nostre nuove offerte e aggiunte. Puoi disattivarle.",
  tr: "Yeni tekliflerimiz ve eklemelerimiz hakkında bilgi almak için e-posta alın. Bunları kapatabilirsiniz.",
  ru: "Получайте электронные письма, чтобы быть в курсе наших новых предложений и дополнений. Вы можете отключить их.",
  pl: "Otrzymuj e-maile, aby dowiedzieć się o naszych nowych ofertach i dodatkach. Możesz je wyłączyć.",
};
export const supportNotifications = {
  en: "Support notifications",
  fr: "Notifications de support",
  es: "Notificaciones de soporte",
  de: "Support-Benachrichtigungen",
  it: "Notifiche di supporto",
  tr: "Destek bildirimleri",
  ru: "Уведомления службы поддержки",
  pl: "Powiadomienia wsparcia",
};
export const supportTicketNotification = {
  en: "Get notified when your support ticket has been responded to",
  fr: "Recevez une notification lorsque votre demande d'assistance reçoit une réponse",
  es: "Recibe una notificación cuando se responda a tu ticket de soporte",
  de: "Erhalte eine Benachrichtigung, wenn auf dein Support-Ticket geantwortet wurde",
  it: "Ricevi una notifica quando viene data una risposta al tuo ticket di supporto",
  tr: "Destek talebinize yanıt verildiğinde bildirim alın",
  ru: "Получайте уведомление, когда на вашу заявку в службу поддержки будет дан ответ",
  pl: "Otrzymuj powiadomienia, gdy udzielono odpowiedzi na twoje zgłoszenie wsparcia",
};
export const feedbackAndSurveys = {
  en: "Feedback and surveys",
  fr: "Commentaires et enquêtes",
  es: "Comentarios y encuestas",
  de: "Feedback und Umfragen",
  it: "Feedback e sondaggi",
  tr: "Geri bildirim ve anketler",
  ru: "Обратная связь и опросы",
  pl: "Opinie i ankiety",
};
export const emailFeedbackSurveys = {
  en: "Receive emails to let you give us your feedback or participate in surveys",
  fr: "Recevez des e-mails pour nous donner vos commentaires ou participer à des enquêtes",
  es: "Recibe correos electrónicos para que nos des tus comentarios o participes en encuestas",
  de: "Erhalten Sie E-Mails, um uns Ihr Feedback zu geben oder an Umfragen teilzunehmen",
  it: "Ricevi e-mail per inviarci i tuoi feedback o partecipare a sondaggi",
  tr: "Geri bildirimde bulunmanızı veya anketlere katılmanızı sağlamak için e-posta alın",
  ru: "Получайте электронные письма, чтобы дать нам отзывы или принять участие в опросах",
  pl: "Otrzymuj e-maile, aby móc przekazać nam swoje opinie lub wziąć udział w ankietach",
};
export const subscriptionExpiry = {
  en: "Subscription expiry",
  fr: "Expiration de l'abonnement",
  es: "Vencimiento de la suscripción",
  de: "Ablauf des Abonnements",
  it: "Scadenza dell'abbonamento",
  tr: "Abonelik sona erme",
  ru: "Истечение срока подписки",
  pl: "Wygaśnięcie subskrypcji",
};
export const subscriptionExpiryEmails = {
  en: "Get emails to let you know that your subscription is about to expire",
  fr: "Recevez des e-mails pour vous informer que votre abonnement est sur le point d'expirer",
  es: "Recibe correos electrónicos para que sepas que tu suscripción está a punto de vencer",
  de: "Erhalte E-Mails, um dich darüber zu informieren, dass dein Abonnement bald abläuft",
  it: "Ricevi e-mail per farti sapere che la tua abbonamento sta per scadere",
  tr: "Aboneliğinizin yakında sona ereceğini bildiren e-postalar alın",
  ru: "Получайте электронные письма, чтобы узнать, что ваша подписка скоро истекает",
  pl: "Otrzymuj e-maile informujące, że twoja subskrypcja jest na końcu",
};

// SWITCH PARAGRAPHS
export const newsAndUpdates = {
  en: "News and updates",
  fr: "Actualités et mises à jour",
  es: "Noticias y actualizaciones",
  de: "Neuigkeiten und Updates",
  it: "Notizie e aggiornamenti",
  tr: "Haberler ve güncellemeler",
  ru: "Новости и обновления",
  pl: "Wiadomości i aktualizacje",
};
export const newsAboutUpdates = {
  en: "News about product and feature updates",
  fr: "Actualités sur les mises à jour de produits et de fonctionnalités",
  es: "Noticias sobre actualizaciones de productos y funciones",
  de: "Neuigkeiten zu Produkt- und Funktionsupdates",
  it: "Notizie sugli aggiornamenti di prodotti e funzionalità",
  tr: "Ürün ve özellik güncellemeleriyle ilgili haberler",
  ru: "Новости о обновлениях продукта и функций",
  pl: "Aktualności dotyczące aktualizacji produktów i funkcji",
};

export const accountCredentials = {
  en: "Account Credentials",
  fr: "Identifiants du compte",
  es: "Credenciales de la cuenta",
  de: "Account-Anmeldeinformationen",
  it: "Credenziali dell'account",
  tr: "Hesap Kimlik Bilgileri",
  ru: "Учетные данные аккаунта",
  pl: "Dane logowania do konta",
};

export const subscriptionOptions = {
  en: "Get emails to find out about our new offers and additions. You can turn these off.",
  fr: "Recevez des e-mails pour être informé de nos nouvelles offres et nouveautés. Vous pouvez désactiver cette option.",
  es: "Recibe correos electrónicos para enterarte de nuestras nuevas ofertas y adiciones. Puedes desactivar esta opción.",
  de: "Erhalten Sie E-Mails, um über unsere neuen Angebote und Ergänzungen informiert zu werden. Sie können dies deaktivieren.",
  it: "Ricevi e-mail per scoprire le nostre nuove offerte e aggiunte. Puoi disattivare questa opzione.",
  tr: "Yeni tekliflerimiz ve eklemelerimiz hakkında bilgi almak için e-posta alın. Bu özelliği kapatabilirsiniz.",
  ru: "Получайте электронные письма, чтобы узнавать о наших новых предложениях и дополнениях. Вы можете отключить это.",
  pl: "Otrzymuj e-maile, aby dowiedzieć się o naszych nowych ofertach i dodatkach. Możesz to wyłączyć.",
};

// NOTIFICATIONS
export const pleaseWait = {
  en: "Please wait!",
  fr: "Veuillez patienter !",
  es: "¡Por favor, espere!",
  de: "Bitte warten Sie!",
  it: "Attendere per favore!",
  tr: "Lütfen bekleyin!",
  ru: "Пожалуйста, подождите!",
  pl: "Proszę czekać!",
};

export const subscriptionStatus = {
  en: "Currently, you don't have any subscriptions in place.",
  fr: "Actuellement, vous n'avez aucune souscription en place.",
  es: "Actualmente, no tienes ninguna suscripción vigente.",
  de: "Aktuell hast du keine Abonnements aktiviert.",
  it: "Al momento, non hai alcuna sottoscrizione attiva.",
  tr: "Şu anda herhangi bir aboneliğiniz bulunmamaktadır.",
  ru: "В данный момент у вас нет активных подписок.",
  pl: "Obecnie nie masz żadnych aktywnych subskrypcji.",
};

export const YouDontHaveVouchers = {
  en: "You don't have any vouchers at the moment! Vouchers are typically provided following the purchase of a subscription.",
  fr: "Vous n'avez actuellement aucun bon de réduction ! Les bons de réduction sont généralement fournis après l'achat d'un abonnement.",
  es: "¡No tienes ningún cupón en este momento! Los cupones suelen proporcionarse después de la compra de una suscripción.",
  de: "Sie haben derzeit keine Gutscheine! Gutscheine werden in der Regel nach dem Kauf eines Abonnements bereitgestellt.",
  it: "Non hai nessun voucher al momento! I voucher vengono di solito forniti dopo l'acquisto di un abbonamento.",
  tr: "Şu anda herhangi bir indirim kuponunuz yok! İndirim kuponları genellikle bir abonelik satın alındıktan sonra sağlanır.",
  ru: "У вас сейчас нет никаких купонов! Купоны обычно предоставляются после покупки подписки.",
  pl: "Obecnie nie masz żadnych kuponów! Kupony są zwykle dostarczane po zakupie subskrypcji.",
};
export const ticketStatus = {
  en: "At this time, you do not have any tickets available.",
  fr: "Actuellement, vous ne disposez d'aucun billet disponible.",
  es: "En este momento, no tienes boletos disponibles.",
  de: "Derzeit hast du keine verfügbaren Tickets.",
  it: "Al momento, non hai a disposizione alcun biglietto.",
  tr: "Şu anda herhangi bir biletiniz bulunmamaktadır.",
  ru: "На данный момент у вас нет доступных билетов.",
  pl: "W tej chwili nie masz dostępnych żadnych biletów.",
};
