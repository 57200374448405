export const sportsExperience = {
  en: "Experience sports events like never before",
  fr: "Vivez des événements sportifs comme jamais auparavant",
  es: "Experimenta eventos deportivos como nunca antes",
  de: "Erleben Sie Sportveranstaltungen wie nie zuvor",
  it: "Vivi gli eventi sportivi come mai prima d'ora",
  tr: "Spor etkinliklerini hiç olmadığı gibi yaşayın",
  ru: "Почувствуйте спортивные события, как никогда раньше",
  pl: "Poczuj sportowe wydarzenia jak nigdy wcześniej",
};
export const relevantCompetitions = {
  en: "For those interested in the most relevant European competitions",
  fr: "Pour ceux qui s'intéressent aux compétitions européennes les plus pertinentes",
  es: "Para aquellos interesados en las competiciones europeas más relevantes",
  de: "Für alle, die sich für die relevantesten europäischen Wettbewerbe interessieren",
  it: "Per coloro interessati alle competizioni europee più rilevanti",
  tr: "En ilgi çekici Avrupa yarışmalarına ilgi duyanlar için",
  ru: "Для тех, кто интересуется самыми актуальными европейскими соревнованиями",
  pl: "Dla zainteresowanych najbardziej istotnymi europejskimi zawodami",
};
export const btSportDescription = {
  en: "Premier sports broadcaster streaming UEFA Champions League, Premier League, FA Cup, and more. Experience top-tier football action and exclusive coverage with BT Sport's dynamic and immersive broadcasts.",
  fr: "Premier diffuseur sportif proposant le streaming de l'UEFA Champions League, Premier League, FA Cup, et bien plus encore. Vivez l'action footballistique de haut niveau avec la couverture exclusive de BT Sport.",
  es: "El principal canal deportivo que transmite la UEFA Champions League, Premier League, FA Cup y más. Vive la emoción del fútbol de primer nivel y disfruta de una cobertura exclusiva con las transmisiones dinámicas e inmersivas de BT Sport.",
  de: "Der führende Sportkanal mit Streaming von UEFA Champions League, Premier League, FA Cup und mehr. Erleben Sie Spitzenfußball und exklusive Berichterstattung mit den dynamischen und immersiven Übertragungen von BT Sport.",
  it: "Il principale canale sportivo che trasmette UEFA Champions League, Premier League, FA Cup e altro. Vivi l'azione del calcio di alto livello e la copertura esclusiva con le trasmissioni dinamiche e coinvolgenti di BT Sport.",
  tr: "UEFA Şampiyonlar Ligi, Premier Lig, FA Cup ve daha fazlasını yayınlayan öncü spor yayıncısı. BT Sport'un dinamik ve etkileyici yayınlarıyla üst düzey futbol heyecanını ve özel yayınları yaşayın.",
  ru: "Ведущий спортивный канал, транслирующий матчи Лиги Чемпионов УЕФА, Английской Премьер-лиги, Кубка Англии и многое другое. Почувствуйте атмосферу футбольных матчей высшего уровня с эксклюзивной трансляцией от BT Sport.",
  pl: "Wiodący nadawca sportowy, transmitujący UEFA Champions League, Premier League, FA Cup i wiele innych. Doświadcz najwyższej klasy piłki nożnej i ekskluzywnego pokrycia z dynamicznymi i wciągającymi transmisjami BT Sport.",
};
export const daznDescription = {
  en: "The leading sports channel streaming a wide range of sporting events including football, boxing, tennis, and more. Enjoy live and on-demand coverage of top-tier competitions and exclusive content on DAZN.",
  fr: "La principale chaîne sportive diffusant une large gamme d'événements sportifs, dont le football, la boxe, le tennis et bien d'autres. Profitez d'une couverture en direct et à la demande de compétitions de haut niveau et de contenus exclusifs sur DAZN.",
  es: "El principal canal deportivo que transmite una amplia gama de eventos deportivos, incluyendo fútbol, boxeo, tenis y más. Disfruta de cobertura en vivo y bajo demanda de competiciones de primer nivel y contenido exclusivo en DAZN.",
  de: "Der führende Sportkanal mit Streaming einer Vielzahl von Sportveranstaltungen, darunter Fußball, Boxen, Tennis und mehr. Genießen Sie Live- und On-Demand-Berichterstattung über Spitzenwettbewerbe und exklusive Inhalte auf DAZN.",
  it: "Il principale canale sportivo che trasmette una vasta gamma di eventi sportivi, tra cui calcio, boxe, tennis e altro. Goditi la copertura in diretta e on-demand delle competizioni di alto livello e contenuti esclusivi su DAZN.",
  tr: "Futbol, boks, tenis ve daha fazlasını içeren geniş bir spor etkinlikleri yelpazesini yayınlayan öncü spor kanalı. DAZN'de üst düzey yarışmalara ve özel içeriğe canlı ve talep üzerine erişim sağlayın.",
  ru: "Ведущий спортивный канал, транслирующий широкий спектр спортивных событий, включая футбол, бокс, теннис и многое другое. Наслаждайтесь прямыми трансляциями и видео по требованию высококлассных соревнований и эксклюзивного контента на DAZN.",
  pl: "Wiodący nadawca sportowy, transmitujący szeroki zakres wydarzeń sportowych, w tym piłkę nożną, boks, tenis i wiele innych. Ciesz się transmisjami na żywo i na żądanie z najlepszych rozgrywek oraz ekskluzywnymi treściami na platformie DAZN.",
};
export const skyF1Description = {
  en: "Sky F1 is the ultimate destination for Formula 1 fans. Watch every thrilling moment of the Formula 1 World Championship, from the exhilarating races to behind-the-scenes insights. Get exclusive access to interviews with drivers, expert analysis, and in-depth coverage of the world's most prestigious motorsport series.",
  fr: "Sky F1 est la destination ultime pour les fans de Formule 1. Regardez chaque moment palpitant du Championnat du Monde de Formule 1, des courses exaltantes aux coulisses du sport automobile. Obtenez un accès exclusif à des interviews avec les pilotes, des analyses d'experts et une couverture approfondie de la série de sports mécaniques la plus prestigieuse au monde.",
  es: "Sky F1 es el destino definitivo para los fanáticos de la Fórmula 1. Disfruta de cada momento emocionante del Campeonato Mundial de Fórmula 1, desde las carreras emocionantes hasta los detalles detrás de escena. Obtén acceso exclusivo a entrevistas con los pilotos, análisis expertos y cobertura detallada de la serie de deportes de motor más prestigiosa del mundo.",
  de: "Sky F1 ist das ultimative Ziel für Formel-1-Fans. Erleben Sie jeden spannenden Moment der Formel-1-Weltmeisterschaft, von den aufregenden Rennen bis zu exklusiven Einblicken hinter die Kulissen. Erhalten Sie exklusiven Zugang zu Interviews mit Fahrern, Expertenanalysen und umfangreicher Berichterstattung über die weltweit renommierteste Motorsportserie.",
  it: "Sky F1 è la destinazione definitiva per i fan della Formula 1. Guarda ogni momento emozionante del Campionato Mondiale di Formula 1, dalle gare entusiasmanti agli approfondimenti dietro le quinte. Ottieni accesso esclusivo a interviste con i piloti, analisi esperte e copertura approfondita della serie motoristica più prestigiosa al mondo.",
  tr: "Sky F1, Formula 1 hayranları için ultimate bir hedeftir. Heyecan dolu Formula 1 Dünya Şampiyonası'nın her anını izleyin, çarpıcı yarışlardan sahne arkası bilgilerine kadar. Sürücülerle yapılan röportajlara, uzman analizlere ve dünyanın en prestijli motorsporları serisinin derinlemesine kapsamına özel erişim sağlayın.",
  ru: "Sky F1 - это идеальное место для поклонников Формулы 1. Смотрите захватывающие моменты Чемпионата мира по Формуле 1, начиная от захватывающих гонок до за кулисами. Получите эксклюзивный доступ к интервью с пилотами, экспертным анализам и глубокой круговой информации об одной из самых престижных серий автоспорта в мире.",
  pl: "Sky F1 to ostateczny cel dla fanów Formuły 1. Oglądaj każdy ekscytujący moment Mistrzostw Świata Formuły 1, od pasjonujących wyścigów po kulisy. Zdobądź dostęp do wywiadów z kierowcami, ekspertów analizy i obszernej relacji z najbardziej prestiżowego cyklu wyścigowego na świecie.",
};
export const eurosportDescription = {
  en: "Eurosport is a leading sports network providing comprehensive coverage of a wide range of sports events. From major tournaments to niche competitions, Eurosport brings you the excitement and drama of sports from around the world. Enjoy live broadcasts, expert analysis, and exclusive content that keeps you at the heart of the action.",
  fr: "Eurosport est un réseau sportif de premier plan offrant une couverture complète d'une large gamme d'événements sportifs. Des grands tournois aux compétitions de niche, Eurosport vous offre l'excitation et le suspense des sports du monde entier. Profitez de diffusions en direct, d'analyses d'experts et de contenus exclusifs qui vous plongent au cœur de l'action.",
  es: "Eurosport es una red deportiva líder que ofrece una cobertura completa de una amplia gama de eventos deportivos. Desde grandes torneos hasta competiciones especializadas, Eurosport te brinda la emoción y el drama del deporte de todo el mundo. Disfruta de transmisiones en vivo, análisis expertos y contenido exclusivo que te mantienen en el centro de la acción.",
  de: "Eurosport ist ein führendes Sportnetzwerk, das umfassende Berichterstattung über eine Vielzahl von Sportveranstaltungen bietet. Von großen Turnieren bis hin zu Nischenwettbewerben bringt Eurosport Ihnen die Spannung und Dramatik des Sports aus der ganzen Welt. Genießen Sie Live-Übertragungen, Expertenanalysen und exklusive Inhalte, die Sie mitten ins Geschehen versetzen.",
  it: "Eurosport è una rete sportiva leader che offre una copertura completa di una vasta gamma di eventi sportivi. Dai grandi tornei alle competizioni di nicchia, Eurosport ti porta l'emozione e il dramma dello sport proveniente da tutto il mondo. Goditi le trasmissioni in diretta, le analisi degli esperti e i contenuti esclusivi che ti tengono al centro dell'azione.",
  tr: "Eurosport, geniş bir spor etkinlikler yelpazesinin kapsamlı bir şekilde sunan önde gelen bir spor ağıdır. Büyük turnuvalardan özel yarışmalara kadar, Eurosport dünyanın dört bir yanındaki sporların heyecanını ve dramını size sunar. Canlı yayınları, uzman analizleri ve sizi eylemin kalbinde tutan özel içerikleriyle keyfini çıkarın.",
  ru: "Eurosport - ведущая спортивная сеть, обеспечивающая всестороннее освещение широкого спектра спортивных событий. От крупных турниров до узкоспециализированных соревнований, Eurosport приносит вам волнение и драму спорта со всего мира. Наслаждайтесь прямыми трансляциями, экспертным анализом и эксклюзивным контентом, который удерживает вас в самом центре событий.",
  pl: "Eurosport to wiodąca sieć sportowa zapewniająca kompleksowe pokrycie szerokiego zakresu wydarzeń sportowych. Od głównych turniejów po konkurencje niszowe, Eurosport przynosi emocje i dramat sportu z całego świata. Ciesz się na żywo transmisjami, ekspertami analizy i ekskluzywnymi treściami, które trzymają cię w centrum wydarzeń.",
};
export const commentaryLanguages = {
  en: "Providing channels with multiple commentary languages",
  fr: "Fournir des chaînes avec plusieurs langues de commentaire",
  es: "Proporcionar canales con múltiples idiomas de comentarios",
  de: "Bereitstellung von Kanälen mit mehreren Kommentarsprachen",
  it: "Fornire canali con diverse lingue di commento",
  tr: "Kanallara çoklu yorum dilleri sağlama",
  ru: "Предоставление каналов с несколькими языками комментариев",
  pl: "Dostarczanie kanałów z wieloma językami komentarzy",
};
export const helmetTitleSports = {
  en: "Crystal+ Premium IPTV, Sport Channels & Events",
  fr: "Crystal+ Premium IPTV, Chaînes de Sport et Événements",
  es: "Crystal+ Premium IPTV, Canales Deportivos y Eventos",
  de: "Crystal+ Premium IPTV, Sportkanäle & Events",
  it: "Crystal+ Premium IPTV, Canali Sportivi ed Eventi",
  tr: "Crystal+ Premium IPTV, Spor Kanalları ve Etkinlikler",
  ru: "Crystal+ Premium IPTV, Спортивные каналы и события",
  pl: "Crystal+ Premium IPTV, Kanały Sportowe i Wydarzenia",
};
export const helmetDescriptionSports = {
  en: "Explore the exciting world of sports with Crystal IPTV, where you'll discover a vast array of sports channels and thrilling live events. Immerse yourself in the excitement of the sporting world, all in stunning HD and 4K quality. With Crystal IPTV, you can stream your favorite sports and events on any device, offering an affordable and immersive sports entertainment experience. Elevate your passion for sports today!",
  fr: "Explorez le monde passionnant du sport avec Crystal IPTV, où vous découvrirez une vaste gamme de chaînes sportives et d'événements en direct passionnants. Plongez-vous dans l'excitation du monde du sport, le tout en qualité HD et 4K époustouflante. Avec Crystal IPTV, vous pouvez diffuser vos sports et événements préférés sur n'importe quel appareil, offrant une expérience de divertissement sportif abordable et immersive. Élevez votre passion pour le sport dès aujourd'hui !",
  es: "Explora el emocionante mundo del deporte con Crystal IPTV, donde descubrirás una amplia variedad de canales deportivos y emocionantes eventos en vivo. Sumérgete en la emoción del mundo del deporte, todo en impresionante calidad HD y 4K. Con Crystal IPTV, puedes transmitir tus deportes y eventos favoritos en cualquier dispositivo, ofreciendo una experiencia de entretenimiento deportivo asequible e inmersiva. ¡Eleva tu pasión por el deporte hoy mismo!",
  de: "Entdecken Sie die aufregende Welt des Sports mit Crystal IPTV, wo Sie eine breite Palette von Sportkanälen und spannenden Live-Events finden. Tauchen Sie ein in die Aufregung der Sportwelt, alles in atemberaubender HD- und 4K-Qualität. Mit Crystal IPTV können Sie Ihre Lieblingssportarten und -veranstaltungen auf jedem Gerät streamen und so ein erschwingliches und immersives Sportunterhaltungserlebnis bieten. Heben Sie Ihre Leidenschaft für den Sport heute an!",
  it: "Esplora l'entusiasmante mondo dello sport con Crystal IPTV, dove scoprirai una vasta gamma di canali sportivi ed emozionanti eventi dal vivo. Immergiti nell'emozione del mondo dello sport, il tutto in splendida qualità HD e 4K. Con Crystal IPTV, puoi trasmettere in streaming i tuoi sport e eventi preferiti su qualsiasi dispositivo, offrendo un'esperienza di intrattenimento sportivo accessibile e coinvolgente. Eleva la tua passione per lo sport oggi!",
  tr: "Crystal IPTV ile spor dünyasının heyecanını keşfedin, geniş bir spor kanalı yelpazesi ve heyecan verici canlı etkinlikler keşfedeceğiniz bir yer. Spor dünyasının heyecanına kendinizi kaptırın, hepsi şaşırtıcı HD ve 4K kalitesinde. Crystal IPTV ile favori sporlarınızı ve etkinliklerinizi herhangi bir cihazda izleyebilir, uygun fiyatlı ve sürükleyici bir spor eğlencesi deneyimi sunabilirsiniz. Bugün spor tutkunuzu yükseltin!",
  ru: "Исследуйте увлекательный мир спорта с Crystal IPTV, где вы найдете огромное количество спортивных каналов и захватывающих живых событий. Погрузитесь в атмосферу спортивного мира в потрясающем качестве HD и 4K. С Crystal IPTV вы можете смотреть свои любимые виды спорта и события на любом устройстве, предлагая доступный и захватывающий опыт спортивного развлечения. Поднимите свою страсть к спорту сегодня!",
  pl: "Odkryj fascynujący świat sportu dzięki Crystal IPTV, gdzie znajdziesz szeroki wybór kanałów sportowych i ekscytujących wydarzeń na żywo. Zanurz się w emocje świata sportu, wszystko w oszałamiającej jakości HD i 4K. Dzięki Crystal IPTV możesz oglądać ulubione dyscypliny sportu i wydarzenia na dowolnym urządzeniu, oferując przystępną cenę i immersyjne wrażenia z rozrywki sportowej. Podnieś swą pasję do sportu już dziś!",
};
