import signUpCompleted from "../images/signup_success.svg";
import "../styles/signup_completed.css";
import { useNavigate } from "react-router-dom";
// import Recoil and atoms
import { useRecoilValue } from "recoil";
import { displayLanguageAtom, authenticatedAtom } from "./../../Atoms";
// import paragraphs
import * as int from "../../paragraphs/signup_completed";
import { useEffect, useState } from "react";

// import components
import PricingCardsCombined from "../../app_comps/comps/pricing_cards/PricingCardsCombined";

function SignUpSuccess() {
  const dispayLanguage = useRecoilValue(displayLanguageAtom);
  const isAuthenticated = useRecoilValue(authenticatedAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "signup_completed_wrapper signup_completed_entrance"
  );
  const navigate = useNavigate();

  // docs: when the user completes the sing up process succussfully, the SignUp component will redirect them
  // to the SignUpSuccess component which will thank them for joining and then redirect them to
  // The HomePage/PlansPage

  // if the client visitied this page from any page apart from signup page we must redirect him to the home page
  // there is no need to show him this page because it's out of the context
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    // handle entrance animation
    setWrapperClass("signup_completed_wrapper");
  }, []);
  if (isAuthenticated) {
    return (
      <>
        <div className={wrapperClass}>
          <img src={signUpCompleted} alt="singup completed" />
          <h1>{int.header[dispayLanguage]}</h1>
          <p>{int.body[dispayLanguage]}</p>
        </div>
        <PricingCardsCombined />
      </>
    );
  }
}

export default SignUpSuccess;
