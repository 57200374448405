import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
// Import components
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";
import FeaturesCard from "../comps/home_page/FeaturesCard";
import WelcomeBar from "../comps/welcome_bar/WelcomeBar";
import HomePageHero from "../comps/home_page/HomePageHero";
import CardOne from "../comps/home_page/CardOne";
import UnrestrictedAccess from "../comps/home_page/UnrestrictedAccess";
import OurQuality from "../comps/home_page/OurQuality";
// import paragraphs
import * as int from "../../paragraphs/home_page_paragraphs.js";
// GOOGLE ANALYTICS INTEGRATION
import ReactGA from "react-ga";
// import images
import home_page_diversity from "../images/home_page/home_page_diversity.webp";

function TempHomePage() {
  const navigate = useNavigate();
  const displayLanguage = useRecoilValue(displayLanguageAtom);

  //
  useEffect(() => {
    if (localStorage.getItem("userInfo") != null) {
      const usertInfo = JSON.parse(localStorage.getItem("userInfo"));
      const isAdmin = usertInfo["isAdmin"];
      if (isAdmin == true) {
        navigate("admin-panel");
      }
    }
    // GOGOLE ANALYTICS EVENT
    // ReactGA.pageview(window.location.pathname);
  }, []);
  //
  return (
    <div>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitle[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescription[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com" />
      </Helmet>
      <WelcomeBar />
      <HomePageHero />
      <CardOne />
      <FeaturesCard />
      <UnrestrictedAccess />
      <OurQuality image={home_page_diversity} />
      <PricingCardsCombined />
    </div>
  );
}

export default TempHomePage;
