export const header = {
  en: "Welcome aboard! We're overjoyed to have you join us",
  fr: "Bienvenue à bord ! Nous sommes ravis de vous accueillir parmi nous",
  es: "¡Bienvenido a bordo! Estamos encantados de que te unas a nosotros",
  de: "Herzlich willkommen an Bord! Wir freuen uns sehr, dass Sie sich uns anschließen",
  it: "Benvenuto a bordo! Siamo felicissimi che tu ti unisca a noi",
  tr: "Hoş geldiniz! Aramıza katıldığınız için çok mutluyuz",
  ru: "Добро пожаловать на борт! Мы рады, что вы присоединились к нам",
  pl: "Witamy na pokładzie! Jesteśmy zachwyceni, że do nas dołączyłeś",
};

export const body = {
  en: "Explore our subscription plans now for an incredible selection of channels and entertainment options",
  fr: "Découvrez dès maintenant nos offres d'abonnement pour une sélection incroyable de chaînes et d'options de divertissement",
  es: "Explora ahora nuestros planes de suscripción para una increíble selección de canales y opciones de entretenimiento",
  de: "Entdecken Sie jetzt unsere Abonnementpläne für eine unglaubliche Auswahl an Kanälen und Unterhaltungsoptionen",
  it: "Esplora ora i nostri piani di abbonamento per una selezione incredibile di canali e opzioni di intrattenimento",
  tr: "Muhteşem bir kanal ve eğlence seçeneği için şimdi abonelik planlarımızı keşfedin",
  ru: "Исследуйте наши планы подписки сейчас, чтобы получить невероятный выбор каналов и вариантов развлечений",
  pl: "Zapoznaj się teraz z naszymi planami subskrypcji, aby skorzystać z niesamowitego wyboru kanałów i opcji rozrywki",
};
