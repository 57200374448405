export const welcome = {
  en: "Welcome",
  fr: "Bienvenue",
  es: "¡Bienvenido",
  de: "Willkommen",
  it: "Benvenuto",
  tr: "Hoş geldin",
  ru: "Добро пожаловать,",
  pl: "Witaj",
};

export const sentence = {
  en: "Your personalized IPTV experience is just a click away",
  fr: "Votre expérience IPTV personnalisée n'est qu'à un clic",
  es: "Tu experiencia de IPTV personalizada está a solo un clic de distancia",
  de: "Dein personalisiertes IPTV-Erlebnis ist nur einen Klick entfernt",
  it: "La tua esperienza IPTV personalizzata è a un solo clic di distanza",
  tr: "Kişiselleştirilmiş IPTV deneyiminiz bir tık uzağınızda",
  ru: "Алекс! Ваш персонализированный IPTV-опыт всего в одном клике",
  pl: "Twoje spersonalizowane doświadczenie IPTV jest na wyciągnięcie ręki",
};

export const chooseYourPlan = {
  en: "Choose your plan",
  fr: "Choisissez votre plan",
  es: "Elige tu plan",
  de: "Wähle deinen Plan",
  it: "Scegli il tuo piano",
  tr: "Planını seç",
  ru: "Выберите свой план",
  pl: "Wybierz swój plan",
};

// WELCOME BAR NOT AUTH
export const streamingShop = {
  en: "Crystal IPTV: Your One-Stop Streaming Shop for Unlimited TV, Movies, and Series.",
  fr: "Crystal IPTV : Votre boutique de streaming tout-en-un pour la télévision illimitée, les films et les séries.",
  es: "Crystal IPTV: Tu tienda de streaming todo en uno para TV, películas y series ilimitadas.",
  de: "Crystal IPTV: Ihr All-in-One-Streaming-Shop für unbegrenzte TV-Sendungen, Filme und Serien.",
  it: "Crystal IPTV: Il tuo negozio di streaming completo per TV, film e serie illimitati.",
  tr: "Crystal IPTV: Sınırsız TV, film ve dizi için tek durak yayın mağazanız.",
  ru: "Crystal IPTV: Ваш магазин потокового вещания для неограниченного просмотра ТВ, фильмов и сериалов.",
  pl: "Crystal IPTV: Twój sklep z nieograniczonym dostępem do telewizji, filmów i seriali.",
};

export const signUp = {
  en: "Sign up",
  fr: "Inscrivez-vous",
  es: "Registrarse",
  de: "Registrieren",
  it: "Registrati",
  tr: "Kaydol",
  ru: "Зарегистрироваться",
  pl: "Zarejestruj się",
};
export const contactUs = {
  en: "Contact us via",
  fr: "Contactez-nous via",
  es: "Contáctenos a través de",
  de: "Kontaktiere uns über",
  it: "Contattaci tramite",
  tr: "Bize ulaşın üzerinden",
  ru: "Свяжитесь с нами через",
  pl: "Skontaktuj się z nami via",
};
