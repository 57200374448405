import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import images
import tickets_icon from "../../images/tickets.svg";

// import paragraphs
import * as int from "../../../paragraphs/account_settings_paragraphs";

function AccountSettingsTickets(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );

  // destructuring data from props
  const data = props.data;

  // JXS producer
  const produceTicketsTableRows = (data) => {
    return data.map((data, index) => {
      // translate the Status before producing the JSX
      let status = "";
      if (data.status) {
        status = int.pending[displayLanguage];
      } else {
        status = int.resolved[displayLanguage];
      }
      return (
        <tr key={index}>
          <td>{data.id * 2342}</td>
          <td>{data.title}</td>
          <td>{data.adding_date}</td>
          <td className={!data.status ? "status_delivered" : "status_pending"}>
            {status}
          </td>
        </tr>
      );
    });
  };

  const produceTicketEntireTable = (data) => {
    return (
      <div className="settings_table_wrapper">
        <table className="settings_table">
          <thead>
            <tr>
              <th>ID</th>
              <th>{int.title[displayLanguage]}</th>
              <th>{int.openingDate[displayLanguage]}</th>
              <th>{int.status[displayLanguage]}</th>
            </tr>
          </thead>
          <tbody>{produceTicketsTableRows(data)}</tbody>
        </table>
      </div>
    );
  };

  const youHaveNoTickets = () => {
    return (
      <>
        <p className="no_data_to_show">{int.ticketStatus[displayLanguage]}</p>
      </>
    );
  };

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="settings_header_wrapper">
        <div className="settings_header_icon_wrapper">
          <div className="header_icon">
            <img src={tickets_icon} alt="ticket_icon" />
          </div>

          <div>
            <h1>{int.tickets[displayLanguage]}</h1>
            <p>{int.ticketInstructions[displayLanguage]}</p>
          </div>
        </div>{" "}
      </div>

      {/* Table */}
      {/* Please wait  and table*/}
      {data == undefined ? (
        <p className="no_data_to_show">{int.pleaseWait[displayLanguage]}</p>
      ) : data.length > 0 ? (
        produceTicketEntireTable(data)
      ) : (
        youHaveNoTickets()
      )}
    </div>
  );
}

export default AccountSettingsTickets;
