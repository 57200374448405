import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import images
import voucher_icon from "../../images/voucher.svg";

// import paragraphs
import * as int from "../../../paragraphs/account_settings_paragraphs";

function AccountSettingsVouchers(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );

  // JXS producer
  const produceVouchersTableRows = (data) => {
    return data.map((data, index) => {
      // translate the Status before producing the JSX
      let status = "";
      if (data.consumed) {
        status = int.consumed[displayLanguage];
      } else {
        status = int.available[displayLanguage];
      }
      return (
        <tr key={index}>
          <td>{data.voucher_code}</td>
          <td>{data.discount_rate}%</td>
          <td>{data.expiration_date}</td>
          <td
            className={!data.consumed ? "status_delivered" : "status_pending"}
          >
            {status}
          </td>
        </tr>
      );
    });
  };
  const produceVouchersEntireTable = () => {
    {
      /* Table */
    }
    return (
      <div className="settings_table_wrapper">
        <table className="settings_table">
          <thead>
            <tr>
              <th>Code</th>
              <th>{int.discountRate[displayLanguage]}</th>
              <th>{int.expirationDate[displayLanguage]}</th>
              <th>{int.status[displayLanguage]}</th>
            </tr>
          </thead>
          <tbody>{produceVouchersTableRows(props.data)}</tbody>
        </table>
      </div>
    );
  };

  const youHaveNoSubscription = () => {
    return (
      <>
        <p className="no_data_to_show">
          {int.YouDontHaveVouchers[displayLanguage]}
        </p>
      </>
    );
  };
  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="settings_header_wrapper">
        <div className="settings_header_icon_wrapper">
          <div className="header_icon">
            <img src={voucher_icon} alt="ticket_icon" />
          </div>

          <div>
            <h1>{int.vouchers[displayLanguage]}</h1>
            <p>{int.voucherInstructions[displayLanguage]}</p>
          </div>
        </div>{" "}
      </div>
      {/* Please wait  and table*/}
      {props.data == undefined ? (
        <p className="no_data_to_show">{int.pleaseWait[displayLanguage]}</p>
      ) : props.data.length != 0 ? (
        produceVouchersEntireTable()
      ) : (
        youHaveNoSubscription()
      )}
    </div>
  );
}

export default AccountSettingsVouchers;
