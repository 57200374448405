import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { grey } from "@mui/material/colors";
// import images
import { useEffect, useState } from "react";
import IPTV_preferences_decoration from "../../images/secure_pay/IPTV_preferences_decoration.webp";

// Recoil imports
import { useRecoilState, useRecoilValue } from "recoil";
import {
  displayLanguageAtom,
  chosenSubscriptionAtom,
  voucherAtom,
  isVoucherValidAtom,
  voucherRateAtom,
} from "../../../Atoms";
// import paragraphs
import * as int from "../../../paragraphs/payment_paragraphs.js";

//
function IPTVPreferencesSubscriptions({ setSelected }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [chosenSubscription, setChosenSubscription] = useRecoilState(
    chosenSubscriptionAtom
  );
  const [voucher, setVoucher] = useRecoilState(voucherAtom);
  const [isVoucherValid, setIsVoucherValid] =
    useRecoilState(isVoucherValidAtom);
  const [voucherRate, setVoucherRate] = useRecoilState(voucherRateAtom);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [wrapperClass, setWrapperClass] = useState(
    "payment_credit_card_wrapper animation_wrapper_entrance2 animation_wrapper"
  );
  //
  // invalid voucher inline style
  const validOrInvalidVoucher = () => {
    if (isVoucherValid == "I") {
      return {
        border: "1px solid #fe96a1",
      };
    } else if (isVoucherValid == "V") {
      return { border: "1px solid #c5ffb9" };
    } else {
      return { border: "none" };
    }
  };

  const producePlansSelect = () => {
    return (
      <div className="single_plan_select_wrapper">
        <label htmlFor="subscription">
          {int.subscription[displayLanguage]}
        </label>
        <select
          key={"subscriptionSelect"}
          name={"subscription"}
          id={"subscription"}
          className="iptv_preferences_select"
          value={chosenSubscription}
          onChange={(e) => {
            setChosenSubscription(e.target.value);
          }}
        >
          <option value={12}>1 {int.year[displayLanguage]}</option>
          <option value={6}>6 {int.months[displayLanguage]}</option>
          <option value={3}>3 {int.months[displayLanguage]}</option>
          <option value={1}>1 {int.month[displayLanguage]}</option>
        </select>
      </div>
    );
  };

  const handleContinue = () => {
    setSelected("email_whatsapp");
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optionally, add smooth scrolling animation
    });
  };

  const handleReset = () => {
    setChosenSubscription(12);
    setVoucher("");
    setIsVoucherValid("N");
  };

  const handleVoucherOnChange = (e) => {
    const voucherCode = e.target.value;
    setVoucher(voucherCode.trim());
    //
    let token = "";
    try {
      token = localStorage.getItem("accessToken");
    } catch {}

    //
    if (voucherCode == "") {
      setIsVoucherValid("N");
    } else {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/orders/check_voucher_status/`,
        params: {
          voucher: voucherCode,
        },
      })
        .then((res) => {
          const data = res.data;
          if (data.message == "voucher is available") {
            setIsVoucherValid("V");
            setVoucherRate(data.discount_rate);
          } else {
            setIsVoucherValid("I");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //
  useEffect(() => {
    setWrapperClass("payment_credit_card_wrapper animation_wrapper ");
    //
    let token = "";
    try {
      token = localStorage.getItem("accessToken");
    } catch {}
    // check if token is not an empty string cause that would be when the comp mounts for the first time
    if (voucher != "") {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BACKEND_URL}/orders/check_voucher_status/`,
        params: {
          voucher: voucher,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.message == "voucher is available") {
            setIsVoucherValid("V");
          } else {
            setIsVoucherValid("I");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div className={wrapperClass}>
      <div className="payment_credit_cards_first_half">
        <img
          style={{ display: imgLoaded ? "block" : "none" }}
          src={IPTV_preferences_decoration}
          alt="IPTV_preferences_decoration"
          onLoad={() => {
            setImgLoaded(true);
          }}
        />
        {!imgLoaded && (
          <div>
            <Skeleton
              variant="rectangular"
              width={170}
              height={341}
              animation="wave"
              sx={{ color: grey[900] }}
            />
          </div>
        )}
      </div>
      <div className="payment_credit_cards_second_half">
        <div className="credit_card_form">
          <div>
            <strong htmlFor="cardNumber" className="my_subscription">
              {int.mySubscriptions[displayLanguage]}
            </strong>
            <div className="select_wrapper"> {producePlansSelect()}</div>
            {/* <label htmlFor="voucher_input">
              {int.voucherOptional[displayLanguage]}
            </label>
            <div className="input_wrapper" style={{ width: "auto" }}>
              <input
                type="text"
                onChange={handleVoucherOnChange}
                style={validOrInvalidVoucher()}
                value={voucher}
              />
            </div>
            {isVoucherValid == "I" && (
              <div id="invalid_voucher" className="invalid_voucher ">
                {int.invalidVoucher[displayLanguage]}
              </div>
            )}
            {isVoucherValid == "V" && (
              <div
                id="invalid_voucher"
                className="invalid_voucher valid_voucher "
              >
                {int.validVoucher[displayLanguage]}
              </div>
            )} */}
          </div>

          <div className="actioning_btns_wrapper">
            <button
              className="validate_btn"
              type="submit"
              onClick={handleContinue}
            >
              {int.continueButton[displayLanguage]}
            </button>
            <button className="validate_btn return_btn" onClick={handleReset}>
              {int.reset[displayLanguage]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IPTVPreferencesSubscriptions;
