import { useState } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { blue } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
//

function formatNumber(amount) {
  const formattedAmount = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "EUR", // You can change the currency code as needed
    minimumFractionDigits: 2,
  });
  return formattedAmount;
}
//
function createData(label, amount) {
  //
  const formattedNumber = formatNumber(amount);
  const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);
  return { label: formattedLabel, amount: formattedNumber };
}

const totalsColor = blue[500];
//

function AdminPanelIncomeStatementPL() {
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState(1);
  const [income, setIncome] = useState([]);
  const [cost, setCost] = useState([]);
  const [netProfit, setNetProfit] = useState(0);

  const fetchProfitLossAPI = (timeframe) => {
    setLoading(true);
    // Fetch token from localstorage
    const userInfo = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userInfo);
    const token = userInfoJson.access;
    //
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/analytics/accounting/profitandloss/`,
      params: {
        timeframe: timeframe,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const income = res.data.income;
        const incomeList = [];
        // Iterate through the object's keys and values
        for (const key in income) {
          if (income.hasOwnProperty(key)) {
            const value = income[key];
            const row = createData(key, value);
            incomeList.push(row);
          }
        }
        setIncome(incomeList);
        //
        const cost = res.data.cost;
        const costList = [];
        // Iterate through the object's keys and values
        for (const key in cost) {
          if (cost.hasOwnProperty(key)) {
            const value = cost[key];
            const row = createData(key, value);
            costList.push(row);
          }
        }
        setCost(costList);
        // handle net profit
        setNetProfit(formatNumber(res.data["net_profit"]));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleMethodChange = (event) => {
    setTimeframe(event.target.value);
  };
  //
  //
  useEffect(() => {
    fetchProfitLossAPI(timeframe);
  }, [timeframe]);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {loading && <CircularProgress />}
      </Box>
      {!loading && (
        <Container>
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              margin: "10px 0",
            }}
          >
            <FormControl>
              <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
              <Select
                minWidth={500}
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={timeframe}
                label="timeframe"
                onChange={handleMethodChange}
              >
                <MenuItem value={0}>Week to date</MenuItem>
                <MenuItem value={1}>Month to date</MenuItem>
                <MenuItem value={2}>Quarter to date</MenuItem>
                <MenuItem value={3}>Year to date</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 300 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Income</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {income.map((row) => (
                  <TableRow
                    key={row.label}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: row.label == "Total income" ? totalsColor : "",
                      }}
                    >
                      <span style={{ opacity: "0" }}>____</span>
                      {row.label}
                    </TableCell>
                    <TableCell
                      style={{
                        color: row.label == "Total income" ? totalsColor : "",
                      }}
                    >
                      {row.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {/* Head for cost  */}
              <TableHead>
                <TableRow>
                  <TableCell>Cost</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cost.map((row) => (
                  <TableRow
                    key={row.label}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color: row.label == "Total cost" ? totalsColor : "",
                      }}
                    >
                      <span style={{ opacity: "0" }}>____</span>
                      {row.label}
                    </TableCell>
                    <TableCell
                      style={{
                        color: row.label == "Total cost" ? totalsColor : "",
                      }}
                    >
                      {row.amount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableBody>
                <TableRow
                  key={"netProfit"}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    Net Profit
                  </TableCell>
                  <TableCell>{netProfit}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </Container>
      )}
    </>
  );
}

export default AdminPanelIncomeStatementPL;
