import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../.././../Atoms";

// import CSS
import "../../styles/our_quality.css";

// import images
import video_qualities from "../../images/home_page/video_qualities.svg";

// import paragraphs
import * as int from "../../../paragraphs/AllIn.js";

function OurQuality(props) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  return (
    <div className="our_quality_wrapper single-slider">
      <div className="our_quality_head">
        <img src={video_qualities} alt="video_qualities" />
        <a
          className="falvaour_btn"
          href="https://wa.me/+212619018921"
          target="_blank"
        >
          {int.twentyFourHourTest[displayLanguage]}
        </a>
      </div>
      <h1>{int.videoResolutions[displayLanguage]}</h1>
      <img src={props.image} alt={"video_resolutions"} className="main_img" />
    </div>
  );
}

export default OurQuality;
