import { useState, useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// icons imports
import uk_icon from "../../images/united_kingdom_icon.svg";
import france_icon from "../../images/france_icon.svg";
import spain_icon from "../../images/spain_icon.svg";
import germany_icon from "../../images/german_icon.svg";
import italy_icon from "../../images/italy_icon.svg";
import turkey_icon from "../../images/turkey_icon.svg";
import russia_icon from "../../images/russia_icon.svg";
import poland_icon from "../../images/poland_icon.svg";
import greece_icon from "../../images/greece_icon.svg";
import language_icon from "../../images/language_icon.svg";

// CONSTANTS
// This const is used to map through it and produce the list of lan btns
const availableLanguages = [
  { code: "en", lan: "English", icon: uk_icon },
  { code: "fr", lan: "Français", icon: france_icon },
  { code: "es", lan: "Español", icon: spain_icon },
  { code: "de", lan: "Deutsch", icon: germany_icon },
  // { code: "it", lan: "Italiano", icon: italy_icon },
  // { code: "tr", lan: "Türk", icon: turkey_icon },
  // { code: "ru", lan: "Русский", icon: russia_icon },
  { code: "pl", lan: "Polski", icon: poland_icon },
  // { code: "el", lan: "Greek", icon: greece_icon },
];

const languagesAttributes = {
  en: { icon: uk_icon, humanReadable: "English" },
  fr: { icon: france_icon, humanReadable: "Français" },
  es: { icon: spain_icon, humanReadable: "Español" },
  de: { icon: germany_icon, humanReadable: "Deutsch" },
  // it: { icon: italy_icon, humanReadable: "Italiano" },
  // tr: { icon: turkey_icon, humanReadable: "Türk" },
  // ru: { icon: russia_icon, humanReadable: "Русский" },
  pl: { icon: poland_icon, humanReadable: "Polski" },
  // el: { icon: greece_icon, humanReadable: "Greek" },
};

function LanguageSelection() {
  // Define the Atoms (Global states)
  const [displayLanguage, setDisplayLanguage] =
    useRecoilState(displayLanguageAtom);
  // Defline the local states
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // JSX PROUCERS
  // define a function that returns langues unites with the map function
  const produceLanUnits = (units) => {
    // we first filter units to remove the one that is currently chosen
    const filteredUnits = units.filter((unit) => unit.lan != displayLanguage);
    return filteredUnits.map((unit) => {
      return (
        <div
          key={unit.code}
          id={unit.code}
          className="language_unit"
          onClick={(e) => {
            updateDisplayLanguage(e);
          }}
        >
          <span className="prevent_select">{unit.lan}</span>
          <img src={unit.icon} alt="english" style={{ height: "12px" }} />
        </div>
      );
    });
  };
  // define a function that would produce JSX for the currently selected language so all languages icons
  // will be rendered in the DOM, but only the selected one will have a display styles of block
  const produceSelectedLanguageIcon = () => {
    return availableLanguages.map((lan, index) => {
      return (
        <img
          key={index}
          style={{ display: displayLanguage !== lan.code ? "none" : "inline" }}
          src={languagesAttributes[lan.code].icon}
          alt={lan.code}
        />
      );
    });
  };
  // EVENTS HANDLERS
  // define a function that updates the display language atom when an option is clicked
  const updateDisplayLanguage = (e) => {
    let lan = null;

    if (e.target.tagName != "DIV") {
      lan = e.target.parentNode.id;
    } else {
      lan = e.target.id;
    }
    setIsOpen(false);
    setDisplayLanguage(lan);
    localStorage.setItem("displayLanguage", lan);
  };
  //   Define a function that handles the disply (on or off) of the languages dropdown menu
  const handleDropdownDisplay = () => {
    setIsOpen(!isOpen);
  };

  // Define useEffect for initializing some mouse click EventListeners
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  //
  return (
    <div className="language_box" ref={dropdownRef}>
      {/* section one contains the currently chosen languages along with the language icon */}
      <div className="lan_box_section1">
        <img
          src={language_icon}
          alt="language_icon"
          className="language_icon"
        />
        <div className="language_unit" onClick={handleDropdownDisplay}>
          <span className="prevent_select">
            {languagesAttributes[displayLanguage].humanReadable}
          </span>
          {produceSelectedLanguageIcon()}
        </div>
        {/* languages dropdown appreas when the user clicks on sectio one to chose other language */}
      </div>
      <div
        className="language_dropdown"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        {produceLanUnits(availableLanguages)}
      </div>
    </div>
  );
}

export default LanguageSelection;
