import { useState } from "react";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
import * as int from "../../../paragraphs/landing_page_paragraphs.js";
// import comps
import PricingCardExecute from "../pricing_cards/PricingCardsExecute";
// import Recoil and atoms

//
function LandingPricingCard({ rainbow, price, periodCode }) {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [clicked, setClicked] = useState(false);
  //

  const library = {
    1: {
      header: <h1>{int.oneMonth[displayLanguage]}</h1>,
      paragraph: <p>{int.oneMonthDesc[displayLanguage]}</p>,
    },
    3: {
      header: <h1>{int.threeMonths[displayLanguage]}</h1>,
      paragraph: <p> {int.threeMonthsDesc[displayLanguage]} </p>,
    },
    6: {
      header: <h1>{int.sixMonths[displayLanguage]}</h1>,
      paragraph: <p>{int.sixMonthsDesc[displayLanguage]}</p>,
    },
    12: {
      header: <h1>{int.oneYear[displayLanguage]}</h1>,
      paragraph: <p>{int.oneYearDesc[displayLanguage]}</p>,
    },
  };

  //
  return (
    <>
      {/* if btn clicked this comp will render which will execute some js code to handle the payment  */}
      {clicked && <PricingCardExecute periodCode={periodCode} />}
      <div className={rainbow ? "card_rainbow" : "card_grey"}>
        <div className="card">
          {rainbow && (
            <div className="best_value_flex">
              <div className="best_value">{int.bestValue[displayLanguage]}</div>
            </div>
          )}
          {library[periodCode]["header"]}
          {library[periodCode]["paragraph"]}
          <div className="landing_price">
            <h2>{price}</h2>
            {/* <span>/{int.month[displayLanguage]}</span> */}
          </div>
          <button
            className="choose_plan_btn"
            onClick={() => {
              setClicked(true);
            }}
          >
            {int.choosePlan[displayLanguage]}
          </button>
        </div>
      </div>
    </>
  );
}

export default LandingPricingCard;
