import { useRecoilValue } from "recoil";
import { authenticatedAtom } from "../../../Atoms";
// import css
import "../../styles/welcome_bar.css";
// import components
import WelcomeBarAuth from "./WelcomeBarAuth";
import WelcomeBarNotAuth from "./WelcomeBarNotAuth";

function WelcomeBar() {
  const isAuthenticated = useRecoilValue(authenticatedAtom);

  // render the sub comoponents bases on a condition

  return isAuthenticated ? <WelcomeBarAuth /> : <WelcomeBarNotAuth />;
}

export default WelcomeBar;
