import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import components
import FaqQuestion from "../FaqQuestion.js";
import ContactUSWhatsapp from "../comps/ContactUSWhatsapp.js";
import {
  SUPPORT_EMAIL_ADDRESS,
  WHATSAPP_NUMBER,
  FORMATTED_WHATSAPP_NUMBER,
} from "../../variable_contacts.js";
// import CSS
import "../styles/faq.css";
// import paragraphs
import * as int from "../../paragraphs/faq_paragraphs.js";
import { useEffect } from "react";

function Faq() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance faq_screen"
  );
  //
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper faq_screen");
  }, []);
  // Questions / Answers
  const QA = [
    {
      question: int.whatIsIPTV[displayLanguage],
      answer: (
        <>
          <p>{int.whatIsIPTVAnswer1[displayLanguage]}</p>
          <p>{int.whatIsIPTVAnswer2[displayLanguage]}</p>
        </>
      ),
    },
    {
      question: int.howItWorks[displayLanguage],
      answer: (
        <>
          <p>{int.howItWorksAnswer1[displayLanguage]}</p>
          <p>{int.howItWorksAnswer2[displayLanguage]}</p>
          <p>
            {int.visitInstallationGuide[displayLanguage]}{" "}
            <Link to="/installation-guide" className="link">
              {int.installationGuide[displayLanguage]}
            </Link>
          </p>
        </>
      ),
    },
    {
      question: int.equipmentNeeded[displayLanguage],
      answer: (
        <>
          {int.equipmentNeededAnswer[displayLanguage]}
          <ul>
            <li>{int.equipmentNeededAnswerBullet1[displayLanguage]}</li>
            <li>{int.equipmentNeededAnswerBullet2[displayLanguage]}</li>
            {/* <li>{int.equipmentNeededAnswerBullet3[displayLanguage]}</li> */}
            <li>{int.equipmentNeededAnswerBullet4[displayLanguage]}</li>
            <li>{int.equipmentNeededAnswerBullet5[displayLanguage]}</li>
          </ul>
        </>
      ),
    },
    {
      question: int.serviceCost[displayLanguage],
      answer: (
        <>
          <p>{int.serviceCostAnswer1[displayLanguage]}</p>
          <p>
            {int.serviceCostAnswer2[displayLanguage]}{" "}
            {int.serviceCostAnswer3[displayLanguage]}{" "}
            <Link to="/pricing" className="link">
              Pricing Page
            </Link>
            {int.serviceCostAnswer4[displayLanguage]}
          </p>
          <p>{int.serviceCostAnswer5[displayLanguage]}</p>
        </>
      ),
    },

    {
      question: int.paymentMethods[displayLanguage],
      answer: int.paymentMethodsAnswer[displayLanguage],
    },
    {
      question: int.freeTrialQuestion[displayLanguage],
      answer: int.freeTrialAnswer[displayLanguage],
    },
    {
      question: int.internetConnectionQuestion[displayLanguage],
      answer: (
        <>
          <p>{int.internetConnectionAnswer1[displayLanguage]}</p>
          <p>{int.internetConnectionAnswer2[displayLanguage]}</p>
        </>
      ),
    },

    {
      question: int.deviceUsageQuestion[displayLanguage],
      answer: int.deviceUsageAnswer[displayLanguage],
    },
    {
      question: int.troubleshootingQuestion[displayLanguage],
      answer: (
        <>
          <p>{int.troubleshootingAnswer1[displayLanguage]}</p>
          <p>{int.troubleshootingAnswer2[displayLanguage]}</p>
          <p>{int.troubleshootingAnswer3[displayLanguage]}</p>
        </>
      ),
    },
    {
      question: int.customerSupportQuestion[displayLanguage],
      answer: (
        <>
          <p>{int.customerSupportAnswer1[displayLanguage]}</p>
          <p>
            {int.customerSupportAnswer2[displayLanguage]}{" "}
            <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} className="link">
              {SUPPORT_EMAIL_ADDRESS}
            </a>
            {". "}
            {int.customerSupportAnswer4[displayLanguage]}
          </p>
          <p>
            {int.customerSupportAnswer5[displayLanguage]}{" "}
            <span className="link">{FORMATTED_WHATSAPP_NUMBER}</span>
            {". "}
            {int.customerSupportAnswer6[displayLanguage]}
          </p>
          <p>{int.customerSupportAnswer7[displayLanguage]}</p>
        </>
      ),
    },

    {
      question: int.videoQualityQuestion[displayLanguage],
      answer: (
        <>
          <p>{int.videoQualityAnswer1[displayLanguage]}</p>
          <p>{int.videoQualityAnswer2[displayLanguage]}</p>
          <p></p>
        </>
      ),
    },
  ];
  return (
    <div className="faq_screen">
      <div className={wrapperClass}>
        <Helmet>
          <title>Crystal IPTV - FAQ</title>
          <meta
            name="description"
            content={int.helmetDescription[displayLanguage]}
          />
          <meta property="og:title" content="Crystal IPTV - FAQ" />

          <meta property="og:url" content="www.crystaliptv.com/faq" />
        </Helmet>
        <h1 className="faq_h1">{int.faqHeader[displayLanguage]}</h1>
        <div className="faq_wrapper">
          {QA.map((qa) => {
            return <FaqQuestion question={qa.question} answer={qa.answer} />;
          })}
        </div>
        <div className="contact_us_faq_wrapper">
          <strong>{int.feelFreeToContactUs[displayLanguage]}</strong>
          <a href="https://wa.me/15551234567" target="_blank">
            <ContactUSWhatsapp color={"white"} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Faq;
