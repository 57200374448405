function SlideUnite({ data }) {
  return (
    <div
      className="slide_unite_container"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <div className="unite_description">
        <h1>{data.header}</h1>
        <p>{data.paragraph}</p>
      </div>
    </div>
  );
}

export default SlideUnite;
