import { useEffect, useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import AdminPanelProcessOrderRequest from "./AdminPanelProcessOrderRequest";
import AdminPanelDialogBox from "./AdminPanelDialogBox";
//
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
//
function AdminPanelProccessOrder({ currentOrderId }) {
  // error dialog box states
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(
    "Fetching order information was unsuccessful!"
  );
  const [alert, setAlert] = useState("error");
  const [alertMessage, setAlertMessage] = useState("");

  // Order information states
  const [orderInformation, setOrderInformation] = useState([]);
  const [voucherInfo, setVoucherInfo] = useState([]);

  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedVod, setSelectedVod] = useState([]);

  // FETCH ORDER INFORMATION DETAILS API

  useEffect(() => {
    // Fetch token from localstorage
    const userInfo = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userInfo);
    const token = userInfoJson.access;
    //
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/orders/order_detailed_info/?order_id=${currentOrderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const data = res.data;
        setOrderInformation(data.orderInformation);
        setVoucherInfo(data.voucherInfo);
        setSelectedChannels(data.selectedChannels);
        setSelectedVod(data.selectedVod);

        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setOpen(true);
        setAlertMessage(err.message);
      });
  }, []);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
        fontSize="20px"
        fontWeight={500}
        mb={5}
      >
        Order ID : {currentOrderId}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} p={0}>
          <Grid item xs={12} md={6}>
            <Item sx={{ border: `1px solid ${grey[700]}`, padding: "10px" }}>
              <TableContainer component={Paper}>
                <Typography
                  variant="h3"
                  fontSize={"18px"}
                  textAlign="left"
                  pb={5}
                  color={grey[700]}
                >
                  Order Information
                </Typography>
                <Table
                  sx={{ minWidth: 300 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {orderInformation.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">{row.label}</TableCell>
                        <TableCell align="right">
                          {row.label == "Price" && "€"}
                          {row.data}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item sx={{ border: `1px solid ${grey[700]}`, padding: "10px" }}>
              <TableContainer component={Paper}>
                <Typography
                  variant="h3"
                  fontSize={"18px"}
                  textAlign="left"
                  pb={5}
                  color={grey[700]}
                >
                  Voucher Information
                </Typography>
                <Table
                  sx={{ minWidth: 200 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {voucherInfo.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">{row.label}</TableCell>
                        <TableCell align="right">
                          {row.data}
                          {row.label == "Discount rate" && "%"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item sx={{ border: `1px solid ${grey[700]}`, padding: "10px" }}>
              <TableContainer component={Paper}>
                <Typography
                  variant="h3"
                  fontSize={"18px"}
                  textAlign="left"
                  pb={5}
                  color={grey[700]}
                >
                  Preferences
                </Typography>
                <Table
                  sx={{ minWidth: 200 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    {selectedChannels.concat(selectedVod).map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">{row.label}</TableCell>
                        <TableCell align="right">{row.data}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item sx={{ border: `1px solid ${grey[700]}`, padding: "10px" }}>
              <AdminPanelProcessOrderRequest currentOrderId={currentOrderId} />
            </Item>
          </Grid>
        </Grid>
      </Box>
      {/* This error dialog box will pop up if the API request ends up unseccessful */}
      <AdminPanelDialogBox
        open={open}
        setOpen={setOpen}
        content={content}
        alert={alert}
        alertMessage={alertMessage}
      />
    </>
  );
}

export default AdminPanelProccessOrder;
