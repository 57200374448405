// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import { Helmet } from "react-helmet";
// import CSS
import "../styles/movies_series.css";
import "../styles/sport_page.css";

// import paragraphs
import * as int from "../../paragraphs/sport_paragraphs.js";
// Import components
import SwiperJsComp from "../comps/SwiperJsComp";
// import components
import ProvidingSubtitles from "../comps/ProvidingSubtitles";
import OurQuality from "../comps/home_page/OurQuality";
import PricingCardsCombined from "../comps/pricing_cards/PricingCardsCombined";

// import images
import la_liga from "../images/sport_page/laliga_icon.svg";
import formula1 from "../images/sport_page/formula1_icon.svg";
import premier_league from "../images/sport_page/premier_league_icon.svg";
import motogp from "../images/sport_page/motogp_icon.svg";
import tour_de_france from "../images/sport_page/tour_de_france_icon.svg";
import champions_league from "../images/sport_page/champions_league_icon.svg";
import europa_league from "../images/sport_page/europa_league_icon.svg";
import caf from "../images/sport_page/caf_icon.svg";

//
import sport_series_hero from "../images/sport_page/sport_hero.webp";
import champions_league_bg from "../images/sport_page/champions_league_bg.webp";
import la_liga_poster from "../images/sport_page/la_liga.webp";
import formula1_poster from "../images/sport_page/formula1.webp";
import tennis_poster from "../images/sport_page/tennis.webp";
import commentary_poster from "../images/sport_page/sport_commentary.webp";
//import channels logos
import bt_sport from "../images/sport_page/BT_Sport_logo_2019.svg";
import danz from "../images/home_page/logos/dazn.svg";
import Sky_Sport_F1_Logo from "../images/sport_page/Sky_Sport_F1_Logo_2020.svg";
import eurosport_logo from "../images/sport_page/Eurosport_Logo_2015.svg";
// import imagesnp

import video_quality_poster from "../images/sport_page/sport_uhd_poster.webp";

function SportPage() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // create a list of items for swiper slides
  const list = [
    {
      height: "45px",
      logo: danz,
      image: la_liga_poster,
      description: int.daznDescription[displayLanguage],
    },
    {
      height: "40px",
      logo: bt_sport,
      image: champions_league_bg,
      description: int.btSportDescription[displayLanguage],
    },
    {
      height: "25px",
      logo: Sky_Sport_F1_Logo,
      image: formula1_poster,
      description: int.skyF1Description[displayLanguage],
    },
    {
      height: "20px",
      logo: eurosport_logo,
      image: tennis_poster,
      description: int.eurosportDescription[displayLanguage],
    },
  ];
  //
  return (
    <>
      <Helmet htmlAttributes={{ lang: displayLanguage }}>
        <title> {int.helmetTitleSports[displayLanguage]}</title>
        <meta
          name="description"
          content={int.helmetDescriptionSports[displayLanguage]}
        />
        <meta property="og:title" content="Crystal IPTV" />
        <meta
          property="og:description"
          content="Premium IPTV and VOD subsriptions"
        />
        <meta property="og:url" content="www.crystaliptv.com/sport" />
      </Helmet>
      <div className="movies_series_wrapper">
        <div className="section_header" id="section_header_sport">
          <h1>{int.sportsExperience[displayLanguage]}</h1>
          <div className="channels">
            <img src={la_liga} alt="netflix_box_icon" />
            <img src={formula1} alt="netflix_box_icon" />
            <img src={premier_league} alt="netflix_box_icon" />
            <img src={motogp} alt="netflix_box_icon" />
            <img src={tour_de_france} alt="netflix_box_icon" />
            <img src={champions_league} alt="netflix_box_icon" />
            <img src={europa_league} alt="netflix_box_icon" />
            <img src={caf} alt="netflix_box_icon" />
          </div>
        </div>
        <div className="main_image_wrapper">
          <img
            src={sport_series_hero}
            alt="sport_series_hero"
            className="main_image"
          />
        </div>

        <h1>{int.relevantCompetitions[displayLanguage]}</h1>
        <SwiperJsComp items={list} />
      </div>
      <ProvidingSubtitles
        header={int.commentaryLanguages[displayLanguage]}
        image={commentary_poster}
      />
      <OurQuality image={video_quality_poster} />
      <PricingCardsCombined />
    </>
  );
}

export default SportPage;
