// import recoil
import { useState, useEffect } from "react";
import ContactUSWhatsapp from "../comps/ContactUSWhatsapp.js";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms.js";
import {
  SUPPORT_EMAIL_ADDRESS,
  WHATSAPP_NUMBER,
  FORMATTED_WHATSAPP_NUMBER,
} from "../../variable_contacts.js";
// import components
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
// import paragraphs
import * as int from "../../paragraphs/refund_policy_paragraphs.js";
import * as intall from "../../paragraphs/faq_paragraphs.js";
// import CSS
import "../styles/terms_and_conditions.css";

function RefundPolicy() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "animation_wrapper animation_wrapper_entrance"
  );
  //
  const chapterOne = {
    title: int.commitmentText[displayLanguage],
    paragraphs: [int.refundPolicyDetails[displayLanguage]],
  };
  const chapterTwo = {
    title: int.refundTimeframe[displayLanguage],
    paragraphs: [int.refundTimeframeDetails[displayLanguage]],
  };
  const chapterThree = {
    title: int.qualifyingForRefund[displayLanguage],
    paragraphs: [int.refundRequestRights[displayLanguage]],
  };
  const chapterFive = {
    title: int.refundProcessingAndDifficulty[displayLanguage],
    paragraphs: [int.refundProcessingDetails[displayLanguage]],
  };

  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);
  //

  const produceChapter = (library) => {
    return (
      <>
        <br />
        <Typography
          variant="h2"
          gutterBottom
          fontSize={"18px"}
          fontWeight={"500"}
        >
          {library.title}
        </Typography>
        <ul>
          {library.paragraphs.map((item, index) => {
            const indent = item[0] === "(" ? true : false;
            return (
              <li key={index} style={{ marginLeft: indent ? "20px" : "0" }}>
                <Typography variant="p" gutterBottom color={grey[800]}>
                  {item}
                </Typography>
              </li>
            );
          })}
        </ul>
      </>
    );
  };
  return (
    <>
      <Container
        maxWidth="md"
        sx={{ margin: "50px auto" }}
        className={wrapperClass}
      >
        <Typography variant="h1" gutterBottom fontSize={"27px"}>
          {int.refundPolicy[displayLanguage]}
        </Typography>
        {produceChapter(chapterOne)}
        {produceChapter(chapterTwo)}
        {produceChapter(chapterThree)}

        {/* This part contains an a tag an strong tag  */}
        <>
          <br />
          <Typography
            variant="h2"
            gutterBottom
            fontSize={"18px"}
            fontWeight={"500"}
          >
            {int.refundInitiation[displayLanguage]}
          </Typography>
          <ul>
            <li key={"request_refund"} style={{ marginLeft: "20px" }}>
              <Typography variant="p" gutterBottom color={grey[800]}>
                {int.dissatisfactionRefundRequest1[displayLanguage] + " "}
              </Typography>
              <Typography variant="s" gutterBottom color={grey[800]}>
                <strong>{FORMATTED_WHATSAPP_NUMBER} </strong>
              </Typography>
              <Typography variant="p" gutterBottom color={grey[800]}>
                {int.dissatisfactionRefundRequest2[displayLanguage] + " "}
              </Typography>
              <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} target="_blank">
                {SUPPORT_EMAIL_ADDRESS}
              </a>
              <Typography variant="p" gutterBottom color={grey[800]}>
                . {int.dissatisfactionRefundRequest3[displayLanguage]}
              </Typography>
            </li>
          </ul>
        </>
        {produceChapter(chapterFive)}
      </Container>
      <div className="contact_us_faq_wrapper">
        <strong>{intall.feelFreeToContactUs[displayLanguage]}</strong>
        <a href={`https://wa.me/${WHATSAPP_NUMBER}`} target="_blank">
          <ContactUSWhatsapp color={"white"} />
        </a>
      </div>
    </>
  );
}

export default RefundPolicy;
