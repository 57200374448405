import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";
import * as int from "../../../paragraphs/landing_page_paragraphs.js";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import components
import SlideUnite from "./SlideUnite";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
// import images
import poster_8 from "../../images/landing_page/landing_page_poster_8.webp";
import poster_9 from "../../images/landing_page/landing_page_poster_9.webp";
import poster_10 from "../../images/landing_page/landing_page_poster_10.webp";
import poster_11 from "../../images/landing_page/landing_page_poster_11.webp";
import poster_12 from "../../images/landing_page/landing_page_poster_12.webp";
import poster_13 from "../../images/landing_page/landing_page_poster_13.webp";

const LandingPageSection3 = () => {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  //
  // Sliders data
  const slidersData = [
    {
      image: poster_8,
      header: int.latestMovies[displayLanguage],
      paragraph: int.movieMagic[displayLanguage],
    },
    {
      image: poster_9,
      header: "UEFA Champions League",
      paragraph: int.championsLeagueExperience[displayLanguage],
    },
    {
      image: poster_10,
      header: "The Last of Us",
      paragraph: int.theLastOfUsDes[displayLanguage],
    },
    {
      image: poster_11,
      header: "National Geographic",
      paragraph: int.natGeoExploration[displayLanguage],
    },
    {
      image: poster_12,
      header: int.kidsChannels[displayLanguage],
      paragraph: int.kidsContent[displayLanguage],
    },
    {
      image: poster_13,
      header: "Outlander",
      paragraph: int.outlanderStory[displayLanguage],
    },
  ];
  //
  return (
    <>
      <h1>{int.discoveryText[displayLanguage]}</h1>
      <p>{int.weeklyContent[displayLanguage]}</p>
      <Swiper
        loop={true}
        slidesPerView={1}
        pagination={{
          type: "bullets",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="my_swiper"
      >
        {slidersData.map((item) => {
          return (
            <SwiperSlide>
              <SlideUnite data={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default LandingPageSection3;
