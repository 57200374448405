export const connectionOffline = {
  en: "It appears that your internet connection is currently offline",
  fr: "Il semblerait que votre connexion Internet soit actuellement hors ligne",
  es: "Parece que tu conexión a Internet está actualmente desconectada",
  de: "Es scheint, dass Ihre Internetverbindung derzeit offline ist",
  it: "Sembra che la tua connessione Internet sia attualmente offline",
  tr: "İnternet bağlantınız şu anda çevrimdışı görünüyor",
  ru: "Похоже, что ваше интернет-соединение в настоящее время отключено",
  pl: "Wygląda na to, że obecnie nie masz połączenia internetowego",
};
export const checkYourConnection = {
  en: "Please check your internet connection and try again when you're back online",
  fr: "Veuillez vérifier votre connexion Internet et réessayer lorsque vous serez de nouveau en ligne",
  es: "Por favor, verifica tu conexión a Internet e intenta nuevamente cuando vuelvas a estar en línea",
  de: "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut, wenn Sie wieder online sind",
  it: "Verifica la tua connessione Internet e riprova quando sei di nuovo online",
  tr: "Lütfen internet bağlantınızı kontrol edin ve tekrar çevrimiçi olduğunuzda yeniden deneyin",
  ru: "Пожалуйста, проверьте ваше интернет-соединение и повторите попытку, когда вы снова будете онлайн",
  pl: "Proszę sprawdzić swoje połączenie internetowe i spróbować ponownie, gdy będziesz ponownie online",
};
