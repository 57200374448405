//

function DeviceCard({ img, title, height }) {
  return (
    <div className="device_card">
      <img src={img} alt="logo" height={height} style={{ margin: "20px 0" }} />
      <p style={{ color: "#fff" }}>{title}</p>
    </div>
  );
}

export default DeviceCard;
