import { useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import AdminPanelDialogBox from "./AdminPanelDialogBox";
// import date picker

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
//

function AdminPanelProcessOrderRequest({ currentOrderId }) {
  //
  const [iptvUsername, setIptvUsername] = useState(null);
  const [iptvPassword, setIptvPassword] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userWhatsapp, setUserWhatsapp] = useState(null);
  const [subscriptionStart, setSubscriptionStart] = useState(null);
  const [subscriptionEnd, setSubscriptionEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogBoxContent, setDialogBoxContent] = useState(
    "Delivery has been sent via Email and via WhatsApp!"
  );
  const [alert, setAlert] = useState("success");
  const [message, setMessage] = useState("");

  // Functions
  const actionOrderAPI = () => {
    setLoading(true);
    // Fetch token from localstorage
    const userInfo = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userInfo);
    const token = userInfoJson.access;

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/orders/action_order/`,
      data: {
        order_id: currentOrderId,
        email: userEmail,
        whatsapp_number: userWhatsapp,
        iptv_username: iptvUsername,
        iptv_password: iptvPassword,
        starting_date: subscriptionStart,
        ending_date: subscriptionEnd,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const data = res.data;
        setLoading(false);
        setOpen(true);
        setDialogBoxContent(data.content);
        setAlert(data.responseAlert);
        setMessage(data.alertMessage);
      })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setDialogBoxContent(
          "Please have a look at the console to see the logged error."
        );
        setAlert("error");
        setMessage("HTTP Request Failed");
        console.log(err);
      });
  };

  return (
    <>
      <Typography variant="h6" textAlign="left">
        Order delivery panel
      </Typography>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="iptv-username"
          label="IPTV Username"
          variant="filled"
          value={iptvUsername}
          onChange={(e) => {
            setIptvUsername(e.target.value);
          }}
          error={(userEmail == null) & (userEmail == "") ? true : false}
        />
        <TextField
          id="iptv-password"
          label="IPTV Password"
          variant="filled"
          value={iptvPassword}
          onChange={(e) => {
            setIptvPassword(e.target.value);
          }}
        />
        <TextField
          error={(userEmail == null) & (userEmail == "") ? true : false}
          id="user-email"
          label="User Email"
          variant="filled"
          type="email"
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
        />
        <TextField
          disabled
          error={(userWhatsapp == null) & (userWhatsapp == "") ? true : false}
          id="user-whatsapp"
          label="User WhatsApp"
          variant="filled"
          value={userWhatsapp}
          onChange={(e) => {
            setUserWhatsapp(e.target.value);
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="Start Date"
              value={subscriptionStart}
              onChange={(e) => {
                setSubscriptionStart(e["$d"]);
              }}
            />
          </DemoContainer>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              label="End Date"
              value={subscriptionEnd}
              onChange={(e) => {
                setSubscriptionEnd(e["$d"]);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Box
          style={{ margin: "auto", justifyContent: "center", padding: "10px" }}
        >
          <LoadingButton
            size="small"
            onClick={actionOrderAPI}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Send</span>
          </LoadingButton>
        </Box>
      </Box>
      <AdminPanelDialogBox
        open={open}
        setOpen={setOpen}
        content={dialogBoxContent}
        alert={alert}
        alertMessage={message}
      />
    </>
  );
}

export default AdminPanelProcessOrderRequest;
