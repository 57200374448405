export const conditionsForCrystalIPTVService = {
  en: "CONDITIONS FOR THE PROVISION OF CRYSTAL IPTV SERVICE",
  fr: "CONDITIONS POUR LA PRESTATION DU SERVICE CRYSTAL IPTV",
  es: "CONDICIONES PARA LA PRESTACIÓN DEL SERVICIO DE IPTV CRYSTAL",
  de: "BEDINGUNGEN FÜR DIE BEREITSTELLUNG DES CRYSTAL IPTV-DIENSTES",
  it: "CONDIZIONI PER LA FORNITURA DEL SERVIZIO IPTV CRYSTAL",
  tr: "KRISTAL IPTV SERVİSİNİN SAĞLANMASI KOŞULLARI",
  ru: "УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГИ CRYSTAL IPTV",
  pl: "WARUNKI ŚWIADCZENIA USŁUGI CRYSTAL IPTV",
};
export const subscriptionServices = {
  en: "1. SUBSCRIPTION SERVICES",
  fr: "1. SERVICES D'ABONNEMENT",
  es: "1. SERVICIOS DE SUSCRIPCIÓN",
  de: "1. ABONNEMENTDIENSTE",
  it: "1. SERVIZI DI ABBONAMENTO",
  tr: "1. ABONELİK HİZMETLERİ",
  ru: "1. УСЛУГИ ПО ПОДПИСКЕ",
  pl: "1. USŁUGI ABONAMENTOWE",
};
export const crystalIPTVSubscriptionInfo = {
  en: "1.1. The Crystal IPTV service is a subscription-based internet service that entitles Subscribers to access the Channels, and other Content Crystal IPTV provides as part of the Subscription Service. Subscribers may access the Subscription Services on authorized devices (such as television sets, mobile devices, tablets, consoles and other devices) connected to the Internet or mobile networks (“Subscription Services”).",
  fr: "1.1. Le service Crystal IPTV est un service Internet basé sur l'abonnement qui permet aux abonnés d'accéder aux Chaînes et autres contenus fournis par Crystal IPTV dans le cadre du Service d'abonnement. Les abonnés peuvent accéder aux Services d'abonnement sur des appareils autorisés (tels que des téléviseurs, des appareils mobiles, des tablettes, des consoles et autres appareils) connectés à Internet ou à des réseaux mobiles (« Services d'abonnement »).",
  es: "1.1. El servicio Crystal IPTV es un servicio de Internet basado en suscripción que permite a los suscriptores acceder a los Canales y otros contenidos que Crystal IPTV proporciona como parte del Servicio de suscripción. Los suscriptores pueden acceder a los Servicios de suscripción en dispositivos autorizados (como televisores, dispositivos móviles, tabletas, consolas y otros dispositivos) conectados a Internet o a redes móviles (« Servicios de suscripción »).",
  de: "1.1. Der Crystal IPTV-Dienst ist ein internetbasiertes Abonnementdienst, der Abonnenten berechtigt, auf die Kanäle und andere Inhalte zuzugreifen, die Crystal IPTV im Rahmen des Abonnementdienstes bereitstellt. Abonnenten können auf autorisierten Geräten (wie Fernsehgeräten, Mobilgeräten, Tablets, Konsolen und anderen Geräten), die mit dem Internet oder Mobilfunknetzen verbunden sind, auf die Abonnementdienste zugreifen („Abonnementdienste“).",
  it: "1.1. Il servizio Crystal IPTV è un servizio internet basato su abbonamento che consente agli abbonati di accedere ai Canali e ad altri contenuti forniti da Crystal IPTV come parte del Servizio di abbonamento. Gli abbonati possono accedere ai Servizi di abbonamento su dispositivi autorizzati (come televisori, dispositivi mobili, tablet, console e altri dispositivi) collegati a Internet o a reti mobili (« Servizi di abbonamento »).",
  tr: "1.1. Crystal IPTV servisi, Abonelere Kanallara ve diğer İçeriklere erişim hakkı veren, abonelik temelli bir internet hizmetidir. Aboneler, İnternet veya mobil ağlara bağlı yetkili cihazlar (televizyonlar, mobil cihazlar, tabletler, konsollar ve diğer cihazlar gibi) üzerinden Abonelik Hizmetlerine erişebilirler (« Abonelik Hizmetleri »).",
  ru: "1.1. Сервис Crystal IPTV является интернет-сервисом на основе подписки, который предоставляет абонентам доступ к каналам и другим контенту, предоставляемым Crystal IPTV в рамках Сервиса подписки. Абоненты могут получить доступ к Сервисам подписки на уполномоченных устройствах (таких как телевизоры, мобильные устройства, планшеты, консоли и другие устройства), подключенных к Интернету или мобильным сетям (« Сервисы подписки »).",
  pl: "1.1. Usługa Crystal IPTV to usługa internetowa oparta na subskrypcji, która uprawnia Abonentów do dostępu do Kanałów i innych treści dostarczanych przez Crystal IPTV w ramach Usługi subskrypcyjnej. Abonenci mogą korzystać z Usług subskrypcyjnych na autoryzowanych urządzeniach (takich jak telewizory, urządzenia mobilne, tablety, konsole i inne urządzenia) podłączonych do Internetu lub sieci komórkowych („Usługi subskrypcyjne”).",
};
export const subscriptionServicesProviderInfo = {
  en: "1.2. The Subscription Services are provided by CRYSTAL PLUS Ltd, whose registered office is at UK.",
  fr: "1.2. Les Services d'abonnement sont fournis par CRYSTAL PLUS Ltd, dont le siège social est au Royaume-Uni.",
  es: "1.2. Los Servicios de suscripción son proporcionados por CRYSTAL PLUS Ltd, cuya sede registrada está en el Reino Unido.",
  de: "1.2. Die Abonnementdienste werden von CRYSTAL PLUS Ltd angeboten, deren eingetragener Sitz im Vereinigten Königreich liegt.",
  it: "1.2. I Servizi di abbonamento sono forniti da CRYSTAL PLUS Ltd, la cui sede legale si trova nel Regno Unito.",
  tr: "1.2. Abonelik Hizmetleri, kayıtlı ofisi Birleşik Krallık'ta bulunan CRYSTAL PLUS Ltd tarafından sağlanmaktadır.",
  ru: "1.2. Услуги по подписке предоставляются CRYSTAL PLUS Ltd, зарегистрированным в Великобритании.",
  pl: "1.2. Usługi subskrypcyjne są świadczone przez CRYSTAL PLUS Ltd, której siedziba znajduje się w Wielkiej Brytanii.",
};
export const crystalIPTVTermsAndConditions = {
  en: "1.3. The following terms and conditions (“CRYSTAL IPTV terms”) apply to the acquisition and use of Subscription Service. CRYSTAL IPTV reserves the right to change or modify the CRYSTAL IPTV Terms at any time and at its sole discretion, without notification of Subscribers. The CRYSTAL IPTV Terms as may be amended are binding on Subscribers. It is the sole responsibility of Subscribers to make themselves aware of the currently valid version of the CRYSTAL IPTV Terms that will always be accessible on the CRYSTAL IPTV website.",
  fr: "1.3. Les présentes conditions générales (« Conditions CRYSTAL IPTV ») s'appliquent à l'acquisition et à l'utilisation du Service d'abonnement. CRYSTAL IPTV se réserve le droit de modifier ou de modifier les Conditions CRYSTAL IPTV à tout moment et à sa seule discrétion, sans notification aux abonnés. Les Conditions CRYSTAL IPTV telles qu'elles peuvent être modifiées sont contraignantes pour les abonnés. Il incombe uniquement aux abonnés de se tenir informés de la version actuellement valide des Conditions CRYSTAL IPTV qui seront toujours accessibles sur le site web de CRYSTAL IPTV.",
  es: "1.3. Los siguientes términos y condiciones (“Términos CRYSTAL IPTV”) se aplican a la adquisición y uso del Servicio de Suscripción. CRYSTAL IPTV se reserva el derecho de cambiar o modificar los Términos CRYSTAL IPTV en cualquier momento y a su sola discreción, sin notificación a los suscriptores. Los Términos CRYSTAL IPTV, en su forma modificada, son vinculantes para los suscriptores. Es responsabilidad exclusiva de los suscriptores estar al tanto de la versión actualmente válida de los Términos CRYSTAL IPTV, que siempre estarán accesibles en el sitio web de CRYSTAL IPTV.",
  de: "1.3. Die folgenden Geschäftsbedingungen („CRYSTAL IPTV-Bedingungen“) gelten für den Erwerb und die Nutzung des Abonnementdienstes. CRYSTAL IPTV behält sich das Recht vor, die CRYSTAL IPTV-Bedingungen jederzeit und nach eigenem Ermessen ohne Benachrichtigung der Abonnenten zu ändern oder zu modifizieren. Die CRYSTAL IPTV-Bedingungen in ihrer geänderten Form sind für die Abonnenten verbindlich. Es obliegt allein den Abonnenten, sich über die derzeit gültige Version der CRYSTAL IPTV-Bedingungen zu informieren, die immer auf der CRYSTAL IPTV-Website zugänglich sein wird.",
  it: "1.3. Le seguenti condizioni (“Termini CRYSTAL IPTV”) si applicano all'acquisizione e all'uso del Servizio di Abbonamento. CRYSTAL IPTV si riserva il diritto di modificare o modificare i Termini CRYSTAL IPTV in qualsiasi momento e a sua esclusiva discrezione, senza notifica agli abbonati. I Termini CRYSTAL IPTV, come potrebbero essere modificati, vincolano gli abbonati. È responsabilità esclusiva degli abbonati essere consapevoli della versione attualmente valida dei Termini CRYSTAL IPTV, che sarà sempre accessibile sul sito web di CRYSTAL IPTV.",
  tr: "1.3. Aşağıda belirtilen şartlar ve koşullar (“CRYSTAL IPTV koşulları”), Abonelik Hizmetinin satın alınması ve kullanımına ilişkin olarak geçerlidir. CRYSTAL IPTV, Aboneleri bilgilendirmeden herhangi bir zamanda ve kendi takdirine bağlı olarak CRYSTAL IPTV Koşullarını değiştirme veya düzenleme hakkını saklı tutar. Değiştirilen CRYSTAL IPTV Koşulları Aboneler üzerinde bağlayıcıdır. Abonelerin CRYSTAL IPTV Koşullarının şu anda geçerli olan sürümünü her zaman CRYSTAL IPTV web sitesinde erişilebilir olduğundan emin olmaları Abonelerin tek sorumluluğundadır.",
  ru: "1.3. Нижеприведенные условия («Условия CRYSTAL IPTV») применяются к приобретению и использованию услуги по подписке. CRYSTAL IPTV оставляет за собой право изменять или модифицировать Условия CRYSTAL IPTV в любое время и по своему собственному усмотрению, без уведомления абонентов. Условия CRYSTAL IPTV в их измененной форме обязательны для абонентов. Это единственная ответственность абонентов ознакомиться с текущей действующей версией Условий CRYSTAL IPTV, которая всегда будет доступна на веб-сайте CRYSTAL IPTV.",
  pl: "1.3. Poniższe warunki i postanowienia („Warunki CRYSTAL IPTV”) dotyczą nabywania i korzystania z Usługi abonamentowej. CRYSTAL IPTV zastrzega sobie prawo do zmiany lub modyfikowania Warunków CRYSTAL IPTV w dowolnym czasie i według własnego uznania, bez powiadomienia Abonentów. Warunki CRYSTAL IPTV w zmienionej formie obowiązują Abonentów. To jest wyłączna odpowiedzialność Abonentów, aby zapoznać się z obecnie obowiązującą wersją Warunków CRYSTAL IPTV, która zawsze będzie dostępna na stronie internetowej CRYSTAL IPTV.",
};
export const subscriptionServicesAvailabilityInfo = {
  en: "1.4. Subscription Services are available in the territory Europe for Subscribers with a bona fide. CRYSTAL IPTV does not make any representations or assurances that the Channels or Content contained in the Subscription Services are appropriate for any other region or on any other basis admitted for viewing in other countries.",
  fr: "1.4. Les Services d'abonnement sont disponibles sur le territoire européen pour les abonnés de bonne foi. CRYSTAL IPTV ne fait aucune déclaration ni aucune garantie quant au fait que les Chaînes ou le Contenu contenus dans les Services d'abonnement sont appropriés pour toute autre région ou sur toute autre base admise pour la visualisation dans d'autres pays.",
  es: "1.4. Los Servicios de suscripción están disponibles en el territorio de Europa para los suscriptores de buena fe. CRYSTAL IPTV no realiza ninguna representación ni garantía de que los Canales o Contenidos contenidos en los Servicios de suscripción sean apropiados para ninguna otra región o en ninguna otra base permitida para su visualización en otros países.",
  de: "1.4. Die Abonnementdienste stehen im Gebiet Europa für Abonnenten zur Verfügung, die bona fide sind. CRYSTAL IPTV macht keine Darstellungen oder Zusicherungen, dass die in den Abonnementdiensten enthaltenen Kanäle oder Inhalte für andere Regionen oder auf anderer Grundlage zur Anzeige in anderen Ländern geeignet sind.",
  it: "1.4. I Servizi di abbonamento sono disponibili nel territorio Europeo per gli abbonati in buona fede. CRYSTAL IPTV non fa rappresentazioni o garanzie che i Canali o i Contenuti contenuti nei Servizi di abbonamento siano appropriati per altre regioni o su altre basi ammesse per la visualizzazione in altri paesi.",
  tr: "1.4. Abonelik Hizmetleri, bonafide olan Aboneler için Avrupa'da mevcuttur. CRYSTAL IPTV, Abonelik Hizmetlerinde bulunan Kanalların veya İçeriklerin diğer bölgeler veya diğer ülkelerde görüntülemeye uygun olduğuna dair herhangi bir temsil veya güvence vermez.",
  ru: "1.4. Услуги по подписке доступны в Европе для абонентов, обладающих добросовестностью. CRYSTAL IPTV не делает никаких заявлений или гарантий относительно того, что Каналы или Содержание, содержащиеся в Услугах по подписке, подходят для просмотра в других регионах или на других основаниях, допущенных для просмотра в других странах.",
  pl: "1.4. Usługi abonamentowe są dostępne na terenie Europy dla Abonentów z godziwymi intencjami. CRYSTAL IPTV nie dokonuje żadnych reprezentacji ani gwarancji co do tego, że Kanały lub Zawartość zawarte w Usługach abonamentowych są odpowiednie dla jakiegokolwiek innego regionu lub na jakiejkolwiek innej podstawie dopuszczonej do oglądania w innych krajach.",
};
export const subscriptionServicesConfirmationInfo = {
  en: "1.5. In using the Subscription Services each Subscriber confirms that they have read the CRYSTAL IPTV Terms, understand them, and irrevocably and unconditionally agree to comply with them.",
  fr: "1.5. En utilisant les Services d'abonnement, chaque abonné confirme avoir lu les Conditions CRYSTAL IPTV, les comprendre et accepter de manière irrévocable et inconditionnelle de s'y conformer.",
  es: "1.5. Al utilizar los Servicios de suscripción, cada suscriptor confirma que ha leído los Términos CRYSTAL IPTV, los entiende y acepta de manera irrevocable e incondicional cumplir con ellos.",
  de: "1.5. Bei der Nutzung der Abonnementdienste bestätigt jeder Abonnent, dass er die CRYSTAL IPTV-Bedingungen gelesen hat, sie versteht und sich unwiderruflich und bedingungslos verpflichtet, sich daran zu halten.",
  it: "1.5. Utilizzando i Servizi di abbonamento, ogni abbonato conferma di aver letto i Termini CRYSTAL IPTV, di comprenderli e di accettare in modo irrevocabile e incondizionato di conformarsi ad essi.",
  tr: "1.5. Abonelik Hizmetlerini kullanırken her Abone, CRYSTAL IPTV Koşullarını okuduklarını, anladıklarını ve bunlara bağlı olarak kesinlikle ve koşulsuz olarak uymayı kabul ettiklerini onaylar.",
  ru: "1.5. При использовании Услуг по подписке каждый абонент подтверждает, что он прочитал Условия CRYSTAL IPTV, понимает их и безусловно и бесповоротно соглашается соблюдать их.",
  pl: "1.5. Każdy Abonent, korzystając z Usług abonamentowych, potwierdza, że przeczytał Warunki CRYSTAL IPTV, rozumie je i nieodwołalnie oraz bezwarunkowo zgadza się je przestrzegać.",
};
export const subscriberAgeDeclaration = {
  en: "1.6. Each Subscriber declares and warrants to CRYSTAL IPTV that they are 18 years of age or over and that they fulfill all applicable legal requirements to accept the commitment to comply with the CRYSTAL IPTV Terms.",
  fr: "1.6. Chaque abonné déclare et garantit à CRYSTAL IPTV qu'il a 18 ans ou plus et qu'il remplit toutes les exigences légales applicables pour accepter l'engagement de se conformer aux Conditions CRYSTAL IPTV.",
  es: "1.6. Cada suscriptor declara y garantiza a CRYSTAL IPTV que tiene 18 años o más y que cumple con todos los requisitos legales aplicables para aceptar el compromiso de cumplir con los Términos CRYSTAL IPTV.",
  de: "1.6. Jeder Abonnent erklärt und versichert gegenüber CRYSTAL IPTV, dass er 18 Jahre oder älter ist und alle geltenden rechtlichen Anforderungen erfüllt, um das Engagement zur Einhaltung der CRYSTAL IPTV-Bedingungen anzunehmen.",
  it: "1.6. Ciascun Abbonato dichiara e garantisce a CRYSTAL IPTV di avere 18 anni o più e di soddisfare tutti i requisiti legali applicabili per accettare l'impegno a conformarsi ai Termini CRYSTAL IPTV.",
  tr: "1.6. Her Abone, 18 yaşından büyük olduğunu ve CRYSTAL IPTV Koşullarına uygun olarak taahhütte bulunmayı kabul etmek için geçerli tüm yasal gereksinimleri yerine getirdiğini CRYSTAL IPTV'ye beyan eder ve garanti eder.",
  ru: "1.6. Каждый абонент заявляет и гарантирует перед CRYSTAL IPTV, что он достиг возраста 18 лет или более и что он выполняет все применимые юридические требования для принятия обязательства соблюдать Условия CRYSTAL IPTV.",
  pl: "1.6. Każdy Abonent oświadcza i gwarantuje przed CRYSTAL IPTV, że ma 18 lat lub więcej i spełnia wszystkie odpowiednie wymagania prawne, aby zaakceptować zobowiązanie do przestrzegania Warunków CRYSTAL IPTV.",
};
export const subscriptionServiceManagementInfo = {
  en: "1.7. CRYSTAL IPTV has employed an external entity (currently it is China Telecom Corporation Limited, a company located in the China of 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hong Kong) to service and manage Subscription Services. The payment processing services for the Subscription Services are provided by CRYSTAL IPTV PAYMENT GATEWAY, a subsidiary of CRYSTAL IPTV.",
  fr: "1.7. CRYSTAL IPTV a engagé une entité externe (actuellement il s'agit de China Telecom Corporation Limited, une société située en Chine au 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hong Kong) pour gérer et fournir des services d'abonnement. Les services de traitement des paiements pour les Services d'abonnement sont fournis par CRYSTAL IPTV PAYMENT GATEWAY, une filiale de CRYSTAL IPTV.",
  es: "1.7. CRYSTAL IPTV ha contratado a una entidad externa (actualmente es China Telecom Corporation Limited, una empresa ubicada en China en 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hong Kong) para prestar y gestionar los Servicios de suscripción. Los servicios de procesamiento de pagos para los Servicios de suscripción son proporcionados por CRYSTAL IPTV PAYMENT GATEWAY, una subsidiaria de CRYSTAL IPTV.",
  de: "1.7. CRYSTAL IPTV hat eine externe Einrichtung (derzeit ist es die China Telecom Corporation Limited, ein Unternehmen mit Sitz in China, 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hongkong) beauftragt, die Abonnementdienste zu betreuen und zu verwalten. Die Zahlungsabwicklungsdienste für die Abonnementdienste werden von CRYSTAL IPTV PAYMENT GATEWAY, einer Tochtergesellschaft von CRYSTAL IPTV, bereitgestellt.",
  it: "1.7. CRYSTAL IPTV ha impiegato un'entità esterna (attualmente è China Telecom Corporation Limited, una società con sede in Cina, 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hong Kong) per gestire e fornire i Servizi di abbonamento. I servizi di elaborazione dei pagamenti per i Servizi di abbonamento sono forniti da CRYSTAL IPTV PAYMENT GATEWAY, una controllata di CRYSTAL IPTV.",
  tr: "1.7. CRYSTAL IPTV, Abonelik Hizmetlerini hizmet vermek ve yönetmek üzere harici bir kuruluş (şu anda Çin'de bulunan 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hong Kong'da bulunan China Telecom Corporation Limited) istihdam etmiştir. Abonelik Hizmetlerinin ödeme işleme hizmetleri, CRYSTAL IPTV'nin bir yan kuruluşu olan CRYSTAL IPTV ÖDEME KAPISI tarafından sağlanmaktadır.",
  ru: "1.7. CRYSTAL IPTV привлекла внешнее предприятие (в настоящее время это China Telecom Corporation Limited, компания, расположенная в Китае по адресу 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Гонконг), для обслуживания и управления Услугами по подписке. Услуги обработки платежей для Услуг по подписке предоставляются CRYSTAL IPTV PAYMENT GATEWAY, дочерней компанией CRYSTAL IPTV.",
  pl: "1.7. CRYSTAL IPTV zatrudniła zewnętrzną jednostkę (obecnie jest nią China Telecom Corporation Limited, firma z siedzibą w Chinach, 28/F., Everbright Centre, 108 Gloucester Road, Wanchai, Hongkong) do obsługi i zarządzania Usługami abonamentowymi. Usługi przetwarzania płatności za Usługi abonamentowe są świadczone przez CRYSTAL IPTV PAYMENT GATEWAY, spółkę zależną CRYSTAL IPTV.",
};
export const thirdPartySoftwareInfo = {
  en: "1.8 The acquisition of, access to or use of Subscription Services may require using third party software which is subject to third party licenses. Subscribers acknowledge and agree to automatically receive and download periodically updated Subscription Services and related third party software.",
  fr: "1.8 L'acquisition, l'accès ou l'utilisation des Services d'abonnement peut nécessiter l'utilisation de logiciels tiers qui sont soumis à des licences tierces. Les abonnés reconnaissent et acceptent de recevoir automatiquement et de télécharger périodiquement les Services d'abonnement mis à jour et les logiciels tiers associés.",
  es: "1.8 La adquisición, el acceso o el uso de los Servicios de suscripción pueden requerir el uso de software de terceros que está sujeto a licencias de terceros. Los suscriptores reconocen y aceptan recibir automáticamente y descargar periódicamente los Servicios de suscripción actualizados y el software de terceros relacionado.",
  de: "1.8 Die Beschaffung, der Zugang oder die Nutzung der Abonnementdienste kann die Verwendung von Software von Drittanbietern erfordern, die Drittanbieterlizenzen unterliegt. Abonnenten erkennen an und stimmen zu, automatisch aktualisierte Abonnementdienste und zugehörige Software von Drittanbietern zu empfangen und herunterzuladen.",
  it: "1.8 L'acquisizione, l'accesso o l'uso dei Servizi di abbonamento potrebbe richiedere l'utilizzo di software di terze parti soggetto a licenze di terze parti. Gli abbonati riconoscono e accettano di ricevere automaticamente e scaricare periodicamente i Servizi di abbonamento aggiornati e il software di terze parti correlato.",
  tr: "1.8 Abonelik Hizmetlerinin edinilmesi, erişimi veya kullanımı, üçüncü taraf lisanslarına tabi olan üçüncü taraf yazılımın kullanılmasını gerektirebilir. Aboneler, otomatik olarak güncellenen Abonelik Hizmetleri ve ilgili üçüncü taraf yazılımı almayı ve indirmeyi kabul ederler.",
  ru: "1.8 Приобретение, доступ или использование Услуг по подписке может потребовать использования программного обеспечения третьих сторон, подпадающего под лицензии третьих сторон. Абоненты признают и соглашаются автоматически получать и загружать периодически обновляемые Услуги по подписке и связанное программное обеспечение третьих сторон.",
  pl: "1.8 Nabycie, dostęp lub korzystanie z Usług abonamentowych może wymagać użycia oprogramowania stron trzecich, które podlega licencjom stron trzecich. Abonenci uznają i zgadzają się automatycznie otrzymywać i pobierać okresowo aktualizowane Usługi abonamentowe oraz związane z nimi oprogramowanie stron trzecich.",
};
export const definitions = {
  en: "2. DEFINITIONS",
  fr: "2. DÉFINITIONS",
  es: "2. DEFINICIONES",
  de: "2. DEFINITIONEN",
  it: "2. DEFINIZIONI",
  tr: "2. TANIMLAR",
  ru: "2. ОПРЕДЕЛЕНИЯ",
  pl: "2. DEFINICJE",
};
export const affiliateDefinition = {
  en: "2.1 “Affiliate” means an affiliate of CRYSTAL IPTV including its respective legal successors, assignees, employees and agents. For the avoidance of doubt, the term includes China Telecom Corporation Limited.",
  fr: "2.1 « Filiale » désigne une filiale de CRYSTAL IPTV, y compris ses successeurs légaux respectifs, cessionnaires, employés et agents. Pour éviter toute confusion, le terme inclut China Telecom Corporation Limited.",
  es: "2.1 “Afiliado” significa un afiliado de CRYSTAL IPTV, incluyendo sus respectivos sucesores legales, cesionarios, empleados y agentes. Para evitar cualquier duda, el término incluye a China Telecom Corporation Limited.",
  de: "2.1 „Tochtergesellschaft“ bedeutet eine Tochtergesellschaft von CRYSTAL IPTV, einschließlich ihrer jeweiligen Rechtsnachfolger, Abtretungsempfänger, Mitarbeiter und Bevollmächtigte. Zur Vermeidung von Zweifeln umfasst der Begriff China Telecom Corporation Limited.",
  it: '2.1 "Affiliato" significa un affiliato di CRYSTAL IPTV, compresi i rispettivi successori legali, cessionari, dipendenti ed agenti. A titolo esemplificativo, il termine include China Telecom Corporation Limited.',
  tr: "2.1 \"İştirakçi,\" CRYSTAL IPTV'nin bir iştirakçisini, ilgili hukuki halef ve devralanları, çalışanları ve temsilcilerini içeren bir terimi ifade eder. Herhangi bir şüpheye mahal vermemek için, terim China Telecom Corporation Limited'ı içerir.",
  ru: '2.1 "Аффилированное лицо" означает аффилированное лицо CRYSTAL IPTV, включая его соответствующих правопреемников, уступателей, сотрудников и агентов. Для избежания сомнений, термин включает China Telecom Corporation Limited.',
  pl: '2.1 "Powiązane przedsiębiorstwo" oznacza przedsiębiorstwo powiązane z CRYSTAL IPTV, w tym jego odpowiednich sukcesorów prawnych, cesjonariuszy, pracowników i agentów. W celu uniknięcia wątpliwości, termin ten obejmuje China Telecom Corporation Limited.',
};
export const contentDefinition = {
  en: "2.2. “Content” means all information, texts, sound files, music, software, motionless and moving images, graphics, data and any other material of any nature including any audio-visual content such as sports TV programmes, apps, channels, related functions, functionalities, user interfaces and any on-demand content (whether or not it is free) contained within the Subscription Services.",
  fr: "2.2. Le terme « Contenu » désigne toutes les informations, textes, fichiers sonores, musique, logiciels, images fixes et animées, graphiques, données et tout autre matériau de toute nature, y compris tout contenu audiovisuel tel que des programmes de télévision sportive, des applications, des chaînes, des fonctions connexes, des fonctionnalités, des interfaces utilisateur et tout contenu à la demande (gratuit ou non) contenu dans les Services d'abonnement.",
  es: "2.2. “Contenido” significa toda la información, textos, archivos de sonido, música, software, imágenes fijas y en movimiento, gráficos, datos y cualquier otro material de cualquier naturaleza, incluido cualquier contenido audiovisual como programas de televisión deportiva, aplicaciones, canales, funciones relacionadas, funcionalidades, interfaces de usuario y cualquier contenido a pedido (gratuito o no) contenido en los Servicios de suscripción.",
  de: '2.2. "Inhalt" umfasst alle Informationen, Texte, Sounddateien, Musik, Software, unbewegte und bewegte Bilder, Grafiken, Daten und jegliches anderes Material jeglicher Art, einschließlich jeglichen audiovisuellen Inhalts wie Sportfernsehsendungen, Apps, Kanäle, zugehörige Funktionen, Funktionalitäten, Benutzeroberflächen und jeglichen On-Demand-Inhalt (unabhängig davon, ob er kostenlos ist), der in den Abonnementdiensten enthalten ist.',
  it: '2.2. "Contenuto" significa tutte le informazioni, testi, file audio, musica, software, immagini fisse e in movimento, grafica, dati e qualsiasi altro materiale di qualsiasi natura, inclusi contenuti audiovisivi come programmi TV sportivi, app, canali, funzioni correlate, funzionalità, interfacce utente e qualsiasi contenuto on-demand (a pagamento o gratuito) contenuto nei Servizi di abbonamento.',
  tr: "2.2. “İçerik”, Abonelik Hizmetleri içinde bulunan bilgileri, metinleri, ses dosyalarını, müziği, yazılımı, sabit ve hareketli görüntüleri, grafikleri, verileri ve başka her türlü malzemeyi, spor TV programları gibi sesli-görüntülü içeriği de içeren, uygulamaları, kanalları, ilgili işlevleri, işlevselliği, kullanıcı arabirimlerini ve talep üzerine sunulan içeriği (ücretsiz veya ücretsiz olmayan) içerir.",
  ru: '2.2. "Содержание" означает всю информацию, тексты, звуковые файлы, музыку, программное обеспечение, неподвижные и движущиеся изображения, графику, данные и любой другой материал любой природы, включая аудиовизуальный контент, такой как спортивные телепередачи, приложения, каналы, связанные функции, функциональность, пользовательские интерфейсы и любой контент по требованию (бесплатный или платный), содержащийся в Услугах по подписке.',
  pl: '2.2. "Zawartość" oznacza wszelkie informacje, teksty, pliki dźwiękowe, muzykę, oprogramowanie, nieruchome i ruchome obrazy, grafikę, dane i wszelki inny materiał o dowolnym charakterze, w tym treści audiowizualne takie jak programy telewizji sportowej, aplikacje, kanały, związane funkcje, funkcjonalności, interfejsy użytkownika oraz wszelką zawartość na żądanie (płatną lub bezpłatną), zawartą w Usługach abonamentowych.',
};
export const channelsDefinition = {
  en: "2.3 “Channels” means TV channels CRYSTAL IPTV offers as part of the Subscription Service currently called “CRYSTAL IPTV” and “CRYSTAL PLUS” and any other TV channels that CRYSTAL IPTV provides at any time.",
  fr: "2.3 Le terme « Chaînes » désigne les chaînes de télévision proposées par CRYSTAL IPTV dans le cadre du Service d'abonnement actuellement appelé « CRYSTAL IPTV » et « CRYSTAL PLUS » ainsi que toute autre chaîne de télévision fournie par CRYSTAL IPTV à tout moment.",
  es: "2.3 “Canales” significa los canales de televisión que CRYSTAL IPTV ofrece como parte del Servicio de suscripción actualmente llamado “CRYSTAL IPTV” y “CRYSTAL PLUS” y cualquier otro canal de televisión que CRYSTAL IPTV proporcione en cualquier momento.",
  de: "2.3 „Kanäle“ bezeichnet die von CRYSTAL IPTV als Teil des Abonnementdienstes derzeit unter den Namen „CRYSTAL IPTV“ und „CRYSTAL PLUS“ angebotenen Fernsehkanäle sowie alle anderen Fernsehkanäle, die CRYSTAL IPTV jederzeit bereitstellt.",
  it: '2.3 "Canali" significa i canali televisivi offerti da CRYSTAL IPTV come parte del Servizio di abbonamento attualmente chiamato "CRYSTAL IPTV" e "CRYSTAL PLUS" e qualsiasi altro canale televisivo fornito da CRYSTAL IPTV in qualsiasi momento.',
  tr: "2.3 “Kanallar”, CRYSTAL IPTV tarafından şu anda “CRYSTAL IPTV” ve “CRYSTAL PLUS” adıyla sunulan Abonelik Hizmeti'nin bir parçası olarak sunulan TV kanallarını ve CRYSTAL IPTV'nin herhangi bir zamanda sağladığı diğer TV kanallarını ifade eder.",
  ru: '2.3 "Каналы" означает телеканалы, предлагаемые CRYSTAL IPTV как часть Услуги по подписке, в настоящее время называемые "CRYSTAL IPTV" и "CRYSTAL PLUS", а также любые другие телеканалы, предоставляемые CRYSTAL IPTV в любое время.',
  pl: '2.3 "Kanały" oznacza kanały telewizyjne oferowane przez CRYSTAL IPTV w ramach Usługi abonamentowej obecnie nazywanej "CRYSTAL IPTV" i "CRYSTAL PLUS" oraz wszelkie inne kanały telewizyjne dostarczane przez CRYSTAL IPTV w dowolnym czasie.',
};
export const personalDataDefinition = {
  en: "2.4 “Personal data” means data, whether true or not, concerning persons whose identities can be determined on the basis of:\n\n(a) such data; or\n\n(b) data and information to which the party has access or is likely to have access.",
  fr: "2.4 Le terme « Données personnelles » désigne des données, vraies ou non, concernant des personnes dont l'identité peut être déterminée sur la base :\n\n(a) de telles données ; ou\n\n(b) de données et d'informations auxquelles la partie a accès ou est susceptible d'avoir accès.",
  es: "2.4 “Datos personales” significa datos, verdaderos o no, relacionados con personas cuya identidad puede determinarse en función de:\n\n(a) dichos datos; o\n\n(b) datos e información a los que la parte tiene acceso o es probable que tenga acceso.",
  de: "2.4 „Personenbezogene Daten“ bedeutet Daten, ob wahr oder nicht, die sich auf Personen beziehen, deren Identität auf der Grundlage von:\n\n(a) solchen Daten; oder\n\n(b) Daten und Informationen, auf die die Partei Zugriff hat oder voraussichtlich Zugriff haben wird, bestimmt werden kann.",
  it: "2.4 “Dati personali” significa dati, veri o non veri, relativi a persone il cui riconoscimento può essere determinato sulla base di:\n\n(a) tali dati; oppure\n\n(b) dati e informazioni a cui la parte ha accesso o è probabile che abbia accesso.",
  tr: "2.4 “Kişisel veriler”, gerçek olsa da olmasa da, kişilerin kimliklerinin aşağıdaki temelde belirlenebileceği verileri ifade eder:\n\n(a) bu tür veriler; veya\n\n(b) tarafın erişimi veya muhtemel erişimi olan veriler ve bilgiler.",
  ru: '2.4 "Персональные данные" означают данные, будь то верные или нет, касающиеся лиц, чья идентичность может быть установлена на основе:\n\n(a) таких данных; или\n\n(b) данных и информации, к которым сторона имеет доступ или, вероятно, имеет доступ.',
  pl: '2.4 "Dane osobowe" oznaczają dane, prawdziwe lub nieprawdziwe, dotyczące osób, których tożsamość można ustalić na podstawie:\n\n(a) takich danych; lub\n\n(b) danych i informacji, do których strona ma dostęp lub prawdopodobnie będzie miała dostęp.',
};
export const subscriberDefinition = {
  en: "2.5. “Subscriber” means a person who has registered a personal account of Subscription Services with CRYSTAL IPTV.",
  fr: "2.5. Le terme « Abonné » désigne une personne qui a enregistré un compte personnel des Services d'abonnement auprès de CRYSTAL IPTV.",
  es: "2.5. “Suscriptor” significa una persona que ha registrado una cuenta personal de Servicios de suscripción con CRYSTAL IPTV.",
  de: "2.5. „Abonnent“ bezeichnet eine Person, die ein persönliches Konto für Abonnementdienste bei CRYSTAL IPTV registriert hat.",
  it: "2.5. “Abbonato” significa una persona che ha registrato un account personale dei Servizi di abbonamento con CRYSTAL IPTV.",
  tr: "2.5. “Abone”, CRYSTAL IPTV ile Abonelik Hizmetleri kişisel hesabını kaydetmiş bir kişiyi ifade eder.",
  ru: '2.5. "Абонент" означает лицо, зарегистрировавшее личный аккаунт Услуг по подписке в CRYSTAL IPTV.',
  pl: '2.5. "Abonent" oznacza osobę, która zarejestrowała osobiste konto Usług abonamentowych w CRYSTAL IPTV.',
};
export const subscriptionPassDefinition = {
  en: "2.6 “Subscription Pass” means a limited, non-exclusive, non-transferable license allowing access to and use of Subscription Services in accordance with the CRYSTAL IPTV Terms and only during the validity period of the given Subscription Pass that is acquired by the relevant Subscriber. No transfer of ownership, either express or implied, is intended by such license.",
  fr: "2.6 Le terme « Pass d'abonnement » désigne une licence limitée, non exclusive et non transférable permettant l'accès et l'utilisation des Services d'abonnement conformément aux Conditions de CRYSTAL IPTV et uniquement pendant la période de validité du Pass d'abonnement donné acquis par l'Abonné concerné. Aucun transfert de propriété, qu'il soit explicite ou implicite, n'est prévu par une telle licence.",
  es: "2.6 “Pase de suscripción” significa una licencia limitada, no exclusiva y no transferible que permite el acceso y uso de los Servicios de suscripción de acuerdo con los términos de CRYSTAL IPTV y solo durante el período de validez del Pase de suscripción dado adquirido por el Suscriptor correspondiente. No se pretende ningún traspaso de propiedad, ya sea expreso o implícito, mediante dicha licencia.",
  de: "2.6 „Abonnement-Pass“ bezeichnet eine eingeschränkte, nicht exklusive, nicht übertragbare Lizenz, die den Zugang zu und die Nutzung der Abonnementdienste gemäß den Bedingungen von CRYSTAL IPTV während der Gültigkeitsdauer des jeweiligen erworbenen Abonnement-Passes durch den relevanten Abonnenten ermöglicht. Eine Übertragung des Eigentums, sei es ausdrücklich oder stillschweigend, ist durch eine solche Lizenz nicht beabsichtigt.",
  it: "2.6 “Pass di abbonamento” significa una licenza limitata, non esclusiva e non trasferibile che consente l'accesso e l'uso dei Servizi di abbonamento in conformità con i Termini di CRYSTAL IPTV e solo durante il periodo di validità del Pass di abbonamento fornito che è acquisito dal relativo Abbonato. Nessun trasferimento di proprietà, esplicito o implicito, è previsto da tale licenza.",
  tr: "2.6 “Abonelik Geçişi”, CRYSTAL IPTV Şartlarına uygun olarak ve ilgili Abone tarafından elde edilen belirli bir Abonelik Geçişi'nin geçerlilik süresi boyunca sadece Abonelik Hizmetlerine erişim ve kullanım izni veren sınırlı, münhasır olmayan, devredilemez bir lisansı ifade eder. Bu tür bir lisansla herhangi bir mülkiyet devri, açık veya zımni olarak amaçlanmamaktadır.",
  ru: '2.6 "Подписной пропуск" означает ограниченную, неисключительную, непередаваемую лицензию, позволяющую доступ и использование Услуг по подписке в соответствии с Условиями CRYSTAL IPTV только на протяжении срока действия соответствующего Подписного пропуска, приобретенного соответствующим Абонентом. Ни явного, ни подразумеваемого передача собственности не предполагается такой лицензией.',
  pl: '2.6 "Przepustka abonamentowa" oznacza ograniczoną, nieekskluzywną, nieprzenoszalną licencję umożliwiającą dostęp i korzystanie z Usług abonamentowych zgodnie z Warunkami CRYSTAL IPTV tylko przez okres ważności danej Przepustki abonamentowej nabytej przez odpowiedniego Abonenta. Żadne przeniesienie własności, zarówno wyraźne, jak i dorozumiane, nie jest zamierzone w ramach takiej licencji.',
};
export const inWritingDefinition = {
  en: "2.7 “In writing” means any form of non-verbal communication, including e-mail, online forms, fax, SMS or similar forms of communication, including messages created by means of signs, permanently stored and available to the addressee in an unchanged form to the one presented by the sender.",
  fr: "2.7 Le terme « Par écrit » désigne toute forme de communication non verbale, y compris les e-mails, les formulaires en ligne, les fax, les SMS ou des formes similaires de communication, y compris les messages créés au moyen de signes, stockés de manière permanente et disponibles pour le destinataire dans une forme inchangée par rapport à celle présentée par l'expéditeur.",
  es: "2.7 “Por escrito” significa cualquier forma de comunicación no verbal, incluyendo correo electrónico, formularios en línea, fax, SMS u otras formas similares de comunicación, incluyendo mensajes creados mediante signos, almacenados permanentemente y disponibles para el destinatario en una forma inalterada a la presentada por el remitente.",
  de: "2.7 „Schriftlich“ bedeutet jede Form der nonverbalen Kommunikation, einschließlich E-Mail, Online-Formularen, Fax, SMS oder ähnlichen Formen der Kommunikation, einschließlich Nachrichten, die durch Zeichen erstellt wurden, dauerhaft gespeichert und dem Empfänger in unveränderter Form in der vom Absender präsentierten Form zur Verfügung stehen.",
  it: '2.7 "Per iscritto" significa qualsiasi forma di comunicazione non verbale, compresi e-mail, moduli online, fax, SMS o forme simili di comunicazione, compresi messaggi creati mediante segni, memorizzati permanentemente e disponibili per il destinatario in una forma inalterata rispetto a quella presentata dal mittente.',
  tr: "2.7 “Yazılı olarak” ifadesi, e-posta, çevrimiçi formlar, faks, SMS veya benzer iletişim şekilleri de dahil olmak üzere sözlü olmayan iletişimin her türlüsünü ifade eder; bu iletişim, işaretler kullanılarak oluşturulan mesajlar dahil, değiştirilmeden alıcıya sunulan bir formda kalıcı olarak saklanır ve alıcının erişimine sunulur.",
  ru: '2.7 "Письменно" означает любую форму невербальной коммуникации, включая электронную почту, онлайн-формы, факс, SMS или аналогичные формы коммуникации, включая сообщения, созданные с помощью знаков, постоянно сохраненные и доступные адресату в неизменной форме, представленной отправителем.',
  pl: '2.7 "Na piśmie" oznacza dowolną formę komunikacji niewerbalnej, w tym pocztę elektroniczną, formularze online, faks, SMS lub podobne formy komunikacji, w tym wiadomości tworzone za pomocą znaków, trwale przechowywane i dostępne dla adresata w niezmienionej formie w sposób przedstawiony przez nadawcę.',
};
export const subscriptionBasisDefinition = {
  en: "3. SUBSCRIPTION BASIS",
  fr: "3. BASE D’ABONNEMENT",
  es: "3. BASE DE SUSCRIPCIÓN",
  de: "3. ABONNEMENTGRUNDLAGE",
  it: "3. BASE DI ABBONAMENTO",
  tr: "3. ABONELİK ESASLARI",
  ru: "3. ОСНОВЫ ПОДПИСКИ",
  pl: "3. PODSTAWA SUBSKRYPCJI",
};
export const twentyFourHourFreeTrial = {
  en: "3.1. 24-hours day free trial",
  fr: "3.1. Essai gratuit de 24 heures",
  es: "3.1. Prueba gratuita de 24 horas",
  de: "3.1. 24-Stunden-Gratis-Testversion",
  it: "3.1. Prova gratuita di 24 ore",
  tr: "3.1. 24 saat ücretsiz deneme",
  ru: "3.1. Бесплатное пробное использование в течение 24 часов",
  pl: "3.1. Darmowy okres próbny trwający 24 godziny",
};
export const trialValidityNotice = {
  en: "(a) This offer is valid from the day of credentials delivery until after 24 hours and the trial account would be withdrawn after this period and it won’t work anymore.",
  fr: "(a) Cette offre est valable à partir du jour de la livraison des identifiants jusqu'à après 24 heures et le compte d'essai sera retiré après cette période et il ne fonctionnera plus.",
  es: "(a) Esta oferta es válida desde el día de la entrega de las credenciales hasta después de 24 horas y la cuenta de prueba será retirada después de este período y ya no funcionará.",
  de: "(a) Dieses Angebot ist ab dem Tag der Bereitstellung der Zugangsdaten bis nach 24 Stunden gültig und das Testkonto wird nach diesem Zeitraum zurückgezogen und funktioniert nicht mehr.",
  it: "(a) Questa offerta è valida dal giorno della consegna delle credenziali fino a dopo 24 ore e l'account di prova verrà ritirato dopo questo periodo e non funzionerà più.",
  tr: "(a) Bu teklif, kimlik bilgilerinin teslim edildiği günden itibaren 24 saat sonra geçerli olup, deneme hesabı bu süre sonrasında geri çekilecek ve artık çalışmayacaktır.",
  ru: "(а) Данное предложение действительно с дня доставки учетных данных и до 24 часов после этого, а пробный аккаунт будет отозван после этого периода и больше не будет работать.",
  pl: "(a) Oferta ta jest ważna od dnia dostarczenia danych logowania do 24 godzin po tym dniu, a konto próbne zostanie wycofane po tym okresie i przestanie działać.",
};
export const trialEntitlementNotice = {
  en: "(b) Subscribers are entitled to a single free trial to evaluate our service. Afterward, they will no longer be eligible for additional free trials and must access our service by purchasing a premium subscription.",
  fr: "(b) Les abonnés ont droit à un seul essai gratuit pour évaluer notre service. Ensuite, ils ne seront plus éligibles pour des essais gratuits supplémentaires et devront accéder à notre service en achetant un abonnement premium.",
  es: "(b) Los suscriptores tienen derecho a una sola prueba gratuita para evaluar nuestro servicio. Después, ya no serán elegibles para pruebas gratuitas adicionales y deberán acceder a nuestro servicio comprando una suscripción premium.",
  de: "(b) Abonnenten haben Anspruch auf eine einzige kostenlose Testversion, um unseren Service zu bewerten. Danach sind sie nicht mehr berechtigt, weitere kostenlose Testversionen zu erhalten, und müssen auf unseren Service zugreifen, indem sie ein Premium-Abonnement kaufen.",
  it: "(b) Gli abbonati hanno diritto a un'unica prova gratuita per valutare il nostro servizio. Successivamente, non saranno più idonei per ulteriori prove gratuite e dovranno accedere al nostro servizio acquistando un abbonamento premium.",
  tr: "(b) Aboneler, hizmetimizi değerlendirmek için tek bir ücretsiz deneme hakkına sahiptir. Bundan sonra ek ücretsiz denemeler için daha fazla hakları olmayacak ve premium bir abonelik satın alarak hizmetimize erişmelidirler.",
  ru: "(б) Абоненты имеют право на одну бесплатную пробную версию, чтобы оценить наш сервис. После этого им больше не будет предоставлено дополнительных бесплатных пробных версий, и для доступа к нашему сервису им придется приобрести премиум-подписку.",
  pl: "(b) Abonenci mają prawo do jednej darmowej wersji próbnej, aby ocenić naszą usługę. Po tym czasie nie będą już uprawnieni do dodatkowych darmowych wersji próbnych i będą musieli uzyskać dostęp do naszej usługi, kupując subskrypcję premium.",
};
export const trialRequestNotice = {
  en: "(c) To request a free 24-hour trial version account, users must contact us either by sending an email or reaching out to us via WhatsApp or Telegram.",
  fr: "(c) Pour demander un compte d'essai gratuit de 24 heures, les utilisateurs doivent nous contacter en envoyant un e-mail ou en nous contactant via WhatsApp ou Telegram.",
  es: "(c) Para solicitar una cuenta de prueba gratuita de 24 horas, los usuarios deben ponerse en contacto con nosotros enviando un correo electrónico o comunicándose con nosotros a través de WhatsApp o Telegram.",
  de: "(c) Um ein kostenloses 24-Stunden-Testkonto anzufordern, müssen sich die Benutzer entweder per E-Mail an uns wenden oder uns über WhatsApp oder Telegram kontaktieren.",
  it: "(c) Per richiedere un account di prova gratuito di 24 ore, gli utenti devono contattarci inviando un'e-mail o raggiungendoci tramite WhatsApp o Telegram.",
  tr: "(c) Ücretsiz 24 saatlik deneme sürümü hesabı talep etmek için kullanıcılar bize e-posta göndererek veya WhatsApp veya Telegram üzerinden iletişime geçerek bizimle iletişime geçmelidirler.",
  ru: "(c) Для запроса бесплатной учетной записи пробной версии на 24 часа пользователи должны связаться с нами либо отправив нам электронное письмо, либо связавшись с нами через WhatsApp или Telegram.",
  pl: "(c) Aby poprosić o bezpłatne konto wersji próbnej na 24 godziny, użytkownicy muszą skontaktować się z nami, wysyłając e-mail lub kontaktując się z nami za pośrednictwem WhatsApp lub Telegram.",
};
export const subscriptionServicesOffer = {
  en: "3.2. Subscription Services are offered to Subscribers who may purchase them in accordance with, and on the basis of the Subscription Pass and the CRYSTAL IPTV Terms.",
  fr: "3.2. Les services d'abonnement sont proposés aux abonnés qui peuvent les acheter conformément et sur la base du Pass d'abonnement et des Conditions de CRYSTAL IPTV.",
  es: "3.2. Los Servicios de suscripción se ofrecen a los suscriptores que pueden adquirirlos de acuerdo con, y sobre la base del Pase de suscripción y las Condiciones de CRYSTAL IPTV.",
  de: "3.2. Die Abonnementdienste werden den Abonnenten angeboten, die sie gemäß dem Abonnement-Pass und den Bedingungen von CRYSTAL IPTV erwerben können.",
  it: "3.2. I Servizi di abbonamento sono offerti agli abbonati che possono acquistarli in conformità con, e sulla base del Pass di abbonamento e delle Condizioni di CRYSTAL IPTV.",
  tr: "3.2. Abonelik Hizmetleri, Abonelik Geçişi ve CRYSTAL IPTV Şartları temelinde satın alabilecek Abonelere sunulmaktadır.",
  ru: "3.2. Услуги по подписке предлагаются Абонентам, которые могут приобрести их в соответствии с Подписным пропуском и Условиями CRYSTAL IPTV.",
  pl: "3.2. Usługi abonamentowe są oferowane Abonentom, którzy mogą je zakupić zgodnie z Podpisem abonamentowym i Warunkami CRYSTAL IPTV.",
};
export const subscriptionServiceChanges = {
  en: "3.3. CRYSTAL IPTV reserves the unconditional right to change or modify the Subscription Services (or parts thereof) at its sole discretion. Such changes may include changes to pricing models, payment options and Subscription Passes offered.",
  fr: "3.3. CRYSTAL IPTV se réserve le droit inconditionnel de modifier ou de modifier les Services d'abonnement (ou une partie de ceux-ci) à sa seule discrétion. Ces modifications peuvent inclure des modifications des modèles de tarification, des options de paiement et des Passes d'abonnement proposés.",
  es: "3.3. CRYSTAL IPTV se reserva el derecho incondicional de cambiar o modificar los Servicios de suscripción (o partes de ellos) a su sola discreción. Tales cambios pueden incluir cambios en los modelos de precios, opciones de pago y Pases de suscripción ofrecidos.",
  de: "3.3. CRYSTAL IPTV behält sich das uneingeschränkte Recht vor, die Abonnementdienste (oder Teile davon) nach eigenem Ermessen zu ändern oder anzupassen. Solche Änderungen können Änderungen an Preismodellen, Zahlungsoptionen und angebotenen Abonnement-Pässen umfassen.",
  it: "3.3. CRYSTAL IPTV si riserva il diritto incondizionato di modificare o modificare i Servizi di abbonamento (o parti di essi) a sua esclusiva discrezione. Tali modifiche possono includere modifiche ai modelli di prezzo, alle opzioni di pagamento e ai Pass di abbonamento offerti.",
  tr: "3.3. CRYSTAL IPTV, Abonelik Hizmetleri'ni (veya bu hizmetlerin bir kısmını) tek taraflı olarak değiştirme veya düzenleme hakkını saklı tutar. Bu tür değişiklikler, fiyatlandırma modelleri, ödeme seçenekleri ve sunulan Abonelik Geçişleri konusunda değişiklikler içerebilir.",
  ru: "3.3. CRYSTAL IPTV оставляет за собой безусловное право изменять или модифицировать Услуги по подписке (или их части) по своему исключительному усмотрению. Такие изменения могут включать в себя изменения моделей ценообразования, вариантов оплаты и предлагаемых Подписных пропусков.",
  pl: "3.3. CRYSTAL IPTV zastrzega sobie nieograniczone prawo do zmiany lub modyfikacji Usług abonamentowych (lub ich części) według własnego uznania. Takie zmiany mogą obejmować zmiany w modelach cenowych, opcjach płatności i oferowanych Przepustkach abonamentowych.",
};
export const subscriptionPriceChanges = {
  en: "3.4. Any changes to a Subscription Pass price will be notified to relevant Subscribers. The relevant price change won’t come into force until after the end of the Subscription period.",
  fr: "3.4. Toute modification du prix d'un Pass d'abonnement sera notifiée aux abonnés concernés. La modification de prix pertinente ne prendra effet qu'après la fin de la période d'abonnement.",
  es: "3.4. Cualquier cambio en el precio de un Pase de suscripción se notificará a los suscriptores relevantes. El cambio de precio relevante no entrará en vigor hasta después del final del período de suscripción.",
  de: "3.4. Änderungen des Preises eines Abonnement-Passes werden den betroffenen Abonnenten mitgeteilt. Die entsprechende Preisanpassung tritt erst nach Ablauf des Abonnementzeitraums in Kraft.",
  it: "3.4. Eventuali modifiche al prezzo di un Pass di abbonamento saranno notificate ai relativi abbonati. La modifica del prezzo pertinente non entrerà in vigore fino alla fine del periodo di abbonamento.",
  tr: "3.4. Bir Abonelik Geçişinin fiyatına yapılan herhangi bir değişiklik ilgili Abonelere bildirilir. İlgili fiyat değişikliği, Abonelik döneminin sonundan sonra yürürlüğe girmeyecektir.",
  ru: "3.4. Любые изменения цены Подписного пропуска будут уведомлены соответствующим Абонентам. Соответствующее изменение цены не вступит в силу до завершения периода подписки.",
  pl: "3.4. Wszelkie zmiany cen Przepustek abonamentowych zostaną zgłoszone odpowiednim Abonentom. Odpowiednia zmiana cenowa nie wchodzi w życie przed zakończeniem okresu subskrypcji.",
};
export const subscriptionAccessFactors = {
  en: "3.5. Access to the Subscription Services may be affected by a number of factors, including in particular rules and regulations, legal rulings, including a judgment or court order, technical problems or delays in the network. Subject to the remaining provisions of these CRYSTAL IPTV Terms, if Subscriber’s access to the Subscription Services is significantly disrupted or suspended, or the Subscription Service is or will be temporarily unavailable (other than due to any act or omission of the Subscriber) CRYSTAL IPTV may at its sole discretion, offer for example, a proportional refund of the Subscription Pass price, free extension of the Subscription Pass, discount etc.",
  fr: "3.5. L'accès aux services d'abonnement peut être affecté par un certain nombre de facteurs, notamment les règles et réglementations, les décisions judiciaires, y compris une décision de justice ou une ordonnance du tribunal, les problèmes techniques ou les retards dans le réseau. Sous réserve des dispositions restantes des présentes Conditions de CRYSTAL IPTV, si l'accès de l'abonné aux services d'abonnement est significativement perturbé ou suspendu, ou si le service d'abonnement est temporairement indisponible ou le sera (à l'exception de tout acte ou omission de l'abonné), CRYSTAL IPTV peut, à sa seule discrétion, offrir par exemple un remboursement proportionnel du prix du Pass d'abonnement, une extension gratuite du Pass d'abonnement, une réduction, etc.",
  es: "3.5. El acceso a los Servicios de suscripción puede verse afectado por varios factores, incluyendo en particular reglas y regulaciones, decisiones legales, incluyendo un fallo o una orden judicial, problemas técnicos o retrasos en la red. Sujeto a las disposiciones restantes de estos Términos de CRYSTAL IPTV, si el acceso del Suscriptor a los Servicios de suscripción se ve significativamente interrumpido o suspendido, o el Servicio de suscripción está o estará temporalmente no disponible (a excepción de cualquier acto u omisión del Suscriptor), CRYSTAL IPTV puede, a su sola discreción, ofrecer por ejemplo, un reembolso proporcional del precio del Pase de suscripción, una extensión gratuita del Pase de suscripción, un descuento, etc.",
  de: "3.5. Der Zugang zu den Abonnementdiensten kann von einer Reihe von Faktoren beeinflusst werden, einschließlich insbesondere Regeln und Vorschriften, rechtlichen Entscheidungen, einschließlich eines Urteils oder Gerichtsbeschlusses, technischen Problemen oder Verzögerungen im Netzwerk. Vorbehaltlich der übrigen Bestimmungen dieser CRYSTAL IPTV-Bedingungen kann CRYSTAL IPTV nach eigenem Ermessen beispielsweise bei erheblichen Störungen oder Aussetzungen des Zugangs des Abonnenten zu den Abonnementdiensten oder wenn der Abonnementdienst vorübergehend nicht verfügbar ist oder sein wird (außer aufgrund eines Handelns oder Unterlassens des Abonnenten) beispielsweise eine anteilige Rückerstattung des Preises für den Abonnement-Pass, eine kostenlose Verlängerung des Abonnement-Passes, einen Rabatt usw. anbieten.",
  it: "3.5. L'accesso ai Servizi di abbonamento può essere influenzato da una serie di fattori, tra cui in particolare regole e regolamenti, decisioni legali, compresa una sentenza o un ordine del tribunale, problemi tecnici o ritardi nella rete. Fermo restando le disposizioni rimanenti di questi Termini di CRYSTAL IPTV, se l'accesso del Sottoscrittore ai Servizi di abbonamento è significativamente interrotto o sospeso, o il Servizio di abbonamento è o sarà temporaneamente non disponibile (ad eccezione di qualsiasi atto od omissione del Sottoscrittore), CRYSTAL IPTV può, a sua esclusiva discrezione, offrire ad esempio un rimborso proporzionale del prezzo del Pass di abbonamento, un'estensione gratuita del Pass di abbonamento, uno sconto, ecc.",
  tr: "3.5. Abonelik Hizmetlerine erişim, özellikle kurallar ve düzenlemeler, hukuki kararlar, bir mahkeme kararı veya mahkeme kararı da dahil olmak üzere bir dizi faktörden etkilenebilir. CRYSTAL IPTV Şartlarının kalan hükümlerine tabi olarak, Abonenin Abonelik Hizmetlerine erişimi önemli ölçüde aksatılırsa veya askıya alınırsa veya Abonelik Hizmeti geçici olarak kullanılamaz veya kullanılamayacaksa (Abonenin herhangi bir eylemi veya ihlali dışında), CRYSTAL IPTV kendi takdirine bağlı olarak örneğin Abonelik Geçiş fiyatının orantılı iadesini, Abonelik Geçişinin ücretsiz uzatılmasını, indirim vb. teklif edebilir.",
  ru: "3.5. Доступ к Услугам по подписке может быть затронут рядом факторов, включая в частности правила и регламенты, правовые решения, включая решение суда или судебный приказ, технические проблемы или задержки в сети. С учетом оставшихся положений настоящих Условий CRYSTAL IPTV, если доступ Абонента к Услугам по подписке значительно нарушается или приостанавливается, или Услуга по подписке временно недоступна или будет временно недоступна (кроме случаев, когда это обусловлено действиями или бездействием Абонента), CRYSTAL IPTV может по собственному усмотрению предложить, например, пропорциональный возврат стоимости Подписного пропуска, бесплатное продление Подписного пропуска, скидку и т. д.",
  pl: "3.5. Dostęp do Usług abonamentowych może być wpływany przez wiele czynników, w tym przede wszystkim przepisy i regulacje, decyzje sądowe, w tym wyroki lub postanowienia sądowe, problemy techniczne lub opóźnienia w sieci. Z zastrzeżeniem pozostałych postanowień niniejszych Warunków CRYSTAL IPTV, jeśli dostęp Abonenta do Usług abonamentowych zostanie znacznie zakłócony lub zawieszony, lub Usługa abonamentowa jest lub będzie tymczasowo niedostępna (z wyjątkiem jakiegokolwiek działania lub zaniechania Abonenta), CRYSTAL IPTV może, według własnego uznania, zaoferować na przykład proporcjonalny zwrot ceny Przepustki abonamentowej, bezpłatne przedłużenie Przepustki abonamentowej, rabat itp.",
};
export const registrationAndPersonalData = {
  en: "3.6. Registration for the Subscription Services is required in order to purchase a Subscription Pass. Each Subscriber is required to provide reliable and complete personal data including, without limitation, name, address, telephone number, e-mail address, credit card / debit card / PayPal data, invoice address and other information which CRYSTAL IPTV may reasonably require. Each Subscriber must immediately notify CRYSTAL IPTV in writing of any change of any personal data.",
  fr: "3.6. L'inscription aux Services d'abonnement est nécessaire pour acheter un Passe d'abonnement. Chaque abonné est tenu de fournir des données personnelles fiables et complètes, notamment, sans limitation, nom, adresse, numéro de téléphone, adresse e-mail, données de carte de crédit / carte de débit / PayPal, adresse de facturation et autres informations dont CRYSTAL IPTV peut raisonnablement avoir besoin. Chaque abonné doit immédiatement informer CRYSTAL IPTV par écrit de tout changement de données personnelles.",
  es: "3.6. Se requiere el registro en los Servicios de suscripción para comprar un Pase de suscripción. Se requiere que cada suscriptor proporcione datos personales fiables y completos, incluyendo, sin limitación, nombre, dirección, número de teléfono, dirección de correo electrónico, datos de tarjeta de crédito / tarjeta de débito / PayPal, dirección de facturación y otra información que CRYSTAL IPTV pueda requerir razonablemente. Cada suscriptor debe notificar inmediatamente a CRYSTAL IPTV por escrito cualquier cambio en los datos personales.",
  de: "3.6. Zur Anmeldung für die Abonnementdienste ist der Kauf eines Abonnement-Passes erforderlich. Jeder Abonnent ist verpflichtet, zuverlässige und vollständige persönliche Daten zur Verfügung zu stellen, einschließlich, aber nicht beschränkt auf Name, Adresse, Telefonnummer, E-Mail-Adresse, Kreditkarten- / Debitkarten- / PayPal-Daten, Rechnungsadresse und andere Informationen, die CRYSTAL IPTV vernünftigerweise benötigen kann. Jeder Abonnent muss CRYSTAL IPTV unverzüglich schriftlich über Änderungen seiner persönlichen Daten informieren.",
  it: "3.6. La registrazione per i Servizi di abbonamento è necessaria per acquistare un Pass di abbonamento. Ciascun Abbonato è tenuto a fornire dati personali affidabili e completi, tra cui, a titolo esemplificativo, nome, indirizzo, numero di telefono, indirizzo e-mail, dati della carta di credito / carta di debito / PayPal, indirizzo di fatturazione e altre informazioni che CRYSTAL IPTV potrebbe ragionevolmente richiedere. Ciascun Abbonato deve notificare immediatamente per iscritto a CRYSTAL IPTV qualsiasi modifica dei propri dati personali.",
  tr: "3.6. Abonelik Geçişi satın almak için Abonelik Hizmetlerine kayıt gereklidir. Her Abone, ad, adres, telefon numarası, e-posta adresi, kredi kartı / banka kartı / PayPal bilgileri, fatura adresi ve CRYSTAL IPTV tarafından makul olarak talep edilebilecek diğer bilgiler de dahil olmak üzere güvenilir ve eksiksiz kişisel verileri sağlamakla yükümlüdür. Her Abone, kişisel verilerinde herhangi bir değişikliği derhal yazılı olarak CRYSTAL IPTV'ye bildirmelidir.",
  ru: "3.6. Для покупки Подписного пропуска требуется регистрация для Услуг по подписке. Каждый Абонент обязан предоставить надежные и полные персональные данные, включая, без ограничения, имя, адрес, номер телефона, адрес электронной почты, данные кредитной карты / дебетовой карты / PayPal, адрес для выставления счетов и другую информацию, которую CRYSTAL IPTV может разумно потребовать. Каждый Абонент должен немедленно уведомить CRYSTAL IPTV в письменной форме о любом изменении его персональных данных.",
  pl: "3.6. Aby zakupić Przepustkę abonamentową, konieczna jest rejestracja w Usługach abonamentowych. Każdy Abonent jest zobowiązany do podania wiarygodnych i kompletnych danych osobowych, w tym, między innymi, imienia, adresu, numeru telefonu, adresu e-mail, danych karty kredytowej / karty debetowej / PayPal, adresu do faktury i innych informacji, które CRYSTAL IPTV może uzasadnienie wymagać. Każdy Abonent musi niezwłocznie poinformować CRYSTAL IPTV na piśmie o jakiejkolwiek zmianie danych osobowych.",
};
export const subscriptionApplicationRefusal = {
  en: "3.7. CRYSTAL IPTV reserves the unconditional right to refuse to accept or otherwise reject an application for a Subscription Service account or the purchase of a Subscription Pass without giving a reason.",
  fr: "3.7. CRYSTAL IPTV se réserve le droit absolu de refuser d'accepter ou de rejeter autrement une demande de compte de service d'abonnement ou l'achat d'un Passe d'abonnement sans donner de raison.",
  es: "3.7. CRYSTAL IPTV se reserva el derecho incondicional de rechazar la aceptación o de otro modo rechazar una solicitud de cuenta de Servicio de suscripción o la compra de un Pase de suscripción sin dar una razón.",
  de: "3.7. CRYSTAL IPTV behält sich das uneingeschränkte Recht vor, eine Anmeldung für ein Abonnementdienst-Konto oder den Kauf eines Abonnement-Passes ohne Angabe von Gründen abzulehnen oder anderweitig abzulehnen.",
  it: "3.7. CRYSTAL IPTV si riserva il diritto incondizionato di rifiutare di accettare o altrimenti respingere una richiesta di un account di Servizio di abbonamento o l'acquisto di un Pass di abbonamento senza fornire una motivazione.",
  tr: "3.7. CRYSTAL IPTV, neden belirtmeksizin bir Abonelik Hizmeti hesabının veya bir Abonelik Geçişi'nin başvurusunu kabul etmeme veya başvuruyu başka bir şekilde reddetme hakkını saklı tutar.",
  ru: "3.7. CRYSTAL IPTV оставляет за собой безоговорочное право отказать в приеме или иным образом отклонить заявку на учетную запись Услуги по подписке или покупку Подписного пропуска без указания причины.",
  pl: "3.7. CRYSTAL IPTV zastrzega sobie bezwarunkowe prawo do odmowy przyjęcia lub inaczej odrzucenia wniosku o konto Usługi abonamentowej lub zakup Przepustki abonamentowej bez podawania przyczyny.",
};
export const subscriptionAccountAccess = {
  en: "3.8. Each Subscription Service account is an account assigned to only one Subscriber and no Subscriber may allow a third party or authorize a third party to access or use their Subscription Service account. Simultaneous logging into the account of Subscription Services from more than one device is not allowed. Each Subscriber may have access to the Subscription Services (or any part thereof) and use them on a maximum of four (4) registered devices, however, they may change or replace any registered device with another device by logging into their Subscription Services account.",
  fr: "3.8. Chaque compte de service d'abonnement est un compte attribué à un seul abonné et aucun abonné ne peut permettre à un tiers ou autoriser un tiers à accéder ou à utiliser son compte de service d'abonnement. La connexion simultanée au compte des Services d'abonnement à partir de plus d'un appareil n'est pas autorisée. Chaque abonné peut avoir accès aux Services d'abonnement (ou à une partie de ceux-ci) et les utiliser sur un maximum de quatre (4) appareils enregistrés, cependant, ils peuvent changer ou remplacer tout appareil enregistré par un autre appareil en se connectant à leur compte de Services d'abonnement.",
  es: "3.8. Cada cuenta de Servicio de suscripción es una cuenta asignada a un solo suscriptor y ningún suscriptor puede permitir que un tercero o autorizar a un tercero a acceder o utilizar su cuenta de Servicio de suscripción. No se permite la conexión simultánea a la cuenta de Servicios de suscripción desde más de un dispositivo. Cada suscriptor puede tener acceso a los Servicios de suscripción (o a cualquier parte de ellos) y utilizarlos en un máximo de cuatro (4) dispositivos registrados, sin embargo, pueden cambiar o reemplazar cualquier dispositivo registrado por otro dispositivo iniciando sesión en su cuenta de Servicios de suscripción.",
  de: "3.8. Jedes Abonnementdienst-Konto ist ein Konto, das nur einem Abonnenten zugewiesen ist, und kein Abonnent darf einem Dritten erlauben oder einem Dritten gestatten, auf sein Abonnementdienst-Konto zuzugreifen oder es zu verwenden. Die gleichzeitige Anmeldung am Konto der Abonnementdienste von mehr als einem Gerät ist nicht erlaubt. Jeder Abonnent kann auf die Abonnementdienste (oder einen Teil davon) zugreifen und sie auf maximal vier (4) registrierten Geräten verwenden, jedoch können sie jedes registrierte Gerät durch ein anderes Gerät ersetzen, indem sie sich in ihr Konto der Abonnementdienste einloggen.",
  it: "3.8. Ciascun account del Servizio di abbonamento è un account assegnato a un solo Abbonato e nessun Abbonato può consentire a terzi o autorizzare terzi a accedere o utilizzare il proprio account del Servizio di abbonamento. Non è consentito effettuare l'accesso simultaneo all'account dei Servizi di abbonamento da più di un dispositivo. Ciascun Abbonato può avere accesso ai Servizi di abbonamento (o a parte di essi) e utilizzarli su un massimo di quattro (4) dispositivi registrati, tuttavia possono cambiare o sostituire qualsiasi dispositivo registrato con un altro dispositivo effettuando l'accesso al proprio account dei Servizi di abbonamento.",
  tr: "3.8. Her Abonelik Hizmeti hesabı sadece bir Aboneye atanmış bir hesaptır ve hiçbir Abone üçüncü bir kişinin Abonelik Hizmeti hesabına erişmesine veya kullanmasına izin veremez veya bir üçüncü kişiyi yetkilendiremez. Aynı anda birden fazla cihazdan Abonelik Hizmetleri hesabına giriş yapmak izin verilmez. Her Abone, Abonelik Hizmetlerine (veya bunların herhangi bir bölümüne) en fazla dört (4) kayıtlı cihazda erişim sağlayabilir ve bunları kullanabilir, ancak Abonelik Hizmetleri hesaplarına giriş yaparak kayıtlı herhangi bir cihazı değiştirebilir veya başka bir cihazla değiştirebilir.",
  ru: "3.8. Каждая учетная запись Услуги по подписке предназначена только для одного Абонента, и ни один Абонент не имеет права разрешать третьей стороне или разрешать третьей стороне получать доступ к его учетной записи Услуги по подписке или использовать ее. Одновременный вход в учетную запись Услуги по подписке с более чем одного устройства не допускается. Каждый Абонент может иметь доступ к Услугам по подписке (или их части) и использовать их на максимум четырех (4) зарегистрированных устройствах, однако они могут изменить или заменить любое зарегистрированное устройство другим устройством, войдя в свою учетную запись Услуг по подписке.",
  pl: "3.8. Każde konto Usługi abonamentowej jest przypisane tylko do jednego Abonenta, i żaden Abonent nie może pozwolić osobie trzeciej ani nie może upoważnić osoby trzeciej do dostępu lub korzystania z jego konta Usługi abonamentowej. Jednoczesne logowanie do konta Usług abonamentowych z więcej niż jednego urządzenia jest zabronione. Każdy Abonent może mieć dostęp do Usług abonamentowych (lub ich części) i korzystać z nich na maksymalnie czterech (4) zarejestrowanych urządzeniach, jednak mogą zmieniać lub wymieniać dowolne zarejestrowane urządzenie na inne, logując się na swoje konto Usług abonamentowych.",
};
export const permittedUse = {
  en: "4. PERMITTED USE",
  fr: "4. UTILISATION AUTORISÉE",
  es: "4. USO PERMITIDO",
  de: "4. ZULÄSSIGE VERWENDUNG",
  it: "4. UTILIZZO CONSENTITO",
  tr: "4. İZİN VERİLEN KULLANIM",
  ru: "4. ДОПУСТИМОЕ ИСПОЛЬЗОВАНИЕ",
  pl: "4. DOZWOLONE UŻYCIE",
};
export const permittedUsePrivate = {
  en: "4.1. Subscription Services (or any part thereof), access to them and use of them is only permitted for private, personal and domestic use.",
  fr: "4.1. Les Services d'abonnement (ou toute partie de ceux-ci), l'accès à ceux-ci et leur utilisation ne sont autorisés que pour un usage privé, personnel et domestique.",
  es: "4.1. Los Servicios de suscripción (o cualquier parte de ellos), el acceso a ellos y su uso solo están permitidos para uso privado, personal y doméstico.",
  de: "4.1. Die Abonnementdienste (oder Teile davon), der Zugang dazu und deren Verwendung sind nur für private, persönliche und häusliche Nutzung gestattet.",
  it: "4.1. I Servizi di abbonamento (o parte di essi), l'accesso ad essi e l'uso di essi sono consentiti solo per uso privato, personale e domestico.",
  tr: "4.1. Abonelik Hizmetleri (veya bunların herhangi bir bölümü), bunlara erişim ve bunların kullanımı yalnızca özel, kişisel ve ev içi kullanım için izin verilir.",
  ru: "4.1. Услуги по подписке (или их часть), доступ к ним и их использование разрешены только для частного, личного и домашнего использования.",
  pl: "4.1. Usługi abonamentowe (lub ich część), dostęp do nich i ich używanie jest dozwolone wyłącznie do celów prywatnych, osobistych i domowych.",
};
export const noExhibitionToPublic = {
  en: "4.2 No Subscription Services (or any part of them) may be exhibited to an open group of people including in retail, business or commercial premises or for any business purpose, or in cinemas, theaters, exhibitions, exhibition halls, hotels, bars, clubs, pubs restaurants or other public places, even if there is no charge. The receipt of further transmission of the Channels or Content and the use of the Subscription Services (or any part thereof) in such a manner is unlawful and violates the rights of third parties.",
  fr: "4.2 Aucun Service d'abonnement (ou aucune partie de ceux-ci) ne peut être exposé à un groupe de personnes ouvert, y compris dans des locaux de vente au détail, commerciaux ou d'affaires, ou à des fins commerciales, ou dans des cinémas, des théâtres, des expositions, des halls d'exposition, des hôtels, des bars, des clubs, des pubs, des restaurants ou d'autres lieux publics, même s'il n'y a pas de frais. La réception d'une transmission ultérieure des Chaînes ou du Contenu et l'utilisation des Services d'abonnement (ou de toute partie de ceux-ci) de cette manière sont illégales et portent atteinte aux droits de tiers.",
  es: "4.2 Ningún Servicio de suscripción (o ninguna parte de ellos) puede ser exhibido a un grupo abierto de personas, incluidos los locales de venta al por menor, comerciales o comerciales, o con fines comerciales, o en cines, teatros, exposiciones, salas de exposiciones, hoteles, bares, clubes, pubs, restaurantes u otros lugares públicos, incluso si no hay ningún cargo. La recepción de una transmisión adicional de los Canales o el Contenido y el uso de los Servicios de suscripción (o de cualquier parte de ellos) de esta manera es ilegal y viola los derechos de terceros.",
  de: "4.2 Kein Abonnementdienst (oder kein Teil von ihnen) darf einer offenen Gruppe von Personen vorgeführt werden, einschließlich in Einzelhandels-, Geschäfts- oder Gewerberäumen oder zu geschäftlichen Zwecken, oder in Kinos, Theatern, Ausstellungen, Ausstellungshallen, Hotels, Bars, Clubs, Pubs, Restaurants oder anderen öffentlichen Orten, auch wenn keine Gebühr erhoben wird. Der Empfang einer weiteren Übertragung der Kanäle oder des Inhalts und die Nutzung der Abonnementdienste (oder eines Teils davon) auf diese Weise sind rechtswidrig und verletzen die Rechte Dritter.",
  it: "4.2 Nessun Servizio di abbonamento (o parte di essi) può essere esposto a un gruppo aperto di persone, compresi i locali commerciali, commerciali o commerciali o per scopi commerciali, o in cinema, teatri, mostre, sale espositive, hotel, bar, club, pub, ristoranti o altri luoghi pubblici, anche se non vi è alcun costo. La ricezione di ulteriori trasmissioni dei Canali o del Contenuto e l'uso dei Servizi di abbonamento (o di una loro parte) in tale modo è illegale e viola i diritti di terzi.",
  tr: "4.2 Hiçbir Abonelik Hizmeti (veya bunların herhangi bir bölümü), perakende, iş veya ticari alanlarda veya iş amacıyla açık bir grup insanın önünde sergilenemez veya sinemalarda, tiyatrolarda, sergilerde, sergi salonlarında, otellerde, barlarda, kulüplerde, publarda, restoranlarda veya diğer kamusal yerlerde, ücretsiz olsa bile. Kanalların veya İçeriğin daha fazla iletilmesinin alınması ve Abonelik Hizmetlerinin (veya bunların herhangi bir bölümünün) bu şekilde kullanılması yasa dışıdır ve üçüncü tarafların haklarını ihlal eder.",
  ru: "4.2 Ни одна Услуга по подписке (или их часть) не может быть представлена открытой группе людей, включая продажи, коммерческие или коммерческие помещения или в коммерческих целях, или в кинотеатрах, театрах, выставках, выставочных залах, отелях, барах, клубах, пабах, ресторанах или других общественных местах, даже если нет платы. Прием дополнительной передачи Каналов или Содержимого и использование Услуг по подписке (или их части) таким образом является незаконным и нарушает права третьих лиц.",
  pl: "4.2 Żadne Usługi abonamentowe (lub ich część) nie mogą być eksponowane przed otwartą grupą ludzi, w tym w punktach sprzedaży detalicznej, na terenach biznesowych lub handlowych ani w celach biznesowych, ani w kinach, teatrach, wystawach, halach wystawowych, hotelach, barach, klubach, pubach, restauracjach lub innych miejscach publicznych, nawet jeśli nie ma opłaty. Odbieranie dalszej transmisji Kanałów lub Zawartości oraz korzystanie z Usług abonamentowych (lub ich części) w taki sposób jest nielegalne i narusza prawa osób trzecich.",
};
export const paymentMethods = {
  en: "5. METHODS OF PAYMENT",
  fr: "5. MOYENS DE PAIEMENT",
  es: "5. MÉTODOS DE PAGO",
  de: "5. ZAHLUNGSMETHODEN",
  it: "5. METODI DI PAGAMENTO",
  tr: "5. ÖDEME YÖNTEMLERİ",
  ru: "5. СПОСОБЫ ОПЛАТЫ",
  pl: "5. METODY PŁATNOŚCI",
};
export const paymentMethodsValid = {
  en: "5.1 Payments for the purchase of any Subscription Pass must be made using one of the valid and accepted methods of payment determined from time to time by CRYSTAL IPTV (“Payment Methods”).",
  fr: "5.1 Les paiements pour l'achat de tout Abonnement doivent être effectués en utilisant l'un des moyens de paiement valides et acceptés déterminés de temps à autre par CRYSTAL IPTV (« Méthodes de paiement »).",
  es: "5.1 Los pagos para la compra de cualquier Pase de suscripción deben realizarse utilizando uno de los métodos de pago válidos y aceptados determinados de vez en cuando por CRYSTAL IPTV (“Métodos de pago”).",
  de: "5.1 Zahlungen für den Kauf eines Abonnement-Passes müssen mit einer der von Zeit zu Zeit von CRYSTAL IPTV festgelegten gültigen und akzeptierten Zahlungsmethoden erfolgen (“Zahlungsmethoden”).",
  it: "5.1 I pagamenti per l'acquisto di qualsiasi Abbonamento devono essere effettuati utilizzando uno dei metodi di pagamento validi e accettati determinati di volta in volta da CRYSTAL IPTV (“Metodi di pagamento”).",
  tr: "5.1 Herhangi bir Abonelik Geçişi satın almak için yapılan ödemeler, CRYSTAL IPTV tarafından zaman zaman belirlenen geçerli ve kabul edilen ödeme yöntemlerinden biri kullanılarak yapılmalıdır (“Ödeme Yöntemleri”).",
  ru: "5.1 Оплата за покупку любого Пропуска на подписку должна осуществляться одним из действительных и принимаемых методов оплаты, устанавливаемых в разное время CRYSTAL IPTV (“Методы оплаты”).",
  pl: "5.1 Płatności za zakup dowolnego Przepustka na subskrypcję muszą być dokonywane jednym z ważnych i akceptowanych metod płatności ustalanych od czasu do czasu przez CRYSTAL IPTV (“Metody płatności”).",
};
export const acquireSubscriptionPass = {
  en: "5.2 Each potential Subscriber may acquire a Subscription Pass by following the relevant instructions on the CRYSTAL IPTV website.",
  fr: "5.2 Chaque futur Abonné peut acquérir un Abonnement en suivant les instructions pertinentes sur le site Web de CRYSTAL IPTV.",
  es: "5.2 Cada posible Suscriptor puede adquirir un Pase de suscripción siguiendo las instrucciones relevantes en el sitio web de CRYSTAL IPTV.",
  de: "5.2 Jeder potenzielle Abonnent kann einen Abonnement-Pass erwerben, indem er den entsprechenden Anweisungen auf der CRYSTAL IPTV-Website folgt.",
  it: "5.2 Ogni potenziale Abbonato può acquistare un Abbonamento seguendo le istruzioni pertinenti sul sito web di CRYSTAL IPTV.",
  tr: "5.2 Her potansiyel Abone, CRYSTAL IPTV web sitesindeki ilgili talimatları takip ederek bir Abonelik Geçişi satın alabilir.",
  ru: "5.2 Каждый потенциальный Абонент может приобрести Пропуск на подписку, следуя соответствующим инструкциям на веб-сайте CRYSTAL IPTV.",
  pl: "5.2 Każdy potencjalny Abonent może nabyć Przepustkę na subskrypcję, postępując zgodnie z odpowiednimi instrukcjami na stronie internetowej CRYSTAL IPTV.",
};
export const paymentSuspension = {
  en: "5.3 If any of the payments (including any instalments) are not paid in full for any reason, CRYSTAL IPTV reserves the right to suspend access to the Subscription Services (or parts thereof) and suspend a Subscriber’s use of the same until CRYSTAL IPTV receives any and all overdue fees. Notwithstanding the foregoing, each Subscriber remains liable to CRYSTAL IPTV for all outstanding payments.",
  fr: "5.3 Si l'un des paiements (y compris tout acompte) n'est pas intégralement payé pour quelque raison que ce soit, CRYSTAL IPTV se réserve le droit de suspendre l'accès aux Services d'abonnement (ou à certaines de leurs parties) et de suspendre l'utilisation par un Abonné des mêmes jusqu'à ce que CRYSTAL IPTV reçoive tous les frais impayés. Nonobstant ce qui précède, chaque Abonné reste redevable envers CRYSTAL IPTV de tous les paiements impayés.",
  es: "5.3 Si alguno de los pagos (incluyendo cualquier plazo) no se paga en su totalidad por cualquier motivo, CRYSTAL IPTV se reserva el derecho de suspender el acceso a los Servicios de suscripción (o a partes de los mismos) y suspender el uso de los mismos por parte de un Suscriptor hasta que CRYSTAL IPTV reciba todos los cargos vencidos. No obstante lo anterior, cada Suscriptor sigue siendo responsable ante CRYSTAL IPTV de todos los pagos pendientes.",
  de: "5.3 Wenn einer der Zahlungen (einschließlich etwaiger Raten) aus irgendeinem Grund nicht in vollem Umfang bezahlt wird, behält sich CRYSTAL IPTV das Recht vor, den Zugang zu den Abonnementdiensten (oder Teilen davon) zu sperren und die Nutzung durch einen Abonnenten zu sperren, bis CRYSTAL IPTV alle überfälligen Gebühren erhalten hat. Ungeachtet dessen bleibt jeder Abonnent gegenüber CRYSTAL IPTV für alle ausstehenden Zahlungen haftbar.",
  it: "5.3 Se uno qualsiasi dei pagamenti (compresi eventuali acconti) non viene pagato per intero per qualsiasi motivo, CRYSTAL IPTV si riserva il diritto di sospendere l'accesso ai Servizi di abbonamento (o a parte di essi) e sospendere l'uso da parte di un Abbonato degli stessi fino a quando CRYSTAL IPTV riceve tutti gli oneri scaduti. Nonostante quanto precede, ogni Abbonato rimane responsabile nei confronti di CRYSTAL IPTV per tutti i pagamenti in sospeso.",
  tr: "5.3 Herhangi bir ödeme (taksitler dahil) herhangi bir nedenle tam olarak ödenmezse, CRYSTAL IPTV, herhangi bir ve tüm gecikmiş ücretleri alana kadar Abonelik Hizmetleri (veya bunların bir bölümü) erişimi askıya alma ve bir Abonenin aynı hizmeti kullanmasını askıya alma hakkını saklı tutar. Yukarıdaki hükümlere rağmen, her Abone CRYSTAL IPTV'ye tüm ödenmemiş ödemelerden sorumludur.",
  ru: "5.3 Если какие-либо платежи (включая любые взносы) по какой-либо причине не оплачены полностью, CRYSTAL IPTV оставляет за собой право приостановить доступ к Услугам по подписке (или их части) и приостановить использование Абонентом до тех пор, пока CRYSTAL IPTV не получит все просроченные сборы. Несмотря на вышеизложенное, каждый Абонент остается ответственным перед CRYSTAL IPTV за все неоплаченные платежи.",
  pl: "5.3 Jeśli którykolwiek z płatności (w tym raty) nie zostanie opłacony w pełnej wysokości z jakiegokolwiek powodu, CRYSTAL IPTV zastrzega sobie prawo do zawieszenia dostępu do Usług abonamentowych (lub ich części) i zawieszenia korzystania z tych samych przez Abonenta, dopóki CRYSTAL IPTV nie otrzyma wszystkich zaległych opłat. Niezależnie od powyższego, każdy Abonent pozostaje odpowiedzialny wobec CRYSTAL IPTV za wszystkie nieuregulowane płatności.",
};
export const downloadPaymentsAndRenewal = {
  en: "6. DOWNLOAD PAYMENTS AND AUTOMATIC RENEWAL OF SUBSCRIPTIONS",
  fr: "6. TÉLÉCHARGER LES PAIEMENTS ET LE RENOUVELLEMENT AUTOMATIQUE DES ABONNEMENTS",
  es: "6. DESCARGA DE PAGOS Y RENOVACIÓN AUTOMÁTICA DE SUSCRIPCIONES",
  de: "6. DOWNLOAD VON ZAHLUNGEN UND AUTOMATISCHER VERLÄNGERUNG VON ABONNEMENTEN",
  it: "6. DOWNLOAD DEI PAGAMENTI E RINNOVO AUTOMATICO DELLE ISCRIZIONI",
  tr: "6. ÖDEMELERİ İNDİRME VE ABONELİKLERİN OTOMATİK YENİLEME",
  ru: "6. ЗАГРУЗКА ПЛАТЕЖЕЙ И АВТОМАТИЧЕСКОЕ ПРОДЛЕНИЕ ПОДПИСКИ",
  pl: "6. POBRANIE PŁATNOŚCI I AUTOMATYCZNE ODNOWIENIE SUBSKRYPCJI",
};
export const subscriptionPaymentTransaction = {
  en: "6.1 Unless expressly stated otherwise, payment for each Subscription Pass is made in one transaction at the time of purchase.",
  fr: "6.1 Sauf indication expresse contraire, le paiement de chaque Abonnement est effectué en une seule transaction au moment de l'achat.",
  es: "6.1 A menos que se indique expresamente lo contrario, el pago de cada Pase de suscripción se realiza en una sola transacción en el momento de la compra.",
  de: "6.1 Sofern nicht ausdrücklich anders angegeben, erfolgt die Zahlung für jeden Abonnement-Pass in einer Transaktion zum Zeitpunkt des Kaufs.",
  it: "6.1 Salvo diversamente specificato, il pagamento per ciascun Abbonamento viene effettuato in un'unica transazione al momento dell'acquisto.",
  tr: "6.1 Aksi açıkça belirtilmediği sürece, her Abonelik Geçişi için ödeme satın alma anında tek bir işlemde yapılır.",
  ru: "6.1 Если не оговорено иное, оплата за каждый Пропуск на подписку производится в одной транзакции в момент покупки.",
  pl: "6.1 Jeśli nie jest wyraźnie określone inaczej, płatność za każdy Przepustka na subskrypcję jest dokonywana jednorazowo w chwili zakupu.",
};
export const subscriptionPassRenewal = {
  en: "6.2 Each Subscription Pass is not automatically renewable. The period of validity commences on the date and time of account delivery and is not automatically renewed upon expiration of the validity period of the Subscription Pass unless the Subscriber ordered the renewal in accordance with these CRYSTAL IPTV Terms.",
  fr: "6.2 Chaque Abonnement n'est pas renouvelable automatiquement. La période de validité commence à la date et à l'heure de la livraison du compte et n'est pas automatiquement renouvelée à l'expiration de la période de validité de l'Abonnement, sauf si le Souscripteur a commandé le renouvellement conformément aux présentes Conditions de CRYSTAL IPTV.",
  es: "6.2 Cada Pase de suscripción no es renovable automáticamente. El período de validez comienza en la fecha y hora de entrega de la cuenta y no se renueva automáticamente al vencimiento del período de validez del Pase de suscripción, a menos que el Suscriptor haya ordenado la renovación de acuerdo con estos Términos de CRYSTAL IPTV.",
  de: "6.2 Jeder Abonnement-Pass wird nicht automatisch verlängert. Die Gültigkeitsdauer beginnt zum Zeitpunkt der Lieferung des Kontos und wird nicht automatisch nach Ablauf der Gültigkeitsdauer des Abonnement-Passes verlängert, es sei denn, der Abonnent hat die Verlängerung gemäß diesen CRYSTAL IPTV-Bedingungen bestellt.",
  it: "6.2 Ciascun Abbonamento non è rinnovabile automaticamente. Il periodo di validità inizia alla data e all'ora della consegna dell'account e non viene rinnovato automaticamente alla scadenza del periodo di validità dell'Abbonamento, a meno che l'Abbonato abbia ordinato il rinnovo in conformità con questi Termini di CRYSTAL IPTV.",
  tr: "6.2 Her Abonelik Geçişi otomatik olarak yenilenmez. Geçerlilik süresi, hesap teslimatının tarihine ve saatinde başlar ve Abonelik Geçişi'nin geçerlilik süresinin sona ermesi durumunda, Abone bu CRYSTAL IPTV Şartlarına uygun olarak yenileme siparişi vermediği sürece otomatik olarak yenilenmez.",
  ru: "6.2 Каждый Пропуск на подписку не автоматически продлевается. Срок действия начинается с даты и времени доставки учетной записи и не продлевается автоматически по истечении срока действия Пропуска на подписку, если Абонент не заказал продление в соответствии с настоящими Условиями CRYSTAL IPTV.",
  pl: "6.2 Każda Przepustka na subskrypcję nie jest automatycznie odnawialna. Okres ważności rozpoczyna się w dniu i godzinie dostawy konta i nie jest automatycznie odnawiany po wygaśnięciu okresu ważności Przepustki na subskrypcję, chyba że Abonent zamówił odnowienie zgodnie z niniejszymi Warunkami CRYSTAL IPTV.",
};
export const subscriptionPassRenewalPayment = {
  en: "6.3 Payment for each renewal of a Subscription Pass is processed by purchasing a new subscription extension using the chosen Payment Method. The process for renewal will be the same as acquiring a new subscription.",
  fr: "6.3 Le paiement pour chaque renouvellement d'un Abonnement est traité en achetant une nouvelle extension d'abonnement à l'aide de la méthode de paiement choisie. Le processus de renouvellement sera le même que l'acquisition d'un nouvel abonnement.",
  es: "6.3 El pago por cada renovación de un Pase de suscripción se procesa mediante la compra de una nueva extensión de suscripción utilizando el Método de pago elegido. El proceso de renovación será el mismo que adquirir una nueva suscripción.",
  de: "6.3 Die Zahlung für jede Verlängerung eines Abonnement-Passes erfolgt durch den Kauf einer neuen Abonnementverlängerung unter Verwendung der gewählten Zahlungsmethode. Der Verlängerungsprozess wird dem Erwerb eines neuen Abonnements entsprechen.",
  it: "6.3 Il pagamento per ciascun rinnovo di un Abbonamento viene elaborato tramite l'acquisto di una nuova estensione dell'abbonamento utilizzando il Metodo di pagamento scelto. Il processo di rinnovo sarà lo stesso dell'acquisto di un nuovo abbonamento.",
  tr: "6.3 Her Abonelik Geçişi yenileme için yapılan ödeme, seçilen Ödeme Yöntemi kullanılarak yeni bir abonelik uzantısı satın alınarak işlenir. Yenileme işlemi, yeni bir abonelik satın almakla aynı olacaktır.",
  ru: "6.3 Оплата каждого продления Пропуска на подписку обрабатывается путем покупки нового продления подписки с использованием выбранного метода оплаты. Процесс продления будет таким же, как приобретение новой подписки.",
  pl: "6.3 Płatność za każde odnowienie Przepustki na subskrypcję jest przetwarzana poprzez zakup nowego przedłużenia subskrypcji przy użyciu wybranej metody płatności. Proces odnowienia będzie taki sam jak w przypadku zakupu nowej subskrypcji.",
};
export const subscriptionPassAgreement = {
  en: "6.4 By purchasing a Subscription Pass, the Subscriber agrees to the use of the Subscription Services for the period selected by the Subscriber.",
  fr: "6.4 En achetant un Passe de suscription, le Souscripteur accepte l'utilisation des Services d'abonnement pour la période sélectionnée par le Souscripteur.",
  es: "6.4 Al comprar un Pase de suscripción, el Suscriptor acepta el uso de los Servicios de suscripción durante el período seleccionado por el Suscriptor.",
  de: "6.4 Durch den Kauf eines Abonnement-Passes stimmt der Abonnent der Nutzung der Abonnement-Dienste für den vom Abonnenten ausgewählten Zeitraum zu.",
  it: "6.4 Acquistando un Abbonamento, l'Abbonato accetta l'utilizzo dei Servizi di abbonamento per il periodo selezionato dall'Abbonato.",
  tr: "6.4 Bir Abonelik Geçişi satın alarak, Abone Abonelik Hizmetlerinin Abone tarafından seçilen süre boyunca kullanımını kabul eder.",
  ru: "6.4 При покупке Пропуска на подписку, Абонент соглашается на использование Сервисов подписки на период, выбранный Абонентом.",
  pl: "6.4 Przy zakupie Przepustki na subskrypcję Abonent zgadza się na korzystanie z Usług subskrypcji przez okres wybrany przez Abonenta.",
};
export const subscriberObligations = {
  en: "7. OBLIGATIONS OF THE SUBSCRIBER",
  fr: "7. OBLIGATIONS DU SOUSCRIPTEUR",
  es: "7. OBLIGACIONES DEL SUSCRIPTOR",
  de: "7. PFLICHTEN DES ABONNENTEN",
  it: "7. OBBLIGHI DELL'ABBONATO",
  tr: "7. ABONEN YÜKÜMLÜLÜKLERİ",
  ru: "7. ОБЯЗАННОСТИ АБОНЕНТА",
  pl: "7. OBOWIĄZKI ABONENTA",
};
export const subscriberResponsibility = {
  en: "7.1. Subscribers shall be solely responsible for all activities carried out using their Internet-connected devices made to acquire, access and use Subscription Services, including taking appropriate precautions against spyware, malware, viruses and other electronic threats that cause circumvention or otherwise cause damage to the use or functionality of the Subscription Services (or any part thereof).",
  fr: "7.1. Les Abonnés sont seuls responsables de toutes les activités effectuées à l'aide de leurs appareils connectés à Internet pour acquérir, accéder et utiliser les Services d'abonnement, y compris en prenant les précautions appropriées contre les logiciels espions, les logiciels malveillants, les virus et autres menaces électroniques susceptibles de contourner ou de causer d'autres dommages à l'utilisation ou à la fonctionnalité des Services d'abonnement (ou de toute partie de ceux-ci).",
  es: "7.1. Los Suscriptores serán los únicos responsables de todas las actividades realizadas utilizando sus dispositivos conectados a Internet para adquirir, acceder y utilizar los Servicios de suscripción, incluyendo la adopción de las precauciones adecuadas contra el spyware, malware, virus y otras amenazas electrónicas que causen eludir o causen daño de otra manera al uso o la funcionalidad de los Servicios de suscripción (o de cualquier parte de los mismos).",
  de: "7.1. Abonnenten sind allein verantwortlich für alle Aktivitäten, die mit ihren Internet-verbundenen Geräten durchgeführt werden, um Abonnementdienste zu erwerben, darauf zuzugreifen und sie zu nutzen, einschließlich der Ergreifung angemessener Vorsichtsmaßnahmen gegen Spyware, Malware, Viren und andere elektronische Bedrohungen, die die Umgehung verursachen oder anderweitig Schäden an der Nutzung oder Funktionalität der Abonnementdienste (oder eines Teils davon) verursachen.",
  it: "7.1. Gli Abbonati sono gli unici responsabili di tutte le attività svolte utilizzando i propri dispositivi connessi a Internet per acquisire, accedere e utilizzare i Servizi di abbonamento, comprese le dovute precauzioni contro spyware, malware, virus e altre minacce elettroniche che causano il bypass o danni all'uso o alla funzionalità dei Servizi di abbonamento (o di parte di essi).",
  tr: "7.1. Aboneler, İnternet bağlantılı cihazlarını kullanarak Abonelik Hizmetleri'ni edinmek, erişmek ve kullanmak için gerçekleştirilen tüm faaliyetlerden, Abonelik Hizmetleri'nin kullanımına veya işlevselliğine zarar veren casus yazılım, kötü amaçlı yazılım, virüsler ve diğer elektronik tehditlere karşı uygun önlemleri almak da dahil olmak üzere yalnızca kendileri sorumludur (veya bunların herhangi bir kısmı).",
  ru: "7.1. Абоненты несут полную ответственность за все действия, совершаемые с использованием их устройств, подключенных к Интернету, с целью приобретения, доступа и использования Сервисов подписки, включая принятие соответствующих мер предосторожности против программ-шпионов, вредоносных программ, вирусов и других электронных угроз, которые могут обходить или иным образом наносить ущерб использованию или функциональности Сервисов подписки (или их части).",
  pl: "7.1. Abonenci ponoszą wyłączną odpowiedzialność za wszystkie działania prowadzone za pomocą swoich urządzeń podłączonych do Internetu, które są podejmowane w celu nabywania, uzyskiwania dostępu i korzystania z Usług subskrypcji, w tym podjęcie odpowiednich środków ostrożności wobec oprogramowania szpiegowskiego, złośliwego oprogramowania, wirusów i innych zagrożeń elektronicznych, które powodują omijanie lub w inny sposób powodują uszkodzenie używania lub funkcjonalności Usług subskrypcji (lub ich części).",
};
export const subscriberUsageAgreement = {
  en: "7.2. Each Subscriber agrees to use the Subscription Service lawfully and in accordance with the instruction of CRYSTAL IPTV. That means Subscribers must not use it in a way that breaks any law, regulation or rule in force in Europe (as appropriate) and must not breach the CRYSTAL IPTV Terms.",
  fr: "7.2. Chaque Souscripteur s'engage à utiliser le Service d'abonnement légalement et conformément aux instructions de CRYSTAL IPTV. Cela signifie que les Souscripteurs ne doivent pas l'utiliser de manière à enfreindre une loi, un règlement ou une règle en vigueur en Europe (le cas échéant) et ne doivent pas enfreindre les Conditions de CRYSTAL IPTV.",
  es: "7.2. Cada Suscriptor acepta utilizar el Servicio de suscripción de manera legal y de acuerdo con las instrucciones de CRYSTAL IPTV. Esto significa que los Suscriptores no deben utilizarlo de manera que infrinja ninguna ley, regulación o norma vigente en Europa (según corresponda) y no deben violar los Términos de CRYSTAL IPTV.",
  de: "7.2. Jeder Abonnent verpflichtet sich, den Abonnementdienst rechtmäßig und gemäß den Anweisungen von CRYSTAL IPTV zu nutzen. Das bedeutet, dass Abonnenten ihn nicht in einer Weise verwenden dürfen, die gegen geltendes Recht, Vorschriften oder Regeln in Europa verstößt (soweit zutreffend) und die CRYSTAL IPTV-Bedingungen nicht verletzen dürfen.",
  it: "7.2. Ciascun Abbonato accetta di utilizzare il Servizio di abbonamento in modo legale e in conformità alle istruzioni di CRYSTAL IPTV. Ciò significa che gli Abbonati non devono usarlo in modo che violi leggi, regolamenti o regole in vigore in Europa (se applicabile) e non devono violare i Termini di CRYSTAL IPTV.",
  tr: "7.2. Her Abone, Abonelik Hizmetini yasal olarak ve CRYSTAL IPTV talimatlarına uygun bir şekilde kullanmayı kabul eder. Bu, Abonelerin Avrupa'da (uygunsa) yürürlükteki herhangi bir yasayı, düzenlemeyi veya kuralı ihlal eden bir şekilde kullanmamaları ve CRYSTAL IPTV Şartlarına aykırı davranmamaları gerektiği anlamına gelir.",
  ru: "7.2. Каждый Абонент соглашается использовать Сервис подписки законно и в соответствии с указаниями CRYSTAL IPTV. Это означает, что Абоненты не должны использовать его таким образом, который нарушает любой закон, регуляторный акт или правило, действующее в Европе (по мере необходимости), и не должны нарушать Условия CRYSTAL IPTV.",
  pl: "7.2. Każdy Abonent zobowiązuje się do korzystania z Usługi subskrypcji w sposób zgodny z prawem i zgodnie z instrukcjami CRYSTAL IPTV. Oznacza to, że Abonenci nie mogą używać jej w sposób naruszający obowiązujące w Europie (o ile dotyczy) prawo, przepisy lub regulacje oraz nie mogą naruszać Warunków CRYSTAL IPTV.",
};
export const subscriberProhibitedActivitiesHeader = {
  en: "7.3. Subscribers may not carry out any of the following activities or assist others (directly or indirectly) in carrying out any of the following activities:",
  fr: "7.3. Les abonnés ne sont pas autorisés à effectuer l'une des activités suivantes ni à aider d'autres personnes (directement ou indirectement) à effectuer l'une des activités suivantes :",
  es: "7.3. Los suscriptores no pueden llevar a cabo ninguna de las siguientes actividades ni ayudar a otros (directa o indirectamente) a llevar a cabo ninguna de las siguientes actividades:",
  de: "7.3. Abonnenten dürfen keine der folgenden Aktivitäten durchführen oder andere (direkt oder indirekt) bei der Durchführung einer der folgenden Aktivitäten unterstützen:",
  it: "7.3. Gli abbonati non possono svolgere nessuna delle seguenti attività o assistere altri (direttamente o indirettamente) nello svolgimento di nessuna delle seguenti attività:",
  tr: "7.3. Aboneler, aşağıdaki aktivitelerden herhangi birini gerçekleştiremez veya diğerlerine (doğrudan veya dolaylı olarak) aşağıdaki aktivitelerden herhangi birini gerçekleştirmelerine yardımcı olamaz:",
  ru: "7.3. Абоненты не могут выполнять ни одну из следующих деятельностей или помогать другим (непосредственно или косвенно) в выполнении ни одной из следующих деятельностей:",
  pl: "7.3. Abonenci nie mogą wykonywać żadnych z poniższych działań ani pomagać innym (bezpośrednio lub pośrednio) w wykonywaniu żadnych z poniższych działań:",
};

export const subscriberProhibitedActivityA = {
  en: "(a) archive, download, reproduce, distribute, modify, display, perform, publish, license, create derivative works, offer for sale and use (subject to the use expressly permitted in these CRYSTAL IPTV Terms) any element of the Subscription Services; or",
  fr: "(a) archiver, télécharger, reproduire, distribuer, modifier, afficher, exécuter, publier, concéder une licence, créer des œuvres dérivées, offrir à la vente et utiliser (sous réserve de l'utilisation expressément autorisée dans ces Conditions de CRYSTAL IPTV) tout élément des Services d'abonnement ; ou",
  es: "(a) archivar, descargar, reproducir, distribuir, modificar, mostrar, ejecutar, publicar, otorgar licencias, crear obras derivadas, ofrecer a la venta y utilizar (sujeto al uso expresamente permitido en estos Términos de CRYSTAL IPTV) cualquier elemento de los Servicios de suscripción; o",
  de: "(a) archivieren, herunterladen, reproduzieren, verteilen, modifizieren, anzeigen, ausführen, veröffentlichen, Lizenzieren, abgeleitete Werke erstellen, zum Verkauf anbieten und verwenden (unterliegt der ausdrücklich in diesen CRYSTAL IPTV-Bedingungen gestatteten Verwendung) jedes Element der Abonnementdienste; oder",
  it: "(a) archiviare, scaricare, riprodurre, distribuire, modificare, visualizzare, eseguire, pubblicare, concedere in licenza, creare opere derivate, offrire in vendita e utilizzare (soggetto all'uso espressamente consentito in questi Termini di CRYSTAL IPTV) qualsiasi elemento dei Servizi di abbonamento; o",
  tr: "(a) arşivlemek, indirmek, çoğaltmak, dağıtmak, değiştirmek, görüntülemek, gerçekleştirmek, yayınlamak, lisans vermek, türev eserler oluşturmak, satışa sunmak ve kullanmak (bu CRYSTAL IPTV Şartlarında açıkça izin verilen kullanıma tabi) Abonelik Hizmetlerinin herhangi bir öğesini; veya",
  ru: "(а) архивировать, загружать, воспроизводить, распространять, модифицировать, отображать, выполнять, публиковать, лицензировать, создавать производные произведения, предлагать к продаже и использовать (с учетом использования, явно разрешенного в настоящих Условиях CRYSTAL IPTV) любой элемент Сервисов подписки; или",
  pl: "(a) archiwizować, pobierać, reprodukować, dystrybuować, modyfikować, wyświetlać, wykonywać, publikować, udzielać licencji, tworzyć prace pochodne, oferować w sprzedaży i używać (z zastrzeżeniem wyraźnie dozwolonego użytku w niniejszych Warunkach CRYSTAL IPTV) dowolnego elementu Usług subskrypcji; lub",
};
export const subscriberProhibitedActivityB = {
  en: "(b) circumvent, remove, alter, deactivate, deteriorate or block any security of content in the Subscription Services; use robots, spiders, scrapers or other automated means to access Subscription Services; decompile, reverse engineer or disassemble any software or other products or processes available through the Subscription Services; insert any code or product or manipulate the Subscription Services in any way; or use methods of data mining, data collection or extraction in relation to Subscription Services; or",
  fr: "(b) contourner, supprimer, altérer, désactiver, détériorer ou bloquer toute sécurité du contenu dans les Services d'abonnement ; utiliser des robots, des araignées, des grattoirs ou d'autres moyens automatisés pour accéder aux Services d'abonnement ; décompiler, rétro-ingénierie ou désassembler tout logiciel ou autres produits ou processus disponibles via les Services d'abonnement ; insérer un code ou un produit ou manipuler les Services d'abonnement de quelque manière que ce soit ; ou utiliser des méthodes d'extraction de données, de collecte de données ou d'extraction de données en relation avec les Services d'abonnement ; ou",
  es: "(b) eludir, eliminar, alterar, desactivar, deteriorar o bloquear cualquier seguridad de contenido en los Servicios de suscripción; utilizar robots, arañas, raspadores u otros medios automatizados para acceder a los Servicios de suscripción; descompilar, realizar ingeniería inversa o desmontar cualquier software u otros productos o procesos disponibles a través de los Servicios de suscripción; insertar cualquier código o producto o manipular los Servicios de suscripción de cualquier manera; o utilizar métodos de minería de datos, recopilación de datos o extracción de datos en relación con los Servicios de suscripción; o",
  de: "(b) Umgehen, entfernen, ändern, deaktivieren, verschlechtern oder blockieren Sie die Sicherheit von Inhalten in den Abonnementdiensten; Verwendung von Robotern, Spinnen, Scrapern oder anderen automatisierten Mitteln, um auf die Abonnementdienste zuzugreifen; Decompilieren, Reverse Engineering oder Zerlegen von Software oder anderen Produkten oder Prozessen, die über die Abonnementdienste verfügbar sind; Einfügen von Code oder Produkt oder Manipulation der Abonnementdienste in irgendeiner Weise; oder Verwendung von Methoden zur Datenmining, Datensammlung oder -extraktion in Bezug auf die Abonnementdienste; oder",
  it: "(b) eludere, rimuovere, alterare, disattivare, deteriorare o bloccare qualsiasi sicurezza dei contenuti nei Servizi di abbonamento; utilizzare robot, ragni, scraper o altri mezzi automatizzati per accedere ai Servizi di abbonamento; decompilare, fare reverse engineering o smontare qualsiasi software o altri prodotti o processi disponibili attraverso i Servizi di abbonamento; inserire qualsiasi codice o prodotto o manipolare i Servizi di abbonamento in qualsiasi modo; o utilizzare metodi di data mining, raccolta dati o estrazione dati in relazione ai Servizi di abbonamento; o",
  tr: "(b) Abonelik Hizmetleri içindeki içerik güvenliğini yanıltmak, kaldırmak, değiştirmek, devre dışı bırakmak, bozmak veya engellemek; Abonelik Hizmetlerine erişmek için robotlar, örümcekler, kazıyıcılar veya diğer otomatik yöntemleri kullanmak; Abonelik Hizmetleri aracılığıyla mevcut olan yazılım veya diğer ürünleri veya süreçleri çözümlemek, tersine mühendislik yapmak veya parçalamak; herhangi bir kod veya ürün eklemek veya Abonelik Hizmetlerini herhangi bir şekilde manipüle etmek; veya Abonelik Hizmetleri ile ilgili olarak veri madenciliği, veri toplama veya veri çıkarma yöntemlerini kullanmak; veya",
  ru: "(б) обходить, удалять, изменять, отключать, ухудшать или блокировать любую защиту контента в Сервисах подписки; использовать роботов, пауков, скраперов или другие автоматизированные средства для доступа к Сервисам подписки; декомпилировать, проводить обратную инженерию или разбирать любое программное обеспечение или другие продукты или процессы, доступные через Сервисы подписки; вставлять любой код или продукт или каким-либо образом манипулировать Сервисами подписки; или использовать методы добычи данных, сбора данных или извлечения данных в отношении Сервисов подписки; или",
  pl: "(b) omijać, usuwać, zmieniać, dezaktywować, pogarszać lub blokować zabezpieczenia treści w Usługach subskrypcji; używać robotów, pająków, scraperów lub innych środków automatyzacji do dostępu do Usług subskrypcji; dekompilować, inżynierię wsteczną lub rozmontowywać oprogramowanie lub inne produkty lub procesy dostępne za pośrednictwem Usług subskrypcji; wstawiać kod lub produkt lub manipulować Usługami subskrypcji w dowolny sposób; lub używać metod wydobywania danych, zbierania danych lub ekstrakcji danych w związku z Usługami subskrypcji; lub",
};
export const subscriberProhibitedActivityC = {
  en: "(c) upload, post, e-mail or otherwise send or transmit any material intended to interrupt, destroy or limit the functionality of any electronic software, hardware or telecommunications devices related to Subscription Services, including, in particular, any computer viruses or any other computer codes, files or programs; or",
  fr: "(c) télécharger, poster, envoyer par e-mail ou autrement envoyer ou transmettre tout matériel destiné à interrompre, détruire ou limiter la fonctionnalité de tout logiciel électronique, matériel ou dispositifs de télécommunication liés aux Services d'abonnement, notamment tout virus informatique ou tout autre code, fichier ou programme informatique ; ou",
  es: "(c) cargar, publicar, enviar por correo electrónico o de cualquier otra manera enviar o transmitir cualquier material destinado a interrumpir, destruir o limitar la funcionalidad de cualquier software electrónico, hardware o dispositivos de telecomunicaciones relacionados con los Servicios de suscripción, incluyendo, en particular, cualquier virus informático u otros códigos, archivos o programas informáticos; o",
  de: "(c) Hochladen, Veröffentlichen, per E-Mail senden oder anderweitig Material senden oder übertragen, das dazu bestimmt ist, die Funktionalität von elektronischer Software, Hardware oder Telekommunikationsgeräten im Zusammenhang mit den Abonnementdiensten zu unterbrechen, zu zerstören oder zu begrenzen, einschließlich insbesondere von Computerviren oder anderen Computer-Codes, Dateien oder Programmen; oder",
  it: "(c) caricare, pubblicare, inviare per e-mail o in altro modo inviare o trasmettere qualsiasi materiale destinato a interrompere, distruggere o limitare la funzionalità di qualsiasi software elettronico, hardware o dispositivi di telecomunicazione correlati ai Servizi di abbonamento, inclusi, in particolare, qualsiasi virus informatico o qualsiasi altro codice, file o programma informatico; o",
  tr: "(c) Abonelik Hizmetleri ile ilgili elektronik yazılım, donanım veya telekomünikasyon cihazlarının işlevselliğini kesmeyi, yok etmeyi veya sınırlamayı amaçlayan herhangi bir malzemeyi yüklemek, göndermek, e-posta ile göndermek veya başka bir şekilde göndermek veya iletmek, özellikle herhangi bir bilgisayar virüsü veya diğer bilgisayar kodları, dosyaları veya programları dahil etmek; veya",
  ru: "(c) загружать, публиковать, отправлять по электронной почте или иным образом отправлять или передавать любой материал, предназначенный для прерывания, уничтожения или ограничения функциональности любого электронного программного обеспечения, аппаратного обеспечения или телекоммуникационных устройств, связанных с Сервисами подписки, включая, в частности, любые компьютерные вирусы или другие компьютерные коды, файлы или программы; или",
  pl: "(c) przesyłać, umieszczać na stronie internetowej, wysyłać e-mailem lub w inny sposób przesyłać lub transmitować jakikolwiek materiał przeznaczony do przerywania, niszczenia lub ograniczania funkcjonalności jakiejkolwiek elektronicznej oprogramowania, sprzętu lub urządzeń telekomunikacyjnych związanych z Usługami subskrypcji, w tym w szczególności jakiekolwiek wirusy komputerowe lub inne kody, pliki lub programy komputerowe; lub",
};
export const subscriberProhibitedActivityD = {
  en: "(d) distribute, broadcast or otherwise transfer any Content or Channels to another person in any way; or",
  fr: "(d) distribuer, diffuser ou transférer de quelque manière que ce soit tout Contenu ou Chaînes à une autre personne ; ou",
  es: "(d) distribuir, transmitir o transferir de cualquier manera cualquier Contenido o Canales a otra persona; o",
  de: "(d) Verbreitung, Übertragung oder anderweitige Übertragung von Inhalten oder Kanälen an eine andere Person in irgendeiner Weise; oder",
  it: "(d) distribuire, trasmettere o trasferire in qualsiasi modo qualsiasi Contenuto o Canali a un'altra persona; o",
  tr: "(d) Herhangi bir şekilde İçerikleri veya Kanalları başka bir kişiye dağıtmak, yayınlamak veya başka bir şekilde transfer etmek; veya",
  ru: "(d) распространять, передавать или иным образом передавать любой Контент или Каналы другому лицу каким-либо образом; или",
  pl: "(d) rozpowszechniać, nadawać lub w inny sposób przekazywać jakąkolwiek Treść lub Kanały innej osobie w dowolny sposób; lub",
};
export const subscriberProhibitedActivityE = {
  en: "(e) do anything which might have a negative effect on CRYSTAL IPTV systems, networks, servers, brand, reputation or security; or",
  fr: "(e) faire tout ce qui pourrait avoir un effet négatif sur les systèmes, réseaux, serveurs, marque, réputation ou sécurité de CRYSTAL IPTV ; ou",
  es: "(e) hacer cualquier cosa que pueda tener un efecto negativo en los sistemas, redes, servidores, marca, reputación o seguridad de CRYSTAL IPTV; o",
  de: "(e) etwas tun, was negative Auswirkungen auf die Systeme, Netzwerke, Server, Marke, Reputation oder Sicherheit von CRYSTAL IPTV haben könnte; oder",
  it: "(e) fare qualsiasi cosa che potrebbe avere un effetto negativo sui sistemi, reti, server, marca, reputazione o sicurezza di CRYSTAL IPTV; o",
  tr: "(e) CRYSTAL IPTV sistemleri, ağları, sunucuları, marka, itibar veya güvenliği üzerinde olumsuz etkisi olabilecek herhangi bir şey yapmak; veya",
  ru: "(е) предпринимать действия, которые могут оказать негативное воздействие на системы, сети, серверы, бренд, репутацию или безопасность CRYSTAL IPTV; или",
  pl: "(e) podejmować działania, które mogą mieć negatywny wpływ na systemy, sieci, serwery, markę, reputację lub bezpieczeństwo CRYSTAL IPTV; lub",
};
export const subscriberProhibitedActivityF = {
  en: "(f) do anything which might have a negative effect on any other Subscriber or user of any CRYSTAL IPTV service.",
  fr: "(f) faire quelque chose qui pourrait avoir un effet négatif sur tout autre abonné ou utilisateur d'un service CRYSTAL IPTV.",
  es: "(f) hacer algo que pueda tener un efecto negativo en cualquier otro suscriptor o usuario de cualquier servicio de CRYSTAL IPTV.",
  de: "(f) etwas tun, was sich negativ auf andere Abonnenten oder Benutzer eines CRYSTAL IPTV-Dienstes auswirken könnte.",
  it: "(f) fare qualsiasi cosa che potrebbe avere un effetto negativo su qualsiasi altro abbonato o utente di qualsiasi servizio CRYSTAL IPTV.",
  tr: "(f) CRYSTAL IPTV hizmetinin herhangi bir diğer Abone veya kullanıcısına olumsuz etki yapabilecek bir şey yapmak.",
  ru: "(f) предпринимать действия, которые могут оказать негативное воздействие на других абонентов или пользователей любого сервиса CRYSTAL IPTV.",
  pl: "(f) podejmować działania, które mogą mieć negatywny wpływ na innych Abonentów lub użytkowników jakiegokolwiek usługi CRYSTAL IPTV.",
};
export const subscriberTerminationPolicy = {
  en: "7.4. CRYSTAL IPTV reserves the right to immediately terminate, suspend or restrict access to the Subscription Services (or any part of them) for any Subscriber, if the Subscriber engages in any of the above activities (set out in section 7.3 above) or in any unlawful activity or otherwise uses the Subscription Services or any part of them unlawfully.",
  fr: "7.4. CRYSTAL IPTV se réserve le droit de résilier, suspendre ou restreindre immédiatement l'accès aux Services d'abonnement (ou à toute partie de ceux-ci) pour tout abonné qui se livre à l'une des activités susmentionnées (telles qu'elles sont définies à la section 7.3 ci-dessus) ou à une activité illégale, ou qui utilise autrement les Services d'abonnement ou toute partie de ceux-ci de manière illégale.",
  es: "7.4. CRYSTAL IPTV se reserva el derecho de terminar, suspender o restringir inmediatamente el acceso a los Servicios de suscripción (o a cualquier parte de ellos) para cualquier suscriptor, si el suscriptor participa en cualquiera de las actividades mencionadas anteriormente (establecidas en la sección 7.3 anterior) o en cualquier actividad ilegal o utiliza de otra manera los Servicios de suscripción o cualquier parte de ellos de manera ilegal.",
  de: "7.4. CRYSTAL IPTV behält sich das Recht vor, den Zugang zu den Abonnementdiensten (oder Teilen davon) für jeden Abonnenten unverzüglich zu kündigen, zu sperren oder einzuschränken, wenn der Abonnent an einer der oben genannten Aktivitäten (wie in Abschnitt 7.3 oben dargelegt) oder an einer rechtswidrigen Aktivität teilnimmt oder die Abonnementdienste oder Teile davon auf rechtswidrige Weise verwendet.",
  it: "7.4. CRYSTAL IPTV si riserva il diritto di terminare, sospendere o limitare immediatamente l'accesso ai Servizi di abbonamento (o a parte di essi) per qualsiasi abbonato che si impegni in una delle attività sopra descritte (come indicate nella sezione 7.3 sopra) o in attività illegali o utilizzi in altro modo i Servizi di abbonamento o parte di essi in modo illegale.",
  tr: "7.4. CRYSTAL IPTV, Abone'nin yukarıda belirtilen faaliyetlerden herhangi birini (yukarıda 7.3 bölümünde belirtildiği gibi) veya yasa dışı herhangi bir faaliyeti sürdürmesi veya Abonelik Hizmetlerini veya onun herhangi bir bölümünü yasa dışı bir şekilde kullanması durumunda, Abonelik Hizmetlerine (veya onun herhangi bir bölümüne) hemen son verme, askıya alma veya erişimi kısıtlama hakkını saklı tutar.",
  ru: "7.4. CRYSTAL IPTV оставляет за собой право немедленно прекратить, приостановить или ограничить доступ к услугам подписки (или их части) для любого абонента, если абонент занимается любой из вышеуказанных деятельностей (как установлено в разделе 7.3 выше) или осуществляет какую-либо незаконную деятельность или иным образом использует услуги подписки или их часть незаконно.",
  pl: "7.4. CRYSTAL IPTV zastrzega sobie prawo do natychmiastowego rozwiązania, zawieszenia lub ograniczenia dostępu do Usług subskrypcji (lub ich części) dla dowolnego Abonenta, jeśli Abonent angażuje się w którąkolwiek z powyższych działań (określonych w sekcji 7.3 powyżej) lub w jakąkolwiek działalność niezgodną z prawem lub w inny sposób korzysta z Usług subskrypcji lub ich części w sposób niezgodny z prawem.",
};
export const subscriberSecurityResponsibility = {
  en: "7.5. Each Subscriber bears sole responsibility for the security and confidentiality of their subscription-related identification data used to log into their account, password(s) and personal identification number, and is obliged to ensure that this information is not disclosed to any third party. Each Subscriber is solely responsible for all activities that take place on their Subscription Services account, regardless of whether these activities are carried out with the consent and / or knowledge of the Subscriber. The Subscriber is obliged to immediately notify CRYSTAL IPTV in writing of any breach of the security of their identification data related to the Subscription Services used to log into their account, passwords and personal identification number and immediately to take any action reasonably recommended by CRYSTAL IPTV to resolve such matters. CRYSTAL IPTV is not responsible for any losses resulting from unauthorized access to the Subscriber’s account or unauthorized use of the Subscriber’s account or any Subscription Pass.",
  fr: "7.5. Chaque abonné est seul responsable de la sécurité et de la confidentialité de ses données d’identification liées à l’abonnement utilisées pour se connecter à son compte, de ses mots de passe et de son numéro d’identification personnel, et est tenu de veiller à ce que ces informations ne soient pas divulguées à des tiers. Chaque abonné est seul responsable de toutes les activités qui ont lieu sur son compte de services d’abonnement, qu’elles soient ou non effectuées avec le consentement et / ou la connaissance de l’abonné. L’abonné est tenu de notifier immédiatement par écrit à CRYSTAL IPTV toute violation de la sécurité de ses données d’identification liées aux services d’abonnement utilisées pour se connecter à son compte, ses mots de passe et son numéro d’identification personnel, et de prendre immédiatement toute mesure raisonnablement recommandée par CRYSTAL IPTV pour résoudre de telles questions. CRYSTAL IPTV n’est pas responsable des pertes résultant d’un accès non autorisé au compte de l’abonné ou de l’utilisation non autorisée du compte de l’abonné ou de tout pass d’abonnement.",
  es: "7.5. Cada suscriptor es el único responsable de la seguridad y confidencialidad de los datos de identificación relacionados con su suscripción que se utilizan para acceder a su cuenta, las contraseñas y el número de identificación personal, y está obligado a garantizar que esta información no se divulgue a terceros. Cada suscriptor es el único responsable de todas las actividades que se llevan a cabo en su cuenta de Servicios de suscripción, independientemente de si estas actividades se realizan con el consentimiento y / o conocimiento del suscriptor. El suscriptor está obligado a notificar inmediatamente por escrito a CRYSTAL IPTV cualquier violación de la seguridad de sus datos de identificación relacionados con los Servicios de suscripción utilizados para acceder a su cuenta, contraseñas y número de identificación personal, y a tomar de inmediato cualquier medida razonablemente recomendada por CRYSTAL IPTV para resolver tales cuestiones. CRYSTAL IPTV no es responsable de las pérdidas resultantes de un acceso no autorizado a la cuenta del suscriptor o del uso no autorizado de la cuenta del suscriptor o de cualquier pase de suscripción.",
  de: "7.5. Jeder Abonnent trägt die alleinige Verantwortung für die Sicherheit und Vertraulichkeit seiner abonnementbezogenen Identifikationsdaten, die zum Einloggen in sein Konto, seine Passwörter und seine persönliche Identifikationsnummer verwendet werden, und ist verpflichtet sicherzustellen, dass diese Informationen nicht an Dritte weitergegeben werden. Jeder Abonnent ist allein verantwortlich für alle Aktivitäten, die auf seinem Konto für Abonnementdienste stattfinden, unabhängig davon, ob diese Aktivitäten mit Zustimmung und / oder Wissen des Abonnenten durchgeführt werden. Der Abonnent ist verpflichtet, CRYSTAL IPTV unverzüglich schriftlich über jede Verletzung der Sicherheit seiner Identifikationsdaten im Zusammenhang mit den Abonnementdiensten, die zur Anmeldung an sein Konto, Passwörter und persönliche Identifikationsnummer verwendet werden, zu informieren und sofort alle von CRYSTAL IPTV vernünftigerweise empfohlenen Maßnahmen zur Behebung solcher Angelegenheiten zu ergreifen. CRYSTAL IPTV haftet nicht für Verluste, die aus unbefugtem Zugriff auf das Konto des Abonnenten oder unbefugter Nutzung des Kontos des Abonnenten oder eines Abonnementpasses resultieren.",
  it: "7.5. Ciascun abbonato è l'unico responsabile della sicurezza e della riservatezza dei dati di identificazione correlati all'abbonamento utilizzati per accedere al proprio account, delle password e del numero di identificazione personale e è obbligato a garantire che tali informazioni non vengano divulgate a terzi. Ciascun abbonato è l'unico responsabile di tutte le attività che si svolgono sul proprio account dei Servizi di abbonamento, indipendentemente dal fatto che tali attività siano svolte con il consenso e / o la conoscenza dell'abbonato. L'abbonato è tenuto a notificare immediatamente per iscritto a CRYSTAL IPTV qualsiasi violazione della sicurezza dei dati di identificazione correlati ai Servizi di abbonamento utilizzati per accedere al proprio account, password e numero di identificazione personale e a intraprendere immediatamente qualsiasi azione ragionevolmente raccomandata da CRYSTAL IPTV per risolvere tali questioni. CRYSTAL IPTV non è responsabile di eventuali perdite derivanti dall'accesso non autorizzato all'account dell'abbonato o dall'uso non autorizzato dell'account dell'abbonato o di un qualsiasi Abbonamento.",
  tr: "7.5. Her bir Abone, hesaplarına giriş yapmak için kullanılan abonelikle ilgili kimlik bilgilerinin, şifre(ler) ve kişisel kimlik numaralarının güvenliği ve gizliliğinden yalnızca kendisi sorumludur ve bu bilgilerin herhangi bir üçüncü tarafa ifşa edilmemesini sağlamakla yükümlüdür. Her bir Abone, Abonelik Hizmetleri hesaplarında gerçekleşen tüm faaliyetlerden yalnızca kendisi sorumludur, bu faaliyetler Abone'nin izni ve / veya bilgisi dahilinde gerçekleştirilmiş olsun veya olmasın. Abone, Abonelik Hizmetleri ile ilgili kimlik bilgilerinin güvenliğinde meydana gelen herhangi bir ihlali derhal yazılı olarak CRYSTAL IPTV'ye bildirmek ve CRYSTAL IPTV tarafından önerilen makul herhangi bir önlemi derhal almakla yükümlüdür. CRYSTAL IPTV, Abone'nin hesabına yetkisiz erişim veya Abone'nin hesabının yetkisiz kullanımı veya herhangi bir Abonelik Geçişinin neden olduğu kayıplardan sorumlu değildir.",
  ru: "7.5. Каждый абонент несет исключительную ответственность за безопасность и конфиденциальность своих данных, относящихся к подписке, используемых для входа в свою учетную запись, паролей и персонального идентификационного номера, и обязан обеспечивать, чтобы эта информация не разглашалась третьим лицам. Каждый абонент несет полную ответственность за все действия, совершенные на его учетной записи услуг подписки, независимо от того, совершались ли эти действия с согласия и / или сведения абонента. Абонент обязан немедленно уведомить CRYSTAL IPTV в письменной форме о нарушении безопасности своих данных, относящихся к услугам подписки, используемых для входа в его учетную запись, паролей и персонального идентификационного номера, и немедленно предпринять любые разумные меры, рекомендованные CRYSTAL IPTV, для урегулирования таких вопросов. CRYSTAL IPTV не несет ответственности за убытки, возникшие вследствие несанкционированного доступа к учетной записи абонента или несанкционированного использования учетной записи абонента или любого абонемента.",
  pl: "7.5. Każdy Abonent ponosi wyłączną odpowiedzialność za bezpieczeństwo i poufność danych związanych z subskrypcją, używanych do logowania się na swoje konto, hasła (hasła) i numeru identyfikacji osobistej, oraz ma obowiązek zapewnić, aby te informacje nie były udostępniane osobom trzecim. Każdy Abonent ponosi wyłączną odpowiedzialność za wszystkie działania, które mają miejsce na jego koncie usług subskrypcyjnych, niezależnie od tego, czy te działania są podejmowane zgodnie z zgodą i / lub wiedzą Abonenta. Abonent ma obowiązek niezwłocznie powiadomić CRYSTAL IPTV na piśmie o naruszeniach bezpieczeństwa danych identyfikacyjnych związanych z usługami subskrypcyjnymi, używanych do logowania się na swoje konto, hasła i numeru identyfikacji osobistej, oraz podjąć niezwłocznie wszelkie działania zalecane przez CRYSTAL IPTV w celu rozwiązania takich problemów. CRYSTAL IPTV nie ponosi odpowiedzialności za straty wynikłe z nieautoryzowanego dostępu do konta Abonenta lub nieautoryzowanego użytkowania konta Abonenta lub dowolnego Abonamentu.",
};
export const identificationDataRights = {
  en: "7.6. CRYSTAL IPTV reserves the right, in its sole discretion, to refuse, amend or delete identification data relating to the Subscription Services used to log in to the account, password(s) and personal identification number which CRYSTAL IPTV considers inappropriate or offensive.",
  fr: "7.6. CRYSTAL IPTV se réserve le droit, à sa seule discrétion, de refuser, de modifier ou de supprimer les données d’identification relatives aux Services de souscription utilisées pour se connecter au compte, les mots de passe et le numéro d’identification personnel que CRYSTAL IPTV considère comme inappropriés ou offensants.",
  es: "7.6. CRYSTAL IPTV se reserva el derecho, a su exclusiva discreción, de rechazar, modificar o eliminar los datos de identificación relacionados con los Servicios de suscripción utilizados para acceder a la cuenta, las contraseñas y el número de identificación personal que CRYSTAL IPTV considere inapropiados u ofensivos.",
  de: "7.6. CRYSTAL IPTV behält sich das Recht vor, nach eigenem Ermessen, die Identifikationsdaten in Bezug auf die Abonnementdienste, die zur Anmeldung am Konto, den Passwörtern und der persönlichen Identifikationsnummer verwendet werden, abzulehnen, zu ändern oder zu löschen, wenn CRYSTAL IPTV sie für unangemessen oder beleidigend hält.",
  it: "7.6. CRYSTAL IPTV si riserva il diritto, a sua esclusiva discrezione, di rifiutare, modificare o eliminare i dati di identificazione relativi ai Servizi di abbonamento utilizzati per accedere all'account, le password e il numero di identificazione personale che CRYSTAL IPTV ritiene inappropriati o offensivi.",
  tr: "7.6. CRYSTAL IPTV, kendi takdirine bağlı olarak, hesaba giriş yapmak için kullanılan Abonelik Hizmetleri ile ilgili kimlik verilerini, şifre(ler) ve kişisel kimlik numarasını reddetme, değiştirme veya silme hakkını saklı tutar ve CRYSTAL IPTV tarafından uygun veya saldırgan olarak kabul edilen verileri kapsar.",
  ru: "7.6. CRYSTAL IPTV оставляет за собой право по собственному усмотрению отказать, изменить или удалить данные идентификации, относящиеся к услугам подписки, используемые для входа в учетную запись, пароли и персональный идентификационный номер, которые CRYSTAL IPTV считает неуместными или оскорбительными.",
  pl: "7.6. CRYSTAL IPTV zastrzega sobie prawo, według własnego uznania, do odmowy, zmiany lub usunięcia danych identyfikacyjnych dotyczących usług subskrypcji używanych do logowania się na konto, haseł i numeru identyfikacji osobistej, które CRYSTAL IPTV uważa za nieodpowiednie lub obraźliwe.",
};
export const subscriberResponsibility7 = {
  en: "7.7. Each Subscriber bears sole responsibility for any and all fees for Internet, mobile or broadband access, use of data, cellular charges, data allowances and / or other costs incurred for the purchase, access to and use of the Subscription Services (or any part thereof).",
  fr: "7.7. Chaque Abonné assume la pleine responsabilité de tous les frais liés à l'accès à Internet, à la téléphonie mobile ou à large bande, à l'utilisation des données, aux frais de téléphonie cellulaire, aux forfaits de données et / ou à d'autres coûts engagés pour l'achat, l'accès et l'utilisation des Services de souscription (ou de toute partie de ceux-ci).",
  es: "7.7. Cada Abonado asume la responsabilidad exclusiva de todos los cargos por acceso a Internet, móvil o banda ancha, uso de datos, cargos de telefonía celular, asignaciones de datos y / u otros costos incurridos en la compra, acceso y uso de los Servicios de suscripción (o cualquier parte de ellos).",
  de: "7.7. Jeder Abonnent trägt die alleinige Verantwortung für sämtliche Gebühren für Internet-, Mobilfunk- oder Breitbandzugang, Nutzung von Daten, Mobilfunkgebühren, Datenguthaben und / oder andere Kosten, die für den Kauf, den Zugang und die Nutzung der Abonnementdienste (oder eines Teils davon) anfallen.",
  it: "7.7. Ciascun Abbonato è responsabile esclusivamente di tutte le tariffe per l'accesso a Internet, mobile o a banda larga, l'utilizzo dei dati, le spese cellulari, le concessioni di dati e / o altri costi sostenuti per l'acquisto, l'accesso e l'utilizzo dei Servizi di abbonamento (o di parte di essi).",
  tr: "7.7. Her bir Abone, İnternet, mobil veya geniş bant erişimi, veri kullanımı, cep telefonu ücretleri, veri izinleri ve / veya Abonelik Hizmetleri'nin satın alınması, erişilmesi ve kullanılması için yapılan diğer masraflar dahil olmak üzere tüm ücretlerden yalnızca kendisi sorumludur.",
  ru: "7.7. Каждый абонент несет исключительную ответственность за все платежи за доступ в Интернет, мобильную или широкополосную связь, использование данных, мобильные расходы, лимиты данных и / или другие расходы, понесенные при покупке, доступе и использовании услуг подписки (или их части).",
  pl: "7.7. Każdy Abonent ponosi wyłączną odpowiedzialność za wszelkie opłaty za dostęp do Internetu, komórkę lub szerokopasmowy dostęp, korzystanie z danych, opłaty za usługi komórkowe, limity danych i / lub inne koszty poniesione w związku z zakupem, dostępem i korzystaniem z Usług subskrypcji (lub ich części).",
};
