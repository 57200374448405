import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";

// import css
import "../../authentication/styles/reset_password.css";

// import images
import offline_icon from "../images/offline_icon.svg";

// import paragraphs
import * as int from "../../paragraphs/you_are_offline_paragraph";

function YouAreOffline() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);
  return (
    <div className={wrapperClass}>
      <div className="reset_email_sent">
        {/* in the following img i made the styling inline cause it inherits from reset_password css file
        and it would be unecessary to create another css file just for one single class  */}
        <img src={offline_icon} alt="offline icon" style={{ height: "40px" }} />
        <h1>{int.connectionOffline[displayLanguage]}</h1>
        <p>{int.checkYourConnection[displayLanguage]}</p>
      </div>
    </div>
  );
}

export default YouAreOffline;
