export const escapeIntoImaginary = {
  en: "Escape into imaginary worlds with our wide selection of anime and animations",
  fr: "Évadez-vous dans des mondes imaginaires avec notre large sélection d'animes et d'animations",
  es: "Escapa a mundos imaginarios con nuestra amplia selección de anime y animaciones",
  de: "Entfliehe in imaginäre Welten mit unserer großen Auswahl an Anime und Animationen",
  it: "Scappate in mondi immaginari con la nostra ampia selezione di anime e animazioni",
  tr: "Geniş anime ve animasyon seçeneğimizle hayali dünyalara kaçın",
  ru: "Погрузитесь в вымышленные миры с нашим широким выбором аниме и анимаций",
  pl: "Ucieknij do wyobraźni z naszym szerokim wyborem anime i animacji",
};
export const animatedTreasure = {
  en: "Unlock a treasure trove of animated movies and series, tailor-made for your little ones!",
  fr: "Déverrouillez un trésor de films et de séries animés, spécialement conçus pour vos petits !",
  es: "¡Descubre un tesoro de películas y series animadas, hechas a medida para los más pequeños!",
  de: "Entdecke einen Schatz an animierten Filmen und Serien, maßgeschneidert für die Kleinen!",
  it: "Sblocca un tesoro di film e serie animate, su misura per i più piccoli!",
  tr: "Küçükleriniz için özel olarak hazırlanmış bir hazine dolusu animasyon film ve diziyi keşfedin!",
  ru: "Откройте сокровищницу анимационных фильмов и сериалов, специально созданных для ваших малышей!",
  pl: "Odkryj skarbnicę filmów i seriali animowanych, specjalnie stworzonych dla twoich pociech!",
};

export const cartoonNetworks = {
  en: "Imaginative, Educational Entertainment for Kids. High-quality animation, positive values, and interactive experiences. Join us for laughter and learning!",
  fr: "Divertissement imaginatif et éducatif pour les enfants. Animation de haute qualité, valeurs positives et expériences interactives. Rejoignez-nous pour rire et apprendre !",
  es: "Entretenimiento imaginativo y educativo para niños. Animación de alta calidad, valores positivos y experiencias interactivas. ¡Únete a nosotros para risas y aprendizaje!",
  de: "Einfallsreiche, pädagogische Unterhaltung für Kinder. Hochwertige Animation, positive Werte und interaktive Erlebnisse. Begleite uns für Lachen und Lernen!",
  it: "Intrattenimento immaginativo ed educativo per bambini. Animazione di alta qualità, valori positivi ed esperienze interattive. Unisciti a noi per risate e apprendimento!",
  tr: "Çocuklar için Hayal Gücü ve Eğitici Eğlence. Yüksek kaliteli animasyon, olumlu değerler ve etkileşimli deneyimler. Gelin, birlikte gülmek ve öğrenmek için katılın!",
  ru: "Воображение, образовательное развлечение для детей. Анимация высокого качества, позитивные ценности и интерактивные впечатления. Присоединяйтесь к нам для смеха и обучения!",
  pl: "Pomysłowa, edukacyjna rozrywka dla dzieci. Animacja wysokiej jakości, pozytywne wartości i interaktywne doświadczenia. Dołącz do nas na śmiech i naukę!",
};

export const disneyChannel = {
  en: "Step into a world of enchantment and adventure on Disney Channel. Experience captivating storytelling, beloved characters, and inspiring lessons that entertain and inspire the whole family. Get ready for a magical journey!",
  fr: "Plongez dans un monde enchanté et rempli d'aventures sur Disney Channel. Découvrez des récits captivants, des personnages aimés de tous et des leçons inspirantes qui divertissent et motivent toute la famille. Préparez-vous pour un voyage magique !",
  es: "Adéntrate en un mundo de encanto y aventuras en Disney Channel. Vive narraciones cautivadoras, personajes queridos y lecciones inspiradoras que entretienen e inspiran a toda la familia. ¡Prepárate para un viaje mágico!",
  de: "Betrete eine Welt voller Zauber und Abenteuer auf dem Disney Channel. Erlebe fesselnde Geschichten, geliebte Charaktere und inspirierende Lektionen, die die ganze Familie unterhalten und inspirieren. Mach dich bereit für eine magische Reise!",
  it: "Entra in un mondo di incanto e avventura su Disney Channel. Vivi narrazioni avvincenti, personaggi amati e insegnamenti ispiratori che intrattengono e motivano tutta la famiglia. Preparati per un viaggio magico!",
  tr: "Disney Channel'da büyülü bir dünyaya adım atın ve maceraya katılın. Sürükleyici hikayeler, sevilen karakterler ve tüm aileyi eğlendiren ve ilham veren derslerin keyfini çıkarın. Sihirli bir yolculuğa hazır olun!",
  ru: "Окунитесь в мир волшебства и приключений на канале Disney. Почувствуйте завораживающую сказочную атмосферу, полюбившихся персонажей и вдохновляющие уроки, которые развлекают и вдохновляют всю семью. Готовьтесь к волшебному путешествию!",
  pl: "Wejdź do świata czarów i przygód na kanale Disney Channel. Doświadcz fascynujących opowieści, ukochanych postaci i inspirujących lekcji, które bawią i inspirują całą rodzinę. Przygotuj się na magiczną podróż!",
};
export const cbeebies = {
  en: "Join us on CBeebies, the ultimate destination for young learners! Explore exciting worlds, embark on educational adventures, and discover the joy of learning through captivating shows. Let curiosity come alive!",
  fr: "Rejoignez-nous sur CBeebies, la destination ultime des jeunes apprenants ! Explorez des mondes passionnants, embarquez pour des aventures éducatives et découvrez la joie d'apprendre à travers des émissions captivantes. Laissez la curiosité s'épanouir !",
  es: "¡Únete a nosotros en CBeebies, el destino definitivo para los jóvenes aprendices! Explora mundos emocionantes, emprende aventuras educativas y descubre la alegría de aprender a través de programas cautivadores. ¡Deja que la curiosidad cobre vida!",
  de: "Sei dabei auf CBeebies, dem ultimativen Ziel für junge Lernende! Erkunde aufregende Welten, gehe auf lehrreiche Abenteuer und entdecke die Freude am Lernen durch fesselnde Shows. Lass die Neugier zum Leben erwachen!",
  it: "Unisciti a noi su CBeebies, la destinazione ultima per giovani apprendisti! Esplora mondi entusiasmanti, intraprendi avventure educative e scopri la gioia dell'apprendimento attraverso spettacoli avvincenti. Lascia che la curiosità prende vita!",
  tr: "CBeebies'te genç öğreniciler için en iyi adresimize katılın! Heyecan verici dünyaları keşfedin, eğitici maceralara atılın ve sürükleyici programlar aracılığıyla öğrenmenin keyfini keşfedin. Merakınız canlansın!",
  ru: "Присоединяйтесь к нам на CBeebies - идеальном месте для молодых учащихся! Исследуйте захватывающие миры, отправляйтесь в образовательные приключения и откройте радость обучения через завораживающие шоу. Пусть любопытство оживет!",
  pl: "Dołącz do nas na CBeebies, ostatecznym miejscu dla młodych uczących się! Poznaj ekscytujące światy, wyrusz w edukacyjne przygody i odkrywaj radość nauki poprzez wciągające programy. Ożyj swoją ciekawość!",
};

export const boomerang = {
  en: "Relive cherished childhood memories and introduce timeless cartoons to a new generation on Boomerang! From beloved characters to iconic shows, we bring the best of the past to create everlasting smiles today.",
  fr: "Revivez les souvenirs d'enfance chéris et faites découvrir des dessins animés intemporels à une nouvelle génération sur Boomerang ! Des personnages adorés aux émissions emblématiques, nous apportons le meilleur du passé pour créer des sourires éternels aujourd'hui.",
  es: "Revive los recuerdos de la infancia queridos y presenta dibujos animados atemporales a una nueva generación en Boomerang. Desde personajes queridos hasta programas icónicos, llevamos lo mejor del pasado para crear sonrisas eternas hoy.",
  de: "Erlebe geliebte Kindheitserinnerungen wieder und führe zeitlose Cartoons einer neuen Generation auf Boomerang ein! Von beliebten Charakteren bis hin zu ikonischen Shows bringen wir das Beste aus der Vergangenheit, um heute unvergessliche Lächeln zu schaffen.",
  it: "Rivivi i ricordi d'infanzia più cari e introduce cartoni animati senza tempo a una nuova generazione su Boomerang! Dai personaggi amati ai programmi iconici, portiamo il meglio del passato per creare sorrisi eterni oggi.",
  tr: "Boomerang'da sevgiyle hatırlanan çocukluk anılarını yeniden yaşayın ve zamansız çizgi filmleri yeni bir nesle tanıtın! Sevilen karakterlerden ikonik şovlara kadar, geçmişin en iyisini bugünün unutulmaz gülümsemelerini yaratmak için sunuyoruz.",
  ru: "Воскресите дорогие воспоминания о детстве и познакомьте новое поколение с вечными мультфильмами на Boomerang! От любимых персонажей до культовых шоу, мы приносим лучшее из прошлого, чтобы создать вечные улыбки сегодня.",
  pl: "Przywróć ukochane wspomnienia z dzieciństwa i wprowadź niewymierne kreskówki nowemu pokoleniu na Boomerang! Od ukochanych postaci po ikoniczne programy, przynosimy to, co najlepsze z przeszłości, aby dziś stworzyć niezatarte uśmiechy.",
};

export const nickelodeon = {
  en: "Enter a world of endless fun and imagination with Nickelodeon! From hilarious comedies to thrilling adventures, we bring you the best in kids' entertainment. Get ready for non-stop laughter and unforgettable moments!",
  fr: "Entrez dans un monde de divertissement et d'imagination sans fin avec Nickelodeon ! Des comédies hilarantes aux aventures palpitantes, nous vous proposons le meilleur du divertissement pour enfants. Préparez-vous à des éclats de rire et à des moments inoubliables sans fin !",
  es: "¡Adéntrate en un mundo de diversión e imaginación infinita con Nickelodeon! Desde comedias divertidísimas hasta emocionantes aventuras, te ofrecemos lo mejor en entretenimiento para niños. ¡Prepárate para risas sin parar y momentos inolvidables!",
  de: "Tauche ein in eine Welt voller Spaß und Vorstellungskraft mit Nickelodeon! Von lustigen Komödien bis hin zu aufregenden Abenteuern bringen wir dir das Beste im Kinderunterhaltung. Mach dich bereit für lachende Gesichter und unvergessliche Momente!",
  it: "Entra in un mondo di divertimento e immaginazione senza fine con Nickelodeon! Dalle commedie esilaranti alle avventure emozionanti, ti offriamo il meglio dell'intrattenimento per bambini. Preparati a risate senza sosta e momenti indimenticabili!",
  tr: "Nickelodeon ile sonsuz eğlence ve hayal dünyasına adım atın! Komik komedilerden heyecan dolu maceralara kadar, çocuk eğlencesinde en iyisini sunuyoruz. Sürekli gülme ve unutulmaz anlar için hazır olun!",
  ru: "Войдите в мир бесконечного веселья и воображения с Nickelodeon! От забавных комедий до захватывающих приключений, мы приносим вам лучшее в развлечениях для детей. Приготовьтесь к бесконечному смеху и незабываемым моментам!",
  pl: "Wejdź do świata niekończącej się zabawy i wyobraźni z Nickelodeon! Od komicznych komedii po ekscytujące przygody, przynosimy ci najlepsze rozrywki dla dzieci. Przygotuj się na nieustający śmiech i niezapomniane chwile!",
};

export const providingSubtitles = {
  en: "Providing Subtitles In Every European Language.",
  fr: "Fourniture de sous-titres dans toutes les langues européennes.",
  es: "Proporcionando subtítulos en todos los idiomas europeos.",
  de: "Bereitstellung von Untertiteln in allen europäischen Sprachen.",
  it: "Fornitura di sottotitoli in tutte le lingue europee.",
  tr: "Avrupa'daki tüm dillerde altyazı sağlama.",
  ru: "Предоставление субтитров на всех европейских языках.",
  pl: "Dostarczanie napisów w każdym języku europejskim.",
};
export const kidsChannelsHelmet = {
  en: "Kids channels, Animations, Cartoons and much more!",
  fr: "Chaînes pour enfants, animations, dessins animés et bien plus encore !",
  es: "Canales infantiles, animaciones, dibujos animados y mucho más!",
  de: "Kinderkanäle, Animationen, Cartoons und vieles mehr!",
  it: "Canali per bambini, Animazioni, Cartoni animati e molto altro!",
  tr: "Çocuk kanalları, Animasyonlar, Çizgi filmler ve daha fazlası!",
  ru: "Детские каналы, анимации, мультфильмы и многое другое!",
  pl: "Kanały dla dzieci, Animacje, Kreskówki i wiele więcej!",
};
export const helmetDescription = {
  en: "Discover the wonders of Crystal IPTV, where you'll find a wide range of animated series, movies, cartoons, and anime tailored for young viewers. Immerse yourself in a vibrant collection of content designed to captivate and entertain kids of all ages. With Crystal IPTV, you can enjoy the enchanting world of children's entertainment in stunning HD and 4K quality. Stream these delightful shows and movies on any device, all at an affordable price. Elevate your child's entertainment experience today!",
  fr: "Découvrez les merveilles de Crystal IPTV, où vous trouverez une vaste gamme de séries animées, de films, de dessins animés et d'animes spécialement conçus pour les jeunes téléspectateurs. Plongez-vous dans une collection dynamique de contenus conçus pour captiver et divertir les enfants de tous âges. Avec Crystal IPTV, vous pouvez profiter du monde enchanteur du divertissement pour enfants en qualité HD et 4K époustouflante. Diffusez ces émissions et films enchanteurs sur n'importe quel appareil, le tout à un prix abordable. Rehaussez dès aujourd'hui l'expérience de divertissement de votre enfant !",
  es: "Descubre las maravillas de Crystal IPTV, donde encontrarás una amplia gama de series animadas, películas, dibujos animados y animes diseñados para los jóvenes espectadores. Sumérgete en una vibrante colección de contenidos diseñados para cautivar y entretener a niños de todas las edades. Con Crystal IPTV, puedes disfrutar del mundo encantador del entretenimiento infantil en calidad HD y 4K impresionante. Transmite estos programas y películas encantadoras en cualquier dispositivo, todo a un precio asequible. ¡Eleva la experiencia de entretenimiento de tu hijo hoy!",
  de: "Entdecken Sie die Wunder von Crystal IPTV, wo Sie eine breite Palette von animierten Serien, Filmen, Cartoons und Animes finden, die speziell für junge Zuschauer zugeschnitten sind. Tauchen Sie ein in eine lebhafte Sammlung von Inhalten, die darauf abzielen, Kinder jeden Alters zu faszinieren und zu unterhalten. Mit Crystal IPTV können Sie die bezaubernde Welt des Kinderentertainments in atemberaubender HD- und 4K-Qualität genießen. Streamen Sie diese zauberhaften Shows und Filme auf jedem Gerät, und das alles zu einem erschwinglichen Preis. Verbessern Sie noch heute das Unterhaltungserlebnis Ihres Kindes!",
  it: "Scopri le meraviglie di Crystal IPTV, dove troverai una vasta gamma di serie animate, film, cartoni animati e anime pensati per i giovani spettatori. Immergiti in una vibrante collezione di contenuti progettati per catturare ed intrattenere bambini di tutte le età. Con Crystal IPTV, puoi goderti l'affascinante mondo dell'intrattenimento per bambini in splendida qualità HD e 4K. Streaming di questi spettacoli e film incantevoli su qualsiasi dispositivo, il tutto a un prezzo accessibile. Eleva oggi stesso l'esperienza di intrattenimento del tuo bambino!",
  tr: "Crystal IPTV'nin harikalarını keşfedin, genç izleyiciler için özel olarak tasarlanmış çeşitli animasyon serileri, filmler, çizgi filmler ve animeleri bulacağınız yer. Tüm yaşlardaki çocukları büyülemek ve eğlendirmek için tasarlanmış içeriklerin canlı bir koleksiyonunda kendinizi kaybedin. Crystal IPTV ile çocukların eğlencesine dair büyüleyici dünyanın tadını çıkarabilirsiniz, hepsi HD ve 4K kalitesinde. Bu sevimli gösterileri ve filmleri herhangi bir cihazda izleyin, hepsi uygun bir fiyata. Çocuğunuzun eğlence deneyimini bugün yükseltin!",
  ru: "Откройте для себя чудеса Crystal IPTV, где вы найдете широкий выбор анимационных сериалов, фильмов, мультфильмов и аниме, созданных специально для молодых зрителей. Погрузитесь в живую коллекцию контента, созданного, чтобы завораживать и развлекать детей любого возраста. С Crystal IPTV вы можете насладиться волшебным миром детского развлечения в потрясающем HD и 4K качестве. Транслируйте эти прекрасные шоу и фильмы на любом устройстве, и все это по доступной цене. Поднимите сегодняшний уровень развлечения для вашего ребенка!",
  pl: "Odkryj cuda Crystal IPTV, gdzie znajdziesz szeroki wybór seriali animowanych, filmów, kreskówek i anime dostosowanych do młodych widzów. Zanurz się w bogatej kolekcji treści zaprojektowanych, aby zachwycić i bawić dzieci w każdym wieku. Dzięki Crystal IPTV możesz cieszyć się czarującym światem rozrywki dla dzieci w oszałamiającej jakości HD i 4K. Oglądaj te urocze programy i filmy na dowolnym urządzeniu, wszystko to w przystępnej cenie. Podnieś jakość rozrywki swojego dziecka już dziś!",
};
