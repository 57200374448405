export const feelFreeToContactUs = {
  en: "Please feel free to contact us if you have any questions!",
  fr: "N'hésitez pas à nous contacter si vous avez des questions !",
  es: "¡No dude en contactarnos si tiene alguna pregunta!",
  de: "Fühlen Sie sich frei, uns zu kontaktieren, wenn Sie Fragen haben!",
  it: "Non esitate a contattarci se avete domande!",
  tr: "Herhangi bir sorunuz varsa lütfen bize ulaşmaktan çekinmeyin!",
  ru: "Пожалуйста, не стесняйтесь связаться с нами, если у вас есть вопросы!",
  pl: "Prosimy o kontakt, jeśli masz jakiekolwiek pytania!",
};
export const iptvInstallationGuide = {
  en: "IPTV Installation Guide for Windows",
  fr: "Guide d'installation IPTV pour Windows",
  es: "Guía de instalación de IPTV para Windows",
  de: "IPTV Installationsanleitung für Windows",
  it: "Guida all'installazione di IPTV per Windows",
  tr: "Windows için IPTV Kurulum Kılavuzu",
  ru: "Руководство по установке IPTV для Windows",
  pl: "Podręcznik instalacji IPTV dla systemu Windows",
};
export const iptvInstallationSteps = {
  en: "When it comes to using IPTV streams on Windows, Smarters IPTV stands out as one of the best software options available. Follow the steps below to install Smarters IPTV and enjoy our IPTV service seamlessly.",
  fr: "Lorsqu'il s'agit d'utiliser des flux IPTV sur Windows, Smarters IPTV se démarque comme l'une des meilleures options logicielles disponibles. Suivez les étapes ci-dessous pour installer Smarters IPTV et profiter de notre service IPTV sans problème.",
  es: "Cuando se trata de usar transmisiones de IPTV en Windows, Smarters IPTV se destaca como una de las mejores opciones de software disponibles. Siga los pasos a continuación para instalar Smarters IPTV y disfrutar de nuestro servicio de IPTV sin problemas.",
  de: "Wenn es darum geht, IPTV-Streams unter Windows zu nutzen, zeichnet sich Smarters IPTV als eine der besten verfügbaren Softwareoptionen aus. Befolgen Sie die unten stehenden Schritte, um Smarters IPTV zu installieren und unseren IPTV-Service nahtlos zu nutzen.",
  it: "Quando si tratta di utilizzare flussi IPTV su Windows, Smarters IPTV si distingue come una delle migliori opzioni di software disponibili. Seguire i passaggi seguenti per installare Smarters IPTV e godere del nostro servizio IPTV in modo semplice.",
  tr: "Windows'ta IPTV yayınlarını kullanmak söz konusu olduğunda, Smarters IPTV en iyi yazılım seçeneklerinden biri olarak öne çıkar. Smarters IPTV'yi kurmak ve IPTV hizmetimizden sorunsuz bir şekilde yararlanmak için aşağıdaki adımları izleyin.",
  ru: "Когда речь идет о использовании потоков IPTV в Windows, Smarters IPTV выделяется как один из лучших доступных программных вариантов. Следуйте указанным ниже шагам, чтобы установить Smarters IPTV и без проблем пользоваться нашим IPTV-сервисом.",
  pl: "Jeśli chodzi o korzystanie z transmisji IPTV w systemie Windows, Smarters IPTV wyróżnia się jako jedna z najlepszych dostępnych opcji oprogramowania. Postępuj zgodnie z poniższymi krokami, aby zainstalować Smarters IPTV i korzystać z naszej usługi IPTV bezproblemowo.",
};
export const prerequisites = {
  en: "Prerequisites:",
  fr: "Prérequis :",
  es: "Requisitos previos:",
  de: "Voraussetzungen:",
  it: "Prerequisiti:",
  tr: "Gereksinimler:",
  ru: "Предварительные требования:",
  pl: "Wymagania wstępne:",
};
export const installationRequirements = {
  en: "Before proceeding with the installation, ensure that you have:",
  fr: "Avant de procéder à l'installation, assurez-vous d'avoir :",
  es: "Antes de continuar con la instalación, asegúrese de tener:",
  de: "Bevor Sie mit der Installation fortfahren, stellen Sie sicher, dass Sie Folgendes haben:",
  it: "Prima di procedere con l'installazione, assicurati di avere:",
  tr: "Kurulum işlemine devam etmeden önce, şunlara sahip olduğunuzdan emin olun:",
  ru: "Перед тем как продолжить установку, убедитесь, что у вас есть:",
  pl: "Przed przejściem do instalacji upewnij się, że masz:",
};
export const stableInternetConnection = {
  en: "A stable internet connection.",
  fr: "Une connexion Internet stable.",
  es: "Una conexión a Internet estable.",
  de: "Eine stabile Internetverbindung.",
  it: "Una connessione internet stabile.",
  tr: "Dengeli bir internet bağlantısı.",
  ru: "Стабильное интернет-соединение.",
  pl: "Stabilne połączenie internetowe.",
};
export const compatibleWindowsOS = {
  en: "A compatible Windows operating system (Windows 7, 8, 10, or 11).",
  fr: "Un système d'exploitation Windows compatible (Windows 7, 8, 10 ou 11).",
  es: "Un sistema operativo Windows compatible (Windows 7, 8, 10 o 11).",
  de: "Ein kompatibles Windows-Betriebssystem (Windows 7, 8, 10 oder 11).",
  it: "Un sistema operativo Windows compatibile (Windows 7, 8, 10 o 11).",
  tr: "Uyumlu bir Windows işletim sistemi (Windows 7, 8, 10 veya 11).",
  ru: "Совместимая операционная система Windows (Windows 7, 8, 10 или 11).",
  pl: "Zgodny system operacyjny Windows (Windows 7, 8, 10 lub 11).",
};
export const installationSteps = {
  en: "Installation Steps:",
  fr: "Étapes d'installation :",
  es: "Pasos de instalación:",
  de: "Installations Schritte:",
  it: "Passaggi di installazione:",
  tr: "Kurulum Adımları:",
  ru: "Шаги установки:",
  pl: "Kroki instalacji:",
};
export const downloadInstaller = {
  en: "Download Smarters IPTV Installer:",
  fr: "Télécharger l'installateur de Smarters IPTV :",
  es: "Descargar el instalador de Smarters IPTV:",
  de: "Smarters IPTV Installer herunterladen:",
  it: "Scarica l'installatore di Smarters IPTV:",
  tr: "Smarters IPTV Yükleyiciyi İndir:",
  ru: "Загрузите установщик Smarters IPTV:",
  pl: "Pobierz instalator Smarters IPTV:",
};
export const downloadInstructions = {
  en: "Visit the official Smarters IPTV website to download the installation file for Windows or download it from our website.",
  fr: "Visitez le site Web officiel de Smarters IPTV pour télécharger le fichier d'installation pour Windows ou téléchargez-le depuis notre site Web.",
  es: "Visite el sitio web oficial de Smarters IPTV para descargar el archivo de instalación para Windows o descárguelo desde nuestro sitio web.",
  de: "Besuchen Sie die offizielle Smarters IPTV-Website, um die Installationsdatei für Windows herunterzuladen, oder laden Sie sie von unserer Website herunter.",
  it: "Visita il sito web ufficiale di Smarters IPTV per scaricare il file di installazione per Windows o scaricalo dal nostro sito web.",
  tr: "Windows için kurulum dosyasını indirmek için resmi Smarters IPTV web sitesini ziyaret edin veya web sitemizden indirin.",
  ru: "Посетите официальный сайт Smarters IPTV, чтобы загрузить файл установки для Windows, или загрузите его с нашего сайта.",
  pl: "Odwiedź oficjalną stronę internetową Smarters IPTV, aby pobrać plik instalacyjny dla systemu Windows lub pobierz go ze strony internetowej.",
};
export const download = {
  en: "Download",
  fr: "Télécharger",
  es: "Descargar",
  de: "Herunterladen",
  it: "Scarica",
  tr: "İndir",
  ru: "Скачать",
  pl: "Pobierz",
};
export const runInstaller = {
  en: "Run the Installer:",
  fr: "Exécutez l'installateur :",
  es: "Ejecutar el instalador:",
  de: "Führen Sie den Installer aus:",
  it: "Esegui l'installatore:",
  tr: "Yükleyiciyi çalıştırın:",
  ru: "Запустите установщик:",
  pl: "Uruchom instalator:",
};
export const locateInstaller = {
  en: "Once the download is complete, locate the installer file (usually named something like 'SmartersIPTVInstaller.exe').",
  fr: "Une fois le téléchargement terminé, localisez le fichier d'installation (généralement nommé quelque chose comme 'SmartersIPTVInstaller.exe').",
  es: "Una vez que la descarga esté completa, localice el archivo del instalador (generalmente con un nombre similar a 'SmartersIPTVInstaller.exe').",
  de: "Sobald der Download abgeschlossen ist, suchen Sie die Installationsdatei (normalerweise mit einem Namen wie 'SmartersIPTVInstaller.exe').",
  it: "Una volta completato il download, individuare il file dell'installatore (di solito chiamato qualcosa come 'SmartersIPTVInstaller.exe').",
  tr: "İndirme tamamlandıktan sonra yükleyici dosyasını bulun (genellikle 'SmartersIPTVInstaller.exe' gibi bir şey adlandırılır).",
  ru: "После завершения загрузки найдите файл установщика (обычно с именем вроде 'SmartersIPTVInstaller.exe').",
  pl: "Po zakończeniu pobierania zlokalizuj plik instalatora (zwykle o nazwie podobnej do 'SmartersIPTVInstaller.exe').",
};
export const doubleClickToInstall = {
  en: "Double-click on the installer file to begin the installation process.",
  fr: "Double-cliquez sur le fichier d'installation pour démarrer le processus d'installation.",
  es: "Haga doble clic en el archivo del instalador para comenzar el proceso de instalación.",
  de: "Doppelklicken Sie auf die Installationsdatei, um den Installationsvorgang zu starten.",
  it: "Fare doppio clic sul file dell'installatore per avviare il processo di installazione.",
  tr: "Kurulum işlemini başlatmak için yükleyici dosyasına çift tıklayın.",
  ru: "Дважды щелкните по файлу установщика, чтобы начать процесс установки.",
  pl: "Kliknij dwukrotnie plik instalatora, aby rozpocząć proces instalacji.",
};

export const launchSmartersIPTV = {
  en: "Launch Smarters IPTV:",
  fr: "Lancez Smarters IPTV :",
  es: "Inicie Smarters IPTV:",
  de: "Starten Sie Smarters IPTV:",
  it: "Avvia Smarters IPTV:",
  tr: "Smarters IPTV'yi Başlatın:",
  ru: "Запустите Smarters IPTV:",
  pl: "Uruchom Smarters IPTV:",
};

export const installationConfirmation = {
  en: "Once the installation is complete, you will see a confirmation message.",
  fr: "Une fois l'installation terminée, vous verrez un message de confirmation.",
  es: "Una vez que la instalación esté completa, verá un mensaje de confirmación.",
  de: "Sobald die Installation abgeschlossen ist, sehen Sie eine Bestätigungsmeldung.",
  it: "Una volta completata l'installazione, vedrai un messaggio di conferma.",
  tr: "Kurulum tamamlandığında, bir onay mesajı göreceksiniz.",
  ru: "После завершения установки вы увидите сообщение с подтверждением.",
  pl: "Po zakończeniu instalacji pojawi się komunikat potwierdzający.",
};
export const clickFinishButton = {
  en: 'Click on the "Finish" button to exit the installer.',
  fr: 'Cliquez sur le bouton "Terminer" pour quitter l\'installateur.',
  es: 'Haga clic en el botón "Finalizar" para salir del instalador.',
  de: 'Klicken Sie auf die Schaltfläche "Fertig stellen", um den Installationsassistenten zu verlassen.',
  it: 'Fare clic sul pulsante "Fine" per uscire dall\'installatore.',
  tr: 'Yükleyiciden çıkmak için "Bitir" düğmesine tıklayın.',
  ru: 'Нажмите кнопку "Завершить", чтобы закрыть установщик.',
  pl: 'Kliknij przycisk "Zakończ", aby zakończyć działanie instalatora.',
};
export const installationCompleteMessage = {
  en: "Smarters IPTV is now installed on your Windows system.",
  fr: "Smarters IPTV est maintenant installé sur votre système Windows.",
  es: "Smarters IPTV ahora está instalado en su sistema Windows.",
  de: "Smarters IPTV ist jetzt auf Ihrem Windows-System installiert.",
  it: "Smarters IPTV è ora installato sul tuo sistema Windows.",
  tr: "Smarters IPTV şimdi Windows sistemine kuruldu.",
  ru: "Теперь Smarters IPTV установлен на вашей системе Windows.",
  pl: "Smarters IPTV jest teraz zainstalowany na twoim systemie Windows.",
};
export const configureSmartersIPTV = {
  en: "Configure Smarters IPTV:",
  fr: "Configurer Smarters IPTV :",
  es: "Configurar Smarters IPTV:",
  de: "Konfigurieren Sie Smarters IPTV:",
  it: "Configura Smarters IPTV:",
  tr: "Smarters IPTV'yi yapılandırın:",
  ru: "Настройка Smarters IPTV:",
  pl: "Skonfiguruj Smarters IPTV:",
};
export const launchFromShortcut = {
  en: "Launch Smarters IPTV from the desktop shortcut or Start menu.",
  fr: "Lancez Smarters IPTV depuis le raccourci du bureau ou le menu Démarrer.",
  es: "Inicie Smarters IPTV desde el acceso directo del escritorio o el menú de inicio.",
  de: "Starten Sie Smarters IPTV über die Desktop-Verknüpfung oder das Startmenü.",
  it: "Avvia Smarters IPTV dall'icona sul desktop o dal menu Start.",
  tr: "Masaüstü kısayolu veya Başlat menüsünden Smarters IPTV'yi başlatın.",
  ru: "Запустите Smarters IPTV из ярлыка на рабочем столе или меню Пуск.",
  pl: "Uruchom Smarters IPTV za pomocą skrótu na pulpicie lub menu Start.",
};
export const enterCredentials = {
  en: "Enter your IPTV service credentials to authenticate and access the service as shown in the screenshot below.",
  fr: "Entrez vos identifiants de service IPTV pour vous authentifier et accéder au service comme indiqué dans la capture d'écran ci-dessous.",
  es: "Ingrese sus credenciales de servicio de IPTV para autenticarse y acceder al servicio como se muestra en la captura de pantalla a continuación.",
  de: "Geben Sie Ihre IPTV-Dienstzugangsdaten ein, um sich zu authentifizieren und auf den Dienst zuzugreifen, wie im untenstehenden Screenshot gezeigt.",
  it: "Inserisci le credenziali del servizio IPTV per autenticarti e accedere al servizio come mostrato nella schermata sottostante.",
  tr: "Hizmete erişmek için IPTV hizmeti kimlik bilgilerinizi girin ve aşağıdaki ekran görüntüsünde gösterildiği gibi doğrulayın.",
  ru: "Введите учетные данные своего IPTV-сервиса для аутентификации и доступа к сервису, как показано на скриншоте ниже.",
  pl: "Wprowadź dane uwierzytelniające usługi IPTV, aby uwierzytelnić się i uzyskać dostęp do usługi, jak pokazano na poniższym zrzucie ekranu.",
};
export const enjoyStreaming = {
  en: "Enjoy IPTV Streaming:",
  fr: "Profitez du streaming IPTV :",
  es: "Disfruta del streaming de IPTV:",
  de: "Genießen Sie das IPTV-Streaming:",
  it: "Goditi lo streaming IPTV:",
  tr: "IPTV Yayınlarının Keyfini Çıkarın:",
  ru: "Наслаждайтесь потоковым вещанием IPTV:",
  pl: "Ciesz się streamingiem IPTV:",
};
export const enjoyIPTVStreaming = {
  en: "Once configured, you can now enjoy IPTV streaming using Smarters IPTV on your Windows device.",
  fr: "Une fois configuré, vous pouvez maintenant profiter du streaming IPTV en utilisant Smarters IPTV sur votre appareil Windows.",
  es: "Una vez configurado, ahora puede disfrutar del streaming de IPTV utilizando Smarters IPTV en su dispositivo Windows.",
  de: "Nach der Konfiguration können Sie nun das IPTV-Streaming mit Smarters IPTV auf Ihrem Windows-Gerät genießen.",
  it: "Una volta configurato, puoi ora goderti lo streaming IPTV usando Smarters IPTV sul tuo dispositivo Windows.",
  tr: "Yapılandırıldıktan sonra, Windows cihazınızda Smarters IPTV kullanarak IPTV yayınlarının keyfini çıkarabilirsiniz.",
  ru: "После настройки вы можете наслаждаться потоковым вещанием IPTV с помощью Smarters IPTV на своем устройстве под управлением Windows.",
  pl: "Po skonfigurowaniu możesz teraz cieszyć się streamingiem IPTV za pomocą Smarters IPTV na urządzeniu z systemem Windows.",
};
export const exploreChannelsAndContent = {
  en: "Explore the available channels and content offered by our IPTV service.",
  fr: "Explorez les chaînes disponibles et le contenu proposé par notre service IPTV.",
  es: "Explora los canales y contenido disponibles ofrecidos por nuestro servicio de IPTV.",
  de: "Erkunden Sie die verfügbaren Kanäle und Inhalte, die von unserem IPTV-Dienst angeboten werden.",
  it: "Esplora i canali e i contenuti disponibili offerti dal nostro servizio IPTV.",
  tr: "IPTV hizmetimiz tarafından sunulan mevcut kanalları ve içerikleri keşfedin.",
  ru: "Изучите доступные каналы и контент, предлагаемые нашим IPTV-сервисом.",
  pl: "Eksploruj dostępne kanały i treści oferowane przez naszą usługę IPTV.",
};
export const conclusion = {
  en: "Conclusion:",
  fr: "Conclusion :",
  es: "Conclusión:",
  de: "Fazit:",
  it: "Conclusione:",
  tr: "Sonuç:",
  ru: "Вывод:",
  pl: "Podsumowanie:",
};
export const congratulationsMessage = {
  en: "Congratulations! You have successfully installed Smarters IPTV on your Windows system. If you encounter any issues during the installation process or while using the application, feel free to reach out to our support team for assistance.",
  fr: "Félicitations ! Vous avez installé avec succès Smarters IPTV sur votre système Windows. Si vous rencontrez des problèmes lors du processus d'installation ou lors de l'utilisation de l'application, n'hésitez pas à contacter notre équipe d'assistance pour obtenir de l'aide.",
  es: "¡Felicidades! Ha instalado correctamente Smarters IPTV en su sistema Windows. Si encuentra algún problema durante el proceso de instalación o mientras utiliza la aplicación, no dude en ponerse en contacto con nuestro equipo de soporte para obtener ayuda.",
  de: "Herzlichen Glückwunsch! Sie haben Smarters IPTV erfolgreich auf Ihrem Windows-System installiert. Wenn Sie während des Installationsprozesses oder bei der Verwendung der Anwendung auf Probleme stoßen, können Sie sich gerne an unser Support-Team wenden.",
  it: "Congratulazioni! Hai installato con successo Smarters IPTV sul tuo sistema Windows. Se incontri problemi durante il processo di installazione o durante l'utilizzo dell'applicazione, non esitare a contattare il nostro team di supporto per assistenza.",
  tr: "Tebrikler! Smarters IPTV'yi Windows sistemine başarıyla yüklediniz. Kurulum sürecinde veya uygulamayı kullanırken herhangi bir sorunla karşılaşırsanız, yardım için destek ekibimize başvurmakta çekinmeyin.",
  ru: "Поздравляем! Вы успешно установили Smarters IPTV на своей системе Windows. Если у вас возникнут проблемы во время процесса установки или при использовании приложения, не стесняйтесь обращаться в нашу службу поддержки.",
  pl: "Gratulacje! Pomyślnie zainstalowano Smarters IPTV na systemie Windows. Jeśli napotkasz jakiekolwiek problemy podczas procesu instalacji lub podczas korzystania z aplikacji, skontaktuj się z naszym zespołem wsparcia.",
};
export const contactInformation = {
  en: "For further inquiries or support, please contact us via WhatsApp or Email.",
  fr: "Pour toute demande de renseignements ou de support, veuillez nous contacter via WhatsApp ou par e-mail.",
  es: "Para más consultas o soporte, contáctenos a través de WhatsApp o correo electrónico.",
  de: "Für weitere Fragen oder Unterstützung kontaktieren Sie uns bitte per WhatsApp oder E-Mail.",
  it: "Per ulteriori domande o supporto, contattaci tramite WhatsApp o Email.",
  tr: "Daha fazla soru veya destek için lütfen bize WhatsApp veya E-posta aracılığıyla ulaşın.",
  ru: "Для дополнительных вопросов или поддержки свяжитесь с нами через WhatsApp или электронную почту.",
  pl: "W celu uzyskania dalszych pytań lub wsparcia skontaktuj się z nami za pośrednictwem WhatsApp lub e-mail.",
};
