import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../Atoms";
import axios from "axios";

// Components imports
import Spinner from "../../app_comps/comps/Spinner";
import PasswordResetEmailSent from "../components/PasswordResetEmailSent";
// Images import
import google_icon from "../images/google_icon.svg";

import * as int from "../../paragraphs/reset_password";

function ResetPassword() {
  const [renderResetPasswordForm, setRenderResetPasswordForm] = useState(true);
  const [email, setEmail] = useState("");
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [submitted, setSubmitted] = useState();
  const [spinning, setSpinning] = useState(false);
  const emailInput = useRef();
  const submitButton = useRef();
  const [wrapperClass, setWrapperClass] = useState(
    "sign_up_wrapper sign_up_wrapper_entrance"
  );

  const handleEmailFieldError = () => {
    if (submitted && email == "") {
      // get our hand on the input using useRef and change its id
      emailInput.current.id = "error_input";

      return (
        <div className="auth_error prevent_select">
          {int.thisFieldIsRequired[displayLanguage]}
        </div>
      );
    }
  };

  // define a function to handle the form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // turn on the spinner showing progess
    setSpinning(true);
    // disable the submit btutton
    submitButton.current.disabled = true;
    // we set submitted to "true" to let handleErrorRender know that the use
    // submitted the form
    setSubmitted(true);
    // check if all the fields conditions were satisfied, if so, fire the HTTP request

    if (email != "") {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/update/resetpassword/`,
        data: {
          email: email,
          language: displayLanguage,
          // language: displayLanguage,
        },
      })
        .then((res) => {
          const accesToken = res.data.access;

          // we need to set the RenderResetPasswordForm to false in order to render the component that says
          // email with reset pass link has been sent to youe email box
          setRenderResetPasswordForm(false);
        })
        .catch((err) => {
          if (err.code == "ERR_NETWORK") {
            alert("your internet connexion is currently offline");
          }
          // if the request was unscuccessfull we will render the "network error" component
          setSpinning(false);
          submitButton.current.disabled = false;
        });
    } else {
      setSpinning(false);
      submitButton.current.disabled = false;
    }
  };

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("sign_up_wrapper");
  }, []);
  // will initially render the resert password form but after that the user send the request we will show him a message saying
  // if email is valid you would recieve an email to reset your password

  return (
    <>
      <div
        className={wrapperClass}
        style={{ display: renderResetPasswordForm ? "block" : "none" }}
      >
        <h1>{int.resetPassword[displayLanguage]}</h1>
        <p>{int.resetPasswordInstructions[displayLanguage]}</p>
        <form onSubmit={handleSubmit} className="form_wrapper">
          <label>{int.email[displayLanguage]}:</label>
          <input
            ref={emailInput}
            type="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              emailInput.current.id = "";
            }}
          />
          {handleEmailFieldError()}

          <button ref={submitButton} type="submit" className="submit_btn">
            {spinning ? (
              <Spinner size="small" />
            ) : (
              int.resetPassword[displayLanguage]
            )}
          </button>
        </form>
        <div className="redirect">
          <span>{int.didNotHaveAnAccount[displayLanguage]}</span>
          <Link to="/signup">{int.signUp[displayLanguage]}</Link>
        </div>
      </div>
      <div style={{ display: renderResetPasswordForm ? "none" : "block" }}>
        <PasswordResetEmailSent />
      </div>
    </>
  );
}

export default ResetPassword;
