// Recoil imports
import { RecoilRoot } from "recoil";

// import google OAuth
import { GoogleOAuthProvider } from "@react-oauth/google";
// React Router imports
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components imports
import LoginPage from "./authentication/screens/LoginPage";
import HomePage from "./app_comps/screens/HomePage";
import SignUpPage from "./authentication/screens/SignUpPage";
import NavBar from "./app_comps/comps/navbar/NavBar";
import SignUpCompleted from "./authentication/screens/SignUpCompleted";
import Initialization from "./Initialization";
import ScrollToTop from "./ScrollToTop";
import ResetPassword from "./authentication/screens/ResetPassword";
import ChangePassword from "./authentication/screens/ChangePassword";
import YouAreOffline from "./app_comps/screens/YouAreOffine";
import AccountSettings from "./app_comps/screens/AccountSettings";
import Footer from "./app_comps/comps/footer/Footer";
import MoviesSeriesPage from "./app_comps/screens/MoviesSeriesPage";
import KidzAnimePage from "./app_comps/screens/KidsAnimePage";
import SportPage from "./app_comps/screens/SportPage";
import DocumentariesPage from "./app_comps/screens/DocumentariesPage";
import Faq from "./app_comps/screens/Faq";
import PricingPage from "./app_comps/screens/PricingPage";
import PaymentPage from "./app_comps/screens/PaymentPage";
import AdminPanel from "./app_comps/screens/AdminPanel";
import LandingPage from "./app_comps/screens/LandingPage";
import ChannelsList from "./app_comps/screens/ChannelsList";
import TermsAndConditions from "./app_comps/screens/TermsAndConditions";
import AboutUs from "./app_comps/screens/AboutUs";
import RefundPolicy from "./app_comps/screens/RefundPolicy";
import InstallationGuide from "./app_comps/screens/InstallationGuide";
import SamsungGuide from "./app_comps/screens/SamsungGuide";
import WindowsGuide from "./app_comps/screens/WindowsGuide";
import NotFound from "./app_comps/screens/NotFound";
// Import css
import "./styles.css";
// GOOGLE ANALYTICS INTEGRATION
import ReactGA from "react-ga4";
const TRACKING_ID = "G-ERS553RWY8"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <GoogleOAuthProvider clientId="788807922859-g1ev6e89j32sli5pho8582up3l6n9jnd.apps.googleusercontent.com">
      <RecoilRoot>
        <Initialization />
        <Router>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route exact path="/" Component={HomePage} />
            <Route path="/login" Component={LoginPage} />
            <Route path="/signup" Component={SignUpPage} />
            <Route path="/signup_completed" Component={SignUpCompleted} />
            <Route path="/reset_password" Component={ResetPassword} />
            <Route path="/change_password/:token" Component={ChangePassword} />
            <Route path="/networkerror" Component={YouAreOffline} />
            <Route path="/account-settings" Component={AccountSettings} />
            <Route path="/movies-and-series" Component={MoviesSeriesPage} />
            <Route path="/kidz" Component={KidzAnimePage} />
            <Route path="/sport" Component={SportPage} />
            <Route path="/documentaries-page" Component={DocumentariesPage} />
            <Route path="/faq" Component={Faq} />
            <Route path="/pricing" Component={PricingPage} />
            <Route path="/process-order" Component={PaymentPage} />
            <Route path="/admin-panel" Component={AdminPanel} />
            <Route path="/landing" Component={LandingPage} />
            <Route path="/channels-list" Component={ChannelsList} />
            <Route path="/about-us" Component={AboutUs} />
            <Route path="/refund-policy" Component={RefundPolicy} />
            <Route
              path="/terms-and-conditions"
              Component={TermsAndConditions}
            />
            <Route path="/installation-guide" Component={InstallationGuide} />
            <Route
              path="/installation-guide/samsung-guide"
              Component={SamsungGuide}
            />
            <Route
              path="/installation-guide/windows-guide"
              Component={WindowsGuide}
            />
            <Route path="*" Component={NotFound} />
          </Routes>
          <Footer />
        </Router>
      </RecoilRoot>
    </GoogleOAuthProvider>
  );
}

export default App;
