import { useState, useEffect } from "react";
// Recoil imports
import { useRecoilValue } from "recoil";
import { displayLanguageAtom } from "../../../Atoms";

// import components
import HighlightedChannels from "./HighlightedChannels";

// import images
// import resolutions_icons from "../../images/home_page/resolutions_icons.svg";
// import CSS
import "../../styles/home_page.css";

// import paragraphs
import * as int from "../../../paragraphs/home_page_paragraphs.js";

function HomePageHero() {
  const displayLanguage = useRecoilValue(displayLanguageAtom);
  const [wrapperClass, setWrapperClass] = useState(
    "account_settings_wrapper account_settings_entrance"
  );

  // use useEffect to change the wrapper classes on when the component mounts
  useEffect(() => {
    // change the wrapper classes on the components mount
    setWrapperClass("animation_wrapper");
  }, []);

  return (
    <div
      className={`single-slider single-slider-hero homepagehero_wrapper ${wrapperClass}`}
    >
      <div className="second_half">
        {/* <img
          src={resolutions_icons}
          alt="resolutions_icons"
          className="resolutions_icons"
        /> */}

        <h1>{int.accessChannels[displayLanguage]}</h1>
        <h2>{int.subscriptionPlans[displayLanguage]}</h2>

        <HighlightedChannels region={displayLanguage} />
      </div>
    </div>
  );
}

export default HomePageHero;
